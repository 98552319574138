/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Service */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const DonateSection = ({ sectionContent, region, lang }) => {
  return (
    <>
      <Box
        maxW="1030px"
        p={{ base: '20px 25px', md: '48px 60px 40px 60px' }}
        bg="#F1EBDF"
        mx={{ base: '20px', xl: 'auto' }}
        mt={{ base: '100px', md: '150px' }}
      >
        {searchByFieldId(sectionContent, 'donate-title') && (
          <Text
            color="#433F3C"
            fontFamily="'FedraSansStd-medium'"
            fontSize="24px"
          >
            {searchByFieldId(sectionContent, 'donate-title').text}
          </Text>
        )}
        {searchByFieldId(sectionContent, 'donate-text') && (
          <StructuredTextParser
            mt="15px"
            str={render(searchByFieldId(sectionContent, 'donate-text').body)}
            fontFamily="'FedraSansStd-book'"
            fontSize="18px"
            maxW="1000px !important"
            className="paLink"
            mb="0px"
          />
        )}
      </Box>
      <style global jsx>
        {`
          .paLink p {
            padding-bottom: 8px;
          }
          .paLink p a {
            color: #ce6113;
            text-decoration: underline;
          }
          .paLink p a:hover {
            color: #ce6113;
            text-decoration: none;
          }
        `}
      </style>
    </>
  );
};
export default DonateSection;
