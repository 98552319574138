import { Box, Circle, Flex, Heading } from '@chakra-ui/react';

const HowItWorksSection = ({ sectionContent }) => {
  const titleText = sectionContent[0].titleText;
  const cards = sectionContent[1].cards.map(
    c => c.description.value.document.children[0].children[0].value
  );
  return (
    <Box margin="25px auto" fontFamily="FedraSansStd-book">
      <Heading as="h2" textAlign={'center'} mb={10}>
        {titleText}
      </Heading>
      <Flex
        flexWrap="wrap"
        mt={{ base: '30px' }}
        mb="30px"
        gridRowGap={{ base: '20px', md: '50px' }}
        gridColumnGap={{ base: '50px', lg: '20px' }}
        w="100%"
        justifyContent={{ base: 'center' }}
      >
        {cards.map((card, index) => (
          <>
            <Box
              flexBasis={'100%'}
              height={0}
              display={{
                base: 'block',
                md: index === 2 ? 'block' : 'none',
                lg: 'none',
              }}
            ></Box>
            <Box key={index}>
              <Circle
                size="68px"
                bg="#E7A019"
                color="white"
                fontWeight={'bold'}
                fontSize={'26px'}
                marginBottom={'-34px'}
                marginLeft={'20px'}
                position={'relative'}
              >
                {index + 1}
              </Circle>
              <Box
                bg="#F1EBDF"
                padding={'50px 20px 10px 0px'}
                width={'249px'}
                height="164px"
              >
                <Box
                  height="90px"
                  pl={'13px'}
                  pt="6px"
                  borderLeft={'6px solid #A81D28'}
                  lineHeight="22px"
                  fontSize="18px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {card}
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Flex>
    </Box>
  );
};

export default HowItWorksSection;
