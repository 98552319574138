/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SingleLineText from '@components/UI/SingleLineText';
import { JoyfulPeopleSt } from '@ieo/Common/CompLangConfig';

/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';

const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);
const ReactPlayer = dynamic(() => import('react-player'));

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} Research Intro
 */
const JoyfulPeopleCorporates = ({
  sectionContent,
  region,
  lang,
  pageData,
  pageName,
}) => {
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }
  const [autoplay, setAutoPlay] = useState(false);
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const scrollDiv = document.getElementById('vid-play');
    const inViewPort = isVisInViewport(scrollDiv);

    if (inViewPort) {
      setAutoPlay(true);
    } else {
      setAutoPlay(false);
    }
  };

  const router = useRouter();
  const changeUi = router.asPath.indexOf('inner-engineering-pc') > 0;
  const youthOffer =
    router.asPath.indexOf('inner-engineering/offer/under-25s') > 0;

  const yoColorArray = ['#247FC1', '#247FC1', '#247FC1', '#247FC1', '#F37021'];

  // console.log('pageName=>', pageName);

  return (
    <Box
      bg={
        pageConfigVal?.joyfulPeople?.bgImage
          ? `url(${pageConfigVal?.joyfulPeople?.bgImage?.desktop}) #F1EBDF`
          : `#F1EBDF`
      }
      bgRepeat={
        pageConfigVal?.joyfulPeople?.bgImage?.repeat === true
          ? 'repeat-x'
          : 'no-repeat'
      }
      bgPos="bottom"
      w="100%"
      pos="relative"
      _after={{
        content: "' '",
        position: 'absolute',
        right: '0',
        background: `url(https://static.sadhguru.org/d/46272/1721696474-group-1171277708.jpg) top right no-repeat`,
        height: { base: 143, lg: 355 },
        width: { base: 320, lg: 700 },
        bgSize: { base: '70%', md: '90%' },
      }}
    >
      <Box
        w="100%"
        h="100%"
        maxW={'1030px'}
        mx="auto"
        fontSize="70px"
        pos="relative"
        id="research-intro"
        pt={{ base: changeUi ? '55px' : '0', md: '0' }}
      >
        <Box
          backgroundPosition="top"
          bgSize="cover"
          height={{ base: '100%', md: '407px', lg: '475px' }}
          top="0"
          width="100%"
          backgroundRepeat="no-repeat"
          position="absolute"
          zIndex="-1"
        ></Box>
        <Box
          maxW={{
            base: '100%',
            sm: 'calc(100% - 66px)',
            xl: '1030px',
          }}
          w="100%"
          px={{ base: '0px', md: '0px' }}
          ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
          mr={{ base: '0px', sm: 'auto', xl: 'auto' }}
        >
          <Flex
            mx="auto"
            w="100%"
            alignItems={{ base: 'start', xl: 'center' }}
            flexWrap="wrap"
            // flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
            px={{ base: '16px', md: '10px', lg: '0' }}
            pt={{ base: '50px', md: '30px', lg: '80px' }}
            pb={{ base: '20px', md: 0 }}
          >
            <Box width="100%">
              <Flex
                flexDir={{
                  base:
                    lang == 'hi' && pageName == 'yo'
                      ? 'column-reverse'
                      : 'column',
                  xl: lang == 'hi' && pageName == 'yo' ? 'row-reverse' : 'row',
                }}
                height={
                  sectionContent[1]?._modelApiKey === 'json_table'
                    ? { base: '90px', md: '140px', xl: 'auto' }
                    : 'auto'
                }
                width="100%"
              >
                <SingleLineText
                  my="0"
                  mt={{ base: '0', lg: '9px' }}
                  mb={{ base: '15px', lg: '9px' }}
                  sectionContent={findField('take-charge')}
                  region={region}
                  lang={lang}
                  {...JoyfulPeopleSt[lang][
                    youthOffer ? 'YoSLBlueTextV3' : 'SLBlueTextV3'
                  ]}
                />

                <Box className="wrapper">
                  {sectionContent[1]?._modelApiKey === 'json_table' && (
                    <Box className="slidingVertical" pl="5px">
                      {Object.keys(sectionContent[1]?.data)?.map(
                        (item, index) => {
                          return (
                            <Box
                              as="span"
                              key={index}
                              fontSize={{ base: '26px', md: '52px' }}
                              color={yoColorArray[index]}
                              position="absolute"
                              height={{ base: '50px', md: 'auto' }}
                              textAlign="left"
                              minW={{ base: '270px', xl: '550px' }}
                              left={{ base: '0', xl: '-30px' }}
                              fontFamily="FedraSansStd-medium"
                              {...JoyfulPeopleSt[lang].HindiAnimation}
                            >
                              {sectionContent[1]?.data[item]}
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  )}
                </Box>
                <style jsx global>
                  {`
                    .wrapper {
                      padding: 0px 40px;
                      position: relative;
                      width: 70%;
                    }

                    .slidingVertical {
                      display: inline;
                      text-indent: 0px;
                    }

                    .slidingVertical span {
                      animation: bottomToTop 12.5s linear infinite 0s;
                      opacity: 0;
                      overflow: hidden;
                      position: absolute;
                    }

                    .slidingVertical span:nth-child(2) {
                      animation-delay: 2.5s;
                    }

                    .slidingVertical span:nth-child(3) {
                      animation-delay: 5s;
                    }

                    .slidingVertical span:nth-child(4) {
                      animation-delay: 7.5s;
                    }

                    .slidingVertical span:nth-child(5) {
                      animation-delay: 10s;
                    }

                    @keyframes bottomToTop {
                      0% {
                        opacity: 0;
                      }
                      5% {
                        opacity: 0;
                        transform: translateY(50px);
                      }
                      10% {
                        opacity: 1;
                        transform: translateY(0px);
                      }
                      25% {
                        opacity: 1;
                        transform: translateY(0px);
                      }
                      30% {
                        opacity: 0;
                        transform: translateY(-50px);
                      }
                      80% {
                        opacity: 0;
                      }
                      100% {
                        opacity: 0;
                      }
                    }
                    @media (min-width: 768px) and (max-width: 1199px) {
                      .wrapper {
                        width: 100%;
                      }
                      .slidingVertical {
                        line-height: 42px;
                      }
                    }
                    @media (max-width: 767px) {
                      .slidingVertical {
                        line-height: 18px;
                      }
                    }
                  `}
                </style>
              </Flex>
            </Box>
          </Flex>
          <Flex
            flexDir={{ base: 'column-reverse', md: 'row' }}
            pos="relative"
            top={{ base: '0', md: '50px' }}
            zIndex="9"
            pb={{ base: '2.5rem', md:'0'}}
          >
            <ResearchVidCard
              maxWidth={{ base: '100%', md: '476px', lg: '780px' }}
              width={{ base: '100%', md: '780px' }}
              height={{
                base: '183px',
                md: '268px !important',
                lg: '440px !important',
              }}
              id="vid-play"
            >
              <ReactPlayer
                url={
                  'https://iso-videos-assets.s3.ap-south-1.amazonaws.com/videos/WhatsApp+Video+2023-09-22+at+17.03.17.mp4' ||
                  sectionContent[2]?.video?.url
                }
                playing={autoplay}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                autoPlay={true}
                height="100%"
                objectFit="cover"
              />
              <style global jsx>
                {`
                  #vid-play video {
                    object-fit: cover;
                  }
                `}
              </style>
            </ResearchVidCard>
            <Box>
              <Flex
                flexDir={{ base: 'column-reverse', md: 'column' }}
                mt={{ base: '26px', md: '10px', lg: '55px' }}
                pl={{ base: '16px', md: '30px' }}
                pr={{ base: '16px', md: '0px' }}
                mb={{ base: '26px', md: '0' }}
                alignItems="start"
              >
                <SingleLineText
                  sectionContent={findField('joyful-people-title')}
                  my="0"
                  // mx="auto"
                  // mb={{ base: '9px', md: '0' }}
                  maxW={{ base: '100%', md: '255px', lg: '300px' }}
                  {...JoyfulPeopleSt[lang]?.ReInHeading}
                  color="#3B444B"
                />

                <SingleLineText
                  sectionContent={findField('research-shows-decs')}
                  my="0"
                  mt={{ base: '9px', md: '11px', lg: '15px' }}
                  mx="auto"
                  mb={{ base: '9px', md: '0' }}
                  maxW={{ base: '100%', md: '168px', lg: '292px' }}
                  {...JoyfulPeopleSt[lang]?.GrayText}
                />
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default JoyfulPeopleCorporates;
