/* Built In Imports */
import { usePathname } from 'next/navigation';
import { useRef, useState } from 'react';
/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { searchByFieldId } from '@components/Utility/Shared/SharedService';
import config from '@config';
import SingleImage from '@ieo/Components/SingleImage';
/* Configs */
import { ProgramSupportSt, ResearchShowsSt } from '@ieo/Common/CompLangConfig';

/* Styles */

/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const ResearchShowsV3 = ({ sectionContent, region, lang }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const [ind, setIndex] = useState(-1);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const btnRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  const pathname = usePathname();
  const isPageURLmatch = pathname.includes('/offer/under-25s');

  return (
    <Box
      w="100%"
      mx="auto"
      px={{ base: '16px', sm: '0' }}
      py={{ base: '40px', md: '60px', lg: '80px' }}
    >
      <Box
        maxW={{
          base: '100%',
          sm: 'calc(100% - 66px)',
          xl: '1120px',
          '2xl': '1120px',
        }}
        w="100%"
        ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
        mt={{ base: '0', sm: '40px' }}
        mr={{ base: '0', sm: 'auto', xl: 'auto' }}
      >
        <Box
          borderBottom={{ base: '1px solid #CF915E', lg: 'none' }}
          pb="15px"
          display={{ base: isPageURLmatch ? 'none' : 'flex' }}
        >
          <Box maxW="480px" m="0 auto" w="100%">
            <Show below="lg">
              <Text
                fontFamily="FedraSansStd-medium"
                color="#3B444B"
                fontSize="32px"
                lineHeight="32px"
                pb="30px"
              >
                {findField('what-learn-text-mob')?.text}
              </Text>
              {findField('value-zigzag') &&
                findField('value-zigzag').cards?.map((list, index) => {
                  return index % 2 == 0 ? (
                    <Box
                      pb="20px"
                      display="flex"
                      alignItems="center"
                      gridGap="20px"
                      key={index}
                    >
                      <Image
                        src={list?.thumbnail?.url}
                        alt=""
                        borderRadius="50%"
                        w="86px"
                        h="86px"
                      />
                      <Box>
                        <Text
                          fontFamily="FedraSansStd-medium"
                          color="#12166f"
                          fontSize="18px"
                        >
                          {list.title}{' '}
                        </Text>
                        <Text
                          fontFamily="FedraSansStd-medium"
                          color="#777"
                          fontSize="16px"
                        >
                          {list.sessions}{' '}
                        </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      pb="20px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      gridGap="20px"
                      key={index}
                    >
                      <Box>
                        <Text
                          fontFamily="FedraSansStd-medium"
                          color="#12166f"
                          fontSize="18px"
                          textAlign="right"
                        >
                          {list.title}
                        </Text>
                        <Text
                          fontFamily="FedraSansStd-medium"
                          color="#777"
                          fontSize="16px"
                          textAlign="right"
                        >
                          {list.sessions}{' '}
                        </Text>
                      </Box>
                      <Image
                        src={list?.thumbnail?.url}
                        alt=""
                        borderRadius="50%"
                        w="86px"
                        h="86px"
                      />
                    </Box>
                  );
                })}
            </Show>
          </Box>
        </Box>

        <Flex
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          flexWrap="wrap"
          alignItems="center"
          height="100%"
        >
          <Box h="100%" w={{ base: '100%', md: '100%', xl: '40%' }}>
            <Flex
              flexDir="column"
              maxW={{ base: '100%', xl: 440 }}
              // flexWrap={{ base: 'nowrap', md: 'nowrap', lg: 'wrap' }}
              flexDirection={{
                base: 'column',
                md: 'row',
                lg: 'row',
                xl: 'column',
              }}
              w="100%"
              pt={{ base: '30px', lg: '0px' }}
              justifyContent="space-between"
              h="100%"
              display={{ base: isPageURLmatch ? 'flex' : 'none', lg: 'flex' }}
            >
              <Box
                maxW={{ base: '100%', md: '348px', lg: '388px' }}
                w="100%"
                pl={{ base: '0', md: '15px', lg: '0' }}
              >
                <StructuredTextParser
                  str={render(sectionContent[0]?.body)}
                  fontFamily="FedraSansStd-book"
                  className="ResearchShowText"
                  fontSize="22px"
                  lineHeight="28px"
                  {...ResearchShowsSt[lang]?.stText}
                  textAlign={lang === 'ar' ? 'right' : 'left'}
                />
              </Box>
              <style global jsx>
                {`
                  .ResearchShowText {
                    position: relative;
                  }
                  .ResearchShowText:before {
                    position: absolute;
                    content: '';
                    background: url(${
                      isPageURLmatch &&
                      'https://static.sadhguru.org/d/46272/1710584133-what-to-learn-bg-1.png'
                    });
                    background-position: center center;
                    background-size: cover;
                    width: 100%;
                    height: 220px;
                    background-size: 100%;
                    top: -90px;
                    background-repeat: no-repeat;
                    left: ${isPageURLmatch ? '-45px' : '0px'};
                  }
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                    font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                    font-weight: ${isPageURLmatch ? '600' : '500'};
                    font-size:${isPageURLmatch ? '30px' : '64px'};
                    line-height: ${isPageURLmatch ? '1.1em' : '64px'};
                    margin-bottom: 59px;
                    position: relative;
                    z-index: 2;
                    text-align: left};
                    margin: auto;
                    min-height: ${isPageURLmatch ? '145px' : 'auto'};
                    padding-bottom: ${isPageURLmatch ? '0px' : '20px'};

                    top: ${
                      isPageURLmatch ? (lang === 'hi' ? '0' : '-50px') : '-20px'
                    };
                  }

                  .ResearchShowText mark {
                    color: #282e8f;
                    font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                    background: none;
                  }
                  .ResearchShowText ul {
                    list-style-type: none;
                    padding-left: 32px;
                    max-width: 355px;
                  }
                  .ResearchShowText ul li {
                    position: relative;
                    margin-bottom: 20px;
                  }
                  .ResearchShowText ul li::before {
                    position: absolute;
                    content: '';
                    left: -30px;
                    top: 12px;
                    width: 21px;
                    height: 17px;
                    background: url(${
                      config.imgPath
                    }/d/46272/1706007377-44.png);
                  }
                  .ResearchShowText p {
                    color: #323232;
                    font-family: FedraSansStd-medium;
                    font-weight: normal;
                  }
                  .ResearchShowText p strong {
                    color: #12166f;
                    font-family: FedraSansStd-medium;
                    font-weight: normal;
                    display: block;
                  }

                  @media (max-width: 992px) and (min-width: 768px) {
                    .ResearchShowText:before {
                      top: -90px;
                      background: url(${
                        isPageURLmatch &&
                        'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                      });
                      background-repeat: no-repeat;
                      background-position: center center;
                      left: 0px;
                      width:360px
                    }
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      font-size: 25px;
                      line-height: 34px;
                      margin-bottom: ${isPageURLmatch ? '40px' : '0px'};
                      width: ${isPageURLmatch ? '240px' : '100%'};
                      top: ${isPageURLmatch ? '-20px' : 'unset'};
                    }
                  }
                  @media (max-width: 768px) and (min-width: 480px) {
                    .ResearchShowText:before {
                      top: -100px;
                      background: url(${
                        isPageURLmatch &&
                        'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                      });
                      background-repeat: no-repeat;
                      background-position: 50% center;
                      left: -50px;
                    }
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                      font-size: 24px;
                      line-height: 26px;
                      width: ${isPageURLmatch ? '240px' : 'auto'};
                      padding-bottom: 30px;
                      top: ${isPageURLmatch ? '-20px' : '0'};
                    }

                    .ResearchShowText p,
                    .ResearchShowText mark {
                      font-size: 18px;
                      line-height: 25px;
                    }
                  }
                  @media (max-width: 480px) {
                    .ResearchShowText:before {
                      top: -50px;
                      background: url(${
                        isPageURLmatch &&
                        'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                      });
                      background-repeat: no-repeat;
                      background-position: center center;
                      height: 131px;
                      background-size: contain;
                      margin: auto;
                      width: 130%;
                      left: -20px;
                    }
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                      font-size: 18px;
                      line-height: 24px;
                      width: ${isPageURLmatch ? '190px' : ''};
                      margin: ${isPageURLmatch ? 'auto' : '0'};
                      padding-bottom: ${isPageURLmatch ? '30px' : '0'};
                      top: ${isPageURLmatch && '-15px'};
                    }
                    .ResearchShowText p,
                    .ResearchShowText mark {
                      font-size: 18px;
                      line-height: 25px;
                    }
                  }
                `}
              </style>

              <SingleImage
                px={{ base: '0', md: '30px', lg: '0' }}
                w={{ base: 'auto', md: '90%', lg: '100%' }}
                maxW={{ base: '100%', md: '350px', lg: '394px' }}
                // ml={{ base: '0', md: 'auto', lg: '0' }}
                marginRight={{ base: '0' }}
                ml={{ base: '0', lg: 'auto', xl: '0' }}
                marginTop={{ base: '30px', md: '85px', lg: '40px' }}
                h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                sectionContent={sectionContent[1]}
              />
            </Flex>
          </Box>

          <Flex
            flexDir="column"
            w={{ base: '100%', xl: '60%' }}
            alignItems="start"
            justifyContent={{ base: 'start', md: 'center', xl: 'start' }}
            mt={{ base: '40px', md: '50px', xl: '0' }}
            maxW={{ base: '100%', lg: '524px', xl: '640px' }}
            mx="auto"
          >
            <Heading
              as="h3"
              color="#12166F"
              fontFamily={ResearchShowsSt[lang]?.heading?.fontFamily}
              fontSize={{ base: '20px', md: '26px', lg: '26px' }}
              fontStyle="normal"
              fontWeight={isIndian ? '600' : 'normal'}
              lineHeight={{ base: '25.4px', md: '27.16px', lg: '25.22px' }}
              w={{
                base: lang === 'ar' ? '94%' : 'auto',
                md: lang === 'ar' ? '94%' : '100%',
                xl: lang === 'ar' ? '94%' : 'auto',
              }}
              mt={{ lg: isPageURLmatch ? '0' : '40px' }}
              textAlign={{
                base: lang === 'ar' ? 'right' : 'left',
                md: lang === 'ar' ? 'right' : 'left',
                lg: lang === 'ar' ? 'right' : 'left',
              }}
            >
              {sectionContent[2]?.titleText}
            </Heading>
            {searchByFieldId(sectionContent, 'research-shows-subtitle') &&
              isPageURLmatch && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'research-shows-subtitle')
                      .body
                  )}
                  region={region}
                  lang={lang}
                  fontSize={{ base: '18px', lg: '22px' }}
                  fontFamily="'FedraSansStd-book'"
                  color="#A81D28"
                  pt="8px"
                />
              )}
            {/* <SingleImage
              sectionContent={findField('research-shows-logo-one')}
              px={{ base: '0', md: '30px', lg: '0' }}
              w={{ base: 'auto', md: '90%', lg: '100%' }}
              maxW={{ base: '100%', md: '350px', lg: '394px' }}
              // ml={{ base: '0', md: 'auto', lg: '0' }}
              mr={0}
              ml={0}
              marginTop={{ base: '30px', md: '85px', lg: '40px' }}
              h={{ base: 'auto', md: 'auto', lg: 'auto' }}
            /> */}
            {!isPageURLmatch && (
              <Flex>
                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  w={{ base: 'auto', md: '90%', lg: 'auto' }}
                  maxW={{ base: '100%', md: '350px', lg: '394px' }}
                  // ml={{ base: '0', md: 'auto', lg: '0' }}
                  mr={0}
                  ml={0}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-two')}
                  mb="0"
                  maxH="40px"
                />

                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  mb="0"
                  w={{ base: 'auto', md: '90%', lg: '100%' }}
                  maxW={{ base: '100%', md: '350px', lg: '394px' }}
                  // ml={{ base: '0', md: 'auto', lg: '0' }}
                  marginRight={{ base: '0', sm: '0', md: 'auto' }}
                  ml={{ base: '0', sm: '0', md: 'auto' }}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-three')}
                />
              </Flex>
            )}
            <Flex
              flexWrap="wrap"
              mt={{ base: '50px', md: '43px', lg: '53px' }}
              gridRowGap={{ base: '0', sm: '50px' }}
              gridColumnGap="20px"
              w="100%"
              justifyContent={{
                base: lang === 'ar' ? 'flex-end' : 'flex-start',
                xl: lang === 'ar' ? 'flex-end' : 'flex-start',
              }}
              alignItems={{ base: 'center', lg: 'start' }}
            >
              {sectionContent[3]?.cards?.map((card, index) => {
                return (
                  <Flex
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    w={{ base: '100%', sm: '318px', md: '250px' }}
                    h={{ base: 'auto', sm: 'auto', md: '150px' }}
                    key={nanoid(4)}
                    mb={{ base: '45px', sm: '0' }}
                    onClick={() => {
                      isMobile && setIndex(index);
                      isMobile &&
                        setIsOpenMobile(
                          index === ind && isOpenMobile ? false : true
                        );
                    }}
                  >
                    <Box
                      h="100%"
                      position="relative"
                      w="100%"
                      // maxW={{ base: '100%', md: '100%', lg: '318px' }}
                      bg="#F1EBDF"
                      display="flex"
                      justifyContent="space-between"
                      flexDir="column"
                    >
                      <Box
                        // maxW={lang === 'ar' ? '100%' : '100px'}
                        alignSelf={lang === 'ar' ? 'flex-end' : 'auto'}
                        width={{ base: '60px', md: '68px' }}
                        height={{ base: '60px', md: '68px' }}
                        position="absolute"
                        top={{ base: '-30px', md: '-34px' }}
                        left={{ base: '26px', md: '20px' }}
                      >
                        <SingleImage
                          my="0"
                          sectionContent={card?.image}
                          mx="0"
                          px="0"
                          py="0"
                          sync={true}
                          w={{ base: '60px', md: '68px' }}
                          h={{ base: '60px', md: '68px' }}
                        />
                      </Box>
                      <Flex
                        w="100%"
                        borderLeft={{
                          base: lang === 'ar' ? 'none' : '7px solid #A81D28',
                          sm: lang === 'ar' ? 'none' : '6px solid #A81D28',
                        }}
                        borderRight={{
                          base: lang === 'ar' ? '7px solid #A81D28' : 'none',
                          sm: lang === 'ar' ? '6px solid #A81D28' : 'none',
                        }}
                        h={{ base: '60%', sm: '58%' }}
                        mt={{ base: '35px', md: 'auto' }}
                        mb="10px"
                        pb="20px"
                        alignItems="center"
                        justifyContent="center"
                        fontFamily="FedraSansStd-book"
                        flexDir="column"
                        px="20px"
                      >
                        <Flex
                          w="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <StructuredTextParser
                            {...ResearchShowsSt[lang].popUpTitle}
                            mt="0"
                            // ml={{ base: '55px' }}
                            str={render(card?.image?.description?.value)}
                            id="ResearchCardText"
                            noOfLines={{ base: null, md: 3 }}
                          />
                          <style global jsx>
                            {`
                              #ResearchCardText p {
                                color: #423e3b;
                                font-family: FedraSansStd-book;
                              }
                              #ResearchCardText p strong {
                                color: #a81d28;
                                font-family: FedraSansStd-medium;
                                font-weight: normal;
                              }
                            `}
                          </style>
                          <Box
                            onClick={() => {
                              setIndex(index), setIsOpenMobile(true);
                            }}
                            display={{
                              base:
                                index === ind && isOpenMobile
                                  ? 'none'
                                  : 'block',
                              md: 'none',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                            >
                              <path
                                d="M18.4167 13L13 13M13 13L7.58332 13M13 13L13 7.58337M13 13L13 18.4167"
                                stroke="#CE6113"
                                stroke-width="3"
                                stroke-linecap="square"
                              />
                            </svg>
                          </Box>
                        </Flex>
                        <Show breakpoint="(max-width: 767px)">
                          <Flex
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            transition="all .4s linear"
                          >
                            <Collapse
                              in={index === ind && isOpenMobile}
                              animateOpacity
                            >
                              <StructuredTextParser
                                fontSize="16px"
                                lineHeight="20px"
                                // display={{
                                //   base: index === ind ? 'block' : 'none',
                                //   md: 'block',
                                // }}
                                {...ProgramSupportSt[lang].stText}
                                mt="0"
                                // {...ResearchShowsSt[lang].cardText}
                                //  noOfLines={{base:2,md:2,lg:3}}
                                str={render(card?.description)}
                              />
                            </Collapse>
                            <Box
                              onClick={event => {
                                event.stopPropagation();
                                setIsOpenMobile(false);
                                setIndex(index);
                              }}
                              alignSelf="flex-start"
                              display={{
                                base:
                                  index === ind && isOpenMobile
                                    ? 'block'
                                    : 'none',
                                md: 'none',
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                              >
                                <path
                                  d="M7.58334 13L18.4167 13"
                                  stroke="#CE6113"
                                  stroke-width="3"
                                  stroke-linecap="square"
                                />
                              </svg>
                            </Box>
                          </Flex>
                        </Show>
                      </Flex>
                    </Box>
                  </Flex>
                );
              })}

              <Show breakpoint="(min-width: 768px)">
                {lang !== 'ar' && (
                  <Flex
                    w={{ base: '100%', md: '240px' }}
                    h={{ base: '70px', md: '150px' }}
                    // display="inline-flex"
                    alignItems={{ base: 'unset', md: 'center' }}
                    justifyContent="center"
                    mt={{ base: '20px', md: '0' }}
                  >
                    <span onClick={onOpen}>
                      {/* 
                <NextLink
                // href={refineUrlDomain( sectionContent[4].linkUrl, region, lang)}
                 onClick={onOpen}
                 legacyBehavior
                > */}
                      <Text
                        fontFamily={ResearchShowsSt[lang]?.heading?.fontFamily}
                        fontWeight={isIndian ? 550 : 450}
                        ref={btnRef}
                        style={sectionContent[4]}
                        textAlign="center"
                        color="#F37021"
                        cursor="pointer"
                        fontSize="18px"
                        _hover={{
                          textDecoration: 'underline',
                          color: '#BC570F',
                        }}
                      >
                        {lang === 'ar' ? (
                          <>
                            <ChevronLeftIcon fontSize="24px" />{' '}
                            {findField('research-shows-popup')?.buttonText}
                          </>
                        ) : (
                          <>
                            {findField('research-shows-popup')?.buttonText}
                            <ChevronRightIcon fontSize="24px" />
                          </>
                        )}
                      </Text>
                      {/* </NextLink> */}
                    </span>
                  </Flex>
                )}
              </Show>
            </Flex>
            {isPageURLmatch && (
              <Flex mt={{ base: '10px', md: '20px' }}>
                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  w={{ base: 'auto', md: '90%', lg: 'auto' }}
                  maxW={{ base: '100%', md: '350px', lg: '394px' }}
                  // ml={{ base: '0', md: 'auto', lg: '0' }}
                  mr={0}
                  ml={0}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-two')}
                  mb="0"
                  maxH="40px"
                />

                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  mb="0"
                  w={{ base: 'auto', md: '90%', lg: '100%' }}
                  maxW={{ base: '100%', md: '350px', lg: '394px' }}
                  // ml={{ base: '0', md: 'auto', lg: '0' }}
                  marginRight={{ base: '0', sm: '0', md: 'auto' }}
                  ml={{ base: '0', sm: '0', md: 'auto' }}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-three')}
                />
              </Flex>
            )}

            {/* <Flex
              flexWrap={{ base: 'wrap', sm: 'wrap', md: 'wrap' }}
              justifyContent={{ base: 'space-between', md: 'center' }}
              alignItems={{
                base: "stretch", md: "center"
              }}
              alignContent='stretch'
              // h={{ base: '120px', lg: '80px' }}
              my={{ base: '0px', md: '50px', lg: '35px' }}
              w="100%"
              mx={{ base: "auto", md: "0" }}
              mb={{ base: '30px', md: '0' }}
              gridColumnGap="23px"
              gridRowGap="12px"
              maxW={{ base: "95%", sm: "100%", md: "100%" }}
              css={{ '-webkit-grid-column-gap': "23px", "-webkit-row-gap": "12px" }}
            // borderBottom={{ base: '1px solid #CF915E', md: 'none' }}
            >
              <Box w={{ base: '100%', sm: 'auto', md: '30%' }}>
                <SingleImage
                  sectionContent={sectionContent[5]}
                  h="100%"
                  px="0"
                  my={{ base: "10px", md: "0px" }}
                  w={{ base: "auto", md: "100%" }}
                  mx={{ base: '0', md: '0px' }}
                  // flex={1}
                  // w={{ base: '122px', sm: '122px', md: '145px', lg: '150px' }}
                />
              </Box>
              <Box w={{ base: '45%', sm: 'auto', md: '30%' }}>
                <SingleImage
                  sectionContent={sectionContent[6]}
                  h="auto"
                  px="0"
                  my="0px"
                  w={{ base: "auto", md: "100%" }}
                  mx={{ base: '0', md: '0px' }}
                // flex={1}
                // w={{ base: '122px', sm: '122px', md: '145px', lg: '150px' }}
                />
              </Box>
              <Box w={{ base: '45%', sm: 'auto', md: '30%' }}
                mx={{ base: 'auto', sm: "0", md: '0px' }}
              >
                <SingleImage
                  sectionContent={sectionContent[7]}
                  h="auto"
                  w={{ base: "auto", md: "100%" }}
                  px="0"
                  my={{ base: "0", sm: "0", md: "0px" }}
                  mx={{ base: 'auto', sm: "0", md: '0px' }}
                  // flex={1}
                  // w={{ base: '122px', sm: '122px', md: '145px', lg: '150px' }}
                />
              </Box>
            </Flex> */}
          </Flex>
        </Flex>
      </Box>
      <Modal
        w={{ md: '808px', base: '321px' }}
        h={{ md: '85vh', base: 'auto' }}
        // closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={useBreakpointValue({ base: 'full', md: '808px' })}
        finalFocusRef={btnRef}
        // scrollBehavior={scrollBehavior}
        background="#F1EBDF"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="0px"
          background="#F1EBDF"
          w="100%"
          maxW={{ base: '100%', md: '746px', lg: '808px' }}
          my={{ base: '0', md: '8px' }}
          h={{ base: '95%', md: '97%' }}
          overflow="hidden"
        >
          <ModalHeader mt={{ base: '13px', md: 'calc(1rem + 20px)' }} pt="0">
            <Heading
              as="h3"
              pt={{ base: '10px', md: 0 }}
              {...ResearchShowsSt[lang]?.h3}
            >
              {sectionContent[2]?.titleText}
            </Heading>
          </ModalHeader>
          <ModalCloseButton
            mt="10px"
            color="E86F34"
            top={{ base: 1, md: 5 }}
            background="transparent"
            _hover={{
              background: 'transparent',
              boxShadow: 'none',
            }}
            _active={{
              background: 'transparent',
              boxShadow: 'none',
            }}
            _focus={{
              background: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19 20.4143L12 13.4143L4.99997 20.4143L3.58576 19.0001L10.5858 12.0001L3.58576 5.00009L4.99997 3.58588L12 10.5859L19 3.58588L20.4142 5.00009L13.4142 12.0001L20.4142 19.0001L19 20.4143Z"
                  fill="#E86F34"
                />
              </svg>
            </Box>
          </ModalCloseButton>
          <ModalBody pr={{ base: '12px', md: 6 }} pl={{ base: '32px', md: 6 }}>
            <Box pb={{ base: '0', md: '0', lg: '30px' }} background="#F1EBDF">
              <Flex
                flexWrap="wrap"
                w="100%"
                justifyContent={{ base: 'start', lg: 'start' }}
                alignItems={{ base: 'start', lg: 'start' }}
                maxH={{ base: '85vh', md: '80vh' }}
                h="100%"
                overflowY="auto"
                pr={{ base: '16px', md: '16px' }}
                className="customScrollbar"
              >
                {sectionContent[3]?.cards?.map((card, index) => {
                  return (
                    <Box
                      w="100%"
                      key={nanoid()}
                      borderBottom={{
                        base:
                          index === sectionContent[3].cards.length - 1
                            ? '0px solid'
                            : 'solid 1px #C5BFB1',
                        md: 'none',
                      }}
                    >
                      <Flex
                        m={{
                          base: '0 0px 0 0px',
                          md: '0 0px 0 10px',
                          lg: '0 0px 0 0px',
                        }}
                        p={{
                          base: '0 0px 0 0px',
                          md: '0 10px 0 0px',
                        }}
                        flexGrow="1"
                        justifyContent={{
                          base: 'center',
                          md: 'start',
                        }}
                        alignItems={{
                          base: 'center',
                          md: 'start',
                        }}
                      >
                        <Box w="100px" height="100%">
                          <LazyLoadImageComponent
                            objectFit="cover"
                            w="100%"
                            h="100%"
                            maxW="65px"
                            src={card.image?.image?.url}
                            alt={card.image?.image?.alt}
                            title={card.image?.image?.title}
                            mt="15px"
                          />
                        </Box>
                        <Box
                          borderBottom={{
                            base: 'none',
                            md: 'solid 1px #C5BFB1',
                          }}
                          w={{
                            base: 'calc(100% - 5%)',
                            md: 'calc(100% - 100px)',
                          }}
                        >
                          <StructuredTextParser
                            {...ResearchShowsSt[lang].popUpTitle}
                            // ml={{ base: '55px' }}
                            str={render(card?.image?.description?.value)}
                          />

                          <StructuredTextParser
                            display={{
                              base: 'none',
                              md: 'block',
                            }}
                            {...ResearchShowsSt[lang].popUpDesc}
                            pt="5px"
                            pb="20px"
                            str={render(card?.description.value)}
                          />
                        </Box>
                      </Flex>
                      <StructuredTextParser
                        display={{
                          base: 'block',
                          md: 'none',
                        }}
                        {...ResearchShowsSt[lang].popUpDesc}
                        pt="5px"
                        pb="10px"
                        str={render(card?.description.value)}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ResearchShowsV3;
