/* External Imports */
import { Box, Heading, Text } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
const ArmyOfferBanner = ({
  sectionContent,
  region,
  lang,
  prgData,
  pageData,
}) => {
  return (
    <Box
      bgImage={{
        base: `url(${sectionContent?.[3]?.mobileImage?.url})`,
        lg: `url(${sectionContent?.[3]?.image.url})`,
      }}
      bgSize="cover"
      textAlign="center"
      py={{ base: '100px', md: '60px' }}
      px={{ base: '20px', md: '30px' }}
    >
      <Box maxW="1000px" w="full" mx="auto">
        <Heading
          as="h2"
          color="#12166F"
          fontSize={{ base: '26px', md: '38px' }}
          fontFamily="'FedraSansStd-medium'"
          fontWeight="500"
          py={2}
        >
          {sectionContent?.[0]?.text}
        </Heading>
        <Text
          fontSize={{ base: '20px', md: '26px' }}
          color="#3B444B"
          fontFamily={{
            base: 'FedraSansStd-book',
            md: "'FedraSansStd-medium'",
          }}
        >
          {' '}
          {sectionContent?.[1]?.text}
        </Text>
        <StructuredTextParser
          mt="20px"
          str={render(sectionContent?.[2].body)}
          fontSize="20px"
          color="#3B444B"
          fontFamily="'FedraSansStd-book'"
          textAlign={'center'}
        />
      </Box>
    </Box>
  );
};
export default ArmyOfferBanner;
