import { useEffect, useState } from 'react';

const useCountdown = targetDate => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = countDown => {
  // calculate time left
  const days = resetAndPadZero(Math.floor(countDown / (1000 * 60 * 60 * 24)));
  const hours = resetAndPadZero(
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );
  const hourWithDays = resetAndPadZero(
    Math.floor(countDown / (1000 * 60 * 60))
  );
  const minutes = resetAndPadZero(
    Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  );
  const seconds = resetAndPadZero(Math.floor((countDown % (1000 * 60)) / 1000));

  // console.log('seconds', days, hours, hourWithDays, minutes, seconds);

  return [days, hours, minutes, seconds, hourWithDays];
};

const resetAndPadZero = num => {
  num = num > 0 ? num : 0;
  return num > 9 ? num : '0' + num;
};

export { useCountdown };
