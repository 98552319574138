/* Built In Imports */
/* External Imports */
import { Box, Flex, HStack, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { useCountdown } from '@msr/hooks/useCountDown';

/* Services */

export default function CountdownTimer({ targetDate, targetText }) {
  const [days, hours, minutes, seconds, hourWithDays] =
    useCountdown(targetDate);

  return (
    <Flex
      ml="14px"
      fontSize={{ base: '16px', md: '22px', lg: '26px', xl: '40px' }}
      color="#C07513"
      fontFamily="FedraSansStd-medium"
      lineHeight="1.2"
    >
      <HStack>
        <Text>{hourWithDays}H :</Text>
        <Text p="0" m="0"></Text>
      </HStack>
      <HStack ml={{ base: '-5px', md: '5px' }}>
        <Text>{minutes}M :</Text>
        <Text p="0" m="0"></Text>
      </HStack>
      <HStack ml={{ base: '-5px', md: '5px' }}>
        <Box display="inline" minW={{ base: '18px', lg: '50px' }}>
          {seconds}
        </Box>
        <Text p="0" m="0 !important">
          S
        </Text>
      </HStack>
    </Flex>
  );
}
