/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { AspectRatio, Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
import config from '@config';
/* Components */
import MediaEmbedV2 from '@components/Embeds/MediaEmbedV2';
import SingleLineText from '@components/UI/SingleLineText';
/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';

const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);
const ReactPlayer = dynamic(() => import('react-player'));

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} Research Intro
 */
const ShambaviMahamudraIYP = ({
  sectionContent,
  region,
  lang,
  pageData,
  pageName,
}) => {
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }
  const [autoplay, setAutoPlay] = useState(false);
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const scrollDiv = document.getElementById('vid-play');
    const inViewPort = isVisInViewport(scrollDiv);

    if (inViewPort) {
      setAutoPlay(true);
    } else {
      setAutoPlay(false);
    }
  };

  const router = useRouter();
  const changeUi = router.asPath.indexOf('inner-engineering-pc') > 0;
  const youthOffer =
    router.asPath.indexOf('inner-engineering/offer/under-25s') > 0;

  return (
    <Box
      minHeight={{ base: 'auto', md: '435px', xl:'380px' }}
      bg={`url(${config.imgPath}/d/46272/1694863168-testimonial-base.png) #00005B`}
      bgPos="top"
      w="100%"
      bgSize="cover"
    >
      <Box
        w="100%"
        h="100%"
        maxW={'1100px'}
        mx="auto"
        pos="relative"
        id="research-intro"
      >
        <Box
          maxW={{
            base: '100%',
            xl: '1130px',
          }}
          w={{ base: '90%', xl: '100%' }}
          px={{ base: '0px', md: '0px' }}
          ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
          mr={{ base: '0px', sm: 'auto' }}
        >
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            pos="relative"
            top={{ base: '0', lg: '80px' }}
            gap={{ base: '20px', lg: '50px' }}
            minH={{ base: '320px', lg: '400px' }}
            py={{ base: '0', md: '20px' }}
          >
            <AspectRatio
              w={{ base: '100vw', sm: '100%', md: '46%', xl: '640px' }}
              ratio={1.6}
            >
              <MediaEmbedV2
                sectionContent={sectionContent[2]}
                //sectionId="ieo-program-steps"
                lang={lang}
              />
            </AspectRatio>

            <Flex
              flexDir="column"
              mb={{ base: '26px', md: '0' }}
              pt={{ base: '0px', lg: '8px' }}
              px={{ base: '15px', lg: '0px' }}
            >
              <SingleLineText
                sectionContent={findField('research-shows-title')}
                my="0"
                maxW={{ base: '100%', md: '355px', lg: '400px' }}
                color="#fff"
                fontSize={{ base: '28px', md: '42px' }}
                lineHeight={{ base: '32px', md: '44px' }}
                fontWeight="600"
                mx="0px"
              />

              <SingleLineText
                sectionContent={findField('research-shows-decs')}
                my="0"
                mt={{ base: '9px', md: '11px', lg: '20px' }}
                mb={{ base: '9px', md: '0' }}
                maxW={{ base: '100%', md: '355px', lg: '390px' }}
                fontSize="18px"
                color="#fff"
                mx="0px"
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default ShambaviMahamudraIYP;
