/* Built In Imports */
/* External Imports */

import { Box, Flex, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { usePathname } from 'next/navigation';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Configs */
import { ProgramRequirementSt } from '@ieo/Common/CompLangConfig';
/* Styles */
/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {String} lang
 * @param {String} region
 * @returns {ReactElement} ProgramRequirement
 */
const ProgramRequirement = ({ sectionContent, lang, region }) => {
  const pathname = usePathname();
  const urlV3 = pathname === `/${region}/${lang}/inner-engineering-v3`;
  const url = pathname.includes('offer/under-25s');
  return (
    <Box
      bg={
        url
          ? 'url(https://static.sadhguru.org/d/46272/1710743888-1710584276-pbg1.jpg)'
          : '#F1EBDF'
      }
      bgRepeat={url && 'repeat'}
      w="100%"
      height="100%"
    >
      <Box
        px={{ base: '17px', md: '17px', lg: '10px', '2xl': 0 }}
        maxW={1050}
        mx="auto"
        w="100%"
        pt={{ base: urlV3 ? '0px' : '50px', xl: urlV3 ? '80px' : '50px' }}
        mb="60px"
      >
        <StructuredTextParser
          textAlign={sectionContent[0].style}
          str={render(sectionContent[0]?.body?.value?.document?.children[0])}
          fontSize={{ base: '20px', md: '26px' }}
          color="#231F20"
          mb="0"
          lineHeight="normal"
          sectionContent={sectionContent}
          {...ProgramRequirementSt[lang].heading}
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
        />

        <StructuredTextParser
          textAlign={sectionContent[0].style}
          str={render(sectionContent[0]?.body?.value?.document?.children[1])}
          color="#6D6358"
          sectionContent={sectionContent}
          fontSize={{ base: '16px', md: '20px', lg: '22px' }}
          lineHeight={{ base: '19.2px', md: '28px', lg: '20px' }}
          fontWeight="450"
          mb={{ base: '24px', md: '20px', lg: '40px' }}
          {...ProgramRequirementSt[lang].subHeading}
          maxW="fit-content !important"
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
        />
        <Flex
          flexWrap="wrap"
          w="100%"
          justify="center"
          mt={{ base: '20px', md: '24px', lg: '40px' }}
        >
          {sectionContent[1].cards.map(card => {
            return (
              <Box
                key={nanoid()}
                mb={{ base: '20px', md: '0' }}
                w={{ base: '100%', sm: '330px', md: '50%' }}
                p={{ base: '0', sm: '8px' }}
                h={{ base: 'auto', md: 'auto', lg: 'auto' }}
              >
                <Flex
                  flexDirection="column"
                  bg="#fff"
                  p={{
                    base: '24px 17px',
                    md: '32px 22px',
                    lg: '40px 40px 29px',
                  }}
                  h="100%"
                >
                  <Heading
                    mb={{ base: '16px', md: '20px', lg: '25px' }}
                    as="h3"
                    {...ProgramRequirementSt[lang].cardHeading}
                  >
                    {card?.title}
                  </Heading>
                  <StructuredTextParser
                    mt="0"
                    px="0"
                    className="reqCardIeo"
                    {...ProgramRequirementSt[lang].cardDesc}
                    str={render(card?.description?.value)}
                    sectionContent={card}
                    color="#6D6358"
                  />
                  <style>
                    {`
                      .reqCardIeo p{
                        margin-bottom:7px;
                      }
                      .reqCardIeo p a{
                    color:#282E8F;
                    text-decoration:underline;
                      }
                      `}
                  </style>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
};

export default ProgramRequirement;
