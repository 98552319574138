/* Built In Imports */

/* External Imports */
import { Box, Image, Show } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} Research Intro
 */
const ImprovementHealthList = ({ sectionContent }) => {
  return (
    <Box w="100%" bg="#F1EBDF">
      <Box
        w="100%"
        h="100%"
        maxW={'1130px'}
        mx="auto"
        fontSize="70px"
        pt={{ base: '35px', md: '130px' }}
        pb={{ base: '35px', md: '80px' }}
        px="15px"
        display="flex"
        gridGap={{ base: '20px', md: '40px' }}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Show above="sm">
          <Image src={sectionContent[0].image?.url} w="46%" />
          <Image src={sectionContent[1].image?.url} w="46%" />
        </Show>
        <Show below="sm">
          <Image src={sectionContent[0].mobileImage.url} w="100%" />
          <Image src={sectionContent[1].mobileImage.url} w="100%" />
        </Show>
      </Box>
    </Box>
  );
};

export default ImprovementHealthList;
