/* Built In Imports */
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import Loader from '@components/Utility/Loader';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import BannerImage from '@ieo/BannerImage';
import { useProgramLanguageSelection } from '@ieo/Components/Form/ieo/context/programLanguageSelectionContext';
import SingleImage from '@ieo/Components/SingleImage';

/* Services */
/* Config */
import { usePageType } from '@hooks/usePageType';
import { TopBannerSt } from '@ieo/Common/CompLangConfig';
import { gtmObject } from '@ieo/Components/Form/ieo/api/analyticsAPi';

/* Styles */

/**
 * @param {Object} sectionContent
 * @param {Object} pageData
 * @param {String} lang
 * @param {String} region
 * @returns
 */
const TopBannerIYP = ({
  sectionContent,
  region,
  lang,
  pageData,
  pageName,
  prgData,
}) => {
  // console.log('TopBanner', Object.values(prgData)[0], pageName);
  // console.log(sectionContent, 'top banner');
  const [isCatOpen, setCatIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [resultArr, setResultArr] = useState([]);
  const [isBannerActive, setIsBannerActive] = useState(true);
  const router = useRouter();
  const { setProgramLanguage } = useProgramLanguageSelection();
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const pathname = usePathname();
  const isV4 = pathname.indexOf('inner-engineering-v4') > -1;

  const programFeeLink = sectionContent?.find(
    e => e.fieldId === 'pc-program-fee'
  );

  const liveClassesIYP = sectionContent?.find(
    e => e.fieldId === 'live-classes-IYP'
  );

  const [descText, setDescText] = useState(sectionContent[2]);
  const [isLoader, setIsLoader] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const changeUi = router.asPath.indexOf('inner-engineering-pc') > 0;

  // setting countdown timer
  let countDownDate = new Date().setDate(21);
  countDownDate = new Date(countDownDate).setHours(0);
  countDownDate = new Date(countDownDate).setMinutes(0);
  countDownDate = new Date(countDownDate).setSeconds(0);

  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const { isGiftPage } = usePageType();

  useEffect(() => {
    setProgramLanguage(router.query.lang || lang);
    const langArr = [];
    config.orderedLang.forEach(language => {
      const newProgs = pageData?.alsoin?.filter(itm => {
        return (
          itm.url ===
          window?.location?.pathname.replace(`/${lang}/`, `/${language}/`)
        );
      });
      if (newProgs?.length > 0) {
        langArr.push(newProgs[0]);
      }
    });
    setResultArr(langArr);
  }, [isCatOpen, router.query.lang, router.asPath]);

  const RightIcon = ({ hover }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 9L12 17L20 9"
          stroke={hover ? '#BC570F' : '#F37021'}
          stroke-width="2.66667"
        />
      </svg>
    );
  };

  const gtmClick = () => {
    gtmObject({
      event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
      IE_LP_Location: 'Top_level_CTA',
      event1: 'add_to_wishlist',
      item_name: isGiftPage
        ? 'Gift Inner Engineering'
        : 'TYLS Inner Engineering',
      item_category4: 'Top_level_CTA',
    });
    gtmObject({
      event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
      IE_LP_Location: 'Top_level_CTA',
      event1: 'view_item',
      item_name: isGiftPage
        ? 'Gift Inner Engineering'
        : 'TYLS Inner Engineering',
      item_category4: 'Top_level_CTA',
    });
  };

  const smMob = useMediaQuery({ maxWidth: 359 });
  const buttonSt = changeUi ? 'uiChangeButtonText' : 'buttonText';
  const stStyle = changeUi ? 'uiChangeStText' : 'stText';
  const url = router.asPath.includes('under-25s');

  return (
    router.asPath && (
      <Box>
        <Box
          background={
            pageConfigVal?.topbanner?.bgImage?.desktop
              ? `url(${pageConfigVal?.topbanner?.bgImage?.desktop}) #00005B`
              : ` url(${config.imgPath}/d/46272/1700299128-ieo-bluebg-2-1.png) #00005B`
          }
          backgroundPosition="bottom"
          backgroundSize={{ base: 'cover', md: 'cover' }}
          position="relative"
        >
          <Box>
            <Box
              display={{ base: 'flex', md: 'block' }}
              flexDir={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              overflow="hidden"
              h={{ base: '97vh', sm: '100%', md: '100%' }}
              maxW="100%"
              py={{ base: '0', md: '50px' }}
              pt={{ base: '8px', md: '20px', lg: url ? '20px' : '100px' }}
              pos="relative"
              className="topBannerContainer"
              minH={
                changeUi
                  ? { base: 'auto', sm: 'auto', lg: '733px' }
                  : { base: 'auto', sm: 'auto', md: '733px' }
              }
            >
              {/* </Flex> */}
              <Box mx="auto" maxW="1130px" w="full">
                <Flex
                  mt={{
                    base: smMob ? '5px' : '31px',
                    md: 0,
                  }}
                  flexDirection="column"
                  maxW={{
                    base: isIndian ? '290px' : '270px',
                    sm: 'max(38%, 244px)',
                    md: 'max(60%, 244px)',
                    lg: '495px',
                  }}
                  textAlign="center"
                  alignItems="center"
                  justifyContent={{
                    base: 'center',
                    md: 'flex-start',
                    lg: 'center',
                  }}
                  mx={{ base: 'auto', md: '0' }}
                  style={sectionContent[4]}
                >
                  <SingleImage
                    mt={{ base: '0', md: '10px' }}
                    mb={{ base: '0px', md: '10px' }}
                    mx="auto"
                    className="textImage"
                    objectFit="contain"
                    alignSelf={{
                      base: 'center',
                      md: 'flex-start',
                      lg: 'center',
                    }}
                    sectionContent={sectionContent[0]}
                    sync="true"
                    alt=""
                  />
                  <Box color="#3B444B">
                    <Text
                      color="#020359"
                      fontSize={{ base: '28px', md: '60px' }}
                      lineHeight={{ base: '1.1em', md: '55px' }}
                      pb={{ base: '0', md: '20px' }}
                      fontWeight="700"
                    >
                      {' '}
                      {sectionContent?.[17]?.titleText}{' '}
                    </Text>

                    {liveClassesIYP && (
                      <Text
                        fontSize={{ base: '18px', md: '24px' }}
                        fontWeight="600"
                        pt="10px"
                        color="#fff"
                      >
                        {' '}
                        {liveClassesIYP?.text}
                      </Text>
                    )}
                  </Box>
                  {isLoader && (
                    <Box
                      w="100%"
                      mt="50px"
                      textAlign="center"
                      display={'block'}
                      minH="85px"
                    >
                      <Loader pos="relative" />
                    </Box>
                  )}
                  {!isLoader && descText && (
                    <StructuredTextParser
                      str={render(sectionContent[2]?.body)}
                      color="#fff"
                      alignSelf="center"
                      textAlign={{
                        base: 'center',
                        md: lang === 'ar' ? 'center' : 'left',
                        lg: 'center',
                      }}
                      fontWeight="400"
                      fontStyle="normal"
                      mb={{ base: '8px', md: '0' }}
                      ml={{ base: '0', md: '18px', lg: '0' }}
                      {...TopBannerSt[lang][stStyle]}
                    />
                  )}
                  {sectionContent[3].linkUrl != '' && (
                    <Box
                      display={{ base: 'none', md: 'flex' }}
                      flexDir={programFeeLink?.linkUrl && 'column'} // dir for ui update
                      h="auto"
                      alignSelf={{
                        base: 'center',

                        lg: 'center',
                      }}
                      to={
                        programFeeLink?.linkUrl.startWith('#')
                          ? programFeeLink?.linkUrl
                          : ''
                      }
                      spy={true}
                      smooth={true}
                      offset={50}
                      mx="auto"
                    >
                      <SimpleButton
                        // width={
                        //   isGiftPage
                        //     ? { base: '251px', md: '244px', lg: '295px' }
                        //     : { base: '215px', md: '184px', lg: '230px' }
                        // }
                        minW="230px"
                        background={
                          '#F37021 !important' || pageConfigVal?.btn?.bgColor
                        }
                        textAlign="center"
                        mb={programFeeLink?.linkUrl ? '0' : '5px'}
                        mx={{ base: 'auto', md: '0', lg: 'auto' }}
                        _hover={{
                          background:
                            '#BC570F !important' ||
                            pageConfigVal?.btn?.bgHoverColor,
                        }}
                        borderRadius="100px !important"
                        style={sectionContent[3]}
                        srcImg={pageData}
                        clickHandler={() => {
                          gtmClick();
                        }}
                        padding={{
                          base: '8px 7px',
                          md: pageConfigVal?.btn?.bgHoverColor
                            ? '10px 0'
                            : '10px 25px',
                          lg: pageConfigVal?.btn?.bgHoverColor
                            ? '12px 10px'
                            : '10px 29px',
                        }}
                        lineHeight={{
                          base: '1.5em',
                          md:
                            lang == 'hi' || lang == 'kn' || lang == 'te'
                              ? '50px'
                              : '37px',
                        }}
                        mt={{ base: '0', md: '30px', lg: '50px' }}
                        zIndex={10}
                        icon={pageConfigVal?.btn?.icon}
                        {...TopBannerSt[lang][buttonSt]}
                      />

                      {/*link to program fee section*/}
                      {programFeeLink?.linkUrl && (
                        <NextLink
                          legacyBehavior
                          href={refineUrlDomain(
                            programFeeLink?.linkUrl,
                            region,
                            lang
                          )}
                          passHref
                        >
                          <Link
                            w="100%"
                            mt={{ base: '0', md: '20px', lg: '20px' }}
                            color="#fff"
                            fontSize={{ base: '16px', md: '18px' }}
                            lineHeight={{ base: '21.44px', md: '25.2px' }}
                            fontFamily={
                              lang
                                ? config.langFont[lang]
                                : 'FedraSansStd-medium'
                            }
                            fontWeight="normal"
                            textAlign={{
                              base: 'center',
                              sm: 'left',
                              lg: 'center',
                            }}
                            ml={{
                              base: '0',
                              sm: '100px',
                              md: isGiftPage ? '15px' : '35px',
                              lg: '0',
                            }}
                            mb={{ base: '20px', md: '30px' }}
                            textDecor="underline"
                            cursor="pointer"
                          >
                            {programFeeLink?.buttonText}
                          </Link>
                        </NextLink>
                      )}
                    </Box>
                  )}
                </Flex>
              </Box>
              <Box
                pos={{ base: 'static', md: 'absolute' }}
                h={{
                  base: '43vh',
                  sm: 'auto',
                  md: '445px',
                  lg: '570px',
                  xl: '630px',
                }}
                top={{ base: 'unset', md: 'unset', lg: 'unset' }}
                bottom={{ base: '0', md: '-10px', lg: '0', xl: '0' }}
                right={{
                  base: '0',
                  md: '5px',
                  lg: '-10px',
                  xl: '-130px',
                  '2xl': '120px',
                }}
                w={{
                  base: '100%',
                  sm: 'auto',
                  md: '550px',
                  lg: '750px',
                  xl: '1030px',
                }}
                zIndex={15}
              >
                <BannerImage
                  px="0"
                  w={{ base: '100%', sm: 'auto', md: 'auto' }}
                  objectPosition="top"
                  mx={{ base: '0', sm: 'auto', md: '0' }}
                  className="sadhguruImage"
                  maxWidth="1030px"
                  height="full"
                  my="0"
                  sectionContent={sectionContent[1]}
                  sync="true"
                  alt=""
                />
              </Box>
              <style jsx global>
                {`
                  .textImage img {
                    margin-inline-start: auto;
                    margin-inline-end: auto;
                    object-fit: contain;
                  }
                  .thanksmessage {
                    font-family: FedraSansStd-book;
                    font-size: 18px;
                  }
                  .thanksmessage.mobile p strong {
                    color: #f37021 !important;
                  }
                  .sevenDays p mark {
                    font-family: FedraSansStd-book;
                    background: none;
                    font-size: smaller;
                  }
                  @media (min-width: 561px) and (max-width: 767px) {
                    .sadhguruImage {
                      margin: auto;
                      width: 100%;
                    }
                    .sadhguruImage img {
                      width: 100%;
                      object-fit: cover;
                    }
                  }
                  @media (min-width: 481px) and (max-width: 560px) {
                    .sadhguruImage {
                      margin: auto;
                      width: 100%;
                    }
                    .sadhguruImage img {
                      width: 100%;
                      object-fit: cover;
                    }
                  }

                  @media (min-width: 300px) and (max-width: 480px) {
                    .sadhguruImage {
                      margin: auto;
                      width: 100%;
                    }
                    .sadhguruImage img {
                      width: 100%;
                      object-fit: cover;
                    }
                  }

                   {
                    /* @media (max-width:359px) and (min-width:300px) {
                      .sadhguruImage img {
                        height: 250px !important;
                        text-align: center;
                        width: 100%;
                        max-width: 100%;
                        object-fit: cover;
                        object-position: top;
                      }
                      .textImage {
                        margin-bottom:10px;
                      }
                      .textImage img {
                        width:155px;
                        height:121px !important;
                        padding:0;
                        margin-top:0;
                      }
                  } */
                  }
                `}
              </style>
            </Box>
            {pageName !== 'ieomigrate' && (
              <Box position={'relative'}>
                <Box
                  display={{ base: 'block', md: 'none' }}
                  mx="auto"
                  pos={{ base: 'absolute', md: 'static' }}
                  bottom={{
                    base: changeUi ? '-50px' : '-20px',
                    sm: changeUi ? '-55px' : '-20px',
                  }}
                  left="50%"
                  w="100%"
                  transform={{ base: 'translateX(-50%)', md: 'none' }}
                  h="auto"
                  zIndex={150}
                >
                  <SimpleButton
                    width={
                      isGiftPage
                        ? { base: '300px', md: '324px' }
                        : { base: 'auto', md: '184px', lg: '210px' }
                    }
                    minW="230px"
                    background={
                      '#F37021 !important' ||
                      (pageConfigVal?.btn?.bgColor
                        ? pageConfigVal?.btn?.bgColor
                        : '#F37021 !important')
                    }
                    textAlign="center"
                    mb={{ base: '0', md: '30px' }}
                    mx="auto"
                    _hover={{
                      background:
                        '#BC570F !important' ||
                        pageConfigVal?.btn?.bgHoverColor,
                    }}
                    borderRadius="100px !important"
                    style={sectionContent[3]}
                    clickHandler={() => {
                      gtmClick();
                    }}
                    padding={{
                      base: '9px 10px',
                      md: '10px 25px',
                      lg: '10px 29px',
                    }}
                    mt={{ base: '0', md: '30px', lg: '50px' }}
                    my="0"
                    zIndex={100}
                    lineHeight="inherit"
                    icon={pageConfigVal?.btn?.icon}
                    {...TopBannerSt[lang][buttonSt]}
                  />
                  {changeUi && (
                    <NextLink
                      legacyBehavior
                      href={refineUrlDomain(
                        programFeeLink?.linkUrl,
                        region,
                        lang
                      )}
                      passHref
                    >
                      <Link
                        w="100%"
                        mt={{ base: '10px', md: '0', lg: '0' }}
                        fontSize={{ base: '16px', md: '18px' }}
                        lineHeight={{ base: '21.44px', md: '25.2px' }}
                        fontFamily={
                          lang ? config.langFont[lang] : 'FedraSansStd-medium'
                        }
                        textAlign={{
                          base: 'center',
                          sm: 'center',
                          lg: 'center',
                        }}
                        color={
                          pageConfigVal?.topbanner?.bottomAd ? '#fff' : '#000'
                        }
                        ml={{ base: '0', sm: '0', lg: '0' }}
                        textDecor="underline"
                        cursor="pointer"
                        display="block"
                      >
                        {programFeeLink?.buttonText}
                      </Link>
                    </NextLink>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
};

export default TopBannerIYP;
