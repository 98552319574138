/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Service */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const FinancialSupportSection = ({ sectionContent, region, lang }) => {
  return (
    <Box
      bgImage="https://static.sadhguru.org/d/46272/1694863180-program-fee.png"
      pt={{ base: '20px', md: '50px' }}
      pb={{ base: '10px', md: '20px' }}
    >
      <Box
        maxW="1030px"
        p={{ base: '35px 25px 20px', md: '48px 60px 35px 60px' }}
        bg="#fff"
        mx={{ base: '20px', xl: 'auto' }}
      >
        {searchByFieldId(sectionContent, 'financial-support-title') && (
          <Text
            color="#433F3C"
            fontFamily="'FedraSansStd-medium'"
            fontSize={{ base: '20px', md: '24px' }}
          >
            {searchByFieldId(sectionContent, 'financial-support-title').text}
          </Text>
        )}
        {searchByFieldId(sectionContent, 'financial-support-text') && (
          <StructuredTextParser
            my="15px"
            str={render(
              searchByFieldId(sectionContent, 'financial-support-text').body
            )}
            fontSize="18px"
            fontFamily="'FedraSansStd-book'"
            maxW="1000px !important"
            className="paLink"
          />
        )}
      </Box>
      <style global jsx>
        {`
          .paLink p {
            padding-bottom: 8px;
          }
          .paLink p a {
            color: #ce6113;
            text-decoration: underline;
          }
          .paLink p a:hover {
            color: #ce6113;
            text-decoration: none;
          }
        `}
      </style>
    </Box>
  );
};
export default FinancialSupportSection;
