/* Built In Imports */

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SingleImage from '@ieo/Components/SingleImage';

/* Configs */
import { ResearchShowsSt } from '@ieo/Common/CompLangConfig';

/* Styles */
/* Services */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';
/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const IeDescriptionV5 = ({ sectionContent, lang }) => {
  return (
    <Box
      w={{ base: '100%', sm: '450px', md: '100%' }}
      px={{ base: '16px', sm: '20px', md: '30px', xl: '0px' }}
      pt={{ base: '40px', md: '90px', lg: '160px' }}
      pb={{ base: '40px', md: '120px' }}
      mx="auto"
    >
      <Box
        maxW={{
          base: '100%',
          xl: 1050,
          '2xl': '1036px',
        }}
        w="100%"
        mx="auto"
      >
        <Box
          display="flex"
          gap={{ base: '30px', lg: '60px' }}
          alignItems="flex-start"
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
        >
          <Box maxW="485px" position="relative">
            {' '}
            {searchByFieldId(sectionContent, 'ie-heading-v5') && (
              <Text
                lineHeight="65px"
                fontFamily="FedraSansStd-medium"
                fontSize={{ base: '32px', md: '64px' }}
                color="#3B444B"
                mb={{ base: '10px', md: '30px', xl: '50px' }}
              >
                {searchByFieldId(sectionContent, 'ie-heading-v5').text}
              </Text>
            )}
            <StructuredTextParser
              str={render(sectionContent[1]?.body)}
              fontFamily="FedraSansStd-book"
              className="innerEngineeringText"
              fontSize="18px"
              lineHeight={{ base: '30px', xl: '27px' }}
              textAlign={lang === 'ar' ? 'right' : 'left'}
              mt={{ base: '10px', lg: '30px' }}
              color="#6D6358"
            />
          </Box>
          <Box>
            <SingleImage maxW="456px" sectionContent={sectionContent[2]} />
          </Box>
        </Box>
      </Box>

      <style global jsx>
        {`
          .innerEngineeringText p:last-child {
            padding-top: 20px;
          }
          .innerEngineeringText mark {
            color: #282e8f;
            font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
            background: none;
          }
        `}
      </style>
    </Box>
  );
};

export default IeDescriptionV5;
