/*External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { QuotesSt } from '@ieo/Common/CompLangConfig';

/*Internal Imports */

/**
 * @param {Object} sectionContent
 *  @param {String} lang
 * @param {String} region
 * @returns QuoteSection
 */
const QuotesSection = ({ sectionContent, lang, region, marginTop }) => {
  return (
    <Box
      bg="black"
      bgImage={{
        base: sectionContent[0].mobileImage.url,
        md: sectionContent[0].image.url,
        lg: 'https://static.sadhguru.org/d/46272/1700888237-1700841826051.jpg',
      }}
      bgSize={{ base: '100% auto', sm: 'auto 319px', md: 'cover' }}
      bgRepeat="no-repeat"
      bgPos={{ base: 'top', md: 'center' }}
      px={{ base: '1rem', md: '47px', xl: '175px', '2xl': '0px' }}
      // mt={{ md: marginTop || '1rem', xl: marginTop || '2rem' }}
    >
      <Flex
        align="center"
        h={{ base: '532px', md: '319px', xl: '570px' }}
        pb={{ base: '41.7px', md: 'initial' }}
        w="full"
        justify="flex-end"
        flexDir={{ base: 'column', md: 'row' }}
        maxW="1030px"
        mx="auto"
      >
        <Flex
          flexDir="column"
          maxW={{ base: '288px', md: '283px', xl: '450px' }}
          color="white"
          align="center"
        >
          <Box
            width={{ base: '33px', md: '27px', xl: '44px' }}
            height={{ base: '27px', md: '22px', xl: '36px' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 44 36"
              fill="none"
            >
              <path
                d="M43.3333 35.4285H23.8333V34.3452V15.7738C23.8333 10.2141 26.1517 6.46868 28.4823 4.12776C29.6408 2.96406 30.7986 2.14984 31.67 1.62466C32.6532 1.03208 33.6047 0.669131 34.6667 0.242432V15.9285L43.3333 15.9285V35.4285Z"
                fill="#CDAE7C"
              />
              <path
                d="M19.5 35.4285H0V34.3452V15.7738C0 10.2141 2.31841 6.46868 4.64893 4.12776C5.80746 2.96406 6.96523 2.14984 7.83664 1.62466C8.81989 1.03208 9.77133 0.669131 10.8333 0.242432V15.9285L19.5 15.9285V35.4285Z"
                fill="#CDAE7C"
              />
            </svg>
          </Box>

          <Text
            textAlign={{ base: 'center' }}
            lineHeight={{ base: '23.4px', md: '26px', xl: '33.8px' }}
            mt="25.5px"
            fontFamily="FedraSansStd-book"
            fontWeight="450"
            {...QuotesSt[lang].h3}
          >
            {sectionContent[1].quote}
          </Text>
          <Box>
            <Image
              alt="sadhguru_sign"
              maxW={{ base: '120px', xl: '200px' }}
              src="/assets/ieo/signature.png"
              mt={{ base: '30px', md: '31px' }}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default QuotesSection;
