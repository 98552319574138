/* Built In Imports */
import { usePathname } from 'next/navigation';
/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ProgramStructureSt } from '@ieo/Common/CompLangConfig';
import ProgramStructureCardV3 from '@ieo/Components/Cards/ProgramStructureCardV3';

/* Services */
/* Styles */

/**
 *
 * @param {Object} SectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} ProgramStructure
 */

const ProgramStructureV5 = ({
  sectionContent,
  region,
  lang,
  pageName,
  pageData,
}) => {
  const path = usePathname();
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const duration = sectionContent.find(
    el => el.fieldId === 'ieo-program-structure-duration'
  );
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  return (
    <Box
      bg={`url(${findField('step-prgm-corporate-bg')?.image?.url
        }) top right no-repeat #F1EBDF`}
      bgSize={{ base: '70%', md: '50%', xl: '40%' }}
    >
      <Flex
        maxW={1030}
        pt={{ base: '50px', lg: '90px' }}
        pb={{ base: '16px', md: '41px' }}
        margin={'0 auto'}
      >
        <Box w="full" pb="44.7px">
          <Flex
            alignItems={{ sm: 'center' }}
            borderBottom={{ base: 'none', md: '1px solid #CF915E' }}
            flexDir={{ base: 'column', sm: 'row' }}
            justify="space-between"
            px={{ base: '1rem', xl: '0px' }}
          >
            <Box
              as="h1"
              lineHeight={{ base: '42px', lg: 'initial' }}
              pb={{ base: '18px', md: '26px' }}
              {...ProgramStructureSt[lang]?.h2}
            >
              {sectionContent?.[0]?.titleText}
            </Box>
            <Flex
              flexShrink="0"
              alignItems={{
                base: lang === 'ar' ? 'flex-end' : 'flex-start',
                sm: 'flex-start',
              }}
              flexDir="column"
              bg="#12166F"
              alignSelf="flex-end"
              p="14px 19px "
              w={{ base: '100%', sm: '290px' }}
              mb={{ base: '20px', sm: '49px', xl: '39px' }}
              maxW={{ base: '100%', sm: 'max-content' }}
              pos="relative"
            >
              <StructuredTextParser
                str={render(findField('right-side-msg').body)}
                fontWeight="500"
                color="#fff"
                {...ProgramStructureSt[lang]?.h6}
                className="colorText"
                mt="0px"
              />

              <Box color="#fff" pt="5px" fontFamily="FedraSansStd-book-i">
                {' '}
                {findField('ieo-program-structure-duration-decs')?.text}
              </Box>
              <Flex
                alignItems="center"
                justify="center"
                p="5px"
                bg="white"
                w="41px"
                h="41px"
                borderRadius="50%"
                pos="absolute"
                right="-20px"
                top="25%"
                display="none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19 3.16675C10.2555 3.16675 3.16663 10.2556 3.16663 19.0001C3.16663 27.7446 10.2555 34.8334 19 34.8334C27.7445 34.8334 34.8333 27.7446 34.8333 19.0001C34.8333 10.2556 27.7445 3.16675 19 3.16675ZM15.8333 16.2292H20.1874L20.1875 26.9167L17.8125 26.9167L17.8124 18.6042H15.8333V16.2292ZM17.8125 13.8542V11.4792H20.1875V13.8542H17.8125Z"
                    fill="#3652B8"
                  />
                </svg>
              </Flex>
            </Flex>
            <Box
              borderBottom="1px solid #CF915E"
              display={{ md: 'none' }}
            ></Box>
          </Flex>
          <Box mt="34px">
            <Text
              as="h4"
              textAlign={{
                base: 'center',
                md:
                  path.indexOf('inner-engineering-v2') > -1
                    ? 'right'
                    : 'center',
              }}
              {...ProgramStructureSt[lang]?.h4}
            >
              {findField('ieo-program-structure-steps')?.text}
            </Text>

            <Text
              as="h4"
              textAlign="center"
              {...ProgramStructureSt[lang]?.h4}
              color="#A67411"
              pt="2px"
              pb="20px"
              display="flex"
              alignItems="center"
              gap="10px"
              justifyContent="center"
              fontSize={{ base: '16px', md: '20px' }}
            >
              <Image
                w={{ base: '20px', md: '24px' }}
                src={sectionContent[5]?.image?.url}
                alt=""
              />{' '}
              {findField('ie-duration-1')?.text}
            </Text>

            <Flex
              gridRowGap={{ base: '0', sm: '40px' }}
              gridColumnGap={{ base: '0', sm: '15px' }}
              flexWrap="wrap"
              alignItems="center"
              mt={{ base: '0', md: '14.4px' }}
              justify="center"
            >
              {sectionContent[7]?.cards?.map(card => {
                return (
                  <ProgramStructureCardV3
                    key={nanoid(4)}
                    lang={lang}
                    data={card}
                  />
                );
              })}
            </Flex>
          </Box>
        </Box>
      </Flex>
      <style>
        {`
        .colorText p code  {
          font-family: 'FedraSansStd-medium';
          color:#FFC90E;
        }
        `}
      </style>
    </Box>
  );
};

export default ProgramStructureV5;
