/* Built In Imports */
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

/* External Imports */
import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import MediaEmbedV2 from '@components/Embeds/MediaEmbedV2';
import ProgramStepsSt from '@ieo/Common/StyleData/ProgramStepsSt';

const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);

/**
 *
 * @param {Object} sectionContent
 * @param {String} lang
 * @returns {ReactElement} ProgramSteps
 */
const ProgramStepsV3 = ({ sectionContent, lang, pageName }) => {
  // console.log('steps', sectionContent, pageName);
  const path = usePathname();
  const under25Url = path.includes('under-25s');
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      w="full"
      bg={
        under25Url
          ? 'url(https://static.sadhguru.org/d/46272/1710743888-1710584276-pbg1.jpg) repeat'
          : '#F1EBDF'
      }
      h="100%"
      mb={pageName === 'ieomigrate' ? { base: '70px', md: '120px' } : '0px'}
      backgroundRepeat={under25Url && 'repeat'}
    >
      <Flex
        flexDir="column"
        maxW={1030}
        pb={{ base: '0px', xl: '41px' }}
        alignItems="center"
        w="full"
        justify="center"
        gridGap="12px"
        px={{ sm: '1.5rem', lg: '0' }}
        maxH={{ xl: under25Url ? '550px' : 'auto' }}
        mx="auto"
      >
        {under25Url && (
          <Box
            lineHeight={{ base: '30px', md: '39px' }}
            maxW={{ base: '268px', md: '919px' }}
            display={{ md: 'block', base: under25Url && 'none' }}
            height="100%"
            p="30px 40px"
            my="2.5rem"
            textAlign={{
              base: 'center',
              md:
                path.indexOf('inner-engineering-v2') > -1 ? 'right' : 'center',
            }}
            backgroundImage={`https://static.sadhguru.org/d/46272/1710580683-orange-watercolor-white-copy-2.png`}
            backgroundSize="100%"
            backgroundPosition="center"
            {...ProgramStepsSt[lang].h4}
            w="100%"
            color={under25Url ? '#fff' : '#12166F'}
          >
            {sectionContent[0].text}
          </Box>
        )}
        {!under25Url && (
          <Text
            lineHeight={{ base: '30px', md: '39px' }}
            maxW={{ base: '268px', md: '743px' }}
            textAlign={{
              base: 'center',
              md:
                path.indexOf('inner-engineering-v2') > -1 ? 'right' : 'center',
            }}
            color={'#12166F'}
            {...ProgramStepsSt[lang].h4}
          >
            {sectionContent[0].text}
          </Text>
        )}

        <Flex
          // bgImage={{
          //   base: sectionContent[5]?.mobileImage?.url,
          //   sm: sectionContent[5]?.image?.url,
          // }}
          bgRepeat="no-repeat"
          bgSize="cover"
          pos="relative"
          align={{ base: 'center', lg: 'initial' }}
          flexDir={{ base: 'column', lg: 'row' }}
          w="100%"
          minH={{ base: '450px', lg: '300px' }}
          p={{ base: '20px 20px 0 20px', md: '0' }}
          gridGap={{ lg: '37px' }}
          // boxShadow={{ md: '0 12px 14px -10px  #A45B0B' }}
          mb={{ base: '0px', sm: '25px', md: '75px', lg: '0' }}
        >
          <Flex
            maxW={{ lg: '380px', xl: '395px' }}
            flexDir="column"
            gridGap="12px"
            color="white"
          >
            <Box>
              {isMobile ? (
                <Box>
                  <Text mb="8px" {...ProgramStepsSt[lang].h5}>
                    {' '}
                    {sectionContent[1]?.text}
                  </Text>
                  <Text
                    fontFamily="FedraSansStd-medium"
                    color="#231F20"
                    fontSize={{ base: '20px', md: '22px' }}
                    mb="20px"
                  >
                    {' '}
                    {sectionContent[2]?.text}
                  </Text>
                </Box>
              ) : (
                <Box>
                  <Text
                    color="#F37021"
                    fontFamily="FedraSansStd-medium"
                    fontSize={{ base: '28px', md: '32px' }}
                  >
                    {sectionContent[1]?.text}
                  </Text>
                  <Text
                    mb="20px"
                    color="#231F20"
                    fontFamily="FedraSansStd-medium"
                    fontSize={{ base: '20px', md: '22px' }}
                  >
                    {sectionContent[2]?.text}
                  </Text>
                </Box>
              )}

              <Box
                fontFamily="FedraSansStd-medium"
                bgColor="#fff"
                p="16px"
                display="flex"
                gridGap="10px"
                boxShadow="0px 10px 10px #cbc7bb"
              >
                {sectionContent[3]?.cards?.map((cardItem, i) => (
                  <Box
                    key={'_ki1' + i}
                    borderLeft={i == 1 ? '1px solid #C6B399' : ''}
                    pl={i == 1 && '20px'}
                    maxW={{
                      base:
                        sectionContent[3]?.cards.length <= 1 ? '100%' : '50%',
                      md: '100%',
                    }}
                  >
                    <Box
                      bg="#CE6113"
                      display="inline-flex"
                      pl="8px"
                      pr="14px"
                      py="4px"
                    >
                      {' '}
                      <Image
                        src="https://static.sadhguru.org/d/46272/1706002328-live-signal.svg"
                        alt=""
                        pr="6px"
                        w="24px"
                        h="25px"
                      />{' '}
                      <Box
                        as="span"
                        fontSize="12px"
                        display="flex"
                        alignItems="center"
                      >
                        {cardItem?.buttonText}
                      </Box>
                    </Box>
                    <Text
                      mt="10px"
                      color="#7A6E4B"
                      fontSize={{ base: '12px', md: '14px' }}
                    >
                      {cardItem?.title}
                    </Text>
                    <Text
                      color="#7A6E4B"
                      fontSize={{ base: '12px', md: '14px' }}
                      fontWeight="400"
                      fontFamily="FedraSansStd-book"
                    >
                      {cardItem?.buttonLink}
                    </Text>
                    <Text mt="6px" color="#B49168" fontSize="12px">
                      <StructuredText
                        data={cardItem?.description?.value?.document}
                      />
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>

            <Text className="datoText" {...ProgramStepsSt[lang].p}>
              <StructuredText data={sectionContent[2]?.body?.value?.document} />
            </Text>
          </Flex>

          <ResearchVidCard>
            <AspectRatio
              w={{ base: '87vw', sm: '400px', md: '516.9px' }}
              ratio={1.6}
            >
              <MediaEmbedV2
                sectionContent={sectionContent[4]}
                sectionId="ieo-program-steps"
                lang={lang}
              />
            </AspectRatio>
          </ResearchVidCard>
        </Flex>
      </Flex>
      <style jsx global>{`
        .datoText {
          display: flex;
          flex-direction: column;
          gap: 13px;
        }
      `}</style>
    </Box>
  );
};

export default ProgramStepsV3;
