/* Built In Imports */

/* External Imports */
import { Box, Flex, Heading } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { ProgramSupportSt } from '@ieo/Common/CompLangConfig';
import ProgramSupportCard from '@ieo/Components/Cards/ProgramSupportCard';

/* Services */
/* Styles */

/**
 *
 * @param {Object} sectionContent
 *  @param {String} lang
 *  @param {String} region
 * @returns {ReactElement} ProgramSupport
 */
const ProgramSupportV3 = ({ sectionContent, region, lang }) => {
  return (
    <Box
      bg={`url(${sectionContent[2]?.image?.url}) top right no-repeat`}
      position="relative"
      bgSize={{ base: '70%', md: '50%', xl: '40%' }}
      _before={{
        content: "''",
        background: `url(${sectionContent[3]?.image?.url}) bottom left no-repeat`,
        position: 'absolute',
        width: '100%',
        height: 400,
        bottom: { base: '-60px', md: '-100px' },
        bgSize: { base: '70%', md: '50%', xl: '40%' },
      }}
    >
      <Box
        maxW={1030}
        w="100%"
        mx="auto"
        pos="relative"
        mt={sectionContent[2]?.image?.url ? '0px' : '45px'}
        pt={sectionContent[2]?.image?.url && '45px'}
      >
        <Box
          pos="absolute"
          top={{ base: 'unset', md: 'unset', lg: '8px' }}
          bottom={{ base: 'unset', md: '-32px', lg: 'unset' }}
          w={{ base: '0', md: '181px', lg: '158px' }}
          right="0"
          h={{ md: '315px', lg: '356px' }}
        >
          <Box display={{ base: 'block', xl: 'none' }}>
            {' '}
            <LazyLoadImageComponent
              src="https://static.sadhguru.org/d/46272/1694600647-26-3.png"
              alt="floral design"
              h="100%"
              w="100%"
            />{' '}
          </Box>
        </Box>
        <Flex
          as="section"
          w="full"
          // mt={{ md: '100px', xl: '146px' }}
          mb={{ base: '60px', md: '100px' }}
          flexDir="column"
          mx="auto"
          px={{ base: '16px', xl: '0px' }}
          pt={{ md: '60px' }}
        >
          <Heading
            as="h2"
            maxW={{ base: lang === 'ar' ? '100%' : '288px', sm: 'initial' }}
            {...ProgramSupportSt[lang].h2}
            p={{ base: '0 20px 26px 0', lg: '0 0 40px 0' }}
            lineHeight={{ base: '36.8px', xl: '64px' }}
          >
            {sectionContent?.[0]?.text}
          </Heading>
          <Flex
            flexWrap="wrap"
            justifyContent="start"
            gridColumnGap={{ base: '38px', md: '38px' }}
            gridRowGap={{ base: '20px', md: '40px' }}
            css={{
              '-webkit-grid-row-gap': '25px !important',
              '-webkit-grid-column-gap': '38px',
            }}
          >
            {sectionContent[1]?.cards?.map((card, index) => {
              return (
                <ProgramSupportCard
                  card={card}
                  lang={lang}
                  region={region}
                  key={nanoid()}
                  flexBasis={index === 1 ? '60%' : 'auto'}
                ></ProgramSupportCard>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProgramSupportV3;
