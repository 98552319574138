/* Built In Imports */

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import NextLink from 'next/link';

/* Services */
/* Config */

const AlreadyPartneredIECorporate = ({ sectionContent, lang }) => {
  return (
    <Box
      position="relative"
      background={`url(${sectionContent[1].image?.url}) #fff`}
      textAlign={'center'}
      p={{ base: '0.5rem', md: '5rem' }}
      bgRepeat="no-repeat"
      bgPos="top right"
      mt="-80px"
      bgSize={{ base: '90%', md: '40%' }}
      _before={{
        content: "''",
        background: `url(${sectionContent[2]?.image?.url}) bottom left no-repeat`,
        position: 'absolute',
        width: '100%',
        height: 400,
        bottom: '0px',
        left: 0,
        bgSize: { base: '70%', md: '40%' },
      }}
    >
      <Box
        maxW="950px"
        w="full"
        mx="auto"
        pt="50px"
        pb="50px"
        position="relative"
        zIndex={2}
      >
        <StructuredTextParser
          fontFamily={'FedraSansStd-medium'}
          str={render(sectionContent[0]?.body)}
          color={'#3B444B'}
          fontSize={{ base: '20px', md: '32px' }}
          lineHeight={{ base: '26px', md: '42px' }}
          fontWeight={'500'}
          textAlign={'center'}
          px={{ base: 0, md: '30px', xl: '100px' }}
          mx="auto"
          py="2rem"
          pb={{ base: '40px', lg: '60px' }}
        />

        <NextLink href={sectionContent[3]?.linkUrl} passHref legacyBehavior>
          <Link
            fontFamily={'FedraSansStd-book'}
            color={{ base: '#fff' }}
            fontWeight={500}
            fontSize={{ base: '16px', md: '18px' }}
            borderRadius="50px"
            textAlign="center"
            bg="#3652B8"
            value="login"
            borderWidth="0"
            padding={{ base: '12px 60px', lg: '14px 90px' }}
            target="_blank"
          >
            {sectionContent[3]?.buttonText}
          </Link>
        </NextLink>
      </Box>
    </Box>
  );
};

export default AlreadyPartneredIECorporate;
