/* Built In Imports */
import { useEffect, useState } from 'react';
/* External Imports */
import { TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Image,
  Input,
  Link,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { sha256 } from 'js-sha256';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { RiCloseCircleLine } from 'react-icons/ri';
import * as Yup from 'yup';

/* Internal Imports */
import { render } from 'datocms-structured-text-to-html-string';
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';
import {
  generateOTP,
  initSession,
  postRefreshCaptcha,
  verifyOtp,
} from 'services/ieoService';

/* Styles */

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns AadhaarCardVerify
 */
const AadhaarCardVerify = ({
  sectionContent,
  region,
  lang,
  context,
  setContext,
}) => {
  const [initialSession, setInitialSession] = useState(null);
  const [otpData, setOtpData] = useState(null);
  const [aadhaarDetail, setAadhaarDetail] = useState(null);
  const [error, setError] = useState(false);
  const [step1Vis, setStep1Vis] = useState(true);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const timerSeconds = 90;
  const [otpTimer, setOtpTimer] = useState(timerSeconds);
  const [otpTimerBoxVis, setStpTimerBoxVis] = useState(true);
  const [startTimer, setStartTimer] = useState(false);
  const msgs = {
    otpSent: 'OTP sent to your mobile number linked to your Aadhaar',
  };
  const router = useRouter();
  const searchParams = useSearchParams();

  const ValidationSchema = Yup.object().shape({
    aadhaarNumber: Yup.string()
      .min(12, 'Aadhaar number is required')
      .required('Aadhaar number is required'),
    captcha: Yup.string()
      .min(5, 'Captcha must be at least 5 characters')
      .max(5, 'Please enter a valid captcha')
      .required('This field is required'),
  });

  const ValidationSchema2 = Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required')
      .min(6, 'OTP must be 6 digits'),
  });

  const initializeSession = async getObj => {
    setInitLoading(true);
    try {
      const res = await initSession(getObj);
      setInitLoading(false);
      setInitialSession(res);
      // console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const reloadCaptcha = async form => {
    try {
      setSpin(true);
      const {
        data: { captcha },
      } = await postRefreshCaptcha({
        consent: true,
        purpose: 'Youth Offer',
        sessionId: initialSession.data?.session_id,
      });

      // console.log('captcha', captcha, initialSession);
      // Update captcha in initialSession without mutating it directly
      setInitialSession(prevSession => ({
        ...prevSession,
        data: {
          ...prevSession.data,
          captcha: captcha,
        },
      }));

      // console.log('captcha11', form, initialSession);
      // form.setFieldValue('captcha', '');
      // form.setTouched('captcha', false);

      // console.log('captcha2', form, initialSession);
    } catch (error) {
      console.error('Error refreshing captcha:', error);
    } finally {
      setSpin(false);
    }
  };

  const generateOtpData = async values => {
    setLoading(true);
    getOTP(values);
  };

  const getOTP = async ({ aadhaarNumber, captcha }) => {
    let aadhaarNumberData = aadhaarNumber;

    const requestData = {
      consent: true,
      purpose: 'Youth Offer',
      sessionId: initialSession.data?.session_id,
      aadhaarNumber: aadhaarNumberData,
      captcha,
    };

    try {
      setError(false);
      const otpResponse = await generateOTP(requestData);
      setOtpData(otpResponse);
      setInitialSession(prevSession => ({
        ...prevSession,
        data: {
          ...prevSession.data,
          aadhaarNumber: aadhaarNumberData,
          captchaVal: captcha,
        },
      }));

      if (otpResponse?.status) {
        setStep1Vis(false);
        setStartTimer(true);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error generating OTP:', error);
      setError(true);
      setLoading(false);
    }
  };

  const resendOtp = () => {
    getOTP({
      aadhaarNumber: initialSession.data?.aadhaarNumber,
      captcha: initialSession.data?.captchaVal,
    });
    setStartTimer(true);
    setOtpTimer(timerSeconds);

    setAadhaarDetail({
      status: false,
      message: '',
      data: null,
    });
  };

  const verifyOtpData = async ({ otp }) => {
    setLoading(true);
    setStartTimer(false);
    setOtpTimer(0);
    setStpTimerBoxVis(false);

    setAadhaarDetail({
      status: false,
      message: '',
      data: null,
    });

    const otpReq = {
      consent: true,
      purpose: 'Youth Offer',
      sessionId: initialSession.data?.session_id,
      kycId: sha256(otpData?.data?.kycId),
      otp: otp,
    };

    const verifyOtpResp = await verifyOtp(otpReq);
    //console.log(verifyOtpResp);
    setAadhaarDetail(verifyOtpResp);
    if (verifyOtpResp?.status) {
      setContext(verifyOtpResp.data);
    } else {
      setStpTimerBoxVis(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    // initializing session
    initializeSession({
      consent: true,
      purpose: 'Youth Offer',
    });
  }, []);

  useEffect(() => {
    // will take to form directly for testing purpose

    if (parseInt(searchParams.get('testmode'))) {
      console.log('testmode activated');
      setContext({
        timestamp: '1705581681599',
        transaction_id: '16b975394ebe482da179dbf37b7c2f90',
        kycId:
          'eaa288b97091fc5f9a53f2fc5ee6ab8effeaf16770d7e25a8b136a4061d51822',
        name: 'John Doe Jr',
        dob: '16-08-1988',
        age: parseInt(searchParams.get('testmode')),
        address: {
          careOf: 'S/O John Doe',
          house: '60A/17',
          landmark: 'Kolkata',
          locality: 'Delhi',
          pin: '700001',
          postOffice: 'Kolkata',
          district: 'Kolkata',
          subDistrict: '',
          state: 'West Bengal',
          country: 'India',
          street: 'Rohini demo',
          vtc: 'Kolkata Corporation',
        },
      });
    }
  }, [searchParams.get('testmode')]);

  useEffect(() => {
    if (startTimer) {
      if (otpTimer > 0) {
        setTimeout(function () {
          const otpTimerRed = otpTimer - 1;
          setOtpTimer(otpTimerRed);
        }, 1100);
      } else {
        setStartTimer(false);
        // setOtpTimer(timerSeconds);
      }
    }
  }, [otpTimer]);

  useEffect(() => {
    if (startTimer) {
      setOtpTimer(otpTimer - 1);
    }
  }, [startTimer]);

  return (
    !context && (
      <Box
        bg="url(https://static.sadhguru.org/d/46272/1710578871-group-1171277531.jpg) #00005B"
        py="30px"
        id="ieo-enroll-section"
      >
        <Flex
          maxW={1030}
          bgRepeat="no-repeat"
          mx={{ base: '15px', sm: '30px', md: '50px', xl: 'auto' }}
          bg="#fff"
          p={{
            base: '15px',
            md: '30px',
            lg: '70px 50px 70px',
            xl: '70px 100px 70px',
          }}
          boxSizing="border-box"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ base: 'column', lg: 'row' }}
          gridGap="30px"
        >
          {step1Vis && (
            <>
              <Flex
                flexDir="column"
                maxW={{ base: '90%', sm: '80%', md: '340px', lg: '310px' }}
              >
                {searchByFieldId(sectionContent, 'verify-your ') && (
                  <Box
                    fontSize={{ base: '24px', lg: '32px' }}
                    fontFamily="FedraSansStd-medium"
                    color="#F37021"
                  >
                    {searchByFieldId(sectionContent, 'verify-your ').text}
                  </Box>
                )}

                {searchByFieldId(sectionContent, 'eligibility') && (
                  <Box
                    fontSize={{ base: '32px', lg: '26px' }}
                    fontFamily="FedraSansStd-medium"
                    color="#F37021"
                    lineHeight="normal"
                  >
                    {searchByFieldId(sectionContent, 'eligibility').text}
                  </Box>
                )}

                {searchByFieldId(sectionContent, 'need-verify') && (
                  <Box
                    fontFamily="FedraSansStd-book"
                    pt="8px"
                    color="#3A3A3A"
                    fontWeight="450"
                    maxW="271px"
                  >
                    {searchByFieldId(sectionContent, 'need-verify').text}
                  </Box>
                )}
              </Flex>

              <Flex width={{ base: '100%', sm: '80%', md: '60%', lg: '50%' }}>
                {initLoading && (
                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    m="0 auto"
                  >
                    <CircularProgress isIndeterminate={true} />
                  </Flex>
                )}
                {!initLoading && (
                  <Formik
                    initialValues={{
                      aadhaarNumber: '',
                      captcha: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={generateOtpData}
                  >
                    {props => (
                      <Form onSubmit={props?.handleSubmit}>
                        <Flex flexDir="column" rowGap="20px">
                          <Field name="aadhaarNumber">
                            {({ field, form }) => (
                              <FormControl
                                isRequired
                                isInvalid={
                                  form?.errors?.aadhaarNumber &&
                                  form?.touched?.aadhaarNumber
                                }
                              >
                                <HStack
                                  border="1px solid #B6B6B6"
                                  p={{ base: '8px', md: '12px 15px' }}
                                  borderRadius="8px"
                                  position="relative"
                                >
                                  {searchByFieldId(
                                    sectionContent,
                                    'adhaar-number'
                                  ) && (
                                      <Box
                                        fontFamily="FedraSansStd-book"
                                        textTransform="uppercase"
                                        color="#3A3A3A"
                                        position="absolute"
                                        top="-10px"
                                        bg="#fff"
                                        px="4px"
                                        fontSize="12px"
                                      >
                                        {
                                          searchByFieldId(
                                            sectionContent,
                                            'adhaar-number'
                                          ).text
                                        }
                                      </Box>
                                    )}
                                  <PinInput
                                    otp
                                    placeholder=""
                                    errorBorderColor="red"
                                    {...field}
                                    // onChange={props.handleChange}
                                    size={{ base: 'xs', md: 'xs' }}
                                    variant="flushed"
                                    name={field.name}
                                    value={field.value}
                                    width={{
                                      base: '100%',
                                      sm: '80%',
                                      md: '60%',
                                      lg: '50%',
                                    }}
                                    onChange={value => {
                                      form.setFieldValue(field.name, value);
                                    }}
                                  >
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                    <PinInputField
                                      fontFamily="FedraSansStd-medium"
                                      fontSize={{ base: '18px', md: '20px' }}
                                    />
                                  </PinInput>
                                </HStack>
                                <FormErrorMessage justifyContent="left">
                                  {form.errors.aadhaarNumber}
                                </FormErrorMessage>
                                <Box
                                  fontFamily="FedraSansStd-book"
                                  fontSize={{ base: '12px', md: '14px' }}
                                  color="#e53e3e"
                                >
                                  {((!otpData?.status ||
                                    otpData?.error?.code) &&
                                    otpData?.message) ||
                                    otpData?.error?.message}
                                </Box>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="captcha" type="text">
                            {({ field, form }) => (
                              <FormControl
                                isRequired
                                isInvalid={
                                  form.errors.captcha && form.touched.captcha
                                }
                              >
                                <Flex
                                  bg="#EFEFEF"
                                  p="10px"
                                  borderRadius={8}
                                  gap={{ base: '8px', md: '16px' }}
                                  alignItems="center"
                                >
                                  <Image
                                    src={initialSession?.data?.captcha}
                                    alt="Captcha"
                                    w={{ base: '97px', md: '153px' }}
                                    title="Captcha"
                                  />

                                  <Input
                                    color="#64748B"
                                    {...field}
                                    placeholder={
                                      sectionContent?.content?.json?.captcha ||
                                      'Enter Captcha'
                                    }
                                    fontFamily="FedraSansStd-book"
                                    fontSize={{ base: '14px', md: '16px' }}
                                    onChange={props.handleChange}
                                    _placeholder={{
                                      color: '#64748B',
                                      fontSize: '16px',
                                    }}
                                    variant="flushed"
                                  />

                                  <Button
                                    //onClick={reloadCaptcha}
                                    onClick={() => {
                                      // props.resetForm({ cat: '' });
                                      reloadCaptcha(props);
                                    }}
                                    bg="none"
                                    _hover={{ bg: 'none' }}
                                    border="none"
                                    fontFamily="FedraSansStd-medium"
                                    p="0px"
                                    _pressed={{ background: 'none' }}
                                    _active={{ background: 'none' }}
                                    mt="15px"
                                  >
                                    <Image
                                      src="https://static.sadhguru.org/d/46272/1707921755-rotate-02-repeat-refresh.png"
                                      alt="refresh"
                                      w={{ base: '28px', md: '32px' }}
                                      className={spin ? 'refresh' : ''}
                                      title="Refresh Captcha"
                                    />
                                  </Button>
                                </Flex>

                                <FormErrorMessage
                                  justifyContent="left"
                                  fontFamily="FedraSansStd-book"
                                  fontSize={{ base: '12px', md: '14px' }}
                                >
                                  {form.errors?.captcha}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Button
                            type="submit"
                            fontFamily="FedraSansStd-medium"
                            fontSize={{ base: '16px', md: '18px' }}
                            bg="#3652B8"
                            width="max-content"
                            mx="auto"
                            p="8px 50px"
                            borderRadius="100px"
                            isLoading={loading}
                            loadingText="Getting OTP..."
                            h={{ base: '42px', md: '50px' }}
                          // disabled={props?.values?.aadhaarNumber?.length < 12}
                          // disabled={
                          //   !props.isValid || !props.dirty || loading
                          //     ? true
                          //     : false
                          // }
                          >
                            {sectionContent[5]?.buttonText}
                          </Button>
                        </Flex>
                      </Form>
                    )}
                  </Formik>
                )}
              </Flex>
            </>
          )}
          {!step1Vis && (
            <>
              <Flex w="full" flexDirection="column" maxW="310px">
                {searchByFieldId(sectionContent, 'verification') && (
                  <Box
                    fontSize={{ base: '24px', md: '32px' }}
                    fontFamily="FedraSansStd-medium"
                    color="#F37021"
                    lineHeight="normal"
                  >
                    {searchByFieldId(sectionContent, 'verification').text}
                  </Box>
                )}
                {searchByFieldId(sectionContent, 'otp') && (
                  <Box
                    fontSize={{ base: '32px', lg: '26px' }}
                    fontFamily="FedraSansStd-medium"
                    color="#F37021"
                    lineHeight="normal"
                  >
                    {searchByFieldId(sectionContent, 'otp').text}
                  </Box>
                )}

                <Box
                  fontFamily="FedraSansStd-book"
                  pt={{ base: '8px', xl: '18px' }}
                >
                  {otpData?.data?.message || msgs.otpSent}
                </Box>
              </Flex>
              <Flex
                w={{ base: 'full', md: 'auto' }}
                pt="10px"
                textAlign="center"
                justifyContent="center"
              >
                <Formik
                  initialValues={{
                    otp: '',
                  }}
                  validationSchema={ValidationSchema2}
                  onSubmit={verifyOtpData}
                  enableReinitialize
                >
                  {props => (
                    <Form onSubmit={props.handleSubmit}>
                      <Flex flexDir="column" rowGap="20px">
                        <Field name="otp">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={form.errors.otp && form.touched.otp}
                            >
                              <HStack spacing={{ base: '1', md: '2', xl: '3' }}>
                                <PinInput
                                  otp
                                  placeholder=""
                                  {...field}
                                  name={field.name}
                                  value={field.value}
                                  onChange={value => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                >
                                  <PinInputField
                                    fontFamily="FedraSansStd-medium"
                                    errorBorderColor="red"
                                  />
                                  <PinInputField
                                    fontFamily="FedraSansStd-medium"
                                    errorBorderColor="red"
                                  />
                                  <PinInputField
                                    fontFamily="FedraSansStd-medium"
                                    errorBorderColor="red"
                                  />
                                  <PinInputField
                                    fontFamily="FedraSansStd-medium"
                                    errorBorderColor="red"
                                  />
                                  <PinInputField
                                    fontFamily="FedraSansStd-medium"
                                    errorBorderColor="red"
                                  />
                                  <PinInputField
                                    fontFamily="FedraSansStd-medium"
                                    errorBorderColor="red"
                                  />
                                </PinInput>
                              </HStack>
                              <Box
                                fontFamily="FedraSansStd-book"
                                fontSize={{ base: '12px', md: '14px' }}
                                color="#e53e3e"
                                pt={{ base: '18px', lg: '6px' }}
                              >
                                {((!aadhaarDetail?.status ||
                                  aadhaarDetail?.error?.code) &&
                                  aadhaarDetail?.message) ||
                                  aadhaarDetail?.error?.message}
                              </Box>
                              <FormErrorMessage
                                justifyContent="left"
                                fontFamily="FedraSansStd-book"
                                fontSize={{ base: '12px', md: '14px' }}
                              ></FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Button
                          type="submit"
                          fontFamily="FedraSansStd-medium"
                          fontSize={{ base: '16px', md: '18px' }}
                          bg="#3652B8"
                          width="max-content"
                          m="0px auto"
                          p="8px 50px"
                          borderRadius="100px"
                          isLoading={loading}
                          loadingText="Verifying..."
                          h="50px"
                          disabled={
                            !props.isValid || !props.dirty || loading
                              ? true
                              : false
                          }
                        >
                          {sectionContent[6]?.buttonText}
                        </Button>
                      </Flex>
                      <Flex justify="center" pt="10px">
                        {otpTimerBoxVis && (
                          <>
                            {!startTimer && otpTimer < 1 && (
                              <Link
                                fontFamily="FedraSansStd-medium"
                                fontSize="14px"
                                color="#3652B8"
                                textDecor="underline"
                                onClick={() => {
                                  props.resetForm({ otp: '' });
                                  resendOtp();
                                }}
                              >
                                {searchByFieldId(
                                  sectionContent,
                                  'resend-otp'
                                ) && (
                                    <>
                                      {
                                        searchByFieldId(
                                          sectionContent,
                                          'resend-otp'
                                        ).text
                                      }
                                    </>
                                  )}
                              </Link>
                            )}
                            {startTimer && otpTimer > 0 && (
                              <Flex
                                flexDir="row"
                                color="#433F3C"
                                fontSize="14px"
                                fontFamily="FedraSansStd-book"
                              >
                                <TimeIcon fontSize="12px" mx="5px" mt="5px" />
                                {otpTimer}{' '}
                                <Box as="span" pl="5px">
                                  {'  '}
                                  Sec
                                </Box>
                              </Flex>
                            )}
                          </>
                        )}
                      </Flex>
                    </Form>
                  )}
                </Formik>
              </Flex>
            </>
          )}
        </Flex>

        {/*Start Army content*/}

        {aadhaarDetail?.error && (
          <Flex
            maxW={1030}
            bgRepeat="no-repeat"
            mx={{ base: '15px', sm: '30px', md: '50px', xl: 'auto' }}
            bg="#fff"
            p={{
              base: '15px',
              md: '30px',
              lg: '40px',
            }}
            boxSizing="border-box"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gridGap="10px"
            mt={{ base: '40px', md: '60px' }}
          >
            <Box
              display="flex"
              gap="5px"
              textAlign={{ base: 'left', md: 'center' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
            >
              <RiCloseCircleLine color="#D30000" fontSize="30px" />
              <StructuredTextParser
                mt="0px"
                str={render(
                  searchByFieldId(
                    sectionContent,
                    'adhar-already-used-validation'
                  )?.body
                )}
                color="#D30000"
                fontSize={{ base: '20px', md: '24px' }}
                fontFamily="FedraSansStd-medium"
              />
            </Box>
            <Box
              borderBottom="1px solid #000000"
              w={{ base: '75%', sm: '25%' }}
              my={4}
            />

            <StructuredTextParser
              str={render(
                searchByFieldId(sectionContent, 'yo-failed-text1')?.body
              )}
              color="#433F3C"
              fontSize={{ base: '16px', md: '18px' }}
              fontFamily="FedraSansStd-book"
              textAlign="center"
              display="flex"
              alignItems={{ base: 'flex-start', md: 'center' }}
              className="aLink"
              mt="0px"
            />
          </Flex>
        )}

        {/*End Army content*/}
        <style jsx global>
          {`
            .aLink p a {
              color: #f37021;
            }
            .refresh {
              transform: rotate(360deg);
              animation: spin 2s infinite;
            }

            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          `}
        </style>
      </Box>
    )
  );
};

export default AadhaarCardVerify;
