/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRef } from 'react';

/* External Imports */
/* Internal Imports */
/* Components */
import StyleConfig from '@ieo/Common/StyleConfig';
import AdBanner2 from '@ieo/Components/Banner/AdBanner2';
import AadhaarCardVerify from '@ieo/Sections/AadhaarCardVerify';
import AdBanner from '@ieo/Sections/AdBanner';
import AlreadyPartneredIECorporate from '@ieo/Sections/AlreadyPartneredIECorporate';
import ArmyOfferBanner from '@ieo/Sections/ArmyOfferBanner';
import ArmyOfferFees from '@ieo/Sections/ArmyOfferFees';
import Communication from '@ieo/Sections/Communication';
import DonateSection from '@ieo/Sections/DonateSection';
import EnrollSection from '@ieo/Sections/EnrollSection';
import EnrollSectionCorporates from '@ieo/Sections/EnrollSectionCorporates';
import EoeAdBanner from '@ieo/Sections/EoeAdBanner';
import FaqContents from '@ieo/Sections/FaqContents';
import FaqContentsV7 from '@ieo/Sections/FaqContentsV7';
import FeesYouthOffer from '@ieo/Sections/FeesYouthOffer';
import FeesYouthOfferIYP from '@ieo/Sections/FeesYouthOfferIYP';
import FinancialSupportSection from '@ieo/Sections/FinancialSupportSection';
import GiftSection from '@ieo/Sections/GiftSection';
import HowItWorksSection from '@ieo/Sections/HowItWorksSection';
import IEOSpecialPricing from '@ieo/Sections/IEOSpecialPricing';
import IeDescriptionV5 from '@ieo/Sections/IeDescriptionV5';
import IeoBenefitsV5 from '@ieo/Sections/IeoBenefitsV5';
import IeoBenefitsV7 from '@ieo/Sections/IeoBenefitsV7';
import IeoInPerson from '@ieo/Sections/IeoInperson';
import IeoMaintenance from '@ieo/Sections/IeoMaintenance';
import IeoNewFormat from '@ieo/Sections/IeoNewFormat';
import IeoPoweredBy from '@ieo/Sections/IeoPoweredBy';
import IeoProgramFeesCorporates from '@ieo/Sections/IeoProgramFeesCorporates';
import IeoProgramFeesSza from '@ieo/Sections/IeoProgramFeesSza';
import ImprovementHealthList from '@ieo/Sections/ImprovementHealthList';
import InvalidProgramCorporates from '@ieo/Sections/InvalidProgramCorporates';
import JoyfulPeopleCorporates from '@ieo/Sections/JoyfulPeopleCorporates';
import OnlineModeIyp from '@ieo/Sections/OnlineModeIyp';
import PageSectionV5 from '@ieo/Sections/PageSectionV5';
import ProgramBenefitsIYP from '@ieo/Sections/ProgramBenefitsIYP';
import ProgramCardSection from '@ieo/Sections/ProgramCardSection';
import ProgramFee from '@ieo/Sections/ProgramFee';
import ProgramFeeV2 from '@ieo/Sections/ProgramFeeV2';
import ProgramFeeV3 from '@ieo/Sections/ProgramFeeV3';
import ProgramFeeV5 from '@ieo/Sections/ProgramFeeV5';
import ProgramOpenCorporates from '@ieo/Sections/ProgramOpenCorporates';
import ProgramRequirement from '@ieo/Sections/ProgramRequirement';
import ProgramResultsIyp from '@ieo/Sections/ProgramResultsIyp';
import ProgramSteps from '@ieo/Sections/ProgramSteps';
import ProgramStepsIYP from '@ieo/Sections/ProgramStepsIYP';
import ProgramStepsV3 from '@ieo/Sections/ProgramStepsV3';
import ProgramStepsV5 from '@ieo/Sections/ProgramStepsV5';
import ProgramStepsV7 from '@ieo/Sections/ProgramStepsV7';
import ProgramStructure from '@ieo/Sections/ProgramStructure';
import ProgramStructureIYP from '@ieo/Sections/ProgramStructureIYP';
import ProgramStructureV3 from '@ieo/Sections/ProgramStructureV3';
import ProgramStructureV5 from '@ieo/Sections/ProgramStructureV5';
import ProgramSupport from '@ieo/Sections/ProgramSupport';
import ProgramSupportIyp from '@ieo/Sections/ProgramSupportIyp';
import ProgramSupportV3 from '@ieo/Sections/ProgramSupportV3';
import ProgramTestimonial from '@ieo/Sections/ProgramTestimonial';
import ProgramTestimonialCorporates from '@ieo/Sections/ProgramTestimonialCorporates';
import ProgramTestimonialV3 from '@ieo/Sections/ProgramTestimonialV3';
import ProgramTestimonialV7 from '@ieo/Sections/ProgramTestimonialV7';
import QuotesSection from '@ieo/Sections/QuotesSection';
import ResearchIntro from '@ieo/Sections/ResearchIntro';
import ResearchIntroV3 from '@ieo/Sections/ResearchIntroV3';
import ResearchShows from '@ieo/Sections/ResearchShows';
import ResearchShowsIyp from '@ieo/Sections/ResearchShowsIyp';
import ResearchShowsV3 from '@ieo/Sections/ResearchShowsV3';
import ResearchShowsV5 from '@ieo/Sections/ResearchShowsV5';
import ResearchShowsV7 from '@ieo/Sections/ResearchShowsV7';
import ShambaviMahamudraIYP from '@ieo/Sections/ShambaviMahamudraIYP';
import Stories from '@ieo/Sections/Stories';
import Testimonal from '@ieo/Sections/Testimonal';
import TestimonialV3 from '@ieo/Sections/TestimonialV3';
import TestimonialV5 from '@ieo/Sections/TestimonialV5';
import TestimonialV7 from '@ieo/Sections/TestimonialV7';
import TopBanner from '@ieo/Sections/TopBanner';
import TopBannerCorporate from '@ieo/Sections/TopBannerCorporate';
import TopBannerIYP from '@ieo/Sections/TopBannerIYP';
import TopBannerIshaYoga7DayProgram from '@ieo/Sections/TopBannerIshaYoga7DayProgram';
import TopBannerV7 from '@ieo/Sections/TopBannerV7';
import TopBannerYo from '@ieo/Sections/TopBannerYo';
import UpcomingPrograms from '@ieo/Sections/UpcomingPrograms';
import WaysToParticipateIyp from '@ieo/Sections/WaysToParticipateIyp';
import WhatLearnPC3 from '@ieo/Sections/WhatLearnPC3';
import WhatYouLearnIYP from '@ieo/Sections/WhatYouLearnIYP';
import WhoCanDoIYP from '@ieo/Sections/WhoCanDoIYP';
import WhoCanDoV5 from '@ieo/Sections/WhoCanDoV5';
import WhoCanDoV7 from '@ieo/Sections/WhoCanDoV7';

/**
 * Renders Render IEO Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Render IEO Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  supportData,
  couponData,
  pageData,
  pageName,
  mode,
  context,
  setContext,
}) => {
  const sectionId = section?.sectionId;
  const scrollRef = useRef(null);
  const scholarshipsRef = useRef(null);

  if (sectionId === 'top-banner') {
    return (
      <TopBanner
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'top-banner-v7') {
    return (
      <TopBannerV7
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        prgData={csrData}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'top-banner-corporate') {
    return (
      <TopBannerCorporate
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        prgData={csrData}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'eoe-ad') {
    return (
      <>
        <EoeAdBanner
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
          pageData={pageData}
          pageName={pageName}
        />
      </>
    );
  } else if (sectionId === 'top-banner-yo') {
    return (
      <TopBannerYo
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'top-banner-Isha-Yoga-7-day-Program') {
    return (
      <TopBannerIshaYoga7DayProgram
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'top-banner-IYP') {
    return (
      <TopBannerIYP
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'program-benefits-IYP') {
    return (
      <>
        <ProgramBenefitsIYP
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
          pageData={pageData}
          pageName={pageName}
          prgData={csrData}
        />
      </>
    );
  } else if (sectionId === 'register-sticky-button') {
    const RegisterStickyButton = dynamic(() =>
      import('@ieo/Components/RegisterStickyButton')
    );
    return (
      <RegisterStickyButton
        lang={lang}
        region={region}
        sectionContent={section.sectionContent}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'research-intro-v3') {
    return (
      <ResearchIntroV3
        lang={lang}
        region={region}
        pageData={pageData}
        pageName={pageName}
        sectionContent={section?.sectionContent}
      />
    );
  } else if (sectionId === 'joyful-people-corporates') {
    return (
      <JoyfulPeopleCorporates
        lang={lang}
        region={region}
        pageData={pageData}
        pageName={pageName}
        sectionContent={section?.sectionContent}
      />
    );
  } else if (sectionId === 'ie-description-v5') {
    return (
      <IeDescriptionV5
        lang={lang}
        region={region}
        pageData={pageData}
        pageName={pageName}
        sectionContent={section?.sectionContent}
      />
    );
  } else if (sectionId === 'research-intro') {
    return (
      <ResearchIntro
        lang={lang}
        region={region}
        pageData={pageData}
        pageName={pageName}
        sectionContent={section?.sectionContent}
      />
    );
  } else if (sectionId === 'ieo-powered-by') {
    return (
      <IeoPoweredBy
        lang={lang}
        region={region}
        pageData={pageData}
        pageName={pageName}
        sectionContent={section?.sectionContent}
      />
    );
  } else if (sectionId === 'ieo-program-fees-sza') {
    return (
      <IeoProgramFeesSza
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'ieo-special-pricing') {
    return (
      <IEOSpecialPricing
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'research-shows-v3') {
    return (
      <ResearchShowsV3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'what-learn-pc3') {
    return (
      <WhatLearnPC3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'research-shows-IYP') {
    return (
      <ResearchShowsIyp
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'research-shows-v5') {
    return (
      <ResearchShowsV5
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'what-you-learn-IYP') {
    return (
      <WhatYouLearnIYP
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'shambavi-mahamudra-IYP') {
    return (
      <ShambaviMahamudraIYP
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'research-shows-v7') {
    return (
      <ResearchShowsV7
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'research-shows') {
    return (
      <ResearchShows
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ieo-benefits-corporates') {
    // <IeoBenefitsCorporates
    //   sectionContent={section.sectionContent}
    //   region={region}
    //   lang={lang}
    // />
  } else if (sectionId === 'ieo-benefits-v5') {
    return (
      <IeoBenefitsV5
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ieo-benefits-v7') {
    return (
      <IeoBenefitsV7
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'financial-support-section') {
    return (
      <FinancialSupportSection
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'donate-section') {
    return (
      <DonateSection
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ieo-program-section') {
    return (
      <ProgramCardSection
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        scholarshipsRef={scholarshipsRef}
      />
    );
  } else if (sectionId === 'Ad-banner') {
    return (
      <AdBanner
        sectionContent={section.sectionContent[0]}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ad-banner-2') {
    return (
      <AdBanner2
        sectionContent={section?.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ieo-new-format') {
    return (
      <IeoNewFormat
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'improvement-health-list-IYP') {
    return (
      <ImprovementHealthList
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'quotes-section') {
    return (
      <QuotesSection
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ieo-testimonal') {
    return (
      <Testimonal
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-testimonal-v3') {
    return (
      <TestimonialV3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-testimonal-v5') {
    return (
      <TestimonialV5
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-testimonal-v7') {
    return (
      <TestimonialV7
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-structure-v3') {
    return (
      <ProgramStructureV3
        lang={lang}
        region={region}
        sectionContent={section.sectionContent}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-structure-iyp') {
    return (
      <ProgramStructureIYP
        lang={lang}
        region={region}
        sectionContent={section.sectionContent}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-structure-v5') {
    return (
      <ProgramStructureV5
        lang={lang}
        region={region}
        sectionContent={section.sectionContent}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-structure') {
    return (
      <ProgramStructure
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-steps') {
    return (
      <ProgramSteps
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-steps-v3') {
    return (
      <ProgramStepsV3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-steps-iyp') {
    return (
      <ProgramStepsIYP
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-steps-v5') {
    return (
      <ProgramStepsV5
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-steps-v7') {
    return (
      <ProgramStepsV7
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageName={pageName}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'program-requirement') {
    return (
      <StyleConfig style={section.styleConfig}>
        <ProgramRequirement
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'program-requirement-v3') {
    return (
      <StyleConfig style={section.styleConfig}>
        <ProgramRequirement
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'program-testimonial') {
    return (
      <ProgramTestimonial
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'program-testimonial-v3') {
    return (
      <ProgramTestimonialV3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'program-testimonial-corporates') {
    return (
      <ProgramTestimonialCorporates
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'program-testimonial-v7') {
    return (
      <ProgramTestimonialV7
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'ieo-fees-youth-offer') {
    return (
      <FeesYouthOffer
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'ieo-fees-youth-offer-iyp') {
    return (
      <FeesYouthOfferIYP
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'ways-to-participate') {
    return (
      <WaysToParticipateIyp
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'upcoming-programs') {
    return (
      <UpcomingPrograms
        sectionContent={section?.sectionContent}
        region={region}
        language={lang}
        isLeftMenu={false}
      />
    );
  } else if (sectionId === 'army-offer-banner') {
    return (
      <ArmyOfferBanner
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'army-offer-fees') {
    return (
      <ArmyOfferFees
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'ieo-program-fees-v3') {
    return (
      <ProgramFeeV3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'ieo-program-fees-v5') {
    return (
      <ProgramFeeV5
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'invalid-coupon-corporate') {
    return (
      <InvalidProgramCorporates
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (sectionId === 'ieo-program-fees-corporates') {
    return (
      <IeoProgramFeesCorporates
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (sectionId === 'ieo-program-support') {
    return (
      <ProgramSupport
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
      />
    );
  } else if (sectionId === 'for-communication') {
    return (
      <Communication
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
      />
    );
  } else if (sectionId === 'ieo-program-support-v3') {
    return (
      <ProgramSupportV3
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
      />
    );
  } else if (sectionId === 'ieo-program-support-IYP') {
    return (
      <ProgramSupportIyp
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
      />
    );
  } else if (sectionId === 'ieo-stories') {
    return (
      <Stories
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'ieo-program-fees') {
    return (
      <ProgramFee
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
        scholarshipsRef={scholarshipsRef}
      />
    );
  } else if (sectionId === 'ieo-program-fees-v2') {
    return (
      <ProgramFeeV2
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        pageData={pageData}
        prgData={csrData}
      />
    );
  } else if (
    sectionId === 'ieo-enroll-section' ||
    sectionId === 'ieo-register-page-section' ||
    sectionId === 'ieo-enroll-section-v3'
  ) {
    return (
      <EnrollSection
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        prgData={csrData}
        couponData={couponData}
        pageData={pageData}
        context={context}
        pageName={pageName}
        setContext={setContext}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'ieo-enroll-section-corporates') {
    return (
      <EnrollSectionCorporates
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        prgData={csrData}
        couponData={couponData}
        pageData={pageData}
        context={context}
        pageName={pageName}
        setContext={setContext}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'ieo-faq-section') {
    return (
      <FaqContents
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'ieo-faq-section-v7') {
    return (
      <FaqContentsV7
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
        scrollRef={scrollRef}
      />
    );
  } else if (sectionId === 'ieo-inperson-details') {
    return (
      // <StyleConfig style={section?.styleConfig}>
      <IeoInPerson
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
        prgData={csrData}
        pageData={pageData}
        supportData={supportData}
      />
      // </StyleConfig>
    );
  } else if (sectionId === 'ie-gift') {
    return (
      // <StyleConfig style={section?.styleConfig}>
      <GiftSection
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
        prgData={csrData}
      />
      // </StyleConfig>
    );
  } else if (sectionId === 'how-it-works') {
    return (
      <HowItWorksSection
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'adhar-card-verify') {
    return (
      <AadhaarCardVerify
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
        csrData={csrData}
        context={context}
        setContext={setContext}
        pageName={pageName}
      />
    );
  } else if (sectionId === 'who-can-do-v5') {
    return (
      <WhoCanDoV5
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'program-open-corporates') {
    return (
      <ProgramOpenCorporates
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'who-can-do-iyp') {
    return (
      <WhoCanDoIYP
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'program-results-IYP') {
    return (
      <ProgramResultsIyp
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'online-mode-IYP') {
    return (
      <OnlineModeIyp
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'who-can-do-v7') {
    return (
      <WhoCanDoV7
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
        scrollRef={scrollRef}
      />
    );
  } else if (
    sectionId === 'program-requirement-v5' ||
    sectionId === 'login-already-partnered'
  ) {
    return (
      <PageSectionV5
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'already-partnered-IE-Corporate') {
    return (
      <AlreadyPartneredIECorporate
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  } else if (sectionId === 'ieo-maintenance') {
    return (
      <IeoMaintenance
        region={region}
        lang={lang}
        sectionContent={section.sectionContent}
      />
    );
  }
};

export default RenderSections;
