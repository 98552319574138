/* Built In Imports */

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useEffect, useState } from 'react';

/* Internal Imports */

/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
/* Config */
import config from '@config';
/* Styles */

/**
 * @param {Object} sectionContent
 * @param {Object} pageData
 * @param {String} lang
 * @param {String} region
 * @returns
 */
const IEOSpecialPricing = ({ sectionContent, region, lang, pageData }) => {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    if (
      pageData.country &&
      (config?.middleEastCountries.indexOf(pageData?.country) > -1 ||
        region === 'uk' ||
        region === 'au' ||
        region === 'sg' ||
        region === 'my')
    ) {
      setIsMatch(true);
    }
  }, [pageData.country]);

  return (
    isMatch && (
      <Box
        mx={{ base: '15px', md: '30px', lg: 'auto' }}
        maxW="1030px"
        bg="#F1EBDF"
        px={{ base: '20px', md: '60px' }}
        py={{ base: '20px', md: '30px' }}
        mt={{ base: '60px', md: '130px', lg: '100px' }}
      >
        <Text
          fontFamily="FedraSansStd-medium"
          fontSize={{ base: '20px', md: '24px' }}
          pb="10px"
        >
          {sectionContent?.[0]?.text}{' '}
        </Text>
        <StructuredTextParser
          color="#433F3C"
          mt="0"
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          lineHeight={{ md: '1.6rem', lg: '1.6rem' }}
          str={render(sectionContent?.[1]?.body)}
          maxW="none !important"
          className="pLinkA"
        />
        <style jsx global>
          {`
            .pLinkA p a {
              color: #ce6113;
              text-decoration: underline;
              font-family: 'FedraSansStd-medium';
            }
            .pLinkA p a:hover {
              text-decoration: none;
            }
          `}
        </style>
      </Box>
    )
  );
};

export default IEOSpecialPricing;
