/* Built In Imports */
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

/* External Imports */
import { AspectRatio, Box, Flex, Image, Text } from '@chakra-ui/react';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import MediaEmbedV2 from '@components/Embeds/MediaEmbedV2';
import ProgramStepsSt from '@ieo/Common/StyleData/ProgramStepsSt';

const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {String} lang
 * @returns {ReactElement} ProgramSteps
 */
const ProgramStepsV7 = ({
  sectionContent,
  lang,
  pageName,
  region,
  scrollRef,
}) => {
  const path = usePathname();
  const under25Url = path.includes('under-25s');

  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  return (
    <Box
      w="full"
      bg={
        under25Url
          ? 'url(https://static.sadhguru.org/d/46272/1710743888-1710584276-pbg1.jpg) repeat'
          : '#F1EBDF'
      }
      h="100%"
      mb={pageName === 'ieomigrate' ? { base: '70px', md: '120px' } : '0px'}
      backgroundRepeat={under25Url && 'repeat'}
    >
      <Flex
        flexDir="column"
        maxW={1030}
        pb={{ base: '0px', xl: '41px' }}
        alignItems="center"
        w="full"
        justify="center"
        px={{ sm: '1rem', lg: '0' }}
        maxH={{ xl: under25Url ? '550px' : 'auto' }}
        mx="auto"
      >
        <Text
          lineHeight={{ base: '30px', md: '39px' }}
          maxW={{ base: '268px', md: '743px' }}
          textAlign="center"
          color={'#12166F'}
          {...ProgramStepsSt[lang].h4}
        >
          {sectionContent[0].text}
        </Text>

        <Text
          lineHeight={{ base: '30px', md: '39px' }}
          maxW={{ base: '268px', md: '743px' }}
          textAlign="center"
          color="#A67411"
          {...ProgramStepsSt[lang].h4}
          gap="10px"
          justifyContent="center"
          fontSize={{ base: '16px', md: '20px' }}
          display="flex"
          alignItems="center"
          pb={{ base: '10px', md: '30px' }}
        >
          <Image
            src={sectionContent[1]?.image?.url}
            w={{ base: '20px', md: '24px' }}
            alt=""
          />{' '}
          {findField('ie-duration-2')?.text}
        </Text>

        <Flex
          bgRepeat="no-repeat"
          bgSize="cover"
          pos="relative"
          align={{ base: 'center', lg: 'initial' }}
          flexDir={{ base: 'column', lg: 'row' }}
          w="100%"
          minH={{ base: '450px', lg: '300px' }}
          p={{ base: '20px 20px 0 20px', md: '0' }}
          gridGap={{ lg: '37px' }}
          mb={{ base: '0px', sm: '25px', md: '75px', lg: '0' }}
        >
          <Flex
            maxW={{ lg: '400px', xl: '480px' }}
            flexDir="column"
            gridGap="12px"
            color="white"
            pl={{ base: '0px', md: '30px', xl: '0px' }}
          >
            <Box>
              <Text
                mb="8px"
                {...ProgramStepsSt[lang].h5}
                textAlign={{ base: 'center', lg: 'left' }}
                fontSize="32px"
              >
                {' '}
                {sectionContent[3]?.text}
              </Text>
              <Text
                fontFamily="FedraSansStd-medium"
                color="#3B444B"
                fontSize={{ base: '20px', md: '22px' }}
                mb="20px"
                textAlign={{ base: 'center', lg: 'left' }}
                px={{ base: '30px', md: '0' }}
              >
                {' '}
                {sectionContent[4]?.text}
              </Text>

              <Box
                fontFamily="FedraSansStd-medium"
                display="flex"
                gridGap="10px"
              >
                {sectionContent[5]?.cards?.map((cardItem, i) => (
                  <Box
                    key={'_ki1' + i}
                    borderLeft={i == 1 ? '1px solid #C6B399' : ''}
                    pl={{ base: i == 1 && '20px', md: i == 1 && '40px' }}
                    maxW={i == 1 ? '50%' : '45%'}
                  >
                    <Box
                      bg="#CE6113"
                      display="inline-flex"
                      pl="8px"
                      pr="14px"
                      py="4px"
                    >
                      <Image
                        src="https://static.sadhguru.org/d/46272/1706002328-live-signal.svg"
                        alt=""
                        pr="6px"
                        w="30px"
                        h="25px"
                      />{' '}
                      {cardItem?.buttonText}
                    </Box>
                    <Text
                      mt="10px"
                      color="#7A6E4B"
                      fontSize={{ base: '14px', md: '18px' }}
                    >
                      {cardItem?.title}
                    </Text>
                    <Text
                      color="#7A6E4B"
                      fontSize={{ base: '14px', md: '18px' }}
                      fontWeight="400"
                      fontFamily="FedraSansStd-book"
                    >
                      {cardItem?.buttonLink}
                    </Text>
                    <Text mt="6px" color="#B49168" fontSize="16px">
                      <StructuredText
                        data={cardItem?.description?.value?.document}
                        fontSize="16px"
                      />
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>

            <Text className="datoText" {...ProgramStepsSt[lang].p}>
              <StructuredText data={sectionContent[3]?.body?.value?.document} />
            </Text>
          </Flex>

          <ResearchVidCard>
            <AspectRatio
              w={{ base: '87vw', sm: '400px', md: '516.9px' }}
              ratio={1.6}
              mt="14px"
            >
              <MediaEmbedV2
                sectionContent={sectionContent[6]}
                sectionId="ieo-program-steps"
                lang={lang}
              />
            </AspectRatio>
          </ResearchVidCard>
        </Flex>
      </Flex>
      {sectionContent?.[8].linkUrl != '' && (
        <Box maxW={{ base: '217px', md: '226px' }} m="0 auto">
          <Flex align={'center'} justifyContent={'center'}>
            <SimpleButton
              style={sectionContent?.[8]}
              bType={sectionContent[8]?.sectionId}
              region={region}
              lang={lang}
              onClick={() => {
                scroll.scrollTo(scrollRef.current.offsetTop, {
                  duration: 500,
                  smooth: true,
                });
              }}
              width={{ base: '250px', md: '100%' }}
              borderRadius={'12px'}
              background={'#F37021'}
              fontbold={'500'}
              _hover={{
                background: '#BC570F !important',
              }}
            />
          </Flex>
        </Box>
      )}
      <style jsx global>{`
        .datoText {
          display: flex;
          flex-direction: column;
          gap: 13px;
        }
      `}</style>
    </Box>
  );
};

export default ProgramStepsV7;
