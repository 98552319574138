/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Flex, Heading, Show, useBreakpointValue } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ProgramSupportSt } from '@ieo/Common/CompLangConfig';
import SingleImage from '@ieo/Components/SingleImage';

/* Services */
/* Styles */

/**
 * @param {Object} card
 * @param {String} region
 * @param {String} lang
 * @param {String} flexBasis
 * @returns
 */
const ProgramSupportCard = ({ card, lang, region, flexBasis, pageData }) => {
  const readLessTxtCount = 35;
  const [show, setShow] = useState(false);
  const [readMoreTxt, setReadMoreTxt] = useState(readLessTxtCount);
  const [txtHide, setTxtHide] = useState(true);
  const descText = render(card?.description);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleOnClick = () => {
    setShow(!show);
    setReadMoreTxt(-1);
  };
  // console.log('pageData', pageData);
  const viewText = () => {
    setReadMoreTxt(-1);
    setTxtHide(false);
  };
  const lessText = () => {
    setReadMoreTxt(readLessTxtCount);
    setTxtHide(true);
  };
  // console.log(isMobile, 'mobile me pravesh');

  return (
    <Box
      w={{ base: '100%', sm: '318px', md: '47%', lg: '30%' }}
      flexBasis={{ base: 'auto', md: 'auto', lg: flexBasis }}
    >
      <Box
        h="auto"
        maxW={{ base: '100%', md: '100%', lg: '318px' }}
        bg="#F1EBDF"
        display="flex"
        justifyContent="space-between"
        flexDir="column"
        onClick={() => {
          isMobile && handleOnClick();
        }}
      >
        <Box
          maxW={lang === 'ar' ? '100%' : '100px'}
          alignSelf={'auto'}
          //height={{ base: '40%', sm: '35%' }}
          pt={{ base: '18px', sm: '15px', lg: '15px' }}
          px={{ base: '18px', sm: '15px', lg: '20px' }}
          minH={{ base: '35px', md: '90px' }}
        >
          <SingleImage
            my="0"
            sectionContent={card?.image}
            sync={true}
            w={{ base: '70px', md: '90px' }}
            h={{ base: '70px', md: '90px' }}
            objectFit="contain"
          />
        </Box>
        <Box
          w="100%"
          p={0}
          borderLeft={{
            base: lang === 'ar' ? 'none' : '7px solid #A81D28',
            sm: lang === 'ar' ? 'none' : '6px solid #A81D28',
          }}
          borderRight={{
            base: lang === 'ar' ? '7px solid #A81D28' : 'none',
            sm: lang === 'ar' ? '6px solid #A81D28' : 'none',
          }}
          h={{ base: '55%', sm: '63%' }}
          mt={{ base: '17px', md: '0' }}
          pb="20px"
          display={'flex'}
          fontFamily="FedraSansStd-book"
          flexDir="column"
          px="20px"
          dir={lang == 'ar' ? 'rtl' : 'ltr'}
        >
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Heading
              as="h3"
              fontFamily="FedraSansStd-medium"
              {...ProgramSupportSt[lang].cardTitle}
              mb="16px"
            >
              {card?.image?.title}
            </Heading>
            <Box
              onClick={() => {
                setShow(true);
                setReadMoreTxt(-1);
              }}
              display={{ base: show ? 'none' : 'block', sm: 'none' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M18.4167 13L13 13M13 13L7.58332 13M13 13L13 7.58337M13 13L13 18.4167"
                  stroke="#CE6113"
                  stroke-width="3"
                  stroke-linecap="square"
                />
              </svg>
            </Box>
          </Flex>

          <Flex w="100%" justifyContent="space-between" flexDirection="column">
            <StructuredTextParser
              fontSize="16px"
              lineHeight="20px"
              display={{ base: show ? 'block' : 'none', sm: 'block' }}
              {...ProgramSupportSt[lang].stText}
              mt="0"
              // {...ResearchShowsSt[lang].cardText}
              //  noOfLines={{base:2,md:2,lg:3}}
              str={`${descText.slice(0, readMoreTxt)}${
                descText.length > readLessTxtCount ? '...' : ''
              } `}
            />

            {descText?.length > readLessTxtCount && (
              <Show above="md">
                {txtHide ? (
                  <Box
                    width="100%"
                    color="#F37021"
                    fontSize="16px"
                    fontFamily="FedraSansStd-medium"
                    pt="10px"
                    onClick={viewText}
                    cursor="pointer"
                    textAlign={lang === 'ar' ? 'right' : 'left'}
                  >
                    {(pageData > 0 &&
                      pageData?.pageConfig[0]?.value?.['post-prg']?.readless) ||
                      'Read More'}
                  </Box>
                ) : (
                  <Box
                    width="100%"
                    color="#F37021"
                    fontSize="16px"
                    fontFamily="FedraSansStd-medium"
                    pt="10px"
                    onClick={lessText}
                    cursor="pointer"
                    textAlign={lang === 'ar' ? 'right' : 'left'}
                  >
                    {pageData?.pageConfig[0]?.value?.['post-prg']?.readless ||
                      'Read Less'}
                  </Box>
                )}
              </Show>
            )}

            <Box
              onClick={() => {
                setShow(false);
                setReadMoreTxt(-1);
              }}
              alignSelf="flex-end"
              display={{ base: show ? 'block' : 'none', md: 'none' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M7.58334 13L18.4167 13"
                  stroke="#CE6113"
                  stroke-width="3"
                  stroke-linecap="square"
                />
              </svg>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramSupportCard;
