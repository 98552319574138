/* Built In Imports */

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';


/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
/* Config */

const IeoMaintenance = ({ sectionContent, lang }) => {

  const maintenanceText = sectionContent?.find(
    e => e.fieldId === 'ieo-maintenance'
  );

  return (
    <Box w="100%" pos="relative">
      <Flex
        py="15px"
        bgSize="cover"
        bgColor="#E86F34"
        w="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex alignItems="center">
          <StructuredTextParser
            color="white"
            textAlign="center"
            mt="0"
            fontFamily="FedraSansStd-book"
            fontSize={{ md: '16px', lg: '20px' }}
            lineHeight={{ md: '1.6rem', lg: '1.6rem' }}
            str={render(maintenanceText?.body)}
            px={{ base: '5%', md: '1%' }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default IeoMaintenance;
