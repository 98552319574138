/* Built In Imports */
import { usePathname } from 'next/navigation';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { ProgramStructureSt } from '@ieo/Common/CompLangConfig';
import ProgramStructureCard from '@ieo/Sections/ProgramStructureCard';

/* Services */
/* Styles */

/**
 *
 * @param {Object} SectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} ProgramStructure
 */

const ProgramStructure = ({
  sectionContent,
  region,
  lang,
  pageName,
  pageData,
}) => {
  // console.log('sec content in program structure', sectionContent)
  const path = usePathname();
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  return (
    <Box
      background={{
        base: pageConfigVal
          ? `url(${pageConfigVal?.prgStructure?.bgImage?.mob}) repeat-x #F1EBDF`
          : `#F1EBDF`,
        sm: pageConfigVal
          ? `url(${pageConfigVal?.prgStructure?.bgImage?.tab}) repeat-x #F1EBDF`
          : `#F1EBDF`,
        md: pageConfigVal
          ? `url(${pageConfigVal?.prgStructure?.bgImage?.desktop}) repeat-x #F1EBDF`
          : `#F1EBDF`,
        lg: pageConfigVal
          ? `url(${pageConfigVal?.prgStructure?.bgImage?.bigScreen}) repeat-x #F1EBDF`
          : `#F1EBDF`,
      }}
      // bgRepeat="repeat-x"
      bgPos={{ base: 'inherit', md: 'top' }}
    // bgSize={{ base: 'auto 50px', md: 'auto 10%', xl: 'auto 15%' }}
    >
      <Flex
        maxW={1030}
        // bg="#F1EBDF"
        pt={{ base: '41px', md: '108px', xl: '151px' }}
        pb={{ base: '16px', md: '41px' }}
        margin={'0 auto'}
      >
        <Box w="full" mx={{ base: '1rem', xl: 'auto' }} pb="44.7px">
          <Flex
            alignItems={{ sm: 'center' }}
            borderBottom={{ sm: '1px solid #CF915E' }}
            flexDir={{ base: 'column', sm: 'row' }}
            justify="space-between"
          >
            <Box
              // w="full"
              as="h1"
              lineHeight={{ base: '42px', lg: 'initial' }}
              pb={{ base: '18px', md: '26px' }}
              {...ProgramStructureSt[lang]?.h2}
              w={{ base: '300px', md: '320px', xl: '480px' }}
            >
              {sectionContent?.[0]?.titleText}
            </Box>
            <Flex
              flexShrink="0"
              alignItems={{
                base: lang === 'ar' ? 'flex-end' : 'flex-start',
                sm: 'center',
              }}
              flexDir="column"
              bg="white"
              alignSelf="flex-end"
              p="14px 19px "
              mr="1rem"
              w={{ base: '100%', sm: 'auto' }}
              mb={{ sm: '29px', xl: '39px' }}
              maxW={{ base: 'calc(100% - 16px)', sm: 'max-content' }}
              pos="relative"
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
            >
              <Box
                color="#6D6358"
                fontWeight="500"
                {...ProgramStructureSt[lang]?.h6}
                dir={lang == 'ar' ? 'rtl' : 'ltr'}
              >
                {' '}
                {sectionContent?.[1]?.text}
              </Box>
              {pageName === 'ieomigrate' && (
                <Box>
                  <Box as="span" position="relative" top="-11px">
                    <Box
                      display="block"
                      width={{
                        base: '216px',
                        sm: '216px',
                        md: '230px',
                        lg: '230px',
                      }}
                      position="relative"
                      borderBottom="1px solid #000000"
                    >
                      {' '}
                      <Box
                        as="span"
                        bgColor="white"
                        pos="relative"
                        top="10px"
                        left="44%"
                        p="0 10px"
                      >
                        {sectionContent?.[5]?.text}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                color="#2A2719"
                {...ProgramStructureSt[lang]?.p}
                dir={lang == 'ar' ? 'rtl' : 'ltr'}
              >
                {' '}
                {sectionContent?.[2]?.text}
              </Box>
              <Flex
                alignItems="center"
                justify="center"
                p="5px"
                bg="white"
                w="41px"
                h="41px"
                borderRadius="50%"
                pos="absolute"
                right="-20px"
                top="25%"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19 3.16675C10.2555 3.16675 3.16663 10.2556 3.16663 19.0001C3.16663 27.7446 10.2555 34.8334 19 34.8334C27.7445 34.8334 34.8333 27.7446 34.8333 19.0001C34.8333 10.2556 27.7445 3.16675 19 3.16675ZM15.8333 16.2292H20.1874L20.1875 26.9167L17.8125 26.9167L17.8124 18.6042H15.8333V16.2292ZM17.8125 13.8542V11.4792H20.1875V13.8542H17.8125Z"
                    fill="#3652B8"
                  />
                </svg>
              </Flex>
            </Flex>
          </Flex>
          <Box mt="34px">
            <Text
              as="h4"
              textAlign={{
                base: 'center',
                md:
                  path.indexOf('inner-engineering-v2') > -1
                    ? 'right'
                    : 'center',
              }}
              pb={{ base: '18px', lg: '0px' }}
              {...ProgramStructureSt[lang]?.h4}
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
            >
              {sectionContent?.[3]?.text}
            </Text>

            <Flex
              gridRowGap={{ base: '0', sm: '40px' }}
              gridColumnGap={{ base: '0', sm: '20px' }}
              flexWrap="wrap"
              alignItems={{ base: 'flex-start', sm: 'center' }}
              mt={{ base: '0', md: '14.4px' }}
              justify={{ base: 'flex-start', sm: 'center' }}
              dir={lang === 'ar' ? 'rtl' : 'ltr'}
            >
              {sectionContent[4]?.cards?.map(card => {
                return (
                  <ProgramStructureCard
                    key={nanoid(4)}
                    lang={lang}
                    data={card}
                  />
                );
              })}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default ProgramStructure;
