/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import filter from 'lodash/filter';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import { usePageType } from '@hooks/usePageType';
import { ProgramFeeSt } from '@ieo/Common/CompLangConfig';
import { useProgramLanguageSelection } from '@ieo/Components/Form/ieo/context/programLanguageSelectionContext';
import { programTypes } from '@ieo/Components/Form/ieo/utils';

/* Services */
/* Styles */

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns ProgramFee
 */
const ProgramFeeV2 = ({ sectionContent, region, lang, prgData, pageData }) => {
  // console.log('prgData=>', prgData, pageData);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const priceData = Object.values(prgData);
  // console.log(priceData, 'pricedata');
  const [isGiftingFlow, setIsGiftingFlow] = useState();
  const { setProgramLanguage } = useProgramLanguageSelection();
  const transProviderObj =
    (pageData._allSharedJsonLocales?.length &&
      pageData._allSharedJsonLocales[0]?.value.json) ||
    pageData.sharedJson?.json;
  const { isGiftPage } = usePageType();
  const additionalText = sectionContent?.find(
    e => e.fieldId === 'additional-off'
  );
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const untilText = sectionContent?.find(e => e.fieldId === 'offer-until');
  const router = useRouter();
  const isLandingOrPcPage =
    region === 'in' &&
    (router.asPath?.split('?')[0].endsWith('inner-engineering-pc') ||
      router.asPath?.split('?')[0].endsWith('inner-engineering'));

  const gtmClick = (lang, price) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'IE_Language_Selected',
        IE_Lang: lang,
        IE_LP_Location: 'Language_Table',
      });

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currency: 'INR', // currency code (Ex - INR,USD,AUD etc.)
          value: price || priceData[0].discountedPrice || '',
          items: [
            {
              item_id: programTypes.IEO, // id of the program
              item_name: isGiftPage
                ? 'Gift Inner Engineering'
                : 'TYLS Inner Engineering', // Name of the program (Ex - TYLS Inner Engineering)
              affiliation: '',
              coupon: '',
              discount: 0, //Actual Discount if any (actual price - pay price)
              index: '',
              item_brand: '', // Country Code (Ex - US,IN etc.)
              item_category: '', // Language (Ex - English,Spanish etc.)
              item_category2: '', // Bay Name (not reuired for IE)
              item_category3: '', // New_User or Repeat_User
              item_category4: 'Language_Table',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: price || priceData[0].discountedPrice || '',
              quantity: '',
            },
          ],
        },
      });
    }
  };
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const sliderArr = ['english', 'hindi'];
  let PriceDataList = [];
  if (region === 'in') {
    config.orderedLangList[lang].forEach(language => {
      const price = priceData.filter(item => {
        return item.lang === language.toUpperCase();
      });
      if (price.length > 0) {
        PriceDataList.push(price[0]);
      }
    });
  } else {
    PriceDataList = [...priceData];
  }
  const isIndian = region === 'in';
  // const changeUi = !isIndian;
  const changeUi =
    router.asPath.indexOf('inner-engineering-pc') > 0 && isIndian;
  const [discountText, setDiscountText] = useState({});

  useEffect(() => {
    if (priceData?.length) {
      let filteredText = filter(pageConfigVal?.discounText, function (item) {
        return (
          item.code === priceData[0].coupon?.couponCode &&
          item.lang?.toLowerCase() === lang.toLowerCase()
        );
      });
      if (filteredText?.length) {
        setDiscountText(filteredText[0]);
      }
    }
  }, [priceData]);

  useEffect(() => {
    if (router.asPath.indexOf('/gift') > -1) {
      setIsGiftingFlow(true);
    }
  }, [router.asPath]);

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        bgColor={Math.ceil(activeSlide + 1) === 2 ? '#ffffff' : '#F37021'}
        onClick={onClick}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos="absolute"
        top={{ base: '220px', md: '200px', lg: '200px' }}
        right="0"
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor: Math.ceil(activeSlide + 1) === 2 ? '#ffffff' : '#BC570F',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.83337 11.3333L7.16671 6.99996L2.83337 2.66663"
            stroke={Math.ceil(activeSlide + 1) === 2 ? '#F37021' : '#ffffff'}
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        cursor="pointer"
        bgColor={Math.ceil(activeSlide + 1) === 2 ? '#F37021' : '#ffffff'}
        onClick={onClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos="absolute"
        top={{ base: '220px', md: '200px', lg: '200px' }}
        right="0"
        left={{ base: '0px', lg: '350px', xl: '400px' }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor: Math.ceil(activeSlide + 1) === 2 ? '#BC570F' : '#ffffff',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.16663 11.6678L2.83329 7.33443L7.16663 3.0011"
            stroke={Math.ceil(activeSlide + 1) === 2 ? '#ffffff' : '#F37021'}
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    arrow: false,
    dots: isMobile ? true : false,
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    initialSlide: 1,
    initialSlide: 0,
    centerMode: true,
    centerPadding: '0',
    nextArrow: isMobile ? <></> : <NextArrow />,
    prevArrow: isMobile ? <></> : <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 1.1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: '0',
        },
      },
    ],
  };

  return (
    <Box
      bg={
        pageConfigVal?.prgFees?.bgImage?.desktop
          ? `url(${pageConfigVal?.prgFees?.bgImage?.desktop})`
          : `url(https://static.sadhguru.org/d/46272/1694863180-program-fee.png)`
      }
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Flex
        maxW={1030}
        bgRepeat="no-repeat"
        pt={{ base: '60px', md: '100px' }}
        id="ieo-program-fees"
        mx="auto"
        mt={{ base: '0px', md: '-140px' }}
      >
        <Flex
          w="full"
          mx="auto"
          flexWrap="wrap"
          px={{ base: changeUi ? '0' : '16px', xl: 0 }}
          pt={{
            base: '0px',
            md: lang === 'ar' ? '30px' : '140px',
            xl: '140px',
          }}
        >
          <Flex
            w="full"
            px={{ base: changeUi ? '16px' : '0', xl: 0 }}
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent="space-between"
            alignItems={changeUi && { base: 'flex-start', lg: 'center' }} // just remove for the older ui
            gridGap={{ base: '30px', md: '80px', '2xl': '70px' }}
            css={{
              '-webkit-grid-gap': '40px',
              '@media screen and (min-width: 768px)': {
                '-webkit-grid-gap': '80px',
              },
            }}
            pb="40px"
          >
            <Box
              color="#fff"
              w={{
                base: '100%',
                md: '60%',
                lg: '80%',
                xl: pageConfigVal?.prgFees?.offerBg?.desktop ? '65%' : '60%',
                '2xl': pageConfigVal?.prgFees?.offerBg?.desktop ? '65%' : '60%',
              }}
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              alignItems={lang === 'ar' ? 'flex-end' : 'flex-start'}
            >
              <Heading as="h2" {...ProgramFeeSt[lang].h2}>
                {sectionContent?.[0]?.titleText}
              </Heading>
              <StructuredTextParser
                mt="10px"
                str={render(sectionContent?.[1].body)}
                {...ProgramFeeSt[lang].p}
                maxW="auto"
                color="#fff"
              />
              <StructuredTextParser
                mt="10px"
                str={render(sectionContent?.[2].body)}
                {...ProgramFeeSt[lang].p1}
                maxW="auto"
              />

              {region !== 'in' &&
                transProviderObj?.globalLanguages &&
                !isGiftingFlow && (
                  <Box position="relative" mt="20px">
                    <Button
                      flex="1"
                      bg="#CE6113"
                      border="none"
                      color="#fff"
                      w="50%"
                      maxW={{ base: 'auto', xl: '297px' }}
                      minW={{ base: '254px', md: '260px' }}
                      h={{ base: '36px', md: '46px' }}
                      rounded="full"
                      position="relative"
                      zIndex={88}
                      justifyContent="space-between"
                      _hover={{ border: 'none' }}
                      _focus={{ border: 'none' }}
                      _active={{ border: 'none' }}
                      // rightIcon={isOpen ? <TbChevronUp /> : <TbChevronDown />}
                      onClick={() => setIsOpen(!isOpen)}
                      textTransform="capitalize"
                      {...ProgramFeeSt[lang].selectLang}
                    >
                      {' '}
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        display="flex"
                        gridGap="10px"
                        alignItems="center"
                      >
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.12842 11.6252H25.8784V27.3752H15.7534L9.00342 31.1252V27.3752H4.12842V11.6252Z"
                            fill="white"
                          />
                          <path
                            d="M25.8751 21.3752H31.8784V5.62524H10.5034V11.6252M25.8784 11.6252H4.12842V27.3752H9.00342V31.1252L15.7534 27.3752H25.8784V11.6252Z"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="square"
                          />
                        </svg>
                        {transProviderObj?.formFields?.selectLanguage}
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30 13.5002L18 25.5002L6 13.5002"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="square"
                          />
                        </svg>
                      </Text>
                    </Button>

                    {isOpen && (
                      <Flex
                        _before={{ content: "''", height: '17px' }}
                        _after={{ content: "''", height: '17px' }}
                        display={isOpen ? 'block' : 'none'}
                        _hover={{ display: 'block' }}
                        className="options"
                        zIndex="9"
                        alignItems="flex-start"
                        alignContent="flex-start"
                        // onMouseLeave={() => {
                        //   setIsOpen(false);
                        //   setLocation(router.query?.location || 'All Locations');
                        // }}
                        onClick={() => setIsOpen(!isOpen)}
                        position="absolute"
                        top={{ base: '25px', md: '18px' }}
                        border="1px solid #EDD1C2"
                        left="0"
                        borderRadius="0 0 14px 14px"
                        minW={{ base: '250px', md: '260px' }}
                        flexDir="column"
                        bg="#ffffff"
                        mt={{ base: '0', md: '8px' }}
                        w="auto"
                        p="17px 17px 0 17px"
                      >
                        <Box padding="8px 0" cursor="pointer">
                          {Object.keys(transProviderObj?.globalLanguages)?.map(
                            (language, index) => {
                              return (
                                <Box
                                  padding="8px 0"
                                  key={nanoid()}
                                  cursor="pointer"
                                  onClick={() => {
                                    // console.log('Called onClick', item.lang);
                                    setProgramLanguage(language.toUpperCase());
                                    gtmClick(language.toUpperCase());
                                  }}
                                >
                                  <Text
                                    {...ProgramFeeSt[lang].selectLangDrowDown}
                                    color="#6D6358"
                                    textAlign="left"
                                    _hover={{ color: '#CE6113' }}
                                  >
                                    {
                                      transProviderObj?.globalLanguages[
                                        language
                                      ]
                                    }
                                  </Text>
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      </Flex>
                    )}
                  </Box>
                )}
            </Box>

            {region !== 'in' &&
            priceData[0]?.coupon?.couponCode &&
            pageConfigVal?.prgFees ? (
              <Flex
                bg={`url(${pageConfigVal?.prgFees?.offerBg?.desktop})`}
                bgRepeat="no-repeat"
                bgSize="cover"
                flexGrow="1"
                py={{ base: '20px', md: '30px' }}
                px={{ base: '20px', md: '70px' }}
                flexDir="column"
                position="relative"
              >
                <Flex
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  gridGap="20px"
                  css={{ '-webkit-grid-gap': '20px' }}
                >
                  <Flex>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.7985 17.5208L34.7477 15.8735L31.1611 15.9836L32.7744 13.985L31.4914 12.9517L28.9888 16.0514L24.6611 16.1827L28.4044 11.5459L27.5448 10.1527L21.7266 11.4315L23.7761 7.61624L27.6676 6.76087L27.3162 5.15176L24.8051 5.70224L26.5031 2.5433L25.0507 1.76414L23.3527 4.92309L22.4295 2.52636L20.8924 3.11919L22.3237 6.83709L20.2742 10.6524L18.1315 5.09247L16.497 5.14329L14.6931 10.8218L12.4149 7.13774L13.6217 3.33938L12.0507 2.83971L11.2758 5.28726L9.38723 2.23418L7.9856 3.10225L9.8742 6.15534L7.33772 5.75729L7.08365 7.38758L11.0175 8.00582L13.2957 11.6898L7.40971 10.7667L6.63479 12.2065L10.6491 16.6061L6.32144 16.7374L3.63676 13.7944L2.41722 14.9038L4.14913 16.8009L0.5625 16.911L0.613314 18.5582L4.19995 18.4481L2.5866 20.4468L3.86966 21.48L6.37225 18.3804L10.6999 18.2491L6.95662 22.8859L7.81622 24.2791L13.6344 23.0002L11.5849 26.8155L7.69342 27.6709L8.04489 29.28L10.5517 28.7295L8.85368 31.8885L10.3061 32.6676L12.0042 29.5087L12.9273 31.9054L14.4644 31.3126L13.0331 27.5947L15.0826 23.7794L17.2253 29.3393L18.8598 29.2885L20.6637 23.61L22.9419 27.294L21.7351 31.0924L23.3061 31.5921L24.0852 29.1445L25.9738 32.1976L27.3754 31.3295L25.4868 28.2764L28.0233 28.6745L28.2774 27.0484L24.3435 26.4302L22.0654 22.7462L27.9513 23.6693L28.7262 22.2296L24.7119 17.8299L29.0396 17.6986L31.7243 20.6416L32.9438 19.5322L31.2119 17.6351L34.7985 17.525V17.5208ZM20.6976 13.3413L25.7282 12.2361L22.493 16.242L19.08 16.3436L20.6976 13.3371V13.3413ZM17.3947 7.75598L19.2452 12.5622L17.6276 15.5687L15.8322 12.668L17.3905 7.76022L17.3947 7.75598ZM9.34912 12.7315L14.4348 13.5276L16.2302 16.4283L12.8172 16.5299L9.34488 12.7273L9.34912 12.7315ZM14.6634 21.0862L9.63283 22.1914L12.868 18.1856L16.2768 18.084L14.6592 21.0905L14.6634 21.0862ZM17.9664 26.6716L16.1159 21.8654L17.7335 18.8589L19.5289 21.7595L17.9706 26.6673L17.9664 26.6716ZM26.0119 21.696L20.9263 20.8999L19.1308 17.9993L22.5439 17.8976L26.0162 21.7002L26.0119 21.696Z"
                        fill="#BD0015"
                      />
                    </svg>
                  </Flex>
                  <StructuredTextParser
                    mt="0px"
                    str={render(sectionContent?.[3].body)}
                    {...ProgramFeeSt[lang].feeDiscount1}
                    maxW="auto"
                    color="#1A1D77"
                    fontSize={{ base: '16px', md: '18px' }}
                  />
                </Flex>

                {/* Older Ui code */}

                <Image
                  src={pageConfigVal?.prgFees?.offerBgFlower?.desktop}
                  alt=""
                  position="absolute"
                  bottom={{ base: '20px', md: '15px' }}
                  left="0px"
                />
              </Flex>
            ) : (
              <Flex flexDir="column">
                <Flex
                  //bg={`url(${pageConfigVal?.prgFees?.offerBg?.desktop})`}
                  bg="#fff"
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  flexGrow="1"
                  py={{ base: '20px', md: '30px' }}
                  px={{ base: '20px', md: '70px' }}
                  flexDir="column"
                  position="relative"
                >
                  <Flex
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    gridGap="20px"
                    css={{ '-webkit-grid-gap': '20px' }}
                  >
                    <Flex>
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34.7985 17.5208L34.7477 15.8735L31.1611 15.9836L32.7744 13.985L31.4914 12.9517L28.9888 16.0514L24.6611 16.1827L28.4044 11.5459L27.5448 10.1527L21.7266 11.4315L23.7761 7.61624L27.6676 6.76087L27.3162 5.15176L24.8051 5.70224L26.5031 2.5433L25.0507 1.76414L23.3527 4.92309L22.4295 2.52636L20.8924 3.11919L22.3237 6.83709L20.2742 10.6524L18.1315 5.09247L16.497 5.14329L14.6931 10.8218L12.4149 7.13774L13.6217 3.33938L12.0507 2.83971L11.2758 5.28726L9.38723 2.23418L7.9856 3.10225L9.8742 6.15534L7.33772 5.75729L7.08365 7.38758L11.0175 8.00582L13.2957 11.6898L7.40971 10.7667L6.63479 12.2065L10.6491 16.6061L6.32144 16.7374L3.63676 13.7944L2.41722 14.9038L4.14913 16.8009L0.5625 16.911L0.613314 18.5582L4.19995 18.4481L2.5866 20.4468L3.86966 21.48L6.37225 18.3804L10.6999 18.2491L6.95662 22.8859L7.81622 24.2791L13.6344 23.0002L11.5849 26.8155L7.69342 27.6709L8.04489 29.28L10.5517 28.7295L8.85368 31.8885L10.3061 32.6676L12.0042 29.5087L12.9273 31.9054L14.4644 31.3126L13.0331 27.5947L15.0826 23.7794L17.2253 29.3393L18.8598 29.2885L20.6637 23.61L22.9419 27.294L21.7351 31.0924L23.3061 31.5921L24.0852 29.1445L25.9738 32.1976L27.3754 31.3295L25.4868 28.2764L28.0233 28.6745L28.2774 27.0484L24.3435 26.4302L22.0654 22.7462L27.9513 23.6693L28.7262 22.2296L24.7119 17.8299L29.0396 17.6986L31.7243 20.6416L32.9438 19.5322L31.2119 17.6351L34.7985 17.525V17.5208ZM20.6976 13.3413L25.7282 12.2361L22.493 16.242L19.08 16.3436L20.6976 13.3371V13.3413ZM17.3947 7.75598L19.2452 12.5622L17.6276 15.5687L15.8322 12.668L17.3905 7.76022L17.3947 7.75598ZM9.34912 12.7315L14.4348 13.5276L16.2302 16.4283L12.8172 16.5299L9.34488 12.7273L9.34912 12.7315ZM14.6634 21.0862L9.63283 22.1914L12.868 18.1856L16.2768 18.084L14.6592 21.0905L14.6634 21.0862ZM17.9664 26.6716L16.1159 21.8654L17.7335 18.8589L19.5289 21.7595L17.9706 26.6673L17.9664 26.6716ZM26.0119 21.696L20.9263 20.8999L19.1308 17.9993L22.5439 17.8976L26.0162 21.7002L26.0119 21.696Z"
                          fill="#BD0015"
                        />
                      </svg>
                    </Flex>
                    <StructuredTextParser
                      id="program-fee-text"
                      mt="0px"
                      str={render(sectionContent?.[3].body)}
                      {...ProgramFeeSt[lang].feeDiscount1}
                      maxW="auto"
                      color="#1A1D77"
                      fontSize={{ base: '16px', md: '18px' }}
                    />
                  </Flex>
                </Flex>
                <Box
                  w={{ base: '100%', lg: '500px', xl: '539px' }}
                  mt={{ base: '30px', md: '20px', lg: '25px' }}
                  position="relative"
                >
                  {priceData?.length > 0 && (
                    <>
                      <Slider {...settings}>
                        {sliderArr?.map((item, index) => (
                          <Box key={index} display="flex">
                            <Box
                              position="relative"
                              textAlign="center"
                              borderRadius={{ base: '3.8px', md: '0' }}
                              py="14px"
                              ml={!index ? '0' : '10px'}
                              mr={index > 0 ? '0' : '10px'}
                              bg="#fff"
                            >
                              {index === 0 && (
                                <>
                                  {' '}
                                  <StructuredTextParser
                                    str={`${
                                      config.lngList[
                                        priceData[0]?.lang?.toLowerCase()
                                      ]
                                    }`}
                                    maxW="none"
                                    {...ProgramFeeSt[lang].regionalLanguages}
                                    color="#A69786"
                                    textAlign="center"
                                    fontSize={{ base: '20px', md: '30px' }}
                                    mt="0px !important"
                                  />
                                  <StructuredTextParser
                                    str={`${priceData[0]?.curr} ${priceData[0]?.pgmFee}`}
                                    maxW="none"
                                    textDecoration="line-through"
                                    textAlign="center"
                                    {...ProgramFeeSt[lang].feeDiscount2}
                                    color="#6D6358"
                                    fontSize={{ base: '24px', md: '36px' }}
                                    mt="9px !important"
                                    lineHeight="40px"
                                  />
                                  <StructuredTextParser
                                    id="discounted-price-text"
                                    str={`${priceData[0]?.curr} ${priceData[0]?.discountedPrice}`}
                                    maxW="none"
                                    mt="0px !important"
                                    textAlign="center"
                                    {...ProgramFeeSt[lang].feeDiscount3}
                                    color="#CD9F00"
                                    fontSize={{ base: '30px', md: '44px' }}
                                  />
                                </>
                              )}

                              {index === 1 && (
                                <>
                                  <StructuredTextParser
                                    str={render(sectionContent?.[6]?.body)}
                                    maxW="none"
                                    {...ProgramFeeSt[lang].regionalLanguages}
                                    color="#A69786"
                                    textAlign="center"
                                    fontSize={{ base: '20px', md: '30px' }}
                                    mt="0px !important"
                                  />
                                  <StructuredTextParser
                                    str={`${priceData[1]?.curr} ${priceData[1]?.pgmFee}`}
                                    textDecoration="line-through"
                                    maxW="auto"
                                    color="#6D6358"
                                    textAlign="center"
                                    {...ProgramFeeSt[lang].feeDiscount2}
                                    fontSize={{ base: '24px', md: '36px' }}
                                    mt="9px !important"
                                    lineHeight="40px"
                                  />
                                  <StructuredTextParser
                                    str={`${priceData[1]?.curr} ${priceData[1]?.discountedPrice}`}
                                    mt="0px !important"
                                    maxW="auto"
                                    color="#CD9F00"
                                    textAlign="center"
                                    {...ProgramFeeSt[lang].feeDiscount3}
                                    fontSize={{ base: '30px', md: '44px' }}
                                  />
                                </>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Slider>
                      <Box
                        color="white"
                        display={{ base: 'none', md: 'flex' }}
                        justifyContent="end"
                        fontFamily="FedraSansStd-medium"
                        pr={{ md: '45%', xl: '170px' }}
                        pt="30px"
                        fontSize="30px"
                      >
                        {' '}
                        {Math.ceil(activeSlide + 1)}/{sliderArr?.length}
                      </Box>
                    </>
                  )}
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>

        <style global>
          {`
            .thanksgiving:hover svg {
              fill: #E86F34;
              color: #E86F34;
            }
            .thanksgiving:hover {
              background: '#FAE4B8';
              color: '#FFFFFF';
            }
              `}
        </style>
        <style jsx global>
          {`
            .slick-dots li {
              margin: 0;
              width: 15px;
            }
            .slick-dots li.slick-active button:before {
              color: white;
              opacity: 1;
            }
            .slick-dots li button:before {
              color: #8f8f8f;
              opacity: 1;
            }
          `}
        </style>
      </Flex>
    </Box>
  );
};

export default ProgramFeeV2;
