/* Built In Imports */

/* External Imports */
import { Box, Flex, Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Configs */
import programStructureCardSt from '@ieo/Common/StyleData/ProgramStructureCardSt';

/* Styles */
/* Services */

/**
 * @param {String} lang
 * @param {Object} data
 * @returns {ReactElement} ProgramStructureCard
 */

const ProgramStructureCard = ({ data, lang }) => {
  return (
    <Box
      pos="relative"
      mt={{ base: '20px', md: '0' }}
      ml={{ base: '5px', sm: '0' }}
      mr={{ base: '5px', sm: '0' }}
      marginBottom={{ base: '40px', md: '60px' }}
    >
      <Flex
        // cursor="pointer"
        bg={`url(${data?.thumbnail?.url})`}
        width={{ base: '138px', sm: '244px' }}
        bgPos="top center"
        bgSize="cover"
        justify="flex-end"
        bgRepeat="no-repeat"
        flexDir="column"
        height={{ base: '138px', sm: '244px' }}
        pb="20px"
      />
      <Flex
        bg="white"
        w={{ base: '116px', sm: '219px' }}
        h={{ base: '55px', sm: '97px' }}
        left={{ base: '11px', sm: '12.5px' }}
        right={{ base: '11px', sm: '12.5px' }}
        pos="absolute"
        bottom={{ base: '-40px', md: '-60px' }}
        // p="25px 25px 27px 25px"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        boxShadow="0 12px 14px -10px  #A45B0B"
        px={{ base: '7px', md: '20px' }}
      >
        <Heading 
          as="h2" {...programStructureCardSt[lang].cardV3} 
          color={lang === 'ta' ? '#12166F' : '#7A6E4B'}
        >
          {data?.title}
        </Heading>
      </Flex>
    </Box>
  );
};

export default ProgramStructureCard;
