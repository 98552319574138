/* Built In Imports */
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
// import copy from 'copy-to-clipboard';
import { render } from 'datocms-structured-text-to-html-string';
import filter from 'lodash/filter';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import { usePageType } from '@hooks/usePageType';
import { ProgramFeeSt } from '@ieo/Common/CompLangConfig';
import { gtmObject } from '@ieo/Components/Form/ieo/api/analyticsAPi';
import { useProgramLanguageSelection } from '@ieo/Components/Form/ieo/context/programLanguageSelectionContext';
import { programTypes } from '@ieo/Components/Form/ieo/utils';
import { mixPanel } from '@utils/Mixpanel';

/* Services */
/* Styles */

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns ProgramFee
 */
const ProgramFeeV5 = ({ sectionContent, region, lang, prgData, pageData }) => {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const priceData = Object?.values(prgData || '');
  const [isGiftingFlow, setIsGiftingFlow] = useState();
  const { setProgramLanguage } = useProgramLanguageSelection();
  const transProviderObj =
    (pageData._allSharedJsonLocales?.length &&
      pageData._allSharedJsonLocales[0]?.value.json) ||
    pageData.sharedJson?.json;
  const { isGiftPage } = usePageType();
  const additionalText = sectionContent?.find(
    e => e.fieldId === 'additional-off'
  );
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const untilText = sectionContent?.find(e => e.fieldId === 'offer-until');
  const router = useRouter();
  const isLandingOrPcPage =
    region === 'in' &&
    (router.asPath?.split('?')[0].endsWith('inner-engineering-pc') ||
      router.asPath?.split('?')[0].endsWith('inner-engineering'));

  // const isUS = pageData?.country === 'US';
  // const isCA = pageData?.country === 'CA';

  const gtmClick = (lang, price) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'IE_Language_Selected',
        IE_Lang: lang,
        IE_LP_Location: 'Language_Table',
      });

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currency: 'INR', // currency code (Ex - INR,USD,AUD etc.)
          value: price || priceData[0].discountedPrice || '',
          items: [
            {
              item_id: programTypes.IEO, // id of the program
              item_name: isGiftPage
                ? 'Gift Inner Engineering'
                : 'TYLS Inner Engineering', // Name of the program (Ex - TYLS Inner Engineering)
              affiliation: '',
              coupon: '',
              discount: 0, //Actual Discount if any (actual price - pay price)
              index: '',
              item_brand: '', // Country Code (Ex - US,IN etc.)
              item_category: '', // Language (Ex - English,Spanish etc.)
              item_category2: '', // Bay Name (not reuired for IE)
              item_category3: '', // New_User or Repeat_User
              item_category4: 'Language_Table',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: price || priceData[0].discountedPrice || '',
              quantity: '',
            },
          ],
        },
      });

      gtmObject({
        event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
        IE_LP_Location: 'Sticky_CTA',
        event1: 'view_item',
        item_name: isGiftPage
          ? 'Gift Inner Engineering'
          : 'TYLS Inner Engineering',
        item_category4: 'Language_Table',
      });
    }
    const Mixpanel = mixPanel(router.asPath);
    if (Object.keys(Mixpanel)?.length > 0) {
      Mixpanel.track('ie_registration_cta', {
        cta: 'Program section',
        element:
          pathname.indexOf('inner-engineering-v4') > -1
            ? 'Start My Transformation'
            : 'Register Now',
        language: config.lngList[lang.toLowerCase()],
      });
    }
  };

  let PriceDataList = [];
  if (region === 'in') {
    config.orderedLangList[lang].forEach(language => {
      const price = priceData.filter(item => {
        return item.lang === language.toUpperCase();
      });
      if (price.length > 0) {
        PriceDataList.push(price[0]);
      }
    });
  } else {
    PriceDataList = [...priceData];
  }
  const isIndian = region === 'in';
  // const changeUi = !isIndian;
  const changeUi =
    router.asPath.indexOf('inner-engineering-pc') > 0 && isIndian;
  const [discountText, setDiscountText] = useState({});

  useEffect(() => {
    if (priceData?.length) {
      let filteredText = filter(pageConfigVal?.discounText, function (item) {
        return (
          item.code === priceData[0].coupon?.couponCode &&
          item.lang?.toLowerCase() === lang.toLowerCase()
        );
      });
      if (filteredText?.length) {
        setDiscountText(filteredText[0]);
      }
    }
  }, [priceData]);

  useEffect(() => {
    if (router.asPath.indexOf('/gift') > -1) {
      setIsGiftingFlow(true);
    }
  }, [router.asPath]);

  // const copyStyle = {
  //   Normal: {
  //     background: '#F1EBDF78',
  //     color: '#5b5237',
  //   },
  //   Selected: {
  //     background: '#E86F34',
  //     color: '#FFFFFF',
  //   },
  // };
  // const clickhandle = () => {
  //   copy('THANKS50');
  //   setActiveCopy(1);
  // }
  return (
    <Box bg="#F1EBDF">
      <Flex
        maxW={1030}
        bgRepeat="no-repeat"
        pt={{ base: '60px', md: '50px' }}
        id="ieo-program-fees"
        mx="auto"
        // mt={{ base: '0px', md: '-140px' }}
      >
        <Flex
          w="full"
          mx="auto"
          flexWrap="wrap"
          px={{ base: changeUi ? '0' : '16px', xl: 0 }}
          pt={{
            base: '0px',
            md: lang === 'ar' ? '30px' : '80px',
            xl: '60px',
          }}
        >
          <Flex
            w="full"
            px={{ base: changeUi ? '16px' : '0', xl: 0 }}
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent="space-between"
            alignItems={changeUi && { base: 'flex-start', lg: 'center' }} // just remove for the older ui
            gridGap={{ base: '30px', md: '80px', '2xl': '70px' }}
            css={{
              '-webkit-grid-gap': '40px',
              '@media screen and (min-width: 768px)': {
                '-webkit-grid-gap': '80px',
              },
            }}
            pb="40px"
          >
            <Box
              color="#fff"
              w={{
                base: '100%',
                md: '60%',
                lg: '80%',
                xl: pageConfigVal?.prgFees?.offerBg?.desktop ? '65%' : '60%',
                '2xl': pageConfigVal?.prgFees?.offerBg?.desktop ? '65%' : '60%',
              }}
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              alignItems={lang === 'ar' ? 'flex-end' : 'flex-start'}
            >
              <Heading as="h2" {...ProgramFeeSt[lang].h2} color="#3B444B">
                {sectionContent?.[0]?.titleText}
              </Heading>
              <StructuredTextParser
                mt="10px"
                str={render(sectionContent?.[1].body)}
                {...ProgramFeeSt[lang].p}
                maxW="auto"
                color="#3B444B"
              />
              <StructuredTextParser
                mt="10px"
                str={render(sectionContent?.[2].body)}
                {...ProgramFeeSt[lang].p1}
                maxW="auto"
                color="#3B444B"
              />

              {region !== 'in' &&
                transProviderObj?.globalLanguages &&
                !isGiftingFlow && (
                  <Box position="relative" mt="20px">
                    <Button
                      flex="1"
                      bg="#CE6113"
                      border="none"
                      color="#fff"
                      w="50%"
                      maxW={{ base: 'auto', xl: '297px' }}
                      minW={{ base: '254px', md: '260px' }}
                      h={{ base: '36px', md: '46px' }}
                      rounded="full"
                      position="relative"
                      zIndex={88}
                      justifyContent="space-between"
                      _hover={{ border: 'none' }}
                      _focus={{ border: 'none' }}
                      _active={{ border: 'none' }}
                      // rightIcon={isOpen ? <TbChevronUp /> : <TbChevronDown />}
                      onClick={() => setIsOpen(!isOpen)}
                      textTransform="capitalize"
                      {...ProgramFeeSt[lang].selectLang}
                    >
                      {' '}
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        display="flex"
                        gridGap="10px"
                        alignItems="center"
                      >
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.12842 11.6252H25.8784V27.3752H15.7534L9.00342 31.1252V27.3752H4.12842V11.6252Z"
                            fill="white"
                          />
                          <path
                            d="M25.8751 21.3752H31.8784V5.62524H10.5034V11.6252M25.8784 11.6252H4.12842V27.3752H9.00342V31.1252L15.7534 27.3752H25.8784V11.6252Z"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="square"
                          />
                        </svg>
                        {transProviderObj?.formFields?.selectLanguage}
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30 13.5002L18 25.5002L6 13.5002"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="square"
                          />
                        </svg>
                      </Text>
                    </Button>

                    {isOpen && (
                      <Flex
                        _before={{ content: "''", height: '17px' }}
                        _after={{ content: "''", height: '17px' }}
                        display={isOpen ? 'block' : 'none'}
                        _hover={{ display: 'block' }}
                        className="options"
                        zIndex="9"
                        alignItems="flex-start"
                        alignContent="flex-start"
                        // onMouseLeave={() => {
                        //   setIsOpen(false);
                        //   setLocation(router.query?.location || 'All Locations');
                        // }}
                        onClick={() => setIsOpen(!isOpen)}
                        position="absolute"
                        top={{ base: '25px', md: '18px' }}
                        border="1px solid #EDD1C2"
                        left="0"
                        borderRadius="0 0 14px 14px"
                        minW={{ base: '250px', md: '260px' }}
                        flexDir="column"
                        bg="#ffffff"
                        mt={{ base: '0', md: '8px' }}
                        w="auto"
                        p="17px 17px 0 17px"
                      >
                        <Box padding="8px 0" cursor="pointer">
                          {Object.keys(transProviderObj?.globalLanguages)?.map(
                            (language, index) => {
                              return (
                                <Box
                                  padding="8px 0"
                                  key={nanoid()}
                                  cursor="pointer"
                                  onClick={() => {
                                    // console.log('Called onClick', item.lang);
                                    setProgramLanguage(language.toUpperCase());
                                    gtmClick(language.toUpperCase());
                                  }}
                                >
                                  <Text
                                    {...ProgramFeeSt[lang].selectLangDrowDown}
                                    color="#6D6358"
                                    textAlign="left"
                                    _hover={{ color: '#CE6113' }}
                                  >
                                    {
                                      transProviderObj?.globalLanguages[
                                        language
                                      ]
                                    }
                                  </Text>
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      </Flex>
                    )}
                  </Box>
                )}
            </Box>

            {region !== 'in' &&
            priceData[0]?.coupon?.couponCode &&
            pageConfigVal?.prgFees ? (
              <Flex
                bg={`url(${pageConfigVal?.prgFees?.offerBg?.desktop})`}
                bgRepeat="no-repeat"
                bgSize="cover"
                flexGrow="1"
                py={{ base: '20px', md: '30px' }}
                px={{ base: '20px', md: '70px' }}
                flexDir="column"
                position="relative"
              >
                <Flex
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  gridGap="20px"
                  css={{ '-webkit-grid-gap': '20px' }}
                >
                  <Flex>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.7985 17.5208L34.7477 15.8735L31.1611 15.9836L32.7744 13.985L31.4914 12.9517L28.9888 16.0514L24.6611 16.1827L28.4044 11.5459L27.5448 10.1527L21.7266 11.4315L23.7761 7.61624L27.6676 6.76087L27.3162 5.15176L24.8051 5.70224L26.5031 2.5433L25.0507 1.76414L23.3527 4.92309L22.4295 2.52636L20.8924 3.11919L22.3237 6.83709L20.2742 10.6524L18.1315 5.09247L16.497 5.14329L14.6931 10.8218L12.4149 7.13774L13.6217 3.33938L12.0507 2.83971L11.2758 5.28726L9.38723 2.23418L7.9856 3.10225L9.8742 6.15534L7.33772 5.75729L7.08365 7.38758L11.0175 8.00582L13.2957 11.6898L7.40971 10.7667L6.63479 12.2065L10.6491 16.6061L6.32144 16.7374L3.63676 13.7944L2.41722 14.9038L4.14913 16.8009L0.5625 16.911L0.613314 18.5582L4.19995 18.4481L2.5866 20.4468L3.86966 21.48L6.37225 18.3804L10.6999 18.2491L6.95662 22.8859L7.81622 24.2791L13.6344 23.0002L11.5849 26.8155L7.69342 27.6709L8.04489 29.28L10.5517 28.7295L8.85368 31.8885L10.3061 32.6676L12.0042 29.5087L12.9273 31.9054L14.4644 31.3126L13.0331 27.5947L15.0826 23.7794L17.2253 29.3393L18.8598 29.2885L20.6637 23.61L22.9419 27.294L21.7351 31.0924L23.3061 31.5921L24.0852 29.1445L25.9738 32.1976L27.3754 31.3295L25.4868 28.2764L28.0233 28.6745L28.2774 27.0484L24.3435 26.4302L22.0654 22.7462L27.9513 23.6693L28.7262 22.2296L24.7119 17.8299L29.0396 17.6986L31.7243 20.6416L32.9438 19.5322L31.2119 17.6351L34.7985 17.525V17.5208ZM20.6976 13.3413L25.7282 12.2361L22.493 16.242L19.08 16.3436L20.6976 13.3371V13.3413ZM17.3947 7.75598L19.2452 12.5622L17.6276 15.5687L15.8322 12.668L17.3905 7.76022L17.3947 7.75598ZM9.34912 12.7315L14.4348 13.5276L16.2302 16.4283L12.8172 16.5299L9.34488 12.7273L9.34912 12.7315ZM14.6634 21.0862L9.63283 22.1914L12.868 18.1856L16.2768 18.084L14.6592 21.0905L14.6634 21.0862ZM17.9664 26.6716L16.1159 21.8654L17.7335 18.8589L19.5289 21.7595L17.9706 26.6673L17.9664 26.6716ZM26.0119 21.696L20.9263 20.8999L19.1308 17.9993L22.5439 17.8976L26.0162 21.7002L26.0119 21.696Z"
                        fill="#BD0015"
                      />
                    </svg>
                  </Flex>
                  <StructuredTextParser
                    mt="0px"
                    str={render(sectionContent?.[3].body)}
                    {...ProgramFeeSt[lang].feeDiscount1}
                    maxW="auto"
                    color="#1A1D77"
                    fontSize={{ base: '16px', md: '18px' }}
                  />
                </Flex>

                {/* Older Ui code */}
                {!changeUi && (
                  <Box
                    borderBottom={{
                      base: '1px solid #6D6358',
                      md: '2px solid #6D6358',
                    }}
                    opacity={0.3}
                    mt="20px"
                  />
                )}

                {!changeUi && priceData?.length > 0 && (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    pb={{ base: '70px', md: '80px' }}
                  >
                    <StructuredTextParser
                      str={`${priceData[0]?.curr} ${priceData[0]?.pgmFee}`}
                      maxW="none"
                      textDecoration="line-through"
                      {...ProgramFeeSt[lang].feeDiscount2}
                      color="#9F8569"
                    />

                    <StructuredTextParser
                      str={`${priceData[0]?.curr} ${priceData[0]?.discountedPrice}`}
                      maxW="none"
                      textDecor="line-through"
                      mt="0px"
                      {...ProgramFeeSt[lang].feeDiscount3}
                      color="#CD9F00"
                    />
                    <Box
                      fontSize={{ base: '26px', md: '30px' }}
                      color="#BD0015"
                      fontFamily="'FedraSansStd-A-medium'"
                      pb="16px"
                      textAlign="center"
                    >
                      {discountText?.text}{' '}
                    </Box>
                    <Box
                      fontSize={{ base: '16px', md: '18px' }}
                      fontFamily="'FedraSansStd-medium'"
                    >
                      <StructuredTextParser
                        str={render(additionalText?.body)?.replace(
                          '#num#',
                          priceData[0]?.coupon.offerValue
                        )}
                        color="#9B7800"
                      />
                    </Box>
                    <Box
                      fontSize={{ base: '32px', md: '42px' }}
                      color="#1A1D77"
                      fontFamily="'FedraSansStd-A-medium'"
                    >
                      {priceData[0]?.curr}{' '}
                      {priceData[0]?.coupon?.postCouponPrice}
                    </Box>
                    {!isLandingOrPcPage && (
                      <Flex
                        fontSize={{ base: '14px', md: '16px' }}
                        color="#9B7800"
                        fontFamily="'FedraSansStd-book-i'"
                        fontWeight="600"
                        paddingLeft="5px"
                      >
                        <StructuredTextParser
                          str={render(untilText?.body)}
                          color="#9B7800"
                        />
                        <Box mt="15px" ml="5px">
                          {moment(priceData[0]?.coupon?.expiryDate).format(
                            'Do MMMM YYYY'
                          )}
                        </Box>
                      </Flex>
                    )}
                  </Flex>
                )}

                <Image
                  src={pageConfigVal?.prgFees?.offerBgFlower?.desktop}
                  alt=""
                  position="absolute"
                  bottom={{ base: '20px', md: '15px' }}
                  left="0px"
                />
              </Flex>
            ) : priceData[0]?.coupon?.couponCode && pageConfigVal?.prgFees ? (
              <Flex
                bg={`url(${pageConfigVal?.prgFees?.offerBg?.desktop})`}
                bgRepeat="no-repeat"
                bgSize="cover"
                flexGrow="1"
                py={{ base: '20px', md: '30px' }}
                px={{ base: '20px', md: '70px' }}
                flexDir="column"
                position="relative"
              >
                <Flex
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  gridGap="20px"
                  css={{ '-webkit-grid-gap': '20px' }}
                >
                  <Flex>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.7985 17.5208L34.7477 15.8735L31.1611 15.9836L32.7744 13.985L31.4914 12.9517L28.9888 16.0514L24.6611 16.1827L28.4044 11.5459L27.5448 10.1527L21.7266 11.4315L23.7761 7.61624L27.6676 6.76087L27.3162 5.15176L24.8051 5.70224L26.5031 2.5433L25.0507 1.76414L23.3527 4.92309L22.4295 2.52636L20.8924 3.11919L22.3237 6.83709L20.2742 10.6524L18.1315 5.09247L16.497 5.14329L14.6931 10.8218L12.4149 7.13774L13.6217 3.33938L12.0507 2.83971L11.2758 5.28726L9.38723 2.23418L7.9856 3.10225L9.8742 6.15534L7.33772 5.75729L7.08365 7.38758L11.0175 8.00582L13.2957 11.6898L7.40971 10.7667L6.63479 12.2065L10.6491 16.6061L6.32144 16.7374L3.63676 13.7944L2.41722 14.9038L4.14913 16.8009L0.5625 16.911L0.613314 18.5582L4.19995 18.4481L2.5866 20.4468L3.86966 21.48L6.37225 18.3804L10.6999 18.2491L6.95662 22.8859L7.81622 24.2791L13.6344 23.0002L11.5849 26.8155L7.69342 27.6709L8.04489 29.28L10.5517 28.7295L8.85368 31.8885L10.3061 32.6676L12.0042 29.5087L12.9273 31.9054L14.4644 31.3126L13.0331 27.5947L15.0826 23.7794L17.2253 29.3393L18.8598 29.2885L20.6637 23.61L22.9419 27.294L21.7351 31.0924L23.3061 31.5921L24.0852 29.1445L25.9738 32.1976L27.3754 31.3295L25.4868 28.2764L28.0233 28.6745L28.2774 27.0484L24.3435 26.4302L22.0654 22.7462L27.9513 23.6693L28.7262 22.2296L24.7119 17.8299L29.0396 17.6986L31.7243 20.6416L32.9438 19.5322L31.2119 17.6351L34.7985 17.525V17.5208ZM20.6976 13.3413L25.7282 12.2361L22.493 16.242L19.08 16.3436L20.6976 13.3371V13.3413ZM17.3947 7.75598L19.2452 12.5622L17.6276 15.5687L15.8322 12.668L17.3905 7.76022L17.3947 7.75598ZM9.34912 12.7315L14.4348 13.5276L16.2302 16.4283L12.8172 16.5299L9.34488 12.7273L9.34912 12.7315ZM14.6634 21.0862L9.63283 22.1914L12.868 18.1856L16.2768 18.084L14.6592 21.0905L14.6634 21.0862ZM17.9664 26.6716L16.1159 21.8654L17.7335 18.8589L19.5289 21.7595L17.9706 26.6673L17.9664 26.6716ZM26.0119 21.696L20.9263 20.8999L19.1308 17.9993L22.5439 17.8976L26.0162 21.7002L26.0119 21.696Z"
                        fill="#BD0015"
                      />
                    </svg>
                  </Flex>
                  <StructuredTextParser
                    mt="0px"
                    str={render(sectionContent?.[3].body)}
                    {...ProgramFeeSt[lang].feeDiscount1}
                    maxW="auto"
                    color="#1A1D77"
                    fontSize={{ base: '16px', md: '18px' }}
                  />
                </Flex>

                {/* Older Ui code */}
                {!changeUi && (
                  <Box
                    borderBottom={{
                      base: '1px solid #6D6358',
                      md: '2px solid #6D6358',
                    }}
                    opacity={0.3}
                    mt="20px"
                  />
                )}

                {!changeUi && priceData?.length > 0 && (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StructuredTextParser
                      str={`${priceData[0]?.curr} ${priceData[0]?.pgmFee}`}
                      maxW="none"
                      textDecoration="line-through"
                      {...ProgramFeeSt[lang].feeDiscount2}
                      color="#6D6358"
                    />

                    <Box
                      fontSize={{ base: '26px', md: '30px' }}
                      color="#BD0015"
                      fontFamily="'FedraSansStd-A-medium'"
                      textAlign="center"
                    >
                      {discountText?.text}{' '}
                    </Box>
                    <Flex
                      fontSize={{ base: '16px', md: '18px' }}
                      fontFamily="'FedraSansStd-medium'"
                      flexDir={{ base: 'column', sm: 'row' }}
                    >
                      <StructuredTextParser
                        mt="0"
                        str={render(additionalText?.body)?.replace(
                          '#num#',
                          priceData[0]?.coupon.offerValue
                        )}
                        color="#9B7800"
                      />

                      {!isLandingOrPcPage && (
                        <Flex
                          fontSize={{ base: '14px', md: '16px' }}
                          color="#9B7800"
                          fontFamily="'FedraSansStd-book-i'"
                          fontWeight="600"
                          paddingLeft="5px"
                        >
                          {' '}
                          <StructuredTextParser
                            str={render(untilText?.body)}
                            mt="0"
                            color="#9B7800"
                          />
                          <Box ml="5px">
                            {moment(priceData[0]?.coupon?.expiryDate).format(
                              'Do MMMM YYYY'
                            )}
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                    <Flex alignItems="center">
                      <StructuredTextParser
                        str={`${priceData[0]?.curr} ${priceData[0]?.discountedPrice}`}
                        maxW="none"
                        mt="0px"
                        textDecor="line-through"
                        {...ProgramFeeSt[lang].couponFeeDiscount3}
                        color="#CD9F00"
                      />

                      <Box
                        ml="10px"
                        fontSize={{ base: '32px', md: '42px' }}
                        color="#CD9F00"
                        fontFamily="'FedraSansStd-A-medium'"
                        {...ProgramFeeSt[lang].couponFee}
                      >
                        {priceData[0]?.curr}{' '}
                        {priceData[0]?.coupon?.postCouponPrice}
                      </Box>
                    </Flex>
                    {priceData.length > 1 && (
                      <>
                        <StructuredTextParser
                          str={render(sectionContent?.[6]?.body)}
                          maxW="none"
                          {...ProgramFeeSt[lang].regionalLanguages}
                          color="#A69786"
                        />
                        <StructuredTextParser
                          id="program-fee-price"
                          str={`${priceData[1].curr} ${priceData[1].pgmFee}`}
                          textDecoration="line-through"
                          maxW="auto"
                          mt="17px"
                          color="#6D6358"
                          {...ProgramFeeSt[lang].feeDiscount2}
                        />
                        <Flex alignItems="center">
                          <StructuredTextParser
                            str={`${priceData[1].curr} ${priceData[1].discountedPrice}`}
                            mt="0px"
                            maxW="auto"
                            textDecor="line-through"
                            color="#CD9F00"
                            {...ProgramFeeSt[lang].couponFeeDiscount3}
                          />
                          <Text
                            color="#CD9F00"
                            ml="7px"
                            {...ProgramFeeSt[lang].postCouponPrice}
                          >
                            INR {priceData[1].coupon.postCouponPrice}
                          </Text>
                        </Flex>
                      </>
                    )}
                    {/* <Flex
                      fontSize={{ base: '14px', md: '16px' }}
                      color="#9B7800"
                      fontFamily="'FedraSansStd-book-i'"
                      fontWeight="600"
                    >
                      {' '}
                      <StructuredTextParser
                        str={render(untilText?.body)}
                        color="#9B7800"
                      />
                      <Box mt="15px" ml="5px">
                        {moment(priceData[0]?.coupon?.expiryDate).format(
                          'Do MMMM YYYY'
                        )}
                      </Box>
                    </Flex> */}
                  </Flex>
                )}
              </Flex>
            ) : (
              <Flex
                bg="#fff !important"
                flexGrow="1"
                py={{ base: '20px', md: '30px' }}
                px={{ base: '20px', md: '70px' }}
                flexDir="column"
              >
                <Flex
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  gridGap="20px"
                  css={{ '-webkit-grid-gap': '20px' }}
                >
                  {lang === 'ar' ? (
                    <>
                      <StructuredTextParser
                        id="program-fee-text"
                        mt="0"
                        str={render(sectionContent?.[3].body)}
                        {...ProgramFeeSt[lang].feeDiscount1}
                        maxW="auto"
                        color="#6D6358"
                      />
                      <Flex>
                        <svg
                          stroke="#be1931"
                          fill="#be1931"
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          height="2.5em"
                          width="2.5em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14 20.408c-.492.308-.903.546-1.192.709-.153.086-.308.17-.463.252h-.002a.75.75 0 0 1-.686 0 16.709 16.709 0 0 1-.465-.252 31.147 31.147 0 0 1-4.803-3.34C3.8 15.572 1 12.331 1 8.513 1 5.052 3.829 2.5 6.736 2.5 9.03 2.5 10.881 3.726 12 5.605 13.12 3.726 14.97 2.5 17.264 2.5 20.17 2.5 23 5.052 23 8.514c0 3.818-2.801 7.06-5.389 9.262A31.146 31.146 0 0 1 14 20.408Z"></path>
                        </svg>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex>
                        <svg
                          stroke="#be1931"
                          fill="#be1931"
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          height="2.5em"
                          width="2.5em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14 20.408c-.492.308-.903.546-1.192.709-.153.086-.308.17-.463.252h-.002a.75.75 0 0 1-.686 0 16.709 16.709 0 0 1-.465-.252 31.147 31.147 0 0 1-4.803-3.34C3.8 15.572 1 12.331 1 8.513 1 5.052 3.829 2.5 6.736 2.5 9.03 2.5 10.881 3.726 12 5.605 13.12 3.726 14.97 2.5 17.264 2.5 20.17 2.5 23 5.052 23 8.514c0 3.818-2.801 7.06-5.389 9.262A31.146 31.146 0 0 1 14 20.408Z"></path>
                        </svg>
                      </Flex>
                      <StructuredTextParser
                        id="program-fee-text"
                        mt="0"
                        str={render(sectionContent?.[3].body)}
                        {...ProgramFeeSt[lang].feeDiscount1}
                        maxW="auto"
                        color="#6D6358"
                      />
                    </>
                  )}
                </Flex>
                {/* Older Ui code */}
                {!changeUi && (
                  <Box
                    borderBottom={{
                      base: '1px solid #6D6358',
                      md: '2px solid #6D6358',
                    }}
                    opacity={0.3}
                    mt="20px"
                  />
                )}
                {!changeUi && priceData?.length > 0 && (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StructuredTextParser
                      str={`${priceData[0]?.curr} ${priceData[0]?.pgmFee}`}
                      maxW="none"
                      textDecoration="line-through"
                      {...ProgramFeeSt[lang].feeDiscount2}
                      color="#6D6358"
                    />
                    <StructuredTextParser
                      id="discounted-price-text"
                      str={`${priceData[0]?.curr} ${priceData[0]?.discountedPrice}`}
                      maxW="none"
                      mt="0px"
                      {...ProgramFeeSt[lang].feeDiscount3}
                      color="#CD9F00"
                    />
                    {priceData.length > 1 && (
                      <>
                        <StructuredTextParser
                          str={render(sectionContent?.[6]?.body)}
                          maxW="none"
                          {...ProgramFeeSt[lang].regionalLanguages}
                          color="#A69786"
                        />
                        <StructuredTextParser
                          id="program-fee-price"
                          str={`${priceData[1].curr} ${priceData[1].pgmFee}`}
                          textDecoration="line-through"
                          maxW="auto"
                          mt="0px"
                          color="#6D6358"
                          {...ProgramFeeSt[lang].feeDiscount2}
                        />
                        <StructuredTextParser
                          str={`${priceData[1].curr} ${priceData[1].discountedPrice}`}
                          mt="0px"
                          maxW="auto"
                          color="#CD9F00"
                          {...ProgramFeeSt[lang].feeDiscount3}
                        />
                      </>
                    )}
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>

          {sectionContent?.[9]?.table && prgData && (
            <Flex w="full" py={{ base: '30px', md: '80px' }}>
              <TableContainer
                whiteSpace="pre-wrap"
                bg="#fff"
                w="full"
                fontFamily="FedraSansStd-medium"
                boxShadow="0 10px 10px -6px #cbc7bb"
              >
                <Table variant="striped" colorScheme="blackAlpha">
                  <Thead bg="#B49168">
                    <Tr>
                      {sectionContent?.[9]?.table?.columns?.map(
                        (column, index) => (
                          <Th
                            display={{
                              base:
                                changeUi && index === 1 ? 'none' : 'table-cell',
                              md: 'table-cell',
                            }}
                            key={nanoid()}
                            textAlign="left"
                            {...ProgramFeeSt[lang].tableHead}
                            color="#fff"
                            lineHeight={{ base: '26px', xl: 'normal' }}
                            px={{ base: '16px', lg: '50px' }}
                            py={{ base: '15px', md: '30px' }}
                            textTransform="none"
                          >
                            {column}
                          </Th>
                        )
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {PriceDataList?.map((item, index) => (
                      <Tr key={index}>
                        <Td
                          px={{ base: '16px', lg: '50px' }}
                          lineHeight={{ base: '26px', xl: 'normal' }}
                          color="#6D6358"
                          {...ProgramFeeSt[lang].bodyTd}
                        >
                          {item['lang'] &&
                            config.lngList[item['lang']?.toLowerCase()]}
                        </Td>
                        {changeUi && (
                          <Td
                            px={{ base: '16px', lg: '50px' }}
                            display={{ base: 'none', md: 'table-cell' }}
                          >
                            <Text
                              as="del"
                              textDecor="line-through"
                              lineHeight={{ base: '26px', xl: 'normal' }}
                              color="#6D6358"
                              {...ProgramFeeSt[lang].bodyTd}
                            >
                              {isIndian && item['curr']} {item['pgmFee']}
                              {/* {item['pgmFee']} */}
                            </Text>
                          </Td>
                        )}
                        <Td
                          px={{ base: '16px', lg: '50px' }}
                          lineHeight={{ base: '26px', xl: 'normal' }}
                          color="#6D6358"
                          {...ProgramFeeSt[lang].bodyTd}
                        >
                          {/* uncomment to add price with discounted price in mobile */}
                          <Text
                            as="del"
                            textDecor="line-through"
                            lineHeight={{ base: '26px', xl: 'normal' }}
                            color="#6D6358"
                            display={{
                              base: changeUi ? 'auto' : 'none',
                              md: 'none',
                            }}
                            {...ProgramFeeSt[lang].bodyTd}
                          >
                            {isIndian && item['curr']} {item['pgmFee']}
                          </Text>
                          <Text>
                            {/* comment the below code to add inr */}
                            {item?.coupon ? (
                              <>
                                {item?.curr} {item?.coupon?.postCouponPrice}
                              </>
                            ) : (
                              <>
                                {isIndian && item['curr']}{' '}
                                {item['discountedPrice']}
                              </>
                            )}
                          </Text>
                        </Td>
                        {sectionContent?.[9]?.table?.columns.indexOf(' ') !==
                          -1 && (
                          <Td
                            px={{ base: '16px', lg: '50px' }}
                            lineHeight={{ base: '26px', xl: 'normal' }}
                            color="#6D6358"
                            wordBreak="auto-phrase"
                            {...ProgramFeeSt[lang].bodyTd}
                          >
                            <NextLink
                              href="#ieo-enroll-section"
                              //href={disabled ? '#' : currentUrl}
                              passHref
                              legacyBehavior
                            >
                              <Link
                                color="#F37021"
                                display="flex"
                                _hover={{ color: '#BC570F' }}
                                onClick={() => {
                                  // console.log('Called onClick', item.lang);
                                  setProgramLanguage(item.lang);
                                  gtmClick(item.lang, item['discountedPrice']);
                                }}
                              >
                                {pathname.indexOf('inner-engineering-v4') > -1
                                  ? 'Start My Transformation'
                                  : 'Register Now'}

                                {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9 20.5L17 12.5L9 4.5" stroke="#F37021" stroke-width="2.66667" />
                                </svg> */}
                              </Link>
                            </NextLink>
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
          )}
        </Flex>
        <style global>
          {`
            .thanksgiving:hover svg {
              fill: #E86F34;
              color: #E86F34;
            }
            .thanksgiving:hover {
              background: '#FAE4B8';
              color: '#FFFFFF';
            }
              `}
        </style>
      </Flex>
    </Box>
  );
};

export default ProgramFeeV5;
