/* Built In Imports */
/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useRouter } from 'next/router';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import SingleImage from '@ieo/Components/SingleImage';

/* Configs */
import { IeoInpersonSt } from '@ieo/Common/CompLangConfig';
import { usePathname } from 'next/navigation';
/* Styles */
/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {Object} prgData
 * @param {String} lang
 * @param {region} region
 * @returns {ReactElement} IeoInPerson
 */
const IeoInPerson = ({
  sectionContent,
  lang,
  region,
  prgData,
  supportData,
}) => {
  // const contactData = Object.values(prgData);
  const contactData = supportData['step-1-6'];
  const indLang = config.indianLang.indexOf('ta') > -1;
  const router = useRouter();

  const changeUi =
    router.asPath.indexOf('inner-engineering-pc') > 0 ||
    router.pathname.includes('inner-engineering/gift') ||
    router.pathname.includes('inner-engineering/offer/scholarship-by-sza');
  // console.log('supportData', supportData);
  const path = usePathname();
  const url = path.includes('offer/under-25s');
  return (
    <Box bg="#F1EBDF">
      <Box
        px={{ base: '16px', md: '20px', lg: '10%' }}
        py="54px"
        mt={!url && { base: '100px', md: '67px' }}
        maxW={'1330px'}
        margin={'0 auto'}
      >
        {changeUi && (
          <Flex
            flexWrap="wrap"
            justifyContent={{ base: 'space-between', lg: 'center' }}
          >
            {/* new UI */}
            <Box
              w={{ base: '100%', md: '100%', lg: '50%' }}
              mb={{ base: '50px', md: '30px', lg: '0' }}
            >
              <StructuredTextParser
                str={render(sectionContent[6].body)}
                color="#433F3C"
                {...IeoInpersonSt[lang].heading}
                mx="0"
                mt="0"
              />
            </Box>
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              alignItems={{ base: 'start', md: 'start', lg: 'center' }}
              justifyContent={{ base: 'start', md: 'start', lg: 'center' }}
              w={{ base: '100%', md: '100%', lg: '50%' }}
            >
              {/* <Box
              width={{ base: '100%', md: '60%', lg: '50%' }}
              mb={{ base: '20px', md: '20px', lg: '0' }}
            >
              <StructuredTextParser
                mt="0"
                textAlign="left"
                pr={{ base: "20px", md: "40px !important" }}
                str={render(sectionContent[1]?.body)}
                sectionContent={sectionContent[1]}
              // {...IeoInpersonSt[lang].heading}
              />
            </Box> */}

              <Box>
                <Flex mb="10px">
                  <Box w="30px" h="30px" mr={{ base: '12px', md: '22px' }}>
                    <SingleImage
                      my="0"
                      px="0"
                      sectionContent={sectionContent[8]}
                    />
                  </Box>
                  {contactData && (
                    <StructuredTextParser
                      mt="0"
                      maxW="400px"
                      fontSize={{ base: '16px', md: '22px' }}
                      str={`<p><a style='color: #E86F34' rel="noopener noreferrer" target="_blank" href="${
                        contactData?.url
                      }">${
                        contactData?.email || 'support.ishafoundation.org'
                      }</a></p>`}
                      sectionContent={sectionContent[2]}
                      fontFamily="FedraSansStd-medium"
                    />
                  )}
                </Flex>
                {contactData?.phone && (
                  <Flex mb="10px">
                    <Box w="26px" h="26px" mr={{ base: '11px', md: '25px' }}>
                      <SingleImage
                        my="0"
                        px="0"
                        sectionContent={sectionContent[9]}
                      />
                    </Box>
                    <StructuredTextParser
                      mt="0"
                      maxW="400px"
                      fontSize={{ base: '16px', md: '22px' }}
                      fontFamily="FedraSansStd-medium"
                      str={`<span>${contactData?.phone}</span>`}
                      sectionContent={sectionContent[7]}
                    />
                  </Flex>
                )}
              </Box>
            </Flex>
          </Flex>
        )}
        {!changeUi && (
          <>
            {/* Uncomment to get the older ui */}
            <Flex
              mb={{ base: '30px', md: '43px', lg: '40px' }}
              flexWrap="wrap"
              flexDir={{ base: 'column-reverse', md: 'column', lg: 'row' }}
            >
              {lang === 'ar' ? (
                <>
                  <Box
                    mt={{ base: '-111px', md: '0', lg: '-111px' }}
                    w={{
                      base: 'auto',
                      md: 'auto',
                      lg: lang === 'ar' ? '50%' : '55%',
                    }}
                  >
                    <SingleImage
                      px="0"
                      textAlign="start"
                      sectionContent={sectionContent[2]}
                    />
                  </Box>
                  <Box
                    w={{
                      base: '100%',
                      md: '100%',
                      lg: lang === 'ar' ? '50%' : '45%',
                    }}
                    mb={{ base: '0px', md: '40px', lg: '0' }}
                    mt={{ base: '30px', md: '0' }}
                  >
                    <Heading {...IeoInpersonSt[lang].SubHeading} as="h3">
                      {sectionContent[0]?.titleSubtext}
                    </Heading>
                    <Heading
                      {...IeoInpersonSt[lang].heading}
                      as="h2"
                      color="#433F3C"
                    >
                      {sectionContent[0]?.titleText}
                    </Heading>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    w={{ base: '100%', md: '100%', lg: '45%' }}
                    mb={{ base: '0px', md: '40px', lg: '0' }}
                    mt={{ base: '30px', md: '0' }}
                  >
                    <Heading {...IeoInpersonSt[lang].SubHeading} as="h3" id="ieo-in-person-text">
                      {sectionContent[0]?.titleSubtext}
                    </Heading>
                    <Heading
                      {...IeoInpersonSt[lang].heading}
                      as="h2"
                      color="#433F3C"
                      id="ieo-in-programs-text"
                    >
                      {sectionContent[0]?.titleText}
                    </Heading>
                  </Box>
                  <Box
                    mt={{ base: '-111px', md: '0', lg: '-111px' }}
                    w={{ base: 'auto', md: 'auto', lg: '55%' }}
                  >
                    <SingleImage
                      px="0"
                      textAlign="start"
                      sectionContent={sectionContent[2]}
                    />
                  </Box>
                </>
              )}
            </Flex>
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              justifyContent="space-between"
            >
              <Box width={{ base: '100%', md: '100%', lg: '47%' }}>
                <Box maxW={lang === 'ar' ? '100%' : 389}>
                  <StructuredTextParser
                    str={render(sectionContent[1]?.body)}
                    sectionContent={sectionContent[1]}
                    className="IeoImgText"
                    maxW="inherit"
                    {...IeoInpersonSt[lang].stBookText}
                    color="#433F3C"
                  />
                </Box>
              </Box>
              <Box width={{ base: '100%', md: '100%', lg: '53%' }}>
                <Box padding={{ base: '0', md: '0', lg: '0 0 0 1.5rem' }}>
                  <StructuredTextParser
                    str={render(sectionContent[3]?.body)}
                    sectionContent={sectionContent[3]}
                    className="IeoImgText"
                    maxW="100%"
                    {...IeoInpersonSt[lang].stBookText}
                    color="#433F3C"
                  />
                </Box>
              </Box>
            </Flex>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={{ base: '20px', md: '15px', lg: '36px' }}
            >
              {lang === 'ar' ? (
                <>
                  <Box px="3px">
                    <ChevronLeftIcon fontSize="34px" color="#F37021" />
                  </Box>
                  <StructuredTextParser
                    //className="ieoInPerson"
                    mt="0px"
                    maxW="100%"
                    textAlign="center"
                    str={render(sectionContent[4]?.body)}
                    sectionContent={sectionContent[4]}
                    {...IeoInpersonSt[lang].AttendText}
                    color="#F37021"
                  />
                </>
              ) : (
                <>
                  {' '}
                  <StructuredTextParser
                    //className="ieoInPerson"
                    mt="0px"
                    maxW="100%"
                    textAlign="center"
                    str={render(sectionContent[4]?.body)}
                    sectionContent={sectionContent[4]}
                    {...IeoInpersonSt[lang].AttendText}
                    color="#F37021"
                  />
                  <Box px="3px">
                    <ChevronRightIcon fontSize="34px" color="#F37021" />
                  </Box>
                </>
              )}
            </Box>
            <Flex
              flexDir="column"
              w="100%"
              mb={{ base: '30px', md: '30px', lg: '40px' }}
              mt={{ base: '', md: '', lg: '27px' }}
            >
              <StructuredTextParser
                className="ieoInPerson"
                maxW="100%"
                str={render(sectionContent[5]?.body)}
                sectionContent={sectionContent[5]}
              />
            </Flex>

            {/* Only US page Gift section Starts*/}
            {/* {isUS && (
              <>
                <Flex
                  mb={{ base: '30px', md: '0', lg: '0' }}
                  flexWrap="wrap"
                  flexDir={{ base: 'column-reverse', md: 'column', lg: 'row' }}
                >
                  <Box
                    w={{ base: '100%', md: '100%', lg: '65%' }}
                    mb={{ base: '0px', md: '40px', lg: '0' }}
                    mt={{ base: '30px', md: '65px' }}
                  >
                    <Heading {...IeoInpersonSt[lang].SubHeading} as="h3">
                      {sectionContent[10]?.titleSubtext}
                    </Heading>
                    <Heading {...IeoInpersonSt[lang].heading} as="h2" color="#433F3C">
                      {sectionContent[10]?.titleText}
                    </Heading>

                    <Flex
                      flexDir={{ base: 'column', md: 'column', lg: 'row' }}
                      justifyContent="space-between"
                      mt="20px"
                    >
                      <Box width={{ base: '100%', md: '100%', lg: '100%' }}>
                        <Box maxW={389}>
                          <StructuredTextParser
                            str={render(sectionContent[11]?.body)}
                            sectionContent={sectionContent[11]}
                            className="IeoImgText"
                            {...IeoInpersonSt[lang].stBookText}
                            color="#433F3C"
                          />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box
                    // mt={{ base: '-111px', md: '0', lg: '-111px' }}
                    w={{ base: 'auto', md: 'auto', lg: '35%' }}
                    d={{ base: 'none', lg: 'block' }}
                  >
                    <SingleImage
                      px="0"
                      maxW="309px"
                      textAlign="start"
                      mt="0"
                      sectionContent={sectionContent[12]}
                    />
                  </Box>
                </Flex>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={{ base: '20px', md: '15px', lg: '60px' }}
                  mb={{ base: '20px', md: '15px', lg: '40px' }}
                >
                  <StructuredTextParser
                    //className="ieoInPerson"
                    mt="0px"
                    maxW="100%"
                    textAlign="center"
                    str={render(sectionContent[13]?.body)}
                    sectionContent={sectionContent[13]}
                    {...IeoInpersonSt[lang].AttendText}
                    color="#F37021"
                    fontFamily="FedraSansStd-medium"
                  />
                  <Box px="3px">
                    <ChevronRightIcon fontSize="34px" color="#F37021" />
                  </Box>
                </Box>
                <Flex
                  flexDir="column"
                  w="100%"
                  mb={{ base: '30px', md: '30px', lg: '40px' }}
                  mt={{ base: '', md: '', lg: '27px' }}
                >
                  <StructuredTextParser
                    className="ieoInPerson"
                    maxW="100%"
                    str={render(sectionContent[14]?.body)}
                    sectionContent={sectionContent[14]}
                  />
                </Flex>
              </>
            )} */}
            {/* Only US page Gift section Ends*/}

            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              alignItems={{ base: 'start', md: 'start', lg: 'center' }}
              justifyContent={{ base: 'start', md: 'start', lg: 'center' }}
            >
              <Box
                width={{ base: '100%', lg: '50%' }}
                mb={{ base: '20px', md: '20px', lg: '0' }}
              >
                <StructuredTextParser
                  mt="0"
                  textAlign="left"
                  pr={{ base: '20px', md: '40px !important' }}
                  color="#433F3C"
                  str={render(sectionContent[6]?.body)}
                  sectionContent={sectionContent[6]}
                  {...IeoInpersonSt[lang].heading}
                  maxW={{ base: '100%', lg: '600px', md: '100%', xl: '770px' }}
                />
              </Box>

              <Box w={{ base: '100%', md: '100%', lg: '50%' }}>
                <Flex
                  mb="10px"
                  justifyContent={lang === 'ar' ? 'flex-end' : 'left'}
                >
                  {lang === 'ar' ? (
                    <>
                      {contactData && (
                        <StructuredTextParser
                          mt="0"
                          maxW="400px"
                          fontSize={{ base: '16px', md: '22px' }}
                          str={`<p><a style='color: #E86F34' rel="noopener noreferrer" target="_blank" href="${
                            contactData?.url
                          }">${
                            contactData?.email || 'support.ishafoundation.org'
                          }</a></p>`}
                          sectionContent={sectionContent[7]}
                          fontFamily="FedraSansStd-medium"
                        />
                      )}
                      <Box w="30px" h="30px" ml={{ base: '12px', md: '22px' }}>
                        <SingleImage
                          my="0"
                          px="0"
                          sectionContent={sectionContent[8]}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Box
                        w="30px"
                        h="30px"
                        mr={{ base: '12px', md: '22px' }}
                        mt={{
                          base: '5px',
                          md: (region === 'us' || region === 'ca') && '10px',
                        }}
                      >
                        <SingleImage
                          my="0"
                          px="0"
                          sectionContent={sectionContent[8]}
                        />
                      </Box>
                      {contactData && (
                        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                          <StructuredTextParser
                            mt="0"
                            maxW="400px"
                            fontSize={{ base: '16px', md: '22px' }}
                            str={`<p><a style='color: #E86F34' id="ieo-support-link" rel="noopener noreferrer" target="_blank" href="${
                              contactData?.url
                            }">${
                              contactData?.email || 'support.ishafoundation.org'
                            }</a></p>`}
                            sectionContent={sectionContent[7]}
                            fontFamily="FedraSansStd-medium"
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Flex>
                {contactData?.phone && (
                  <Flex
                    mb="10px"
                    justifyContent={lang === 'ar' ? 'flex-end' : 'left'}
                  >
                    {lang === 'ar' ? (
                      <>
                        <StructuredTextParser
                          mt="0"
                          maxW="400px"
                          fontSize={{ base: '16px', md: '22px' }}
                          fontFamily="FedraSansStd-medium"
                          str={`<span>${contactData?.phone}</span>`}
                          sectionContent={sectionContent[7]}
                        />
                        <Box
                          w="26px"
                          h="26px"
                          ml={{ base: '11px', md: '25px' }}
                        >
                          <SingleImage
                            my="0"
                            px="0"
                            sectionContent={sectionContent[9]}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        {' '}
                        <Box
                          w="26px"
                          h="26px"
                          mr={{ base: '11px', md: '25px' }}
                        >
                          <SingleImage
                            my="0"
                            px="0"
                            sectionContent={sectionContent[9]}
                          />
                        </Box>
                        <StructuredTextParser
                          mt="0"
                          maxW="400px"
                          fontSize={{ base: '16px', md: '22px' }}
                          fontFamily="FedraSansStd-medium"
                          str={`<span>${contactData?.phone}</span>`}
                          sectionContent={sectionContent[7]}
                        />
                      </>
                    )}
                  </Flex>
                )}
              </Box>
            </Flex>
            <style global jsx>
              {`
                .ieoInPerson {
                  max-width: 100% !important;
                }
                .IeoImgText p {
                  margin: 6.5px 0 6.5px 0;
                }
                .IeoImgText p strong {
                  color: #433f3c;
                  font-feature-settings: 'case' on;
                  font-size: 26px;
                  font-style: normal;
                  line-height: ${indLang ? '37px' : '31.2px'};
                  margin-bottom: 14px;
                }
                @media only screen and (max-width: 660px) {
                  .IeoImgText p strong {
                    color: #433f3c;
                    font-feature-settings: 'case' on;
                    font-size: 22px;
                    font-style: normal;
                    line-height: 26.4px;
                    text-transform: capitalize;
                    margin-bottom: 12px;
                  }
                }
              `}
            </style>
          </>
        )}
      </Box>
    </Box>
  );
};

export default IeoInPerson;
