/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Image } from '@chakra-ui/react';
import { TbPlayerPlayFilled } from 'react-icons/tb';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
// import ReactPlayer from 'react-player';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import { getEmbedUrl } from '@components/Utility/utils';

/* Services */
import { isInViewport } from '@components/Utility/Shared/SharedService';

/* Styles */
import ProgramStepsSt from '@ieo/Common/StyleData/ProgramStepsSt';
import { usePathname } from 'next/navigation';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for Section
 * @param sectionContent.margin
 * @param sectionContent.width
 * @param sectionContent.pxBase
 * @returns {ReactElement} MediaEmbed component
 */

const MediaEmbedV2 = ({ sectionContent, margin, width, lang, ...props }) => {
  const [vidVis, setVidVis] = useState(false);
  const [opts, setOpts] = useState({});
  const path = usePathname();
  const checkV5Version = path.includes('v5');
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    setOpts({
      height: isMobile ? '200px' : '420px',
      //height: '315',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global?.window, isMobile]);

  useEffect(() => {
    if (props.autoplay) {
      window.addEventListener('scroll', listenToScroll);
    }
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const scrollDiv = document.getElementById(props['sectionId']);
    const inViewPort = isInViewport(scrollDiv);
    if (!vidVis && inViewPort) {
      setVidVis(true);
    }

    // console.log('sdsdsd', props['sectionId'], inViewPort);
    // const heightToHideFrom = window.innerHeight / 1.5;
    // const winScroll = document.body.scrollTop ||
    //   document.documentElement.scrollTop;

    // if (winScroll > heightToHideFrom) {
    //   setVidVis(true);
    // }
  };

  const onReady = event => {
    event.target.playVideo();
  };

  const playerOverlay =
    props?.sectionId === 'ieo-program-steps' ? (
      <Box
        pos="relative"
        // bg="black"
        w="full"
        h="full"
        display={vidVis ? 'none' : 'block'}
        onClick={e => setVidVis(!vidVis)}
      >
        <Flex
          flexDir="column"
          gridGap={{
            base: checkV5Version ? '2px' : '6px',
            md: checkV5Version ? '4px' : '11px',
          }}
          pos="absolute"
          left={{ base: '1rem', md: '1.2rem' }}
          bottom={{
            base: checkV5Version ? '24px' : '35px',
            sm: checkV5Version && '14px',
            md: 'unset',
          }}
          top={{
            base: 'unset',
            sm: 'unset',
            md: '41%',
            lg: checkV5Version ? '43%' : '40%',
          }}
        >
          <Flex
            align="center"
            justify="center"
            w={{ base: '47px', md: '85px' }}
            h={{ base: '47px', md: '85px' }}
            bg={checkV5Version ? 'transparent' : 'white'}
            borderRadius={checkV5Version !== true && (props.round || '50%')}
            onClick={e => setVidVis(!vidVis)}
            display={vidVis ? 'none' : 'flex'}
          >
            <Flex
              align="center"
              justify="center"
              w={{
                base: checkV5Version ? '39.8px' : '37px',
                md: checkV5Version ? '77.36px' : '75px',
              }}
              h={{
                base: checkV5Version ? '39.8px' : '37px',
                md: checkV5Version ? '76.88px' : '75px',
              }}
              fontSize={{ base: '26px', md: '45px' }}
              borderRadius="50%"
              bg={checkV5Version ? 'transparent' : '#9A4C21'}
              color="white"
            >
              {' '}
              {checkV5Version ? (
                <Image
                  src="https://www.datocms-assets.com/46272/1719377601-play_button_video.png"
                  alt="play Button"
                  width="full"
                  height="full"
                />
              ) : (
                <TbPlayerPlayFilled />
              )}
            </Flex>
          </Flex>
          <Box
            fontSize={{
              base: checkV5Version ? '8.93px' : '12px',
              md: checkV5Version ? '17.26px' : '1rem',
            }}
            fontFamily="FedraSansStd-medium"
            color="white"
            fontWeight={450}
            // noOfLines={{ base: '3', sm: '2', lg: '3' }}
            maxW={{ base: checkV5Version ? '100px' : '91px', md: '145px' }}
            lineHeight={{
              base: checkV5Version ? '16px' : '18px',
              md: checkV5Version ? '21.57px' : '20px',
            }}
            {...(checkV5Version ? ProgramStepsSt[lang].videoV5 : ProgramStepsSt[lang].video)}
            dir={lang == 'ar' ? 'rtl' : 'ltr'}
          >
            {sectionContent?.title}
          </Box>
        </Flex>
      </Box>
    ) : (
      <Flex
        position="relative"
        top={0}
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        alignItems="center"
        display={vidVis ? 'none' : 'flex'}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          w={{ base: '40px', md: '84px' }}
          h={{ base: '40px', md: '84px' }}
          rounded={props.round || 'full'}
          background={props.bgcolor || '#FFCB12'}
          _hover={{
            boxShadow: ' -4px 14px 22px -5px rgba(0,0,0,0.66)',
          }}
          textAlign="center"
          fontSize={{ base: '15px', md: '30px' }}
          onClick={e => setVidVis(!vidVis)}
          display={vidVis ? 'none' : 'flex'}
          color={props.txtcolor || ''}
        >
          <TbPlayerPlayFilled />
        </Flex>
      </Flex>
    );

  if (sectionContent?.thumbnailDesktopImage?.url) {
    return (
      <MidContainer w={width || '696px'} h="100%">
        <Box
          backgroundImage={
            !vidVis ? sectionContent?.thumbnailDesktopImage?.url : 'none'
          }
          bgSize="contain"
          backgroundSize="100%"
          // backgroundColor="#000"
          backgroundRepeat="no-repeat"
          backgroundPosition={
            sectionContent.id === '170001416' ? 'left' : 'center'
          }
          width="100%"
          position="relative"
          display="flex"
          // boxShadow="0px 31px 20px -13px rgba(0,0,0,0.47)"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          cursor="pointer"
          // onClick={e => setVidVis(!vidVis)}

          top="0px"
          borderRadius={props.round || { base: '6px', md: 'none' }}
          height={{ base: '200px', md: '470px' }}
          m={margin || '0 auto 30px auto'}
          id="embed-vid"
          {...props}
        >
          {playerOverlay}

          {vidVis && (
            <Box maxW="100%" w="full" height={{ base: '200px', md: '390px' }}>
              <YouTube
                videoId={getEmbedUrl(sectionContent?.video?.url)}
                onReady={onReady}
                opts={opts}
                loading="eager"
              />{' '}
            </Box>
          )}
        </Box>
      </MidContainer>
    );
  }

  return (
    <MidContainer w={width || '696px'}>
      <Box
        // backgroundImage={sectionContent?.video?.thumbnailUrl}
        backgroundSize="cover"
        backgroundColor="#000"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        width="100%"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        cursor="pointer"
        onClick={() => setVidVis(!vidVis)}
        overflow="hidden"
        height={{ base: '300px', md: '430px' }}
        m={margin || '0 auto 30px auto'}
        id="embed-vid"
      >
        {vidVis && (
          <Box maxW="100%" w="full">
            <YouTube
              videoId={getEmbedUrl(sectionContent?.video?.url)}
              onReady={onReady}
              opts={opts}
              loading="lazy"
            />{' '}
          </Box>
        )}
      </Box>
    </MidContainer>
  );
};

export default MediaEmbedV2;
