/* Built In Imports */
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
/* External Imports */
import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import MediaEmbedV2 from '@components/Embeds/MediaEmbedV2';
import ProgramStepsSt from '@ieo/Common/StyleData/ProgramStepsSt';
const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);

/**
 *
 * @param {Object} sectionContent
 * @param {String} lang
 * @returns {ReactElement} ProgramSteps
 */
const ProgramSteps = ({ sectionContent, lang, pageName }) => {
  // console.log('steps', sectionContent);
  const path = usePathname();

  return (
    <Box
      w="full"
      bg="#F1EBDF"
      h="100%"
      mb={pageName === 'ieomigrate' ? { base: '70px', md: '120px' } : '0px'}
    >
      <Flex
        flexDir="column"
        maxW={1030}
        pb="41px"
        alignItems="center"
        w="full"
        justify="center"
        gridGap="12px"
        px={{ sm: '1.5rem', lg: '0' }}
        //maxH={{ xl: '370px' }}
        mx="auto"
      >
        <Text
          lineHeight={{ base: '30px', md: '39px' }}
          maxW={{ base: '268px', md: '743px' }}
          textAlign={{
            base: 'center',
            md: path.indexOf('inner-engineering-v2') > -1 ? 'right' : 'center',
          }}
          color="#12166F"
          {...ProgramStepsSt[lang].h4}
        >
          {sectionContent[0].text}
        </Text>

        <Flex
          bgImage={{
            base: sectionContent[5]?.mobileImage?.url,
            sm: sectionContent[5]?.image?.url,
          }}
          bgRepeat="no-repeat"
          bgSize="cover"
          pos="relative"
          align={{ base: 'center', lg: 'initial' }}
          flexDir={{ base: 'column', lg: 'row' }}
          w="100%"
          // minH={{ base: '450px', lg: '300px' }}
          p={{ base: '30px', md: '1.8rem 50px' }}
          gridGap={{ lg: '37px' }}
          boxShadow={{ md: '0 12px 14px -10px  #A45B0B' }}
          mb={{ base: '0px', sm: '25px', md: '75px', lg: '0' }}
        >
          <Flex
            maxW={{ lg: '380px', xl: '395px' }}
            flexDir="column"
            gridGap="12px"
            color="white"
            dir={lang == 'ar' ? 'rtl' : 'ltr'}
          >
            <Box>
              <Text
                mb={{ base: '8px', xl: '4.7px' }}
                {...ProgramStepsSt[lang].h5}
              >
                {' '}
                {sectionContent[1]?.text}
              </Text>
              <Text {...ProgramStepsSt[lang].h6}>
                {' '}
                {sectionContent[2]?.text}
              </Text>
            </Box>
            <Text className="datoText" {...ProgramStepsSt[lang].p}>
              <StructuredText
                data={sectionContent[3]?.body?.value?.document}
                dir={lang == 'ar' ? 'rtl' : 'ltr'}
              />
            </Text>
          </Flex>

          <ResearchVidCard
            position={{ base: 'relative' }}
            bottom={{ base: '-90px', md: '-100px', lg: '-70px' }}
            //right={{ lg: '-28px' }}
          >
            <AspectRatio
              w={{ base: '85vw', sm: '400px', md: '500px' }}
              h={{ base: 'auto', md: '290px' }}
            >
              <MediaEmbedV2
                sectionContent={sectionContent[4]}
                sectionId="ieo-program-steps"
                lang={lang}
              />
            </AspectRatio>
          </ResearchVidCard>
        </Flex>
      </Flex>
      <style jsx global>{`
        .datoText {
          display: flex;
          flex-direction: column;
          gap: 13px;
        }
      `}</style>
    </Box>
  );
};

export default ProgramSteps;
