/* Built In Imports */
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import MediaEmbedV2 from '@components/Embeds/MediaEmbedV2';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { searchByFieldId } from '@components/Utility/Shared/SharedService';
import ProgramStepsSt from '@ieo/Common/StyleData/ProgramStepsSt';
import { useState } from 'react';
import { fetchUpcomingInitiationDates } from 'services/ieoService';

const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);

// Formatting Date and Time functions
const formatDate = dateString => {
  const getDate = dateString.substring(0, 10);
  const date = new Date(getDate);
  // Format the date to the "29 Sept 2024"
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};

const formatTime = dateString => {
  const date = new Date(dateString);
  const startHour = new Date(date);
  const endHour = new Date(date);
  // Set start and end hour base time
  startHour.setHours(startHour.getHours() + 0);
  endHour.setHours(endHour.getHours() + 6);
  // Start and End format the time to "4:30 PM - 10:30 PM"
  const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedStartTime = startHour.toLocaleTimeString('en-US', timeOptions);
  const formattedEndTime = endHour.toLocaleTimeString('en-US', timeOptions);
  return `${formattedStartTime} - ${formattedEndTime}`;
};

const DateDisplay = ({ dateString }) => (
  <HStack spacing={4} justifyContent="space-between">
    <Text
      fontFamily="FedraSansStd-book"
      color="#5B5237"
      fontWeight="350"
      fontSize="18px"
      lineHeight="34px"
      letterSpacing={'-0.5px'}
    >
      {formatDate(dateString)} -
    </Text>
    <Text
      fontFamily="FedraSansStd-book"
      color="#7A6E4B"
      fontWeight="400"
      fontSize={{ base: '12px', md: '14px' }}
      lineHeight={{ base: '34.56px', md: '35px' }}
    >
      {formatTime(dateString)}
    </Text>
  </HStack>
);

/**
 *
 * @param {Object} sectionContent
 * @param {String} lang
 * @param {String} region
 * @returns {ReactElement} ProgramSteps
 */

const ProgramStepsV5 = ({ sectionContent, lang, pageName, region }) => {
  const path = usePathname();
  const under25Url = path.includes('under-25s');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [upcomingDate, setUpcomingDate] = useState();
  const isMobile = useBreakpointValue({ base: true, md: false });

  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  const fetchUpcomingDates = async () => {
    try {
      const response = await fetchUpcomingInitiationDates(lang, region);
      setUpcomingDate(response);
    } catch (error) {
      console.log('Error fetching upcoming dates:', error);
    }
  };

  const handleLinkClick = () => {
    fetchUpcomingDates();
    onOpen();
  };

  return (
    <Box
      w="full"
      bg={
        under25Url
          ? 'url(https://static.sadhguru.org/d/46272/1710743888-1710584276-pbg1.jpg) repeat'
          : `url(${
              findField('step-prgm-corporate-bg-bottom')?.image?.url
            }) bottom left no-repeat #F1EBDF`
      }
      bgSize={{ base: '70%', md: '50%', xl: '40%' }}
      h="100%"
      mb={pageName === 'ieomigrate' ? { base: '70px', md: '120px' } : '0px'}
      backgroundRepeat={under25Url && 'repeat'}
    >
      <Flex
        flexDir="column"
        maxW={1030}
        pb={{ base: '0px', xl: '41px' }}
        alignItems="center"
        w="full"
        justify="center"
        px={{ sm: '1rem', lg: '0' }}
        maxH={{ xl: under25Url ? '550px' : 'auto' }}
        mx="auto"
      >
        <Text
          lineHeight={{ base: '30px', md: '39px' }}
          maxW={{ base: '268px', md: '743px' }}
          textAlign="center"
          color={'#12166F'}
          {...ProgramStepsSt[lang].h4}
        >
          {sectionContent[0].text}
        </Text>

        <Text
          lineHeight={{ base: '30px', md: '39px' }}
          maxW={{ base: '268px', md: '743px' }}
          textAlign="center"
          color="#A67411"
          {...ProgramStepsSt[lang].h4}
          gap="10px"
          justifyContent="center"
          fontSize={{ base: '16px', md: '20px' }}
          display="flex"
          alignItems="center"
          pb={{ base: '10px', md: '30px' }}
        >
          <Image
            src={sectionContent[1]?.image?.url}
            w={{ base: '20px', md: '24px' }}
            alt=""
          />{' '}
          {findField('ie-duration-2')?.text}
        </Text>

        <Flex
          bgRepeat="no-repeat"
          bgSize="cover"
          pos="relative"
          align={{ base: 'center', lg: 'initial' }}
          flexDir={{ base: 'column', lg: 'row' }}
          w="100%"
          minH={{ base: '450px', lg: '300px' }}
          p={{ base: '20px 20px 0 20px', md: '0' }}
          gridGap={{ lg: '37px' }}
          mb={{
            base: '0px',
            sm: region !== 'us' && '25px',
            md: region !== 'us' && '75px',
            lg: '0',
          }}
        >
          <Flex
            maxW={{ lg: '400px', xl: '480px' }}
            flexDir="column"
            gridGap="12px"
            color="white"
            pl={{ base: '0px', md: '30px', xl: '0px' }}
          >
            <Box>
              <Text
                mb="8px"
                {...ProgramStepsSt[lang].h5}
                textAlign={{ base: 'center', lg: 'left' }}
                fontSize="32px"
              >
                {' '}
                {sectionContent[3]?.text}
              </Text>
              <Text
                fontFamily="FedraSansStd-medium"
                color="#3B444B"
                fontSize={{ base: '20px', md: '22px' }}
                mb="20px"
                textAlign={{ base: 'center', lg: 'left' }}
                px={{ base: '30px', md: '0' }}
              >
                {' '}
                {sectionContent[4]?.text}
              </Text>

              <Box
                fontFamily="FedraSansStd-medium"
                display="flex"
                gridGap="10px"
              >
                {sectionContent[5]?.cards?.map((cardItem, i) => (
                  <Box
                    key={'_ki1' + i}
                    borderLeft={i == 1 ? '1px solid #C6B399' : ''}
                    pl={{ base: i == 1 && '20px', md: i == 1 && '40px' }}
                    maxW={i == 1 ? '50%' : '45%'}
                  >
                    <Box
                      bg="#CE6113"
                      display="inline-flex"
                      pl="8px"
                      pr="14px"
                      py="4px"
                    >
                      <Image
                        src="https://static.sadhguru.org/d/46272/1706002328-live-signal.svg"
                        alt=""
                        pr="6px"
                        w="30px"
                        h="25px"
                      />{' '}
                      {cardItem?.buttonText}
                    </Box>
                    <Text
                      mt="10px"
                      color="#7A6E4B"
                      fontSize={{ base: '14px', md: '18px' }}
                    >
                      {cardItem?.title}
                    </Text>
                    <Text
                      color="#7A6E4B"
                      fontSize={{ base: '14px', md: '18px' }}
                      fontWeight="400"
                      fontFamily="FedraSansStd-book"
                    >
                      {cardItem?.buttonLink}
                    </Text>
                    <Text mt="6px" color="#B49168" fontSize="16px">
                      <StructuredText
                        data={cardItem?.description?.value?.document}
                        fontSize="16px"
                      />
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>

            <Text className="datoText" {...ProgramStepsSt[lang].p}>
              <StructuredText data={sectionContent[3]?.body?.value?.document} />
            </Text>
          </Flex>

          <ResearchVidCard>
            <AspectRatio
              w={{ base: '87vw', sm: '400px', md: '516.9px' }}
              ratio={1.6}
              mt="14px"
            >
              <MediaEmbedV2
                sectionContent={sectionContent[6]}
                sectionId="ieo-program-steps"
                lang={lang}
              />
            </AspectRatio>
          </ResearchVidCard>
        </Flex>
        {region === 'us' && (
          <Box>
            <Text
              fontFamily="FedraSansStd-medium"
              color="#CE6113"
              fontSize={{ base: '20px', md: '22px' }}
              lineHeight={{ base: '30px', md: '33px' }}
              textAlign="center"
              textDecoration="underline"
              cursor="pointer"
              py={{ base: '16px', md: '26px' }}
              mb={{ base: '24px', md: '16px' }}
              onClick={handleLinkClick}
            >
              {sectionContent[8]?.text || 'View Upcoming Step 7 Dates'}
            </Text>
          </Box>
        )}
      </Flex>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        scrollBehavior={'inside'}
        maxH={'auto'}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#F1EBDF"
          borderRadius="0px"
          py={{ base: '0.5rem', md: '2rem' }}
          px={{ base: '0.1rem', md: '2rem' }}
          maxW={{ base: '90%', md: '670px' }}
        >
          <ModalHeader px={{ base: '10px', md: '24px' }}>
            {searchByFieldId(sectionContent, 'upcoming-title') && (
              <Text
                color={'#423e3b'}
                fontWeight="500"
                fontSize={{ base: '20px', md: '22px' }}
                lineHeight={{ base: '30px', md: '33px' }}
                fontFamily="FedraSansStd-medium"
              >
                {searchByFieldId(sectionContent, 'upcoming-title')?.text}
              </Text>
            )}
            {searchByFieldId(sectionContent, 'recommended-popup-title') && (
              <StructuredTextParser
                className="recommendedTitle"
                mt="10px"
                fontFamily="FedraSansStd-medium"
                str={render(sectionContent?.[10]?.body)}
                maxW="auto"
              />
            )}
          </ModalHeader>
          <ModalCloseButton
            color="#E86F34"
            fontSize="1rem"
            m={{ base: '0rem', md: '1.1rem' }}
          />
          <ModalBody
            className="initiation_date"
            p={0}
            m="0"
            w="100%"
            position="relative"
          >
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem border="none" isDisabled={true}>
                <AccordionButton
                  px={{ base: '10px', md: '16px' }}
                  sx={{
                    _hover: { bg: 'transparent' },
                    _disabled: { opacity: 1 },
                  }}
                >
                  {searchByFieldId(sectionContent, 'heading-title1') && (
                    <Box
                      as="div"
                      flex="1"
                      textAlign="left"
                      bg={'#CE6113'}
                      p={{ base: 2, md: 4 }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Heading
                        size="md"
                        color={'#FFFFFF'}
                        fontFamily="FedraSansStd-medium"
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight={{ base: '17.64px', md: '20.16px' }}
                        fontWeight="500"
                      >
                        {
                          searchByFieldId(sectionContent, 'heading-title1')
                            ?.text
                        }
                      </Heading>
                    </Box>
                  )}
                </AccordionButton>

                <AccordionPanel px={{ base: '10px', md: '16px' }}>
                  {upcomingDate?.best_suited.map((date, index) => {
                    return (
                      <Box
                        key={date.pgmId}
                        maxW="100%"
                        mx="0px"
                        p={{ base: 2, md: 4 }}
                        mb={4}
                        bg="#FFFFFF"
                        position="relative"
                      >
                        {date?.recomm_flag && (
                          <Box position="absolute" top="0.5rem" left="-3px">
                            <Image
                              src="https://www.datocms-assets.com/46272/1727422145-orange_ribbon_stickybtn.svg"
                              alt="Sample Image"
                              objectFit="cover"
                            />
                          </Box>
                        )}
                        <VStack
                          align="start"
                          spacing={2}
                          mt={{
                            base: date?.recomm_flag && '20px',
                            md: date?.recomm_flag && '14px',
                          }}
                          pl={{ base: '16px', md: '20px' }}
                        >
                          <DateDisplay dateString={date.day1_timing} />
                          <DateDisplay dateString={date.day2_timing} />
                        </VStack>
                      </Box>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem border="none">
                <AccordionButton
                  px={{ base: '10px', md: '16px' }}
                  sx={{ _hover: { bg: 'transparent' } }}
                >
                  {searchByFieldId(sectionContent, 'heading-title2') && (
                    <Box
                      as="div"
                      flex="1"
                      textAlign="left"
                      bg={'#CE6113'}
                      p={{ base: 2, md: 4 }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Heading
                        size="md"
                        color={'#FFFFFF'}
                        fontFamily="FedraSansStd-medium"
                        fontWeight="500"
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight={{ base: '17.64px', md: '20.16px' }}
                      >
                        {
                          searchByFieldId(sectionContent, 'heading-title2')
                            ?.text
                        }
                      </Heading>
                      <AccordionIcon color="#fff" />
                    </Box>
                  )}
                </AccordionButton>
                <AccordionPanel px={{ base: '10px', md: '16px' }}>
                  {searchByFieldId(
                    sectionContent,
                    'recommended-popup-text'
                  ) && (
                    <Text
                      mb={4}
                      color="#423E3B"
                      fontFamily="FedraSansStd-medium"
                      fontWeight="500"
                      fontSize="18px"
                      lineHeight="27px"
                    >
                      {
                        searchByFieldId(
                          sectionContent,
                          'recommended-popup-text'
                        )?.text
                      }
                    </Text>
                  )}

                  {upcomingDate?.others.map((date, index) => {
                    return (
                      <Box
                        key={date.pgmId}
                        maxW="100%"
                        mx="0px"
                        p={{ base: 2, md: 4 }}
                        mb={4}
                        bg="#FFFFFF"
                      >
                        <VStack
                          align="start"
                          spacing={2}
                          pl={{ base: '16px', md: '20px' }}
                        >
                          <DateDisplay dateString={date.day1_timing} />
                          <DateDisplay dateString={date.day2_timing} />
                        </VStack>
                      </Box>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <SimpleButton
              style={sectionContent[12]}
              region={region}
              lang={lang}
              bg="#E86F34"
              mx="auto"
              borderRadius="30px"
              width="auto"
              className="simpleButton"
              fontSize="18px"
              lineHeight="20px"
              padding={{ md: '14px 25px', base: '14px 21px' }}
              mb="0"
              _hover={{ bg: '#CE6113' }}
              onClick={onClose}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <style jsx global>{`
        .datoText {
          display: flex;
          flex-direction: column;
          gap: 13px;
        }
        .recommendedTitle p {
          color: #423e3b;
          font-family: FedraSansStd-book;
          font-weight: 400;
          font-size: ${isMobile ? '14px' : '16px'};
          line-height: ${isMobile ? '17.64px' : '20.16px'};
        }
        .recommendedTitle p strong {
          color: #423e3b;
          font-family: FedraSansStd-medium;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
        }
        .initiation_date::-webkit-scrollbar {
          width: 8px;
        }
        .initiation_date::-webkit-scrollbar-track {
          background: #ffffff;
        }
        .initiation_date::-webkit-scrollbar-thumb {
          background: #cf4520;
          border-radius: 2px;
        }
      `}</style>
    </Box>
  );
};

export default ProgramStepsV5;
