/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SliderExperience from '@ieo/Components/SliderExperience';

/* Services */
/* Styles */

/**
 *
 * @param {Object} SectionContent
 *  @param {String} lang
 *  @param {String} region
 * @returns ProgramTestimonial
 */
const ProgramTestimonialV7 = ({ sectionContent, region, lang, pageData, scrollRef }) => {
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  return (
    <Box
      bg="url(https://static.sadhguru.org/d/46272/1694863168-testimonial-base.png)"
      bgRepeat={
        pageConfigVal?.myExp?.bgImage?.repeat === true
          ? 'repeat-x'
          : 'no-repeat'
      }
      bgPos={{ base: 'center -10%', md: 'bottom' }}
      bgSize="cover"
    >
      <Flex maxW="1330px" flexDir="column" margin={'0 auto'}>
        <Box
          pos={{ base: 'static', md: 'relative' }}
          top="30px"
          // h={{ base: '640px', md: '500px', lg: '520px' }}
        >
          <SliderExperience
            sectionContent={sectionContent?.[0]}
            lang={lang}
            region={region}
            paddingBottom={{ base: '0px !important', md: '0px !important' }}
            heightBase={'auto'}
            color="#fff"
            arrowTop="inherit"
            left="15%"
            right="-3%"
            mb={'0rem !important'}
          />
          {sectionContent[1].linkUrl != '' && (
            <Box maxW={{ base: '217px', md: '226px' }} m="0 auto">
              <Flex align={'center'} justifyContent={'center'}>
                <SimpleButton
                  style={sectionContent[1]}
                  bType={sectionContent[1]?.sectionId}
                  region={region}
                  lang={lang}
                  onClick={() => {
                    scroll.scrollTo(scrollRef.current.offsetTop, {
                      duration: 500,
                      smooth: true,
                    });
                  }}
                  width={{ base: '250px', md: '100%' }}
                  borderRadius={'12px'}
                  background={'#F37021'}
                  fontbold={'500'}
                  _hover={{
                    background: '#BC570F !important',
                  }}
                />
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ProgramTestimonialV7;
