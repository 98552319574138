/* External Imports */
import {
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useRouter } from 'next/router';

/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ProgramFeeSt } from '@ieo/Common/CompLangConfig';

/* Service */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const SpecialPriceSection = ({ sectionContent, region, lang, programCard }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  const isIndian = region === 'in';
  const priceData = Object.values(sectionContent);
  // const changeUi = !isIndian;
  const changeUi =
    router.asPath.indexOf('inner-engineering-pc') > 0 && isIndian;
  return (
    <>
      <Box
        maxW="1030px"
        p={{ base: '30px 25px', md: '35px 25px' }}
        textAlign="left"
        bg="#F1EBDF"
      >
        <Text
          color="#433F3C"
          fontFamily="'FedraSansStd-medium'"
          fontSize={{ base: '20px', md: '24px' }}
        >
          {programCard[2]?.title}
        </Text>

        <StructuredTextParser
          mt="15px"
          str={render(programCard[2]?.description)}
          fontFamily="'FedraSansStd-book'"
          fontSize="18px"
          maxW="1000px !important"
          className="paLink"
          mb={{ base: '2rem', md: '1.5rem' }}
        />
        {searchByFieldId(sectionContent, 'learn-more-link') && (
          <Link
            href={searchByFieldId(sectionContent, 'learn-more-link')?.linkUrl}
            color="#CE6113"
            fontFamily="'FedraSansStd-medium'"
            textDecor="underline"
            _hover={{ textDecor: 'none' }}
          >
            {searchByFieldId(sectionContent, 'learn-more-link')?.buttonText}
          </Link>
        )}
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        onCloseComplete={() => {
          router.push(router.asPath.split('#')[0] + '#ieo-enroll-section');
        }}
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#F1EBDF"
          borderRadius="0px"
          p={{ base: '1rem 0rem', md: '3.2rem' }}
          maxW={{ base: '90%', md: '670px' }}
        >
          <ModalHeader {...ProgramFeeSt[lang].retakeHeading}>
            {searchByFieldId(sectionContent, 'special-pricing-popup-title') && (
              <>
                {
                  searchByFieldId(sectionContent, 'special-pricing-popup-title')
                    .text
                }
              </>
            )}
          </ModalHeader>
          <ModalCloseButton
            color="#E86F34"
            fontSize="1rem"
            m={{ base: '0rem', md: '1.1rem' }}
          />
          <ModalBody>
            {searchByFieldId(sectionContent, 'popup-text1') && (
              <StructuredTextParser
                str={render(
                  searchByFieldId(sectionContent, 'popup-text1').body
                )}
                mt="0px"
                p="0px !important"
                {...ProgramFeeSt[lang].retakeTextP}
                className="textPspacing"
              />
            )}
            {!changeUi && priceData?.length > 0 && (
              <>
                <Box mt={{ base: '30px', md: '30px' }}>
                  <StructuredTextParser
                    str={render(
                      searchByFieldId(
                        sectionContent,
                        'program-fee-discount-popup1'
                      )?.body
                    )}
                    maxW="none"
                    textDecoration="line-through"
                    {...ProgramFeeSt[lang].feeDiscount2}
                    color="#6D6358"
                    textAlign="center"
                  />
                </Box>
                <StructuredTextParser
                  str={render(
                    searchByFieldId(
                      sectionContent,
                      'program-fee-discount-popup2'
                    )?.body
                  )}
                  maxW="none"
                  mt="0px"
                  {...ProgramFeeSt[lang].feeDiscount3}
                  color="#CD9F00"
                  textAlign="center"
                />
              </>
            )}
            {searchByFieldId(sectionContent, 'special-pricing-popup-text') && (
              <Box color={'#6D6358'}>
                {
                  searchByFieldId(sectionContent, 'special-pricing-popup-text')
                    .text
                }
              </Box>
            )}
            {searchByFieldId(sectionContent, 'popup-text2') && (
              <StructuredTextParser
                str={render(
                  searchByFieldId(sectionContent, 'popup-text2').body
                )}
                mt="2rem"
                {...ProgramFeeSt[lang].retakeTextP}
              />
            )}
          </ModalBody>
          <ModalFooter>
            {sectionContent[sectionContent.length - 2]._modelApiKey ===
              'simple_button' && (
                <SimpleButton
                  style={sectionContent[sectionContent.length - 2]}
                  region={region}
                  lang={lang}
                  mx="auto"
                  borderRadius="30px"
                  bgColor="#E86F34"
                  minW="240px"
                  mb="0px"
                  _hover={{ bgColor: '#BC570F' }}
                  onClick={() => {
                    onClose();
                  }}
                />
              )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <style global jsx>
        {`
          .paLink p {
            padding-bottom: 8px;
          }
          .paLink p a {
            color: #ce6113;
            text-decoration: underline;
          }
          .paLink p a:hover {
            color: #ce6113;
            text-decoration: none;
          }
          .textPspacing p {
            padding-top: 0px !important;
          }
        `}
      </style>
    </>
  );
};
export default SpecialPriceSection;
