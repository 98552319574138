/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SliderExperience from '@ieo/Components/SliderExperience';

/* Services */
/* Styles */

/**
 *
 * @param {Object} SectionContent
 *  @param {String} lang
 *  @param {String} region
 * @returns ProgramTestimonial
 */
const ProgramTestimonialV3 = ({ sectionContent, region, lang, pageData }) => {
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  return (
    <Box
      bg="url(https://static.sadhguru.org/d/46272/1694863168-testimonial-base.png)"
      bgRepeat={
        pageConfigVal?.myExp?.bgImage?.repeat === true
          ? 'repeat-x'
          : 'no-repeat'
      }
      bgPos={{ base: 'center -10%', md: 'bottom' }}
      bgSize="cover"
    >
      <Flex maxW="1330px" flexDir="column" margin={'0 auto'}>
        <Box
          pos={{ base: 'static', md: 'relative' }}
          top="30px"
          // h={{ base: '640px', md: '500px', lg: '520px' }}
        >
          <SliderExperience
            sectionContent={sectionContent?.[0]}
            lang={lang}
            region={region}
            paddingBottom={'50px'}
            heightBase={'auto'}
            color="#fff"
            arrowTop="inherit"
            left="15%"
            right="-3%"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ProgramTestimonialV3;
