/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Configs */
import { ResearchShowsSt } from '@ieo/Common/CompLangConfig';
/* Styles */
/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const IeoNewFormat = ({ sectionContent, region, lang }) => {
  // console.log('Research', sectionContent);
  // const router = useRouter();
  // const [scrollBehavior, setScrollBehavior] = React.useState('inside');
  // const isIndian = config.indianLang.indexOf(lang) > -1;

  // const btnRef = React.useRef(null);
  return (
    <Box
      maxW={1330}
      w="100%"
      mx="auto"
      px={{ base: '16px', sm: '0', md: '0px' }}
      py="40px"
    >
      <Box
        maxW={{ base: '100%', sm: 'calc(100% - 66px)', xl: 1050 }}
        w="100%"
        ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
        mr={{ base: '0', sm: 'auto', xl: 'auto' }}
      >
        <Box
          w={{ base: '100%', md: '100%', xl: '100%' }}
          className="NewIeoFormat"
        >
          <Box w="100%">
            <Box
              w="full"
              as="h1"
              lineHeight={{ base: '42px', lg: 'initial' }}
              pb={{ base: '0px', md: '0px' }}
              {...ResearchShowsSt[lang].stText}
            >
              {sectionContent?.[0]?.titleText}
            </Box>
            <Box
              width={{ base: '100%', sm: '100%' }}
              bgColor="#CF915E"
              height="1px"
            ></Box>
          </Box>
          <Box
            maxW={{ base: '100%', md: '100%' }}
            w="100%"
            mb={{ base: '30px', md: '80px' }}
          >
            <StructuredTextParser
              str={render(sectionContent[1]?.body)}
              fontFamily="FedraSansStd-book"
              className="NewFormatText"
              fontSize="18px"
              lineHeight="27px"
              {...ResearchShowsSt[lang].stText}
            />
          </Box>
          <style global jsx>
            {`
                    .NewFormatText { max-width: 100% !important}
                    .NewIeoFormat h1{
                        color: #3B444B;
                        font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                        font-weight:${ResearchShowsSt[lang]?.heading?.fontWeight};
                        font-size: 64px;
                        line-height: 64px;
                        margin-bottom:30px;
                        margin-top: 60px;
                    }
                    .NewFormatText h2{
                        font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                        font-weight:${ResearchShowsSt[lang]?.heading?.fontWeight};
                        color: #12166F;
                        font-size: 48px;
                        margin-top: 50px;
                        margin-bottom: 30px;
                    }
                    .NewFormatText h3{
                        font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                        font-weight:${ResearchShowsSt[lang]?.heading?.fontWeight};
                        color: #282E8F;
                        font-size: 24px;
                        margin-top: 50px;
                        margin-bottom: 30px;
                    }
                    .NewFormatText ul {
                        margin-left: 25px;
                    }
                    .NewFormatText ul li p {
                      color:#6D6358;
                      font-size: 18px;
                      line-height: 27px;
                      padding: 5px 0;
                    }
                    .NewFormatText p {
                        padding-top: 15px;
                        line-height: 27px;
                    }
                    @media (max-width:992px) and (min-width:768px){
                    .NewIeoFormat h1,
                    .NewFormatText h2{
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom:30px;
                    }                    
                    .NewFormatText h2{
                        font-size: 24px;
                        margin-top: 50px;
                    }
                    .NewFormatText h3{
                        font-size: 20px;
                    }
                    }
                    @media (max-width:768px) and (min-width:480px){
                    .NewIeoFormat h1,
                    .NewFormatText h2{
                        color: #3B444B;
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom:20px;
                    }                    
                    .NewFormatText h2{
                        font-size: 24px;
                        margin-top: 30px;
                    }
                    .NewFormatText h3{
                        font-size: 20px;
                    }
                    .NewFormatText p{
                        font-size: 16px;
                        line-height: 27px;
                    }
                }
                    @media (max-width:480px){
                      .NewIeoFormat h1,
                    .NewFormatText h2{
                        color: #3B444B;
                        font-size: 32px;
                        line-height: 32px;
                        margin-bottom:20px;
                    }
                    .NewFormatText p{
                        font-size: 18px;
                        line-height: 27px;
                    }                    
                    .NewFormatText h2{
                        font-size: 24px;
                        margin-top: 30px;
                    }
                    .NewFormatText h3{
                        font-size: 20px;
                    }
                    `}
          </style>
        </Box>
      </Box>
    </Box>
  );
};

export default IeoNewFormat;
