/* Built In Imports */
import { usePathname } from 'next/navigation';
import { useRef, useState } from 'react';
/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { searchByFieldId } from '@components/Utility/Shared/SharedService';
import config from '@config';
import SingleImage from '@ieo/Components/SingleImage';
/* Configs */
import { ProgramSupportSt, ResearchShowsSt } from '@ieo/Common/CompLangConfig';

/* Styles */

/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const IeoBenefitsV5 = ({ sectionContent, region, lang }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const [ind, setIndex] = useState(-1);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const btnRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  const pathname = usePathname();
  const isPageURLmatch = pathname.includes('/offer/under-25s');

  return (
    <Box
      w="100%"
      mx="auto"
      px={{ base: '16px', sm: '0' }}
      py={{ base: '40px', md: '60px', lg: '80px' }}
    >
      <Box
        maxW={{
          base: '100%',
          sm: 'calc(100% - 66px)',
          xl: '1120px',
          '2xl': '1120px',
        }}
        w="100%"
        ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
        mr={{ base: '0', sm: 'auto', xl: 'auto' }}
      >
        <Heading
          as="h3"
          color="#12166F"
          fontFamily={ResearchShowsSt[lang]?.heading?.fontFamily}
          fontSize={{ base: '20px', md: '26px', lg: '26px' }}
          fontStyle="normal"
          fontWeight={isIndian ? '600' : 'normal'}
          lineHeight={{ base: '25.4px', md: '27.16px', lg: '25.22px' }}
          w={{
            base: lang === 'ar' ? '94%' : 'auto',
            md: lang === 'ar' ? '94%' : '100%',
            xl: lang === 'ar' ? '94%' : 'auto',
          }}
          maxW="500px"
          textAlign="center"
          mx="auto"
          pb="30px"
        >
          {sectionContent[0]?.titleText}
        </Heading>
        <Flex height="100%">
          <Flex
            justifyContent={{ base: 'start', md: 'center', xl: 'start' }}
            mt={{ base: '40px', md: '50px', xl: '0' }}
            flexWrap="wrap"
          >
            {searchByFieldId(sectionContent, 'research-shows-subtitle') &&
              isPageURLmatch && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'research-shows-subtitle')
                      .body
                  )}
                  region={region}
                  lang={lang}
                  fontSize={{ base: '18px', lg: '22px' }}
                  fontFamily="'FedraSansStd-book'"
                  color="#A81D28"
                  pt="8px"
                />
              )}

            <Flex
              mt={{ base: '50px', md: '43px', lg: '53px' }}
              gridRowGap={{ base: '0', sm: '50px' }}
              gridColumnGap="20px"
              flexWrap="wrap"
              maxW="880px"
              mx="auto"
            >
              {sectionContent[1]?.cards?.map((card, index) => {
                return (
                  <Flex
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    w={{ base: '100%', sm: '318px', md: '250px' }}
                    h={{ base: 'auto', sm: 'auto', md: '150px' }}
                    key={nanoid(4)}
                    mb={{ base: '45px', sm: '0' }}
                    onClick={() => {
                      isMobile && setIndex(index);
                      isMobile &&
                        setIsOpenMobile(
                          index === ind && isOpenMobile ? false : true
                        );
                    }}
                  >
                    <Box
                      h="100%"
                      position="relative"
                      w="100%"
                      bg="#F1EBDF"
                      display="flex"
                      justifyContent="space-between"
                      flexDir="column"
                      pt={{ base: '40px', md: '0px' }}
                    >
                      <Box
                        alignSelf={lang === 'ar' ? 'flex-end' : 'auto'}
                        width={{ base: '60px', md: '68px' }}
                        height={{ base: '60px', md: '68px' }}
                        position="absolute"
                        top={{ base: '-30px', md: '-34px' }}
                        left={{ base: '26px', md: '20px' }}
                      >
                        <SingleImage
                          my="0"
                          sectionContent={card?.image}
                          mx="0"
                          px="0"
                          py="0"
                          sync={true}
                          w={{ base: '60px', md: '68px' }}
                          h={{ base: '60px', md: '68px' }}
                        />
                      </Box>
                      <Flex
                        w="100%"
                        borderLeft={{
                          base: lang === 'ar' ? 'none' : '7px solid #A81D28',
                          sm: lang === 'ar' ? 'none' : '6px solid #A81D28',
                        }}
                        borderRight={{
                          base: lang === 'ar' ? '7px solid #A81D28' : 'none',
                          sm: lang === 'ar' ? '6px solid #A81D28' : 'none',
                        }}
                        h={{ base: '60%', sm: '58%' }}
                        mt={{ base: '35px', md: 'auto' }}
                        mb="10px"
                        pb="20px"
                        alignItems="center"
                        justifyContent="center"
                        fontFamily="FedraSansStd-book"
                        flexDir="column"
                        px="20px"
                      >
                        <Flex
                          w="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <StructuredTextParser
                            {...ResearchShowsSt[lang].popUpTitle}
                            mt="0"
                            // ml={{ base: '55px' }}
                            str={render(card?.image?.description?.value)}
                            id="ResearchCardText"
                            noOfLines={{ base: null, md: 3 }}
                          />
                          <style global jsx>
                            {`
                              #ResearchCardText p {
                                color: #423e3b;
                                font-family: FedraSansStd-book;
                              }
                              #ResearchCardText p strong {
                                color: #a81d28;
                                font-family: FedraSansStd-medium;
                                font-weight: normal;
                              }
                            `}
                          </style>
                          <Box
                            onClick={() => {
                              setIndex(index), setIsOpenMobile(true);
                            }}
                            display={{
                              base:
                                index === ind && isOpenMobile
                                  ? 'none'
                                  : 'block',
                              md: 'none',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                            >
                              <path
                                d="M18.4167 13L13 13M13 13L7.58332 13M13 13L13 7.58337M13 13L13 18.4167"
                                stroke="#CE6113"
                                stroke-width="3"
                                stroke-linecap="square"
                              />
                            </svg>
                          </Box>
                        </Flex>
                        <Show breakpoint="(max-width: 767px)">
                          <Flex
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            transition="all .4s linear"
                          >
                            <Collapse
                              in={index === ind && isOpenMobile}
                              animateOpacity
                            >
                              <StructuredTextParser
                                fontSize="16px"
                                lineHeight="20px"
                                {...ProgramSupportSt[lang].stText}
                                mt="0"
                                str={render(card?.description)}
                              />
                            </Collapse>
                            <Box
                              onClick={event => {
                                event.stopPropagation();
                                setIsOpenMobile(false);
                                setIndex(index);
                              }}
                              alignSelf="flex-start"
                              display={{
                                base:
                                  index === ind && isOpenMobile
                                    ? 'block'
                                    : 'none',
                                md: 'none',
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                              >
                                <path
                                  d="M7.58334 13L18.4167 13"
                                  stroke="#CE6113"
                                  stroke-width="3"
                                  stroke-linecap="square"
                                />
                              </svg>
                            </Box>
                          </Flex>
                        </Show>
                      </Flex>
                    </Box>
                  </Flex>
                );
              })}

              <Show breakpoint="(min-width: 768px)">
                {lang !== 'ar' && (
                  <Flex
                    w={{ base: '100%', md: '240px' }}
                    h={{ base: '70px', md: '150px' }}
                    alignItems={{ base: 'unset', md: 'center' }}
                    justifyContent="center"
                    mt={{ base: '20px', md: '0' }}
                  >
                    <span onClick={onOpen}>
                      <Text
                        fontFamily={ResearchShowsSt[lang]?.heading?.fontFamily}
                        fontWeight={isIndian ? 550 : 450}
                        ref={btnRef}
                        style={sectionContent[4]}
                        textAlign="center"
                        color="#F37021"
                        cursor="pointer"
                        fontSize="18px"
                        _hover={{
                          textDecoration: 'underline',
                          color: '#BC570F',
                        }}
                      >
                        {lang === 'ar' ? (
                          <>
                            <ChevronLeftIcon fontSize="24px" />{' '}
                            {findField('research-shows-popup')?.buttonText}
                          </>
                        ) : (
                          <>
                            {findField('research-shows-popup')?.buttonText}
                            <ChevronRightIcon fontSize="24px" />
                          </>
                        )}
                      </Text>
                      {/* </NextLink> */}
                    </span>
                  </Flex>
                )}
              </Show>
            </Flex>
            <Flex
              pt={{ base: '0px', lg: '50px' }}
              flexDir={{ base: 'column', md: 'row' }}
              alignItems="baseline"
              justify="center"
              mx={{ base: '0px', md: 'auto' }}
              gap="20px"
              textAlign="center"
            >
              <Box
                textAlign="center"
                display="flex"
                justify="center"
                alignItems="center"
              >
                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  w="auto"
                  maxW={{ base: '100%', md: 'auto', lg: '394px' }}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-one')}
                  mb="0"
                  maxH="40px"
                />
              </Box>
              <Box
                textAlign="center"
                display="flex"
                justify="center"
                alignItems="center"
              >
                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  w="full"
                  maxW={{ base: '100%', md: '300px', lg: '394px' }}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-two')}
                  mb="0"
                  maxH="40px"
                />
              </Box>
              <Box
                textAlign="center"
                display="flex"
                justify="center"
                alignItems="center"
              >
                <SingleImage
                  px={{ base: '0', md: '30px', lg: '0' }}
                  mb="0"
                  w="auto"
                  maxW={{ base: '100%', md: '350px', lg: '394px' }}
                  marginRight={{ base: '0', sm: '0', md: 'auto' }}
                  marginTop={{ base: '24px', md: '18px', lg: '18px' }}
                  h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                  sectionContent={findField('research-shows-logo-three')}
                  maxH="40px"
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Modal
        w={{ md: '808px', base: '321px' }}
        h={{ md: '85vh', base: 'auto' }}
        isOpen={isOpen}
        onClose={onClose}
        size={useBreakpointValue({ base: 'full', md: '808px' })}
        finalFocusRef={btnRef}
        background="#F1EBDF"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="0px"
          background="#F1EBDF"
          w="100%"
          maxW={{ base: '100%', md: '746px', lg: '808px' }}
          my={{ base: '0', md: '8px' }}
          h={{ base: '95%', md: '97%' }}
          overflow="hidden"
        >
          <ModalHeader mt={{ base: '13px', md: 'calc(1rem + 20px)' }} pt="0">
            <Heading
              as="h3"
              pt={{ base: '10px', md: 0 }}
              {...ResearchShowsSt[lang]?.h3}
            >
              {sectionContent[1]?.titleText}
            </Heading>
          </ModalHeader>
          <ModalCloseButton
            mt="10px"
            color="E86F34"
            top={{ base: 1, md: 5 }}
            background="transparent"
            _hover={{
              background: 'transparent',
              boxShadow: 'none',
            }}
            _active={{
              background: 'transparent',
              boxShadow: 'none',
            }}
            _focus={{
              background: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19 20.4143L12 13.4143L4.99997 20.4143L3.58576 19.0001L10.5858 12.0001L3.58576 5.00009L4.99997 3.58588L12 10.5859L19 3.58588L20.4142 5.00009L13.4142 12.0001L20.4142 19.0001L19 20.4143Z"
                  fill="#E86F34"
                />
              </svg>
            </Box>
          </ModalCloseButton>
          <ModalBody pr={{ base: '12px', md: 6 }} pl={{ base: '32px', md: 6 }}>
            <Box pb={{ base: '0', md: '0', lg: '30px' }} background="#F1EBDF">
              <Flex
                flexWrap="wrap"
                w="100%"
                justifyContent={{ base: 'start', lg: 'start' }}
                alignItems={{ base: 'start', lg: 'start' }}
                maxH={{ base: '85vh', md: '80vh' }}
                h="100%"
                overflowY="auto"
                pr={{ base: '16px', md: '16px' }}
                className="customScrollbar"
              >
                {sectionContent[1]?.cards?.map((card, index) => {
                  return (
                    <Box
                      w="100%"
                      key={nanoid()}
                      borderBottom={{
                        base:
                          index === sectionContent[1].cards.length - 1
                            ? '0px solid'
                            : 'solid 1px #C5BFB1',
                        md: 'none',
                      }}
                    >
                      <Flex
                        m={{
                          base: '0 0px 0 0px',
                          md: '0 0px 0 10px',
                          lg: '0 0px 0 0px',
                        }}
                        p={{
                          base: '0 0px 0 0px',
                          md: '0 10px 0 0px',
                        }}
                        flexGrow="1"
                        justifyContent={{
                          base: 'center',
                          md: 'start',
                        }}
                        alignItems={{
                          base: 'center',
                          md: 'start',
                        }}
                      >
                        <Box w="100px" height="100%">
                          <LazyLoadImageComponent
                            objectFit="cover"
                            w="100%"
                            h="100%"
                            maxW="65px"
                            src={card.image?.image?.url}
                            alt={card.image?.image?.alt}
                            title={card.image?.image?.title}
                            mt="15px"
                          />
                        </Box>
                        <Box
                          borderBottom={{
                            base: 'none',
                            md: 'solid 1px #C5BFB1',
                          }}
                          w={{
                            base: 'calc(100% - 5%)',
                            md: 'calc(100% - 100px)',
                          }}
                        >
                          <StructuredTextParser
                            {...ResearchShowsSt[lang].popUpTitle}
                            // ml={{ base: '55px' }}
                            str={render(card?.image?.description?.value)}
                          />

                          <StructuredTextParser
                            display={{
                              base: 'none',
                              md: 'block',
                            }}
                            {...ResearchShowsSt[lang].popUpDesc}
                            pt="5px"
                            pb="20px"
                            str={render(card?.description.value)}
                          />
                        </Box>
                      </Flex>
                      <StructuredTextParser
                        display={{
                          base: 'block',
                          md: 'none',
                        }}
                        {...ResearchShowsSt[lang].popUpDesc}
                        pt="5px"
                        pb="10px"
                        str={render(card?.description.value)}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default IeoBenefitsV5;
