/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SingleImage from '@components/Card/SingleImage';
import SingleLineText from '@components/UI/SingleLineText';
import { AdBanner2St } from '@ieo/Common/CompLangConfig';

/* Configs */
/* Styles */
/* Services */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 * Renders sectionContent
 * @param {Object} sectionContent
 * @returns {ReactElement} AdBanner2
 */
const AdBanner2 = ({ sectionContent, region, lang }) => {
  const findButtons = sectionContent.filter(el => {
    return el._modelApiKey === 'simple_button';
  });

  return (
    <Box
      maxW={1345}
      w="100%"
      mx="auto"
      // bg="lightgray"
      bgImage={{
        base: sectionContent[0]?.mobileImage?.url,
        lg: sectionContent[0]?.image?.url,
      }}
      bgSize="cover"
      pr={{ base: '16px', lg: '0' }}
      pl={{ base: '16px', lg: '12%' }}
      py="35px"
      mb={{ base: '90px', lg: '100px' }}
    >
      <Box
        w={{ base: '100%', lg: '82%' }}
        ml="auto"
        maxW={{ base: '100%', md: 700, xl: 900 }}
        mr={{ base: 'auto', lg: '4%', xl: '8%' }}
        px={{ base: '16px', lg: '0' }}
        pt={{ base: '30px', lg: '0' }}
      >
        <Flex
          alignItems="center"
          flexDir={{ base: 'column', lg: 'row' }}
          justifyContent={{ base: 'unset', lg: 'space-evenly' }}
        >
          {searchByFieldId(sectionContent, 'text-heading') && (
            <Box
              maxW={{ base: '100%', lg: 400 }}
              w={{ base: '100%', lg: '45%' }}
            >
              <SingleLineText
                sectionContent={searchByFieldId(sectionContent, 'text-heading')}
                color="#fff"
                textAlign={{ base: 'center', lg: 'left' }}
                my="0"
                pl={{ base: '0', lg: '25px', xl: '0' }}
                {...AdBanner2St[lang].textHeading}
                fontFamily="FedraSerifAStdBook"
              />
            </Box>
          )}
          {searchByFieldId(sectionContent, 'divider-img') && (
            <Flex
              justifyContent="center"
              alignItems="center"
              my={{ base: '15px', lg: '0' }}
            >
              <Flex
                justifyContent="center"
                opacity="0.5"
                alignItems="center"
                transform={{ base: 'rotate(90deg)', lg: 'rotate(0deg)' }}
                w={{ base: '101px', lg: 'auto' }}
                h={{ base: '40px', lg: 'auto' }}
              >
                <SingleImage
                  verticalAlign="middle"
                  sectionContent={searchByFieldId(
                    sectionContent,
                    'divider-img'
                  )}
                />
              </Flex>
            </Flex>
          )}
          <Flex
            flexDir="column"
            maxW={{ base: '100%', lg: 400 }}
            w={{ base: '100%', lg: '45%' }}
          >
            {searchByFieldId(sectionContent, 'join-live') && (
              <Box mb="20px">
                <SingleLineText
                  my="0"
                  py="0"
                  color="#fff"
                  sectionContent={searchByFieldId(sectionContent, 'join-live')}
                  textAlign={{ base: 'center', lg: 'left' }}
                  {...AdBanner2St[lang].joinLive}
                />
              </Box>
            )}
            {searchByFieldId(sectionContent, 'date-2') ? (
              <>
                <Flex
                  mb="10px"
                  alignItems="center"
                  flexDir={{ base: 'column', lg: 'row' }}
                  justifyContent={{ base: 'center', lg: 'space-between' }}
                >
                  {searchByFieldId(sectionContent, 'date-1') && (
                    <>
                      <Text
                        textAlign={{ base: 'center', lg: 'left' }}
                        color="#FF8501"
                        my="0"
                        maxW="auto"
                        mx={0}
                        height="auto"
                        {...AdBanner2St[lang].date}
                      >
                        {searchByFieldId(sectionContent, 'date-1')?.text}
                        <Text
                          as="span"
                          textAlign={{ base: 'center', lg: 'left' }}
                          color="#FF8501"
                          my="0"
                          mx="0"
                          height="auto"
                          {...AdBanner2St[lang].date}
                        >
                          {searchByFieldId(sectionContent, 'time-1')?.text}
                        </Text>
                      </Text>
                    </>
                  )}
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    mt={{ base: '15px', lg: '0' }}
                    maxH="38px"
                    w="167px"
                    ml={{ base: '0', lg: '10px' }}
                  >
                    <SimpleButton
                      style={findButtons[0]}
                      bg="#FF8501"
                      padding="8px"
                      mt="0"
                      height="38px"
                      width="167px"
                      mb="0"
                      borderRadius="4px"
                      fontFamily="FedraSansStd-book"
                      fontSize="18px"
                      fontStyle="normal"
                      fontbold="450"
                      lineHeight="21.6px"
                      minHeight="0"
                    />
                  </Flex>
                </Flex>
                <Flex
                  alignItems="center"
                  flexDir={{ base: 'column', lg: 'row' }}
                  justifyContent={{ base: 'center', lg: 'space-between' }}
                  mt={{ base: '20px', lg: '0' }}
                >
                  {searchByFieldId(sectionContent, 'date-2') && (
                    <SingleLineText
                      textAlign={{ base: 'center', lg: 'left' }}
                      sectionContent={searchByFieldId(sectionContent, 'date-2')}
                      color="#FF8501"
                      my="0"
                      height="auto"
                      mx={{ base: 'auto', lg: '0' }}
                      {...AdBanner2St[lang].date}
                    />
                  )}
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    maxH="38px"
                    w="167px"
                    ml={{ base: '0', lg: '10px' }}
                    mt={{ base: '15px', lg: '0' }}
                  >
                    <SimpleButton
                      style={findButtons[1]}
                      bg="#FF8501"
                      padding="8px"
                      height="38px"
                      width="167px"
                      mt="0"
                      mb="0"
                      borderRadius="4px"
                      fontFamily="FedraSansStd-book"
                      fontSize="18px"
                      fontStyle="normal"
                      fontbold="450"
                      lineHeight="21.6px"
                      minHeight="0"
                    />
                  </Flex>
                </Flex>
              </>
            ) : (
              <Flex
                alignItems="center"
                flexDir={{ base: 'column', lg: 'row' }}
                justifyContent={{ base: 'center', lg: 'space-between' }}
                w="100%"
              >
                {searchByFieldId(sectionContent, 'date-1') && (
                  <Box
                    mb={{ base: '10px', lg: '0' }}
                    w="100%"
                    pr={{ base: '0', lg: '15px' }}
                  >
                    <Text
                      textAlign={{ base: 'center', lg: 'left' }}
                      color="#FF8501"
                      my="0"
                      mx={{ base: 'auto', lg: '0' }}
                      height="auto"
                      {...AdBanner2St[lang].date}
                    >
                      {searchByFieldId(sectionContent, 'date-1')?.text}{' '}
                      <Text
                        as="span"
                        textAlign={{ base: 'center', lg: 'left' }}
                        color="#FF8501"
                        my="0"
                        mx={{ base: 'auto', lg: '0' }}
                        height="auto"
                        {...AdBanner2St[lang].date}
                      >
                        {searchByFieldId(sectionContent, 'time-1')?.text}
                      </Text>
                    </Text>
                  </Box>
                )}
                {/* {searchByFieldId(sectionContent, 'date-2') && (
                <SingleLineText
                  textAlign={{ base: 'center', lg: 'left' }}
                  sectionContent={searchByFieldId(sectionContent, 'date-2')}
                  color="#FF8501"
                  my="0"
                  height="auto"
                  px={{ base: '0', lg: '15px' }}
                  mx={{ base: 'auto', lg: '0' }}
                  {...AdBanner2St[lang].date}
                />
              )} */}
                <SimpleButton
                  style={findButtons[0]}
                  ml="auto"
                  mr={{ base: 'auto', lg: '0' }}
                  bg="#FF8501"
                  padding="8px"
                  height="38px"
                  width="167px"
                  mt={{ base: '15px', lg: '0' }}
                  mb="0"
                  borderRadius="4px"
                  fontFamily="FedraSansStd-book"
                  fontSize="18px"
                  fontStyle="normal"
                  fontbold="450"
                  lineHeight="21.6px"
                  minHeight="0"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default AdBanner2;
