/* Built In Imports */

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';

/* Services */
/* Config */
import { PageSectionV5St } from '@ieo/Common/CompLangConfig';

const PageSectionV5 = ({ sectionContent, lang }) => {
  const cardsData = sectionContent[1].cards;

  return (
    <Box
      backgroundColor={'#FBFBFB'}
      textAlign={'center'}
      p={{ base: '0.5rem', md: '5rem' }}
    >
      <Box maxW="950px" w="full" mx="auto">
        <StructuredTextParser
          str={render(sectionContent[0]?.body)}
          color={'#3B444B'}
          fontFamily={'FedraSansStd-medium'}
          fontSize={{ base: '32px', md: '64px' }}
          lineHeight={{ base: '40px', md: '64px' }}
          fontWeight={'500'}
          textAlign={'center'}
          mx="auto"
          py="2rem"
        />
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={{ base: 'column', lg: 'row' }}
          gap={'20px'}
          py={{ base: 1, md: 4 }}
        >
          {cardsData.map((card, index) => {
            return (
              <Box
                key={index}
                display={'flex'}
                alignItems={'center'}
                flexDirection={{ base: 'column', md: 'row' }}
                gap={'10px'}
                maxW={{ base: '290px', sm: '300px', md: '450px' }}
                w="full"
                mx="auto"
              >
                <Image
                  src={card.thumbnail.url}
                  alt={card.thumbnail.alt}
                  mr={4}
                  w={{ base: '134px', md: '95px' }}
                  h={{ base: '134px', md: '95px' }}
                />
                <Box textAlign={'left'} p={2} w="100%">
                  <Text
                    color={'#323232'}
                    fontFamily={'FedraSansStd-medium'}
                    fontSize={{ base: '22px', md: '22px' }}
                    lineHeight={{ base: '30px', md: '28.6px' }}
                    fontWeight={'500'}
                  >
                    {card.title}
                  </Text>
                  <StructuredTextParser
                    str={render(card.description.value)}
                    mt={{ base: '4px', md: '8px' }}
                    fontFamily={'FedraSansStd-book'}
                    fontSize={{ base: '14px', md: '18px' }}
                    lineHeight={'19.2px'}
                    fontWeight={'400'}
                    pt={1}
                    fontStyle="italic"
                    textAlign={'left'}
                    {...PageSectionV5St[lang]}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PageSectionV5;
