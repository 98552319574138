/* Built In Imports */

/* External Imports */
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ProgramSupportSt } from '@ieo/Common/CompLangConfig';
import { usePathname } from 'next/navigation';

/* Services */
/* Styles */

/**
 *
 * @param {Object} sectionContent
 *  @param {String} lang
 *  @param {String} region
 * @returns {ReactElement} Communication
 */
const Communication = ({ sectionContent, region, lang, pageData }) => {
  // const isMobile = useMediaQuery({ maxWidth: 560 });
  const path = usePathname();
  const url = path.includes('offer/under-25s');

  return (
    <Box
      pos="relative"
      maxH={url && '100%'}
      overflow={url && 'hidden'}
      w="100%"
      _before={{
        content: "''",
        position: 'absolute',
        display: { base: 'none', md: url && 'block' },
        width: 665,
        height: 600,
        backgroundImage:
          'https://www.datocms-assets.com/46272/1722428984-bg-communication.png',
        backgroundPosition: 'top',
        right: '0px',
        top: '110px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        maxW={1030}
        w="100%"
        h={{ base: '100%', md: '100%' }}
        mx="auto"
        pos="relative"
        mt="16px"
        dir={lang == 'ar' ? 'rtl' : 'ltr'}
      >
        <Box
          pos="absolute"
          top={{ base: 'unset', md: 'unset', lg: '8px' }}
          bottom={{ base: 'unset', md: '-32px', lg: 'unset' }}
          w={{ base: '0', md: '181px', lg: '158px' }}
          right={lang == 'ar' ? 'auto' : '0'}
          left={lang == 'ar' ? '0' : 'auto'}
          h={{ md: '315px', lg: '356px' }}
        >
          <Box display={{ base: 'none', xl: 'none' }}>
            {' '}
            <Image
              src="https://static.sadhguru.org/d/46272/1694600647-26-3.png"
              alt="floral design"
              h="100%"
              w="100%"
            />{' '}
          </Box>
        </Box>
        <Flex
          as="section"
          w="full"
          // mt={{ md: '100px', xl: '146px' }}
          mb={{ base: '60px', md: '100px' }}
          flexDir={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'start', lg: '"self-start"' }}
          justifyContent={{ base: 'center', lg: 'space-between' }}
          gap={{ base: '0px', md: '20px' }}
          mx="auto"
          px={{ base: '16px', xl: '0px' }}
        >
          <Heading
            as="h2"
            maxW={{ base: lang === 'ar' ? '100%' : '288px', sm: 'initial' }}
            {...ProgramSupportSt[lang].h2}
            p={{ base: '0 20px 26px 0', lg: '0 0 40px 0' }}
            lineHeight={{ base: '36.8px', xl: '62.8px' }}
            dir={lang == 'ar' ? 'rtl' : 'ltr'}
            textAlign={lang == 'ar' ? 'right' : 'left'}
          >
            {sectionContent?.[0]?.titleText}
          </Heading>
          <Box
            bg={{ base: url ? '#F3F3F3' : '#fff', md: '#F3F3F3' }}
            maxW="510px"
          >
            <Box
              display="flex"
              alignItems="self-start"
              justifyContent="space-between"
              p={{ base: '16px 20px 16px 8px ', md: '16px 20px 16px 30px' }}
            >
              {' '}
              <Image
                src="https://www.datocms-assets.com/46272/1722427084-communication-icon.png"
                alt=""
                w="26px"
                h="20px"
                mx={{ base: url ? '0px' : '8px', md: '16px' }}
                mt={{ base: '0px', md: '15px' }}
              />{' '}
              <StructuredTextParser
                str={render(sectionContent[1]?.body)}
                region={region}
                sectionContent={sectionContent}
                className="communication-link"
                m={'0px'}
                pl="5px"
                fontFamily="FedraSansStd-medium"
                fontSize={{ base: '14px', md: '22px' }}
                lineHeight={{ base: '20.16px', md: '46px' }}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
      <style global jsx>
        {`
          .communication-link {
            margin: 0px !important;
          }

          .communication-link p {
            width: 100%;
          }
          .communication-link p a {
            color: #e86f34 !important;
          }
        `}
      </style>
    </Box>
  );
};

export default Communication;
