/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
/* Configs */
import programStructureCardSt from '../Common/StyleData/ProgramStructureCardSt';
/* Styles */
/* Services */

/**
 * @param {String} lang
 * @param {Object} data
 * @returns {ReactElement} ProgramStructureCard
 */

const ProgramStructureCard = ({ pageName, data, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [mouse, setMouse] = useState(false);

  return (
    <Box
      pos="relative"
      height={pageName === "hytt" && "273px"}
      boxShadow={pageName === "hytt" ? "none" : "0 12px 14px -10px  #A45B0B"}
      overflow="hidden"
      mb="20px"
    >
      <Flex

        cursor="pointer"
        bg={pageName === "hytt" ? `url(${data?.thumbnail?.url})` : `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.95) 89%), url(${data?.thumbnail?.url})`}
        width={pageName === "hytt" ? "auto" : { base: '90vw', sm: '330px' }}
        bgPos="top center"
        bgSize="cover"
        justify="flex-end"
        bgRepeat="no-repeat"
        flexDir="column"
        pl="30px"
        pr="20px"
        pb="20px"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        {...programStructureCardSt[lang].card}
      >
        <Text as="h2" {...programStructureCardSt[lang].h2}>
          {data?.sessions}
        </Text>
        <Flex justify="space-between" dir="ltr">
          {lang === 'ar' ? (
            <>
              <Box
                cursor="pointer"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                >
                  <path
                    d="M15 8.99243V15.2424M15 15.2424V21.4924M15 15.2424H21.25M15 15.2424H8.75"
                    stroke={hover ? '#BC570F' : '#F37021'}
                    strokeWidth="3.33333"
                    strokeLinecap="square"
                  />
                </svg>
              </Box>
              <Text as="p" {...programStructureCardSt[lang].p} dir="rtl">
                {data?.title}
              </Text>
            </>
          ) : (
            <>
              {' '}
              <Text as="p" {...programStructureCardSt[lang].p}>
                {data?.title}
              </Text>
              <Box
                cursor="pointer"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                >
                  <path
                    d="M15 8.99243V15.2424M15 15.2424V21.4924M15 15.2424H21.25M15 15.2424H8.75"
                    stroke={hover ? '#BC570F' : '#F37021'}
                    strokeWidth="3.33333"
                    strokeLinecap="square"
                  />
                </svg>
              </Box>
            </>
          )}
        </Flex>
      </Flex>

      <Flex
        transition="all 0.3s ease"
        bg="white"
        opacity="0.9"
        w={pageName === "hytt" ? "auto" : "full"}
        h={pageName === "hytt" ? "100%" : "90%"}
        pos="absolute"
        bottom={isOpen ? '0' : '-110%'}
        flexDir="column"
        justify="space-between"
        align="flex-end"

        {...programStructureCardSt[lang].span}
      >
        <Box
          pos="relative"
          right={lang === 'ar' ? 'auto' : '-10px'}
          left={lang === 'ar' ? '0' : 'auto'}
          top="-10px"
          cursor="pointer"
          onClick={() => {
            setIsOpen(false);
          }}
          onMouseEnter={() => setMouse(true)}
          onMouseLeave={() => setMouse(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
          >
            <path
              d="M20 10.2424L10 20.2424M10 10.2424L20 20.2424"
              stroke={mouse ? '#BC570F' : '#F37021'}
              strokeWidth="3.33333"
              strokeLinecap="square"
            />
          </svg>
        </Box>
        <Box {...programStructureCardSt[lang].p1}>
          <StructuredText data={data.description.value.document} />
        </Box>
      </Flex>
    </Box>
  );
};

export default ProgramStructureCard;
