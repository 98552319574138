/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import { WhoCanDoV5St } from '../Common/CompLangConfig';

/* Services */
/* Config */

/**
 * @param {Object} sectionContent - Data
 * @param {string} region
 * @param {string} lang - Language of the page
 * @returns
 */

const styles = {
  contentText: {
    p: {
      padding: '4px 0',
      fontFamily: 'FedraSansStd-book',
      fontStyle: 'italic',
      a: {
        color: '#F37021',
        textDecoration: 'underline',
      },
    },
  },
};

const WhoCanDoV7 = ({ sectionContent, lang, region, scrollRef }) => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = sectionContent
      .filter(item => item._modelApiKey === 'single_image')
      .map(item => item);
    setImageUrls(urls);
  }, [sectionContent]);

  const urlData = [
    `Open to everyone, regardless of one's background or beliefs`,
    'No specific physical flexibility or agility required',
    'No prior experience or knowledge of Yoga is necessary',
  ];

  //console.log('==', sectionContent);
  return (
    <Box
      backgroundColor={'#F1EBDF'}
      maxW={'100%'}
      py={8}
      px={{ base: 4, md: 8 }}
    >
      <Box maxW={'1031px'} mx={'auto'}>
        <Text
          mt={{ base: '2.5rem', md: '4rem' }}
          mb={{ base: '1.5rem', md: '3rem' }}
          color={'#3B444B'}
          fontFamily={'FedraSansStd-medium'}
          fontSize={{ base: '32px', md: '64px' }}
          lineHeight={{ base: '35.2px', md: '64px' }}
          fontWeight={'500'}
        >
          {sectionContent[0].text}
        </Text>
        <Grid
          gap={{ base: 4, md: 8 }}
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        >
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Box maxW="100%" maxH="100%">
              <Box
                bgImage={{
                  base: sectionContent[1]?.mobileImage.url,
                  md: sectionContent[1]?.image?.url,
                }}
                width={{ base: '280px', md: '100%' }}
                height={{ base: '250px', md: '470px' }}
                display="flex"
                alignItems="flex-end"
                bgSize="cover"
                bgPos="top center"
                p={{ base: '15px', md: '30px 40px' }}
                mx="auto"
              >
                <Text
                  py={{ base: 4, sm: 2, md: 2 }}
                  color={'#FF9840'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={{ base: '14px', md: '24px' }}
                  lineHeight={{ base: '18px', md: '33.6px' }}
                  fontWeight={'500'}
                >
                  {urlData[0]}
                </Text>
              </Box>
            </Box>
          </GridItem>
          {sectionContent.slice(2, 4)?.map((url, index) => (
            <GridItem key={index} colSpan={{ base: 1, md: 1 }}>
              <Box
                bgImage={{
                  base: url?.mobileImage?.url,
                  md: url?.image?.url,
                }}
                width={{ base: '280px', md: '100%' }}
                height={{ base: '250px', md: '470px' }}
                display="flex"
                alignItems="flex-end"
                bgSize="cover"
                bgPos="top center"
                p={{ base: '5px 15px', md: '30px 40px' }}
                mx="auto"
              >
                <Text
                  bottom={{ base: 3, sm: 3, lg: 12 }}
                  left={{ base: 4, sm: 6, lg: 10 }}
                  py={{ base: 4, sm: 2, md: 2 }}
                  color={'#FF9840'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={{ base: '14px', md: '24px' }}
                  lineHeight={{ base: '18px', md: '33.6px' }}
                  fontWeight={'500'}
                >
                  {urlData[index + 1]}
                </Text>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
      <Box
        textAlign={'center'}
        mt={{ base: '1.5rem', md: '3rem' }}
        mb={{ base: '2.5rem', md: '4rem' }}
      >
        <StructuredTextParser
          str={render(sectionContent[4]?.body)}
          color="#000"
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          fontSize={{ base: '16px', md: '22px' }}
          fontWeight="400"
          fontStyle="italic"
          mx="auto"
          sx={styles.contentText} // Apply the styles here
          {...WhoCanDoV5St[lang]}
        />
        {sectionContent[5].linkUrl != '' && (
          <Box maxW={{ base: '217px', md: '226px' }} m="0 auto">
            <Flex align={'center'} justifyContent={'center'}>
              <SimpleButton
                style={sectionContent[5]}
                bType={sectionContent[5]?.sectionId}
                region={region}
                lang={lang}
                onClick={() => {
                  scroll.scrollTo(scrollRef.current.offsetTop, {
                    duration: 500,
                    smooth: true,
                  });
                }}
                width={{ base: '250px', md: '100%' }}
                mt={'3rem'}
                mb={'0 !important'}
                borderRadius={'12px'}
                background={'#F37021'}
                fontbold={'500'}
                _hover={{
                  background: '#BC570F !important',
                }}
              />
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WhoCanDoV7;
