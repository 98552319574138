import { Box, Flex, Heading, Image } from '@chakra-ui/react';

const GiftSection = ({ sectionContent }) => {
  // console.log(sectionContent);
  const title = sectionContent[0].text;
  const paragraphs = sectionContent[1].body.value.document.children.map(
    c => c.children[0].value
  );
  const imageUrl = sectionContent[2].image.url;

  return (
    <Box
      maxW={'1030px'}
      margin={{ base: '20px auto', md: ' auto', xl: '100px auto' }}
      padding={{ base: '16px', md: '40px 16px 180px 16px', xl: '0 0 100px 0' }}
    >
      <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box w={{ base: '100%', md: '392px' }}>
          <Heading fontSize={{ base: '32px', md: '40px', lg: '63px' }} mb={8}>
            {title}
          </Heading>
          <Box key="p1" as="p" mb={2}>
            {paragraphs[0]}
          </Box>
          <Box key="p2" as="p">
            {paragraphs[1]}
          </Box>
        </Box>
        <Box
          flexBasis={'100%'}
          height={0}
          display={{ base: 'block', md: 'none' }}
        ></Box>
        <Box>
          <Image src={imageUrl} width={{ base: '309px', lg: '520px' }} />
        </Box>
      </Flex>
    </Box>
  );
};

export default GiftSection;
