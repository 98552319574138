/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { shuffleArray } from '@components/Utility/Shared/SharedService';

/* Configs */
import { TestimonalSt } from '@ieo/Common/CompLangConfig';
/* Styles */
/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {String} lang
 * @returns Testimonial
 */
const TestimonialV5 = ({ sectionContent, lang, pageData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeCard, setActiveCard] = useState(0);
  const sliderRef = useRef(null);
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        bgColor={
          Math.ceil(activeSlide + 1) ===
          sectionContent?.[0]?.testimonials?.length
            ? '#ffffff'
            : '#F37021'
        }
        onClick={onClick}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos="absolute"
        top={{ base: '15%', md: '-120px', lg: '-135px', xl: '-142px' }}
        right={{ base: '0%', md: '40px', xl: '70px' }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor:
            Math.ceil(activeSlide + 1) ===
            sectionContent?.[0]?.testimonials?.length
              ? '#ffffff'
              : '#BC570F',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.83337 11.3333L7.16671 6.99996L2.83337 2.66663"
            stroke={
              Math.ceil(activeSlide + 1) ===
              sectionContent?.[0]?.testimonials?.length
                ? '#F37021'
                : '#ffffff'
            }
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;

    return (
      <Box
        cursor="pointer"
        bgColor={
          Math.ceil(activeSlide + 1) ===
          sectionContent?.[0]?.testimonials?.length
            ? '#F37021'
            : '#ffffff'
        }
        onClick={onClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos="absolute"
        top={{ base: '15%', md: '-120px', lg: '-135px', xl: '-142px' }}
        right={{ base: '0px', md: '174px', xl: '175px' }}
        left={{ base: '0%', md: '76%', lg: '82%', xl: '84%' }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor:
            Math.ceil(activeSlide + 1) ===
            sectionContent?.[0]?.testimonials?.length
              ? '#BC570F'
              : '#ffffff',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.16663 11.6678L2.83329 7.33443L7.16663 3.0011"
            stroke={
              Math.ceil(activeSlide + 1) ===
              sectionContent?.[0]?.testimonials?.length
                ? '#ffffff'
                : '#F37021'
            }
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  useEffect(() => {
    // const totalSlides = sliderRef.current.props.children.length;
    // const randomIndex = Math.floor(Math.random() * totalSlides);
    // setActiveSlide(randomIndex);
    sectionContent[0].testimonials = shuffleArray(
      sectionContent?.[0]?.testimonials
    );
  }, []);

  const settings = {
    arrow: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    afterChange: currentSlide => {
      setActiveSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box bg="#F1EBDF" mt="-105px" pt="120px">
      <Flex
        maxW="1330px"
        flexDir="column"
        py={{ base: '40px', md: '40px', lg: '90px', xl: '80px' }}
        alignItems="center"
        w="full"
        justify="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        // h={{ base: '820px', md: '630px', lg: '660px', xl: '730px' }}
        margin={'0 auto'}
      >
        <Box maxW={1130} w="full" pos="relative">
          <Flex
            justifyContent="space-between"
            w={{ base: 'full', md: '87%', lg: '80%', xl: '87%' }}
            alignItems={{ md: 'baseline', lg: 'center' }}
            minH={{ base: '60px', md: '120px', xl: '140px' }}
          >
            <Flex flexDir="column" w="full" px="16px">
              <Heading
                as="h2"
                pb={{ base: '8px', md: '10px' }}
                color="#3B444B"
                pr={{
                  md: lang === 'ar' ? '15%' : '5%',
                  lg: lang === 'ar' ? '32%' : '2%',
                }}
                textAlign="left"
                {...TestimonalSt[lang].subTitle}
              >
                {sectionContent?.[0]?.title[0]?.titleSubtext}
                <Box
                  as="span"
                  display="block"
                  color="#3B444B"
                  {...TestimonalSt[lang].h2}
                >
                  {' '}
                  {sectionContent?.[0]?.title[0]?.titleText}
                </Box>
              </Heading>
            </Flex>
            <Text
              pos={{ base: 'absolute', md: 'static' }}
              bottom="30px"
              left="47%"
              display={{ base: 'none', md: 'contents' }}
              {...TestimonalSt[lang].countNumber}
              color="#3B444B"
            >
              {' '}
              {Math.ceil(activeSlide + 1)}/
              {sectionContent?.[0]?.testimonials?.length}
            </Text>
          </Flex>
        </Box>
        <Box
          maxW={1330}
          w="full"
          pos="relative"
          pt={{ base: '20px', md: '40px' }}
        >
          <Slider {...settings} ref={sliderRef}>
            {sectionContent?.[0]?.testimonials?.map((item, index) => (
              <Box w="full" key={index} height="100%">
                <Box
                  position="relative"
                  display="flex"
                  flexDirection={{ base: 'column', md: 'row' }}
                  gridGap={{ base: '0px', md: '30px' }}
                  css={{ '-webkit-grid-gap': '30px' }}
                >
                  {' '}
                  <Image
                    src={item?.photo?.url}
                    alt={item?.photo?.alt || ''}
                    title={item?.photo?.title || ''}
                    mx="auto"
                    mt={{ base: '12px', md: '0px' }}
                    w={{ base: 'auto', md: '380px', lg: '479px' }}
                    h={{ base: '320px', md: '450px', xl: '540px' }}
                  />
                  <Flex
                    flexDirection="column"
                    justifyContent={{ base: 'flex-start', md: 'center' }}
                    m={{
                      base: '0 6px 0 10px',
                      md: '0 0px 0 0px',
                      lg: '0 10px 0 20px',
                      xl: '0 0px 0 20px',
                    }}
                    p={{
                      base: '0 20px 0 2px  0px',
                      md: '0 20px',
                      xl: '0 40px 0 20px',
                    }}
                    flexGrow="1"

                    //height={{ base: '830px', md: '400px', xl: '540px' }}
                  >
                    <Box
                      px={{ base: '25px', md: 0 }}
                      //  h={{ base: '150px', md: 'auto' }}
                    >
                      <StructuredTextParser
                        str={render(item?.testimonialTitle)}
                        {...TestimonalSt[lang].description}
                        position="relative"
                        className="highlightText"
                        color="#3B444B"
                      />

                      <Box position="relative" w="full">
                        {lang === 'ar' ? (
                          <svg
                            width="58"
                            height="47"
                            style={{
                              position: 'relative',
                              right: '-90%',

                              top: '-60px',
                            }}
                            viewBox="0 0 58 47"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.665367 46.9091H26.1654V45.4924V21.2067C26.1654 13.9363 23.1336 9.0385 20.086 5.97729C18.571 4.45553 17.057 3.39078 15.9175 2.70402C14.6317 1.9291 13.3875 1.45448 11.9987 0.896484V21.4091L0.665367 21.4091V46.9091Z"
                              fill="#cbc6bc"
                            />
                            <path
                              d="M31.832 46.9091H57.332V45.4924V21.2067C57.332 13.9363 54.3003 9.0385 51.2527 5.97729C49.7377 4.45553 48.2236 3.39078 47.0841 2.70402C45.7983 1.9291 44.5541 1.45448 43.1654 0.896484V21.4091L31.832 21.4091V46.9091Z"
                              fill="#cbc6bc"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="68"
                            height="69"
                            style={{ position: 'relative', left: '-30px' }}
                            viewBox="0 0 68 69"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                d="M62.3337 56.9091H36.8337V55.4924V31.2067C36.8337 23.9363 39.8654 19.0385 42.913 15.9773C44.428 14.4555 45.942 13.3908 47.0816 12.704C48.3674 11.9291 49.6115 11.4545 51.0003 10.8965V31.4091L62.3337 31.4091V56.9091Z"
                                fill="#cbc6bc"
                              />
                              <path
                                d="M31.167 56.9091H5.66699V55.4924V31.2067C5.66699 23.9363 8.69876 19.0385 11.7464 15.9773C13.2614 14.4555 14.7754 13.3908 15.9149 12.704C17.2007 11.9291 18.4449 11.4545 19.8337 10.8965V31.4091L31.167 31.4091V56.9091Z"
                                fill="#cbc6bc"
                              />
                            </g>
                          </svg>
                        )}

                        <Text
                          pt={{ base: '30px', md: '40px', lg: '40px' }}
                          position="relative"
                          zIndex="2"
                          top={{ base: '-60px', md: '-80px' }}
                          w="100%"
                          {...TestimonalSt[lang].title}
                          color="#3B444B"
                        >
                          {item?.userName}{' '}
                        </Text>
                        {render(item?.additionalDetails) &&
                          render(item?.additionalDetails) !== '<p></p>' && (
                            <>
                              <Box
                                onClick={() => {
                                  setActiveCard(index);
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent={
                                    lang === 'ar' ? 'flex-end' : 'flex-start'
                                  }
                                  paddingRight={{
                                    base: '0px',
                                    md: lang === 'ar' ? '60px' : '0px',
                                  }}
                                >
                                  {lang === 'ar' ? (
                                    <>
                                      <Box
                                        px="3px"
                                        top="-46px"
                                        position="relative"
                                      >
                                        <ChevronLeftIcon
                                          fontSize="30px"
                                          color="#F37021"
                                        />
                                      </Box>
                                      <StructuredTextParser
                                        onClick={onOpen}
                                        str={render(item?.additionalDetails)}
                                        {...TestimonalSt[lang].linkReadMore}
                                        w="100%"
                                        color="#F37021"
                                        position="relative"
                                        top="-60px"
                                        cursor="pointer"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      <StructuredTextParser
                                        onClick={onOpen}
                                        str={render(item?.additionalDetails)}
                                        {...TestimonalSt[lang].linkReadMore}
                                        w="100%"
                                        color="#F37021"
                                        position="relative"
                                        top="-60px"
                                        cursor="pointer"
                                      />{' '}
                                      <Box
                                        px="3px"
                                        top="-46px"
                                        position="relative"
                                      >
                                        {/* <ChevronRightIcon
                                          fontSize="30px"
                                          color="#F37021"
                                        /> */}
                                      </Box>
                                    </>
                                  )}
                                </Box>
                              </Box>
                              <Modal
                                isOpen={isOpen}
                                onClose={onClose}
                                size="2xl"
                                isCentered={true}
                                h={{ md: '424px', base: '100%' }}
                              >
                                <ModalOverlay bg="blackAlpha.400" />
                                <ModalContent
                                  background="none"
                                  my={{ base: '0', md: '0', lg: '0' }}
                                >
                                  <ModalBody
                                    p={{
                                      base: '25px 0 0 0',
                                      md: '0 0 0 30px',
                                      lg: '0 0 0 10px',
                                      xl: '0px',
                                    }}
                                  >
                                    <Center
                                      pb={{ base: '0%', md: '10%' }}
                                      w={{
                                        base: '100%',
                                        md: '632px',
                                        lg: '694px',
                                      }}
                                      mx="auto"
                                      height="100%"
                                      m={{ base: '15% 0 0 0', md: '10% 0 0' }}
                                      pos="relative"
                                      bg="#F1EBDF"
                                      maxH={{
                                        base: '100%',
                                        md: '446px',
                                        lg: '424px',
                                      }}
                                    >
                                      <Box
                                        position="absolute"
                                        top={{
                                          base: '17px',
                                          md: '21px',
                                          lg: '21px',
                                        }}
                                        right={{
                                          base: '22px',
                                          md: '24px',
                                          lg: '15px',
                                        }}
                                        onClick={onClose}
                                        w="auto"
                                        height="auto"
                                        cursor="pointer"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M19 20.4143L12 13.4143L4.99997 20.4143L3.58576 19.0001L10.5858 12.0001L3.58576 5.00009L4.99997 3.58588L12 10.5859L19 3.58588L20.4142 5.00009L13.4142 12.0001L20.4142 19.0001L19 20.4143Z"
                                            fill="#E86F34"
                                          />
                                        </svg>
                                      </Box>
                                      <Flex
                                        h="100%"
                                        w={{
                                          base: '100%',
                                          md: '90%',
                                          lg: '100%',
                                        }}
                                        flexDir={{ base: 'column', md: 'row' }}
                                        pl={{ base: '35px', md: '0' }}
                                        pr={{ base: '23px', md: '0' }}
                                        mb={{ base: '30px', md: '0' }}
                                        pt={{ base: '20px', md: '30px' }}
                                      >
                                        <Box
                                          w={{
                                            base: '167px',
                                            md: '239px',
                                            lg: '239px',
                                          }}
                                          maxW="none"
                                          // h={{base:"auto",md:"310px",lg:"269px"}}
                                        >
                                          <Image
                                            src={
                                              sectionContent?.[0]?.testimonials[
                                                activeCard
                                              ]?.photo?.url
                                            }
                                            alt={
                                              sectionContent?.[0]?.testimonials[
                                                activeCard
                                              ]?.photo?.alt || ''
                                            }
                                            mt={{
                                              base: '-64px',
                                              md: '80px',
                                              lg: '90px',
                                              xl: '100px',
                                            }}
                                            ml={{ base: '10px', md: '-55px' }}
                                            h={{ base: 'auto', md: '302px' }}
                                            w={{ base: '100%', md: '226.5px' }}
                                            maxW="none"
                                            objectPosition="top"
                                          />
                                        </Box>
                                        <Box
                                          p={{
                                            base: '0px',
                                            md: '20px 20px 0 20px',
                                            lg: '20px',
                                          }}
                                        >
                                          <Box
                                            mt={{
                                              base: '20px',
                                              md: '60px',
                                              lg: '80px',
                                            }}
                                            w="100%"
                                            maxH={330}
                                            h="100%"
                                            overflowY="auto"
                                            pl={{ md: '30px', lg: '50px' }}
                                            pr={{ md: '26px', lg: '30px' }}
                                            className="customScrollbar"
                                          >
                                            <StructuredTextParser
                                              str={render(
                                                sectionContent?.[0]
                                                  ?.testimonials[activeCard]
                                                  .testimonialText.value
                                              )}
                                              {...TestimonalSt[lang].modalDes}
                                              color="#423E3B"
                                              mt="0px"
                                              className="textp"
                                            />
                                            <StructuredTextParser
                                              str={render(
                                                sectionContent?.[0]
                                                  ?.testimonials[activeCard]
                                                  .userDescription
                                              )}
                                              {...TestimonalSt[lang].modalName}
                                              color="#6D6358"
                                            />
                                            <StructuredTextParser
                                              mt={{
                                                base: '7px',
                                                md: '10px',
                                                lg: '12px',
                                              }}
                                              color={{
                                                base: '#433F3C',
                                                md: '#12166F',
                                                lg: '#423E3B',
                                              }}
                                              str={render(
                                                sectionContent?.[0]
                                                  ?.testimonials[activeCard]
                                                  .details
                                              )}
                                              {...TestimonalSt[lang].modalPlace}
                                            />
                                          </Box>
                                        </Box>
                                      </Flex>
                                    </Center>
                                  </ModalBody>
                                </ModalContent>
                              </Modal>
                            </>
                          )}
                      </Box>
                    </Box>
                    <style global>
                      {`
                          .customScrollbar::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.customScrollbar::-webkit-scrollbar-track {
background:white;
border:none;
width: 12px;
  
}
 
/* Handle */
.customScrollbar::-webkit-scrollbar-thumb {
  width: 9.6px;
  margin: 0 1px 0 auto;
height: 14.4px;
background: #CF4520;
}

/* Handle on hover */
.customScrollbar::-webkit-scrollbar-thumb:hover {
  #background: #CF4520;; 
}
                          `}
                    </style>
                  </Flex>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Flex>

      <style jsx global>
        {`
          .highlightText p mark {
            color: #9d7a00;
            background: none;
            font-weight: 600;
          }
          .textp p {
            padding-bottom: 20px;
          }
        `}
      </style>
    </Box>
  );
};

export default TestimonialV5;
