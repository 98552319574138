/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { EnrollSectionSt } from '@ieo/Common/CompLangConfig';

/**
 *
 * @param {*} sectionContent
 * @returns
 */
const EoeAdBanner = ({ sectionContent, lang, region }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const programFeeGiftText1 = sectionContent?.find(
    e => e.fieldId === 'program-fee-gift-text_1'
  );
  return (
    <>
      <Box mx="auto" maxW="1030px" py={{ base: 5, md: 10 }} mt="70px">
        <NextLink
          href={refineUrlDomain(sectionContent[0]?.linkUrl, region, lang)}
          passHref
          legacyBehavior
        >
          <Link target="_blank">
            <LazyLoadImageComponent
              style={{ cursor: 'pointer' }}
              alt={
                sectionContent[0]?.image?.title ||
                sectionContent[0]?.image?.alt ||
                sectionContent[0]?.mobileImage?.alt ||
                ''
              }
              w="100%"
              title={sectionContent[0]?.image?.title}
              textAlign="center"
              src={
                isMobile
                  ? sectionContent[0]?.mobileImage?.url ||
                    sectionContent[0]?.image?.url
                  : sectionContent[0]?.image?.url
              }
            />
          </Link>
        </NextLink>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        flexDir="column"
        alignItems="center"
        px={6}
      >
        {(region === 'us' || region === 'ca') &&
          sectionContent[2]?.linkUrl &&
          sectionContent[2]?._modelApiKey === 'simple_button' && (
            <>
              <StructuredTextParser
                str={render(programFeeGiftText1?.body)}
                fontSize="18px"
                fontWeight="400"
                textAlign={{ base: 'center', md: 'left' }}
                fontFamily="FedraSansStd-book"
                mt="0px"
              />

              <NextLink
                href={refineUrlDomain(sectionContent[2]?.linkUrl, region, lang)}
                passHref
                legacyBehavior
              >
                <Link
                  bg="#E86F34"
                  _hover={{ bg: '#BC570F' }}
                  w={{
                    base: 'auto',
                    sm: 'auto',
                    md: 'auto',
                    lg: '298px',
                  }}
                  display="flex"
                  maxW={{ base: '280px', md: '298px' }}
                  // justifyContent="center"
                  // alignItems="center"
                  px="17px"
                  pt="8px"
                  color="#fff"
                  borderRadius="full"
                  minH="40px"
                  m={{ base: '30px auto 0', md: '0' }}
                  mt={{ base: '30px', md: '30px' }}
                  mb={{ md: '10px' }}
                  {...EnrollSectionSt[lang].button}
                >
                  <LazyLoadImageComponent
                    src="https://static.sadhguru.org/d/46272/1701844040-482ef9a940b329b2555068725d140d40.png"
                    alt=""
                    maxH="52px"
                    maxW="36px"
                    objectFit="cover"
                  />
                  <Box
                    display="flex"
                    mx={{ base: '8px', md: '14px' }}
                    fontSize="18px"
                    mt="11.5px"
                    height="auto"
                  >
                    {sectionContent[2]?.buttonText}
                  </Box>
                </Link>
              </NextLink>
            </>
          )}
      </Box>
    </>
  );
};

export default EoeAdBanner;
