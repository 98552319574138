/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import { WhoCanDoV5St } from '../Common/CompLangConfig';

/* Services */
/* Config */

/**
 * @param {Object} sectionContent - Data
 * @param {string} lang - Language of the page
 * @returns
 */

const styles = {
  contentText: {
    p: {
      padding: '4px 0',
      fontFamily: 'FedraSansStd-book',
      fontStyle: 'italic',
      a: {
        color: '#FF9840',
        textDecoration: 'underline',
      },
    },
  },
  descContent: {
    p: {
      padding: '4px 0',
      fontFamily: 'FedraSansStd-book',
      fontStyle: 'normal',
      textAlign: 'left',
      a: {
        color: '#FF9840',
        textDecoration: 'underline',
      },
    },
  },
};

const WaysToParticipateIyp = ({ sectionContent, lang }) => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = sectionContent
      .filter(item => item._modelApiKey === 'single_image')
      .map(item => item);
    setImageUrls(urls);
  }, [sectionContent]);

  return (
    <Box bg={'#ffffff'} maxW={'100%'} py={8} px={{ base: 4, md: 8 }}>
      <Box maxW={'1031px'} mx={'auto'}>
        <Text
          mt={{ base: '2.5rem', md: '0rem' }}
          mb={{ base: '1.5rem', md: '3rem' }}
          color={'#433F3C'}
          fontFamily={'FedraSansStd-medium'}
          fontSize={{ base: '32px', md: '64px' }}
          lineHeight={{ base: '36.8px', md: '80.64px' }}
          fontWeight={{ base: '500', md: '600' }}
        >
          {sectionContent[0].titleText}
        </Text>
        <Grid
          gap={{ base: 4, md: 8 }}
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        >
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Box maxW="100%" maxH="100%">
              <Box
                bgImage={{
                  base: sectionContent[1]?.mobileImage.url,
                  md: sectionContent[1]?.image?.url,
                }}
                width={{ base: '100%', md: '100%' }}
                height={{ base: '250px', md: '470px' }}
                display="flex"
                alignItems="flex-end"
                bgSize="cover"
                bgPos="top center"
                p={{ base: '15px', md: '30px 40px' }}
                mx="auto"
              ></Box>
            </Box>
          </GridItem>
        </Grid>
      </Box>
      <Box
        maxW={'1031px'}
        mx={'auto'}
        textAlign={'center'}
        mt={{ base: '1rem', md: '1rem' }}
        mb={{ base: '0rem', md: '2rem' }}
      >
        <StructuredTextParser
          str={render(sectionContent[2]?.body)}
          color="#000"
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          fontSize={{ base: '20px', md: '24px' }}
          fontWeight="500"
          mx="auto"
          sx={lang === 'ta' ? styles.descContent : styles.contentText} // Apply the styles here
          {...WhoCanDoV5St[lang]}
        />
      </Box>
    </Box>
  );
};

export default WaysToParticipateIyp;
