/* Built In Imports */
import { usePathname } from 'next/navigation';
import { useRef, useState } from 'react';
/* External Imports */
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import SingleImage from '@ieo/Components/SingleImage';
/* Configs */
import { ResearchShowsSt } from '@ieo/Common/CompLangConfig';

/* Styles */

/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const WhatLearnPC3 = ({ sectionContent, region, lang }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const [ind, setIndex] = useState(-1);
  const btnRef = useRef(null);

  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  const pathname = usePathname();
  const isPageURLmatch = pathname.includes('/offer/under-25s');

  return (
    <Box
      w={{ base: '100%', sm: '450px', md: '100%' }}
      px={{ base: '16px', xl: '0' }}
    >
      <Box
        w="100%"
        maxW={{
          base: '100%',
          xl: '1000px',
        }}
        mx="auto"
        pb={{ base: '10px', lg: '50px' }}
      >
        <Flex pb="30px" justify="center">
          <Box h="100%" w="full">
            <Flex
              flexDirection={{
                base: 'column-reverse',
                md: 'row',
              }}
              w="100%"
              justifyContent="space-between"
              h="100%"
              gridGap={{ base: '10px', lg: '90px' }}
            >
              <SingleImage
                px={{ base: '0', md: '30px', lg: '0' }}
                w={{ base: 'auto', lg: '100%' }}
                maxW="500px"
                mb="0px"
                h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                sectionContent={sectionContent[1]}
              />
              <Box
                //maxW={{ base: '100%', md: '448px', lg: '440px' }}
                w="100%"
                pl={{ base: '0', md: '15px', lg: '0' }}
              >
                <StructuredTextParser
                  str={render(sectionContent[0]?.body)}
                  fontFamily="FedraSansStd-book"
                  className="ResearchShowText"
                  fontSize="22px"
                  lineHeight="28px"
                  {...ResearchShowsSt[lang]?.stText}
                  textAlign={lang === 'ar' ? 'right' : 'left'}
                />{' '}
              </Box>

              <style global jsx>
                {`
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    color: #3b444b;
                    font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                    font-weight: ${ResearchShowsSt[lang]?.heading?.fontWeight};
                    font-size: ${isPageURLmatch
                    ? lang === 'en' || lang === 'hi'
                      ? '64px'
                      : '45px'
                    : '64px'};
                    line-height: 64px;
                    margin-bottom: 59px;
                    position: relative;
                    z-index: 2;
                    text-align: left;
                    margin: auto;
                    top: ${isPageURLmatch
                    ? lang === 'hi'
                      ? '0'
                      : '-50px'
                    : '-20px'};
                  }

                  .ResearchShowText mark {
                    color: #323232;
                    font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                    background: none;
                  }
                  .ResearchShowText ul {
                    list-style-type: none;
                    padding-left: 32px;
                  }
                  .ResearchShowText ul li {
                    position: relative;
                    margin-bottom: 20px;
                  }
                  .ResearchShowText ul li::before {
                    position: absolute;
                    content: '';
                    left: -30px;
                    top: 12px;
                    width: 21px;
                    height: 17px;
                    background: url(${config.imgPath}/d/46272/1706007377-44.png);
                  }
                  .ResearchShowText p {
                    color: #323232;
                    font-family: FedraSansStd-book;
                    font-weight: normal;
                    margin-top: 10px;
                  }
                  .ResearchShowText p strong {
                    color: #12166f;
                    font-family: FedraSansStd-medium;
                    font-weight: normal;
                  }

                  @media (max-width: 992px) and (min-width: 768px) {
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      font-size: 40px;
                      line-height: 40px;
                      margin-bottom: ${isPageURLmatch ? '40px' : '0px'};
                      width: ${isPageURLmatch ? '240px' : '100%'};
                      top: ${isPageURLmatch ? '-20px' : 'unset'};
                    }
                  }
                  @media (max-width: 768px) and (min-width: 480px) {
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                      font-size: 40px;
                      line-height: 40px;
                      width: ${isPageURLmatch ? '240px' : 'auto'};
                      padding-bottom: 30px;
                      top: ${isPageURLmatch ? '-20px' : '0'};
                    }

                    .ResearchShowText p,
                    .ResearchShowText mark {
                      font-size: 18px;
                      line-height: 25px;
                    }
                  }
                  @media (max-width: 480px) {
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                      font-size: 32px;
                      line-height: 32px;
                      width: ${isPageURLmatch ? '160px' : ''};
                      margin: ${isPageURLmatch ? 'auto' : '0'};
                      padding-bottom: ${isPageURLmatch ? '30px' : '0'};
                      top: ${isPageURLmatch && '-15px'};
                    }
                    .ResearchShowText p,
                    .ResearchShowText mark {
                      font-size: 18px;
                      line-height: 25px;
                    }
                  }
                `}
              </style>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default WhatLearnPC3;
