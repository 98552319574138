/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SingleLineText from '@components/UI/SingleLineText';
import { ResearchIntroSt } from '@ieo/Common/CompLangConfig';

/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';

const ResearchVidCard = dynamic(() =>
  import('@ieo/Components/Cards/ResearchVidCard')
);
const ReactPlayer = dynamic(() => import('react-player'));
/* Styles */
/* Services */

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} Research Intro
 */
const ResearchIntro = ({ sectionContent, region, lang, pageData }) => {
  const [autoplay, setAutoPlay] = useState(false);
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const scrollDiv = document.getElementById('vid-play');
    const inViewPort = isVisInViewport(scrollDiv);

    if (inViewPort) {
      setAutoPlay(true);
    } else {
      setAutoPlay(false);
    }
  };

  const router = useRouter();
  const changeUi = router.asPath.indexOf('inner-engineering-pc') > 0;
  return (
    <Box
      bg={
        pageConfigVal?.research?.bgImage
          ? `url(${pageConfigVal?.research?.bgImage?.desktop}) #F1EBDF`
          : `#F1EBDF`
      }
      bgRepeat={
        pageConfigVal?.research?.bgImage?.repeat === true
          ? 'repeat-x'
          : 'no-repeat'
      }
      bgPos="bottom"
    >
      <Box
        w="100%"
        h="100%"
        maxW="1030px"
        mx="auto"
        fontSize="70px"
        pos="relative"
        id="research-intro"
        pt={{ base: changeUi ? '55px' : '0', md: '0' }}
      >
        <Box
          backgroundPosition="top"
          bgSize="cover"
          height={{ base: '100%', md: '407px', lg: '475px' }}
          top="0"
          width="100%"
          backgroundRepeat="no-repeat"
          position="absolute"
          zIndex="-1"
        ></Box>
        <Box
          maxW={{ base: '100%', sm: 'calc(100% - 66px)', xl: 1030 }}
          w="100%"
          px={{ base: '0px', md: '0px' }}
          ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
          mr={{ base: '0px', sm: 'auto', xl: 'auto' }}
        >
          {/* <Flex
            mx="auto"
            w="100%"
            alignItems={{ base: 'start', xl: 'center' }}
            flexWrap={{ base: 'wrap', md: 'wrap', lg: 'wrap' }}
            // flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
            px={{ base: '16px', md: '10px', lg: '0' }}
            pt={{ base: '50px', md: '30px', lg: '50px' }}
            pb={{ base: '0px', md: '30px', lg: '50px' }}
          >
            <Box
              display="flex"
              alignItems="center"
              maxW={{ base: '100%', md: 443, lg: 443 }}
            >
              <SingleLineText
                my="9px"
                sectionContent={sectionContent[0]}
                region={region}
                lang={lang}
                mx="0"
                {...ResearchIntroSt[lang].SLBlueText}
              />
            </Box>
            <Flex
              // flex={1}
              flexWrap={{ base: 'wrap', sm: 'wrap', md: 'wrap' }}
              justifyContent={{ base: 'space-between', md: 'center' }}
              alignItems={{
                base: "stretch", md: "center"
              }}
              alignContent='stretch'
              // h={{ base: '120px', lg: '80px' }}
              // mt={{ base: '0', md: '-20px' }}
              mb={{ base: '0px', md: '0' }}
              gridColumnGap={{ base: '23px', sm: '25px' }}
              css={{ "-webkit-grid-column-gap": "23px", "-webkit-row-gap": "17px" }}
              gridRowGap={{ base: '17px' }}
              mt={{ base: '10px', xl: '0px' }}
              pb={{ base: '27px', md: '0' }}
              ml={{ base: '0', lg: "15px", xl: '40px' }}
              borderBottom={{ base: sectionContent[1]?.image?.url ? '1px solid #CF915E' : "none", md: 'none' }}
            >
              <LazyLoadImageComponent
                mr={{ base: '12px', sm: '0' }}
                my={{ base: '8.5px', sm: '0' }}
                src={sectionContent[1]?.image?.url}
                alt={sectionContent[1]?.image?.alt || ""}
                objectFit="contain"
                w={{ base: '50%', sm: 'auto', md: 'auto' }}
                maxW="142px"
              /> */}
          {/* <SingleImage
                  sectionContent={sectionContent[1]}
                  h="100%"
                  px="0"
                  my="0"
                  mx={{ base: '0', md: '8px' }}
                  // flex={1}
                  w={{ base: '100%', sm: '135px', md: '155px' }}
                /> */}
          {/* </Image> */}
          {/* <LazyLoadImageComponent
                mr={{ base: '0', sm: '0' }}
                my={{ base: '8.5px', sm: '0' }}
                src={sectionContent[2]?.image?.url}
                alt={sectionContent[2]?.image?.alt || ""}
                w={{ base: '38%', sm: 'auto', md: 'auto' }}
                objectFit="contain"
                maxW="126px"
              />
              <LazyLoadImageComponent
                mr={{ base: 'auto', sm: '0' }}
                ml={{ base: "0", sm: "0" }}
                my={{ base: '8.5px', sm: '0' }}
                alt={sectionContent[3]?.image?.alt || ""}
                src={sectionContent[3]?.image?.url}
                w={{ base: '50%', sm: 'auto', md: 'auto' }}
                objectFit="contain"
                maxW="170px"
              />
            </Flex>
          </Flex> */}
          <Flex
            flexDir={{ base: 'column-reverse', md: 'row' }}
            pt={{ base: '0', md: '20px' }}
            pos="relative"
            top="50px"
          >
            <ResearchVidCard
              // ml={{ base: '0', md: '34px', lg: '50px' }}
              maxWidth={{ base: '100%', md: '476px', lg: '780px' }}
              width={{ base: '100%', md: '780px' }}
              // ref={playerRef}
              height={{
                base: '183px',
                md: '268px !important',
                lg: '440px !important',
              }}
              id="vid-play"
            >
              {/* <video
                id="background-video"
                autoPlay="autoplay"
                loop="loop"
                muted
                // controls={true}
                style={{ height: "100%", width: "100%" }}
                poster={sectionContent[4]?.thumbnailDesktopImage?.url}
              >
                <source src={'https://iso-videos-assets.s3.ap-south-1.amazonaws.com/videos/WhatsApp+Video+2023-09-22+at+17.03.17.mp4'} />
              </video> */}
              {/* <style jsx>
                {`
            #background-video {
              width: 100%;
              object-fit: cover;
              position: static;
              left: 0;
              right: 0;
              top: 0px;
              bottom: 0;
              z-index: 99;
              height: auto;
            }
            }
          `}
              </style> */}
              <ReactPlayer
                url={
                  'https://iso-videos-assets.s3.ap-south-1.amazonaws.com/videos/WhatsApp+Video+2023-09-22+at+17.03.17.mp4' ||
                  sectionContent[4]?.video?.url
                }
                // light={sectionContent[4]?.thumbnailDesktopImage?.url}
                playing={autoplay}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                autoPlay={true}
                height="100%"
                objectFit="cover"
              />
              <style global jsx>
                {`
                  #vid-play video {
                    object-fit: cover;
                  }
                `}
              </style>
            </ResearchVidCard>
            <Box>
              <Flex
                flexDir="column"
                mt={{ base: '0', md: '10px', lg: '55px' }}
                pl={{ base: '16px', md: '30px' }}
                pr={{ base: '16px', md: '0px' }}
                mb={{ base: '26px', md: '0' }}
                alignItems="start"
              >
                <SingleLineText
                  sectionContent={sectionContent[5]}
                  my="0"
                  // mx="auto"
                  // mb={{ base: '9px', md: '0' }}
                  maxW={{ base: '100%', md: '300px' }}
                  {...ResearchIntroSt[lang]?.ReInHeading}
                />
                <SingleLineText
                  sectionContent={sectionContent[6]}
                  my="0"
                  mt={{ base: '9px', md: '11px', lg: '15px' }}
                  mx="auto"
                  mb={{ base: '9px', md: '0' }}
                  maxW={{ base: '100%', md: '292px' }}
                  {...ResearchIntroSt[lang]?.GrayText}
                />
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default ResearchIntro;
