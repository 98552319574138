/* Built In Imports */

/* External Imports */
import { Box, Heading, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} Research Intro
 */
const ProgramBenefitsIYP = ({ sectionContent }) => {
  return (
    <Box w="100%" bg="#F1EBDF" pb={{ base: '20px', md: '0px' }}>
      <Box w="100%" h="100%" maxW={'1140px'} mx="auto" px="15px">
        <Heading
          fontSize={{ base: '32px', md: '64px' }}
          color="#433F3C"
          lineHeight="68px"
          pb={{ base: '20px', md: '90px' }}
          pl={{ base: '0', md: '40px' }}
        >
          {sectionContent[0]?.text}{' '}
        </Heading>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap="40px"
          rowGap={{ base: '20px', md: '120px' }}
        >
          {sectionContent[1]?.cards.map((item, i) => (
            <Box
              display="flex"
              flexDir="column"
              gap="30px"
              alignItems="center"
              maxW={{ base: '100%', md: '340px' }}
            >
              {i % 2 == 0 ? (
                <Box
                  display="flex"
                  flexDir={{ base: 'row', md: 'column' }}
                  gap="10px"
                  alignItems="center"
                >
                  {' '}
                  <Image
                    src={item?.thumbnail?.url}
                    alt=""
                    w={{ base: '86px', md: '250px' }}
                    h={{ base: '86px', md: '120px' }}
                    objectFit="contain"
                  />
                  <Text
                    textAlign={{ base: 'left', md: 'center' }}
                    fontSize={{ base: '18px', md: '20px' }}
                    color="#433F3C"
                    pt="30px"
                  >
                    {item.title}
                  </Text>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDir={{ base: 'row-reverse', md: 'column' }}
                  gap="10px"
                  alignItems="center"
                >
                  {' '}
                  <Image
                    src={item?.thumbnail?.url}
                    alt=""
                    w={{ base: '86px', md: '250px' }}
                    h={{ base: '86px', md: '120px' }}
                    objectFit="contain"
                  />
                  <Text
                    textAlign={{ base: 'right', md: 'center' }}
                    fontSize={{ base: '18px', md: '20px' }}
                    color="#433F3C"
                    pt="30px"
                  >
                    {item.title}
                  </Text>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramBenefitsIYP;
