/* Built In Imports */

/* External Imports */
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { ProgramSupportSt } from '@ieo/Common/CompLangConfig';
import ProgramSupportCard from '@ieo/Components/Cards/ProgramSupportCard';
import { usePathname } from 'next/navigation';

/* Services */
/* Styles */

/**
 *
 * @param {Object} sectionContent
 *  @param {String} lang
 *  @param {String} region
 * @returns {ReactElement} ProgramSupport
 */
const ProgramSupport = ({ sectionContent, region, lang, pageData }) => {
  // const isMobile = useMediaQuery({ maxWidth: 560 });
  const path = usePathname();
  const url = path.includes('offer/under-25s');

  return (
    <Box
      pos="relative"
      maxH={url && '100%'}
      overflow={url && 'hidden'}
      w="100%"
      _before={{
        content: "''",
        position: 'absolute',
        display: { base: 'none', md: url && 'block' },
        width: 370,
        height: 600,
        backgroundImage:
          'https://static.sadhguru.org/d/46272/1710580730-5-copy-2-4-1.png',
        backgroundPosition: 'top',
        left: '-95px',
        top: 0,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        maxW={1030}
        w="100%"
        mx="auto"
        pos="relative"
        mt="90px"
        dir={lang == 'ar' ? 'rtl' : 'ltr'}
      >
        <Box
          pos="absolute"
          top={{ base: 'unset', md: 'unset', lg: '8px' }}
          bottom={{ base: 'unset', md: '-32px', lg: 'unset' }}
          w={{ base: '0', md: '181px', lg: '158px' }}
          right={lang == 'ar' ? 'auto' : '0'}
          left={lang == 'ar' ? '0' : 'auto'}
          h={{ md: '315px', lg: '356px' }}
        >
          <Box display={{ base: 'none', xl: 'none' }}>
            {' '}
            <Image
              src="https://static.sadhguru.org/d/46272/1694600647-26-3.png"
              alt="floral design"
              h="100%"
              w="100%"
            />{' '}
          </Box>
        </Box>
        <Flex
          as="section"
          w="full"
          // mt={{ md: '100px', xl: '146px' }}
          mb={{ base: '60px', md: '100px' }}
          flexDir="column"
          mx="auto"
          px={{ base: '16px', xl: '0px' }}
          //pt={{ md: "120px" }}
        >
          <Heading
            as="h2"
            maxW={{ base: lang === 'ar' ? '100%' : '288px', sm: 'initial' }}
            {...ProgramSupportSt[lang].h2}
            p={{ base: '0 20px 26px 0', lg: '0 0 40px 0' }}
            lineHeight={{ base: '36.8px', xl: '64px' }}
            dir={lang == 'ar' ? 'rtl' : 'ltr'}
            textAlign={lang == 'ar' ? 'right' : 'left'}
          >
            {sectionContent?.[0]?.text}
          </Heading>
          <Flex
            flexWrap="wrap"
            justifyContent="start"
            gridColumnGap={{ base: '38px', md: '38px' }}
            gridRowGap={{ base: '20px', md: '40px' }}
            css={{
              '-webkit-grid-row-gap': '25px !important',
              '-webkit-grid-column-gap': '38px',
            }}
          >
            {sectionContent[1]?.cards?.map((card, index) => {
              {
                /* if(index===2){
              return (
                <Box key={nanoid()} w={{base:"0",md:"0",lg:"30%"}} h={{base:"auto",md:"254px"}}></Box>
              )
            } */
              }
              return (
                <ProgramSupportCard
                  card={card}
                  lang={lang}
                  region={region}
                  key={nanoid()}
                  // w={{ base: '100%', md: '47%', lg: '30%' }}
                  // h={{ base: '100%', md: '254px' }}
                  // bg="#F1EBDF"
                  // display="flex"
                  // justifyContent="space-between"
                  // flexDir="column"
                  pageData={pageData}
                  flexBasis={index === 1 ? '60%' : 'auto'}
                >
                  {/* <Box
                  maxW="100px"
                  height={{ base: '40%', md: '35%' }}
                  pt={{ base: '18px', md: '15px', lg: '15px' }}
                  pl={{ base: '18px', md: '15px', lg: '20px' }}
                >
                  <SingleImage my="0" sectionContent={card.image} />
                </Box>
                <Box
                  w="100%"
                  p={0}
                  borderLeft={{
                    base: '7px solid #A81D28',
                    md: '6px solid #A81D28',
                  }}
                  h={{ base: '55%', md: '63%' }}
                  mt={{ base: '17px', md: '0' }}
                  pb="20px"
                  display="flex"
                  fontFamily="FedraSansStd-book"
                  flexDir="column"
                  px="20px"
                >
                  <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Heading
                      as="h3"
                      fontFamily="FedraSansStd-medium"
                      {...ProgramSupportSt[lang].cardTitle}
                      mb="5px"
                    >
                      {card?.image?.title}
                    </Heading>
                    <Box
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M18.4167 13L13 13M13 13L7.58332 13M13 13L13 7.58337M13 13L13 18.4167"
                          stroke="#CE6113"
                          stroke-width="3"
                          stroke-linecap="square"
                        />
                      </svg>
                    </Box>
                  </Flex>

                  <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <StructuredTextParser
                      fontSize="16px"
                      lineHeight="20px"
                      // display={{ base: show && 'block' }}
                      {...ProgramSupportSt[lang].stText}
                      mt="0"
                      // {...ResearchShowsSt[lang].cardText}
                      //  noOfLines={{base:2,md:2,lg:3}}
                      str={render(card?.description)}
                    />
                    <Box
                      onClick={() => {
                       
                      }}
                      alignSelf="flex-end"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M7.58334 13L18.4167 13"
                          stroke="#CE6113"
                          stroke-width="3"
                          stroke-linecap="square"
                        />
                      </svg>
                    </Box>
                  </Flex>
                </Box> */}
                </ProgramSupportCard>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProgramSupport;
