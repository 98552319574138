/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SliderExperience from '@ieo/Components/SliderExperience';

/* Services */
/* Styles */

/**
 *
 * @param {Object} SectionContent
 * @param {String} lang
 * @param {String} region
 * @returns ProgramTestimonial
 */
const ProgramTestimonial = ({ sectionContent, region, lang, pageData }) => {
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  return (
    <Box
      bg={
        pageConfigVal
          ? `url(${pageConfigVal?.myExp?.bgImage?.desktop})#F1EBDF`
          : `#F1EBDF`
      }
      bgRepeat={
        pageConfigVal?.myExp?.bgImage?.repeat === true
          ? 'repeat-x'
          : 'no-repeat'
      }
      bgPos={{ base: 'center -10%', md: 'bottom' }}
      pb={{ base: '20px', md: '0' }}
    >
      <Flex
        maxW="1330px"
        flexDir="column"
        mb={{
          md: lang === 'es' ? '0px' : '40px',
          xl:
            region === 'us' || region === 'ca' || lang === 'es'
              ? '0px'
              : '90px',
        }}
        margin={'0 auto'}
      >
        <Box
          pos={{ base: 'static', md: 'relative' }}
          top="30px"
          // h={{ base: '640px', md: '500px', lg: '480px' }}
        >
          <SliderExperience
            sectionContent={sectionContent?.[0]}
            lang={lang}
            arrowTop="inherit"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ProgramTestimonial;
