/* Built In Imports */
import { usePathname } from 'next/navigation';
import { useRef, useState } from 'react';
/* External Imports */
import { Box, Flex, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import SingleImage from '@ieo/Components/SingleImage';
/* Configs */
import { ResearchShowsSt } from '@ieo/Common/CompLangConfig';

/* Styles */

/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const ResearchShowsV7 = ({ sectionContent, region, lang, scrollRef }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const [ind, setIndex] = useState(-1);
  const btnRef = useRef(null);
  const isMobileButton = useBreakpointValue({ base: true, md: false });
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }

  const pathname = usePathname();
  const isPageURLmatch = pathname.includes('/offer/under-25s');

  return (
    <Box
      w={{ base: '100%', sm: '450px', md: '100%' }}
      px={{ base: '16px', xl: '0' }}
    >
      <Box
        w="100%"
        maxW={{
          base: '100%',
          xl: '1036px',
        }}
        mx="auto"
        pb={{ base: '10px', lg: '50px' }}
      >
        <Flex pb="30px" justify="center">
          <Box h="100%" w="full">
            <Flex
              flexDirection={{
                base: 'column-reverse',
                md: 'row',
              }}
              w="100%"
              justifyContent="space-between"
              h="100%"
              gridGap={{ base: '10px', lg: '100px' }}
            >
              <SingleImage
                px={{ base: '0', md: '30px', lg: '0' }}
                w={{ base: 'auto', lg: '100%' }}
                maxW="496px"
                mb="0px"
                h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                sectionContent={sectionContent[1]}
              />
              <Box
                maxW={{ base: '100%', md: '448px', lg: '440px' }}
                w="100%"
                pl={{ base: '0', md: '15px', lg: '0' }}
              >
                <StructuredTextParser
                  str={render(sectionContent[0]?.body)}
                  fontFamily="FedraSansStd-book"
                  className="ResearchShowText"
                  fontSize="22px"
                  lineHeight="28px"
                  {...ResearchShowsSt[lang]?.stText}
                  textAlign={lang === 'ar' ? 'right' : 'left'}
                />{' '}
                {isMobileButton != true ? (
                  <Box
                    maxW={{ base: '217px', md: '226px' }}
                    m={{ base: '0 auto', md: '0' }}
                  >
                    <Flex
                      align={'center'}
                      justifyContent={{ base: 'center', md: 'start' }}
                    >
                      <SimpleButton
                        style={sectionContent[4]}
                        bType={sectionContent[4]?.sectionId}
                        region={region}
                        lang={lang}
                        onClick={() => {
                          scroll.scrollTo(scrollRef.current?.offsetTop, {
                            duration: 500,
                            smooth: true,
                          });
                        }}
                        width={{ base: '250px', md: '100%' }}
                        mb={'0'}
                        borderRadius={{ base: '8px', md: '12px' }}
                        background={'#F37021'}
                        fontbold={'500'}
                        _hover={{
                          background: '#BC570F !important',
                        }}
                      />
                    </Flex>
                  </Box>
                ) : (
                  ''
                )}
              </Box>

              <style global jsx>
                {`
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    color: #3b444b;
                    font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                    font-weight: ${ResearchShowsSt[lang]?.heading?.fontWeight};
                    font-size: ${isPageURLmatch
                      ? lang === 'en' || lang === 'hi'
                        ? '64px'
                        : '45px'
                      : '64px'};
                    line-height: 64px;
                    margin-bottom: 59px;
                    position: relative;
                    z-index: 2;
                    text-align: left;
                    margin: auto;
                    top: ${isPageURLmatch
                      ? lang === 'hi'
                        ? '0'
                        : '-50px'
                      : '-20px'};
                  }

                  .ResearchShowText mark {
                    color: #282e8f;
                    font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                    background: none;
                  }
                  .ResearchShowText ul {
                    list-style-type: none;
                    padding-left: 32px;
                    max-width: 355px;
                  }
                  .ResearchShowText ul li {
                    position: relative;
                    margin-bottom: 20px;
                  }
                  .ResearchShowText ul li::before {
                    position: absolute;
                    content: '';
                    left: -30px;
                    top: 12px;
                    width: 21px;
                    height: 17px;
                    background: url(${config.imgPath}/d/46272/1706007377-44.png);
                  }
                  .ResearchShowText p {
                    color: #323232;
                    font-family: FedraSansStd-book;
                    font-weight: normal;
                    margin-top: 10px;
                  }
                  .ResearchShowText p strong {
                    color: #12166f;
                    font-family: FedraSansStd-medium;
                    font-weight: normal;
                    display: block;
                  }

                  @media (max-width: 992px) and (min-width: 768px) {
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      font-size: 40px;
                      line-height: 40px;
                      margin-bottom: ${isPageURLmatch ? '40px' : '0px'};
                      width: ${isPageURLmatch ? '240px' : '100%'};
                      top: ${isPageURLmatch ? '-20px' : 'unset'};
                    }
                  }
                  @media (max-width: 768px) and (min-width: 480px) {
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                      font-size: 40px;
                      line-height: 40px;
                      width: ${isPageURLmatch ? '240px' : 'auto'};
                      padding-bottom: 30px;
                      top: ${isPageURLmatch ? '-20px' : '0'};
                    }

                    .ResearchShowText p,
                    .ResearchShowText mark {
                      font-size: 18px;
                      line-height: 25px;
                    }
                  }
                  @media (max-width: 480px) {
                    .ResearchShowText h1,
                    .ResearchShowText h2 {
                      color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                      font-size: 32px;
                      line-height: 32px;
                      width: ${isPageURLmatch ? '160px' : ''};
                      margin: ${isPageURLmatch ? 'auto' : '0'};
                      padding-bottom: ${isPageURLmatch ? '30px' : '0'};
                      top: ${isPageURLmatch && '-15px'};
                    }
                    .ResearchShowText p,
                    .ResearchShowText mark {
                      font-size: 18px;
                      line-height: 25px;
                    }
                  }
                `}
              </style>
            </Flex>
          </Box>
        </Flex>
        {sectionContent[4].linkUrl != '' && isMobileButton && (
          <Box
            maxW={{ base: '217px', md: '226px' }}
            m={{ base: '0 auto', md: '0' }}
            p={{ base: '0px 8px 0px 34px', md: '0px 8px 0px 8px' }}
          >
            <Flex
              align={'center'}
              justifyContent={{ base: 'center', md: 'start' }}
            >
              <SimpleButton
                style={sectionContent[4]}
                bType={sectionContent[4]?.sectionId}
                region={region}
                lang={lang}
                onClick={() => {
                  scroll.scrollTo(scrollRef.current.offsetTop, {
                    duration: 500,
                    smooth: true,
                  });
                }}
                width={{ base: '250px', md: '100%' }}
                borderRadius={{ base: '8px', md: '12px' }}
                background={'#F37021'}
                fontbold={'500'}
                _hover={{
                  background: '#BC570F !important',
                }}
              />
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ResearchShowsV7;
