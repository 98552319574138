/* Built In Imports */
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  Hide,
  Link,
  Show,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import Loader from '@components/Utility/Loader';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import BannerImage from '@ieo/BannerImage';
import { useProgramLanguageSelection } from '@ieo/Components/Form/ieo/context/programLanguageSelectionContext';
import SingleImage from '@ieo/Components/SingleImage';
import CountdownTimer from '@ieo/Components/Timer/CountdownTimer';

/* Services */
/* Config */
import { usePageType } from '@hooks/usePageType';
import { TopBannerSt } from '@ieo/Common/CompLangConfig';
import { gtmObject } from '@ieo/Components/Form/ieo/api/analyticsAPi';

/* Styles */

/**
 * @param {Object} sectionContent
 * @param {Object} pageData
 * @param {String} lang
 * @param {String} region
 * @returns
 */
const TopBannerYo = ({
  sectionContent,
  region,
  lang,
  pageData,
  pageName,
  prgData,
}) => {
  // console.log('TopBanner', Object.values(prgData)[0], pageName);
  // console.log(sectionContent, 'top banner');
  const [isCatOpen, setCatIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [resultArr, setResultArr] = useState([]);
  const [isBannerActive, setIsBannerActive] = useState(true);
  const router = useRouter();
  const { setProgramLanguage } = useProgramLanguageSelection();
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const pathname = usePathname();
  const isV4 = pathname.indexOf('inner-engineering-v4') > -1;

  const programFeeLink = sectionContent?.find(
    e => e.fieldId === 'pc-program-fee'
  );
  const whiteBannerText1 = sectionContent?.find(
    e => e.fieldId === 'white-banner-text1'
  );
  const whiteBannerText2 = sectionContent?.find(
    e => e.fieldId === 'white-banner-text2'
  );
  const whiteBannerText3 = sectionContent?.find(
    e => e.fieldId === 'white-banner-text3'
  );
  const greyBannerText1 = sectionContent?.find(
    e => e.fieldId === 'grey-banner-text1'
  );
  const greyBannerText2 = sectionContent?.find(
    e => e.fieldId === 'grey-banner-text2'
  );
  const greyBannerText3 = sectionContent?.find(
    e => e.fieldId === 'grey-banner-text3'
  );
  const greyBannerText4 = sectionContent?.find(
    e => e.fieldId === 'grey-banner-text4'
  );
  const couponThemeText = sectionContent?.find(
    e => e.fieldId === 'coupon-theme'
  );

  const [descText, setDescText] = useState(sectionContent[2]);
  const [isLoader, setIsLoader] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const changeUi = router.asPath.indexOf('inner-engineering-pc') > 0;

  // setting countdown timer
  let countDownDate = new Date().setDate(21);
  countDownDate = new Date(countDownDate).setHours(0);
  countDownDate = new Date(countDownDate).setMinutes(0);
  countDownDate = new Date(countDownDate).setSeconds(0);

  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const { isGiftPage } = usePageType();

  useEffect(() => {
    setDescText(couponThemeText || sectionContent[2]);

    setProgramLanguage(router.query.lang || lang);
    const langArr = [];
    config.orderedLang.forEach(language => {
      const newProgs = pageData?.alsoin?.filter(itm => {
        return (
          itm.url ===
          window?.location?.pathname.replace(`/${lang}/`, `/${language}/`)
        );
      });
      if (newProgs?.length > 0) {
        langArr.push(newProgs[0]);
      }
    });
    setResultArr(langArr);
  }, [isCatOpen, router.query.lang, router.asPath]);

  const RightIcon = ({ hover }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 9L12 17L20 9"
          stroke={hover ? '#BC570F' : '#F37021'}
          stroke-width="2.66667"
        />
      </svg>
    );
  };

  const gtmClick = () => {
    gtmObject({
      event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
      IE_LP_Location: 'Top_level_CTA',
      event1: 'add_to_wishlist',
      item_name: isGiftPage
        ? 'Gift Inner Engineering'
        : 'TYLS Inner Engineering',
      item_category4: 'Top_level_CTA',
    });
    gtmObject({
      event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
      IE_LP_Location: 'Top_level_CTA',
      event1: 'view_item',
      item_name: isGiftPage
        ? 'Gift Inner Engineering'
        : 'TYLS Inner Engineering',
      item_category4: 'Top_level_CTA',
    });
  };

  const smMob = useMediaQuery({ maxWidth: 359 });
  const buttonSt = changeUi ? 'uiChangeButtonText' : 'buttonText';
  const stStyle = changeUi ? 'uiChangeStText' : 'stText';
  const url = router.asPath.includes('under-25s');
  // console.log(pageConfigVal, 'pageConfigVal');
  return (
    router.asPath && (
      <Box>
        {/* position static ad at top */}
        {isBannerActive && url && (
          <Show
            below="md"
            display={isBannerActive && 'block'}
            position="relative"
          >
            <Box
              w="100%"
              pos={{ base: 'fixed', md: 'relative' }}
              top="0"
              zIndex="999"
            >
              <Link href="#ieo-program-fees" _hover={{ textDecor: 'none' }}>
                {/* <Image src={pageConfigVal?.topbanner?.topAd?.mob} alt="" /> */}
                <Flex
                  bgImage={pageConfigVal?.topbanner?.topAd?.mob}
                  // h="60px"
                  py="10px"
                  px="15px"
                  bgSize="cover"
                  w="100%"
                  position="relative"
                  alignItems={url ? 'center' : 'center'}
                  justifyContent={url ? 'center' : 'center'}
                >
                  <Flex flexDir="column">
                    <StructuredTextParser
                      color="#ffffff"
                      textAlign="center"
                      display="flex"
                      alignItems={url ? 'left' : 'center'}
                      fontFamily="FedraSerifAStdBook"
                      //FedraSansStd-A-medium
                      mt="0"
                      fontSize="20px"
                      fontStyle="normal"
                      flexDir="column"
                      fontWeight="500"
                      lineHeight="23px"
                      verticalAlign="middle"
                      str={render(whiteBannerText1?.body)}
                    ></StructuredTextParser>
                    <Flex alignItems="center" justifyContent="center">
                      <StructuredTextParser
                        str={render(whiteBannerText2?.body)}
                        as="span"
                        mt="0"
                        color="#ffffff"
                        textAlign={url ? 'center' : 'center'}
                        fontFeatureSettings="'case' on"
                        fontFamily="FedraSansStd-medium"
                        fontSize="16px"
                        // lineHeight="37px"
                      ></StructuredTextParser>
                      {whiteBannerText3 && !showTimer && (
                        <StructuredTextParser
                          pl="9px"
                          color="#CD3C4C"
                          textAlign={url ? 'center' : 'center'}
                          fontFamily="FedraSansStd-medium"
                          fontSize="12px"
                          lineHeight="14px"
                          mt="0"
                          str={render(whiteBannerText3?.body)}
                        />
                      )}
                    </Flex>
                    {showTimer && (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                      >
                        <CountdownTimer targetDate={countDownDate} />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Link>
              <Box
                position={'absolute'}
                right={{ base: '5%', md: '4%', lg: '3%', xl: '5%' }}
                bottom={url ? '20px' : '30%'}
              ></Box>
            </Box>
          </Show>
        )}
        <Box
          background={
            pageConfigVal?.topbanner?.bgImage?.desktop
              ? `url(${pageConfigVal?.topbanner?.bgImage?.desktop}) #00005B`
              : ` url(https://static.sadhguru.org/d/46272/1710757530-group-1171277561.png) #00005B`
          }
          backgroundPosition="bottom"
          // backgroundRepeat="repeat"
          backgroundSize={{ base: 'cover', md: 'cover' }}
          position="relative"
          mt={{ base: '68px', md: '0' }}
        >
          {!isLoader &&
            whiteBannerText1 &&
            whiteBannerText2 &&
            isBannerActive &&
            Object.values(prgData)?.length > 0 &&
            Object.values(prgData)[0]?.coupon &&
            pageConfigVal?.topbanner?.topAd && (
              <Box
                w="100%"
                pos={url ? 'relative' : 'fixed'}
                top="0"
                zIndex="999"
              >
                <Link href="#ieo-program-fees" _hover={{ textDecor: 'none' }}>
                  <Hide below="md">
                    {' '}
                    {/* <Image src={pageConfigVal?.topbanner?.topAd?.desktop} alt="" /> */}
                    <Flex
                      bgImage={pageConfigVal?.topbanner?.topAd?.desktop}
                      // h="82px"
                      py="14px"
                      bgSize="cover"
                      bgPos={url && 'center'}
                      w="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {' '}
                      <Flex alignItems="center">
                        <StructuredTextParser
                          color="#ffffff"
                          textAlign="center"
                          mt="0"
                          fontFamily="FedraSerifAStdBook"
                          //FedraSansStd-A-medium //bold serif font name
                          fontSize={
                            url
                              ? { md: '30px', base: '24px' }
                              : { md: '30px', lg: '41px' }
                          }
                          fontStyle="normal"
                          fontWeight="600"
                          lineHeight={{ md: '37px', lg: '49px' }}
                          str={render(whiteBannerText1?.body)}
                          paddingRight="25px"
                          borderRight="1px solid white"
                        ></StructuredTextParser>
                        <StructuredTextParser
                          str={render(whiteBannerText2?.body)}
                          pl="22px"
                          color="#ffffff"
                          textAlign="center"
                          fontFeatureSettings="'case' on"
                          fontFamily="FedraSansStd-medium"
                          fontSize={
                            url
                              ? { base: '20px', md: '24px' }
                              : { md: '22px', lg: '32px' }
                          }
                          mt="0"
                        />
                        {whiteBannerText3 && !showTimer && (
                          <StructuredTextParser
                            pl="22px"
                            color="#CD3C4C"
                            textAlign="center"
                            fontFamily="FedraSansStd-medium"
                            fontSize={{ md: '12px', lg: '24px' }}
                            mt="0"
                            str={render(whiteBannerText3?.body)}
                          />
                        )}

                        {showTimer && (
                          <CountdownTimer targetDate={countDownDate} />
                        )}
                      </Flex>
                    </Flex>
                  </Hide>
                  {!url && (
                    <Show below="md">
                      {/* <Image src={pageConfigVal?.topbanner?.topAd?.mob} alt="" /> */}
                      <Flex
                        bgImage={pageConfigVal?.topbanner?.topAd?.mob}
                        // h="60px"
                        py="10px"
                        px="15px"
                        bgSize="cover"
                        w="100%"
                        alignItems={url ? 'flex-start' : 'center'}
                        justifyContent={url ? 'flex-start' : 'center'}
                      >
                        <Flex flexDir="column">
                          <StructuredTextParser
                            color="#ffffff"
                            textAlign="center"
                            display="flex"
                            alignItems={url ? 'left' : 'center'}
                            fontFamily="FedraSerifAStdBook"
                            //FedraSansStd-A-medium
                            mt="0"
                            fontSize="20px"
                            fontStyle="normal"
                            flexDir="column"
                            fontWeight="500"
                            lineHeight="23px"
                            verticalAlign="middle"
                            str={render(whiteBannerText1?.body)}
                          ></StructuredTextParser>
                          <Flex alignItems="center">
                            <StructuredTextParser
                              str={render(whiteBannerText2?.body)}
                              as="span"
                              mt="0"
                              color="#ffffff"
                              textAlign={url ? 'left' : 'center'}
                              fontFeatureSettings="'case' on"
                              fontFamily="FedraSansStd-medium"
                              fontSize="16px"
                              // lineHeight="37px"
                            ></StructuredTextParser>
                            {whiteBannerText3 && !showTimer && (
                              <StructuredTextParser
                                pl="9px"
                                color="#CD3C4C"
                                textAlign={url ? 'left' : 'center'}
                                fontFamily="FedraSansStd-medium"
                                fontSize="12px"
                                lineHeight="14px"
                                mt="0"
                                str={render(whiteBannerText3?.body)}
                              />
                            )}
                          </Flex>
                          {showTimer && (
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              w="100%"
                            >
                              <CountdownTimer targetDate={countDownDate} />
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Show>
                  )}
                </Link>

                <Box
                  position="absolute"
                  right={{ base: '5%', md: '4%', lg: '3%', xl: '5%' }}
                  bottom={{
                    base: url ? '15px' : '30%',
                    lg: url ? '20px' : '30%',
                  }}
                >
                  <Hide breakpoint="(max-width: 767px)">
                    <IoCloseCircleSharp
                      cursor="pointer"
                      onClick={() => {
                        setIsBannerActive(false);
                      }}
                      fontSize={'37px'}
                      color="white"
                    />
                  </Hide>
                </Box>
              </Box>
            )}

          <Box>
            <Box
              display={{ base: 'flex', md: 'block' }}
              flexDir={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              overflow="hidden"
              h={{ base: '90vh', sm: '100%', md: '100%' }}
              maxW="100%"
              py={{ base: '0', md: '50px' }}
              pt={{ base: '8px', md: '20px', lg: url ? '20px' : '100px' }}
              pos="relative"
              // pl={{ base: '0', md: '68px', lg: "68px", xl: 'min(155px, 10%)' }}
              className="topBannerContainer"
              minH={
                changeUi
                  ? { base: 'auto', sm: 'auto', lg: '733px' }
                  : { base: 'auto', sm: 'auto', md: '733px' }
              }
              // minH={{ base: 'auto', sm: 'auto', lg: '733px' }} // for the updated ui uncomment this
              // mb={{ base: "40px", md: "0", lg: "0" }}
            >
              <Flex
                width={{
                  base: '100%',
                  md: '690px',
                  lg: '900px',
                  xl: '1230px',
                  '2xl': '1300px',
                }}
                mx="auto"
                height="auto"
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                alignItems="center"
                px={{ base: '24px', md: '0' }}
              >
                {pageData?.alsoin?.length > 0 ? (
                  <Button
                    bg="transparent"
                    value={config.lngListTrans[lang]}
                    color="#B7987E"
                    fontSize={{ base: '16px', md: '18px' }}
                    variant="link"
                    w="auto"
                    h={{ base: '20px', md: '21px' }}
                    rounded="full"
                    fontWeight={350}
                    display="inline-flex"
                    _hover={{ border: '0px solid #B7987E' }}
                    _focus={{ border: '0px solid #B7987E' }}
                    _active={{ border: '0px solid #B7987E' }}
                    rightIcon={<RightIcon hover={hover} />}
                    // onMouseOver={(e)=>{e.preventDefault(); setHover(true)}}
                    // onMouseOut={(e)=>{e.preventDefault(); setHover(false)}}
                    onClick={() => setCatIsOpen(!isCatOpen)}
                    //  onBlur={() => setCatIsOpen(false)}
                    className="selectButton"
                    textTransform="capitalize"
                    {...TopBannerSt[lang]?.loginButton}
                    mr={{ base: '51px', md: '30px' }}
                    justifyContent="center"
                  >
                    <Text
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      color="#F37021"
                      _hover={{ color: '#BC570F' }}
                      w="auto"
                      minW="60px"
                      {...TopBannerSt[lang]?.loginButton}
                    >
                      {config.lngListTrans[lang]}
                    </Text>
                    {isCatOpen && (
                      <Flex
                        // _before={{ content: "''", height: '17px' }}
                        // _after={{ content: "''", height: '17px' }}
                        // display={isCatOpen ? 'block' : 'none'}
                        whiteSpace="break-spaces"
                        // _hover={{ display: 'block' }}
                        className="options"
                        alignItems="flex-start"
                        alignContent="flex-start"
                        // onMouseLeave={() => {
                        {...TopBannerSt[lang]?.loginButton}
                        //   setCatIsOpen(false);
                        //   setIsCategory('All Rituals');
                        // }}
                        // onClick={() => setCatIsOpen(false)}
                        position="absolute"
                        top={{ base: '45px', md: '47px' }}
                        zIndex="100"
                        left="0"
                        boxShadow="0px 0.75926px 2.36111px 0px rgba(0, 0, 0, 0.03), 0px 3.34074px 4.88889px 0px rgba(0, 0, 0, 0.05), 0px 8.2px 9.75px 0px rgba(0, 0, 0, 0.06), 0px 15.79259px 19.11111px 0px rgba(0, 0, 0, 0.07), 0px 26.57407px 35.13889px 0px rgba(0, 0, 0, 0.09), 0px 41px 60px 0px rgba(0, 0, 0, 0.12)"
                        w={{ base: '103px', md: '116px' }}
                        flexDir="column"
                        bg="#ffffff"
                        mt={{ base: '0', md: '0' }}
                        p={{ base: '10px 15px', md: '10px 15px' }}
                      >
                        {region === 'in'
                          ? resultArr?.map((lg, i) => {
                              return (
                                <Box
                                  padding="8px 0px"
                                  cursor="pointer"
                                  key={nanoid()}
                                  value={lg}
                                >
                                  <Text
                                    textAlign="left"
                                    fontFamily={TopBannerSt[lang].loginButton}
                                  >
                                    <NextLink
                                      href={lg?.url}
                                      passHref
                                      legacyBehavior
                                    >
                                      <Link
                                        color="#6D6358"
                                        {...TopBannerSt[lang]?.loginButton}
                                        textAlign="left"
                                        _hover={{
                                          color: '#BC570F',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                        }}
                                        _active={{
                                          color: '#BC570F',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                        }}
                                        _focus={{
                                          color: '#BC570F',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                        }}
                                      >
                                        {lg.title}
                                      </Link>
                                    </NextLink>
                                  </Text>
                                </Box>
                              );
                            })
                          : pageData.alsoin?.map((lg, i) => {
                              return (
                                <Box
                                  padding="8px 0px"
                                  cursor="pointer"
                                  key={nanoid()}
                                  value={lg}
                                >
                                  <Text
                                    textAlign="left"
                                    fontFamily={TopBannerSt[lang].loginButton}
                                  >
                                    <NextLink
                                      href={lg?.url}
                                      passHref
                                      legacyBehavior
                                    >
                                      <Link
                                        color="#6D6358"
                                        {...TopBannerSt[lang]?.loginButton}
                                        textAlign="left"
                                        _hover={{
                                          color: '#BC570F',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                        }}
                                        _active={{
                                          color: '#BC570F',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                        }}
                                        _focus={{
                                          color: '#BC570F',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                        }}
                                      >
                                        {lg.title === 'Indonesian'
                                          ? 'Bahasa Indonesia'
                                          : lg.title}
                                      </Link>
                                    </NextLink>
                                  </Text>
                                </Box>
                              );
                            })}
                      </Flex>
                    )}
                  </Button>
                ) : (
                  <Spacer />
                )}

                {(region === 'us' || region === 'ca') &&
                  sectionContent[8]?.linkUrl &&
                  sectionContent[8]?._modelApiKey === 'simple_button' && (
                    <Flex
                      flexDir="column"
                      alignItems="flex-end"
                      justify="space-between"
                    >
                      <NextLink
                        href={refineUrlDomain(
                          sectionContent[8]?.linkUrl,
                          region,
                          lang
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          {...TopBannerSt[lang]?.loginButton}
                          color={{ base: '#fff' }}
                          bg="#CE6113"
                          fontWeight={500}
                          height={{ base: '100%', md: '43px' }}
                          padding={{
                            base: '5px 21px',
                            md: '5px 30px 11px',
                            lg: '8px 10px',
                          }}
                          fontSize={{ base: '14px', md: '18px' }}
                          borderRadius={0}
                          textAlign="center"
                          w={{ base: 'auto', lg: '136px' }}
                          _hover={{ bg: '#BC570F' }}
                          _active={{ bg: '#BC570F' }}
                          _focus={{ bg: '#BC570F' }}
                          value="gift"
                          borderWidth="0"
                          h={{ base: '32px', md: '44px' }}
                          border="0px solid #CE6113 !important"
                          mt={{ base: '27px', lg: '23px' }}
                          mr={{ base: '0', md: '20px' }}
                          target="_blank"
                        >
                          {sectionContent[8]?.buttonText}
                        </Link>
                      </NextLink>
                    </Flex>
                  )}

                {sectionContent[5]?.linkUrl && (
                  <Flex
                    flexDir="column"
                    alignItems="flex-end"
                    justify="space-between"
                  >
                    <Box
                      as="span"
                      fontSize={{ base: '12px', md: '14px' }}
                      color={{ base: '#F37021' }}
                      {...TopBannerSt[lang]?.loginButton}
                      letterSpacing={{ base: 'normal', md: '0' }}
                      fontWeight={{ base: '450', md: '500' }}
                      textAlign="end"
                      w="auto"
                    >
                      {/* Already registered text */}
                      {sectionContent[4]?.text}
                    </Box>
                    <NextLink
                      href={sectionContent[5]?.linkUrl}
                      passHref
                      legacyBehavior
                    >
                      <Link
                        {...TopBannerSt[lang]?.loginButton}
                        // variant="outline"
                        color={{ base: '#F37021' }}
                        fontWeight={500}
                        height={{ base: '100%', md: '43px' }}
                        padding={{
                          base: '3px 21px',
                          md: '5px 30px 11px',
                          lg: '5px 10px',
                        }}
                        fontSize={{ base: '14px', md: '18px' }}
                        borderRadius={0}
                        textAlign="center"
                        w={{ base: 'max-content', lg: '136px' }}
                        _hover={{
                          bg: '#BC570F',
                          color: '#fff',
                          border: '2px solid #BC570F !important',
                        }}
                        _active={{
                          bg: '#BC570F',
                          color: '#fff',
                          border: '2px solid #BC570F !important',
                        }}
                        _focus={{
                          bg: '#BC570F',
                          color: '#fff',
                          border: '2px solid #BC570F !important',
                        }}
                        value="login"
                        h={{ base: '32px', md: 'auto' }}
                        borderWidth="0"
                        border="2px solid #CE6113 !important"
                        mt={{ base: '8px', lg: '0' }}
                        target="_blank"
                      >
                        {sectionContent[5]?.buttonText}
                      </Link>
                    </NextLink>
                  </Flex>
                )}
              </Flex>
              {/* </Flex> */}
              <Box mx="auto" maxW="1030px" w="full">
                <Flex
                  mt={{
                    base: smMob ? '15px' : '31px',
                    md: '100px',
                    lg: '50px',
                  }}
                  flexDirection="column"
                  maxW={{
                    base: isIndian ? '270px' : '270px',
                    sm: 'max(38%, 244px)',
                    lg: '355px',
                  }}
                  textAlign="center"
                  alignItems="center"
                  justifyContent={{
                    base: 'center',
                    md: 'flex-start',
                    lg: 'center',
                  }}
                  mx={{ base: 'auto', md: '0' }}
                  style={sectionContent[4]}
                >
                  <SingleImage
                    mt={{ base: '0', md: '10px' }}
                    mb={{ base: '15px', md: '10px' }}
                    mx="auto"
                    className="textImage"
                    objectFit="contain"
                    alignSelf={{
                      base: 'center',
                      md: 'flex-start',
                      lg: 'center',
                    }}
                    w="314px"
                    h={{ base: '22vh', md: '189px', lg: '244px' }}
                    sectionContent={sectionContent[0]}
                    sync="true"
                    alt=""
                  />

                  {isLoader && (
                    <Box
                      w="100%"
                      mt="50px"
                      textAlign="center"
                      display={'block'}
                      minH="85px"
                    >
                      <Loader pos="relative" />
                    </Box>
                  )}

                  {!isLoader && descText && (
                    <StructuredTextParser
                      str={render(sectionContent[2]?.body)}
                      color="#fff"
                      alignSelf="center"
                      textAlign={{
                        base: 'center',
                        md: lang === 'ar' ? 'center' : 'left',
                        lg: 'center',
                      }}
                      fontWeight="400"
                      fontStyle="normal"
                      mb={{ base: '8px', md: '0' }}
                      ml={{ base: '0', md: '18px', lg: '0' }}
                      {...TopBannerSt[lang][stStyle]}
                    />
                  )}

                  {sectionContent[3].linkUrl != '' && (
                    <Box
                      display={{ base: 'none', md: 'flex' }}
                      flexDir={programFeeLink?.linkUrl && 'column'} // dir for ui update
                      h="auto"
                      alignSelf={{
                        base: 'center',

                        lg: 'center',
                      }}
                      to="ieo-enroll-section"
                      spy={true}
                      smooth={true}
                      offset={50}
                      mx="auto"
                    >
                      <SimpleButton
                        // width={
                        //   isGiftPage
                        //     ? { base: '251px', md: '244px', lg: '295px' }
                        //     : { base: '215px', md: '184px', lg: '230px' }
                        // }
                        minW="230px"
                        background={
                          '#F37021 !important' || pageConfigVal?.btn?.bgColor
                        }
                        h={{ base: '50px', md: '60px', lg: '60px' }}
                        textAlign="center"
                        // mb={{ base: "0", md: "30px", lg: "15px" }}
                        // mb="0" // updated ui
                        mb={programFeeLink?.linkUrl ? '0' : '5px'}
                        mx={{ base: 'auto', md: '0', lg: 'auto' }}
                        _hover={{
                          background:
                            '#BC570F !important' ||
                            pageConfigVal?.btn?.bgHoverColor,
                        }}
                        borderRadius="10px !important"
                        style={sectionContent[3]}
                        srcImg={pageData}
                        clickHandler={() => {
                          gtmClick();
                        }}
                        padding={{
                          base: '8px 7px',
                          md: pageConfigVal?.btn?.bgHoverColor
                            ? '10px 0'
                            : '10px 25px',
                          lg: pageConfigVal?.btn?.bgHoverColor
                            ? '12px 10px'
                            : '10px 29px',
                        }}
                        lineHeight={{
                          base: '1.5em',
                          md:
                            lang == 'hi' || lang == 'kn' || lang == 'te'
                              ? '50px'
                              : '37px',
                        }}
                        // mt={{ base: '0', md: '30px', lg: '40px' }} ui update
                        mt={{ base: '0', md: '30px', lg: '50px' }}
                        zIndex={100}
                        icon={pageConfigVal?.btn?.icon}
                        {...TopBannerSt[lang][buttonSt]}
                      />

                      {/*link to program fee section*/}
                      {programFeeLink?.linkUrl && (
                        <NextLink
                          legacyBehavior
                          href={refineUrlDomain(
                            programFeeLink?.linkUrl,
                            region,
                            lang
                          )}
                          passHref
                        >
                          <Link
                            w="100%"
                            mt={{ base: '0', md: '20px', lg: '20px' }}
                            color="#fff"
                            fontSize={{ base: '16px', md: '18px' }}
                            lineHeight={{ base: '21.44px', md: '25.2px' }}
                            fontFamily={
                              lang
                                ? config.langFont[lang]
                                : 'FedraSansStd-medium'
                            }
                            fontWeight="normal"
                            textAlign={{
                              base: 'center',
                              sm: 'left',
                              lg: 'center',
                            }}
                            ml={{
                              base: '0',
                              sm: '100px',
                              md: isGiftPage ? '15px' : '35px',
                              lg: '0',
                            }}
                            mb={{ base: '20px', md: '30px' }}
                            textDecor="underline"
                            cursor="pointer"
                          >
                            {programFeeLink?.buttonText}
                          </Link>
                        </NextLink>
                      )}
                    </Box>
                  )}
                </Flex>
              </Box>
              <Box
                pos={{ base: 'static', md: 'absolute' }}
                h={{
                  base: '43vh',
                  sm: 'auto',
                  md: '445px',
                  lg: '570px',
                  xl: '630px',
                }}
                top={{ base: 'unset', md: 'unset', lg: 'unset' }}
                bottom={{ base: '0', md: '-10px', lg: '0', xl: '0' }}
                right={{
                  base: '0',
                  md: '5px',
                  lg: '-10px',
                  xl: '-130px',
                  '2xl': '120px',
                }}
                w={{
                  base: '100%',
                  sm: 'auto',
                  md: '614px',
                  lg: '750px',
                  xl: '1030px',
                }}
                zIndex={15}
              >
                <BannerImage
                  px="0"
                  w={{ base: '100%', sm: 'auto', md: 'auto' }}
                  objectPosition="top"
                  mx={{ base: '0', sm: 'auto', md: '0' }}
                  className="sadhguruImage"
                  maxWidth="1030px"
                  height="full"
                  my="0"
                  sectionContent={sectionContent[1]}
                  sync="true"
                  alt=""
                />
              </Box>
              <style jsx global>
                {`
                  .textImage img {
                    margin-inline-start: auto;
                    margin-inline-end: auto;
                    object-fit: contain;
                  }
                  .thanksmessage {
                    font-family: FedraSansStd-book;
                    font-size: 18px;
                  }
                  .thanksmessage.mobile p strong {
                    color: #f37021 !important;
                  }
                  @media (min-width: 561px) and (max-width: 767px) {
                    .sadhguruImage {
                      margin: auto;
                      width: 100%;
                    }
                    .sadhguruImage img {
                      width: 100%;
                      object-fit: cover;
                    }
                  }
                  @media (min-width: 481px) and (max-width: 560px) {
                    .sadhguruImage {
                      margin: auto;
                      width: 100%;
                    }
                    .sadhguruImage img {
                      width: 100%;
                      object-fit: cover;
                    }
                  }

                  @media (min-width: 300px) and (max-width: 480px) {
                    .sadhguruImage {
                      margin: auto;
                      width: 100%;
                    }
                    .sadhguruImage img {
                      width: 100%;
                      object-fit: cover;
                    }
                  }

                   {
                    /* @media (max-width:359px) and (min-width:300px) {
                      .sadhguruImage img {
                        height: 250px !important;
                        text-align: center;
                        width: 100%;
                        max-width: 100%;
                        object-fit: cover;
                        object-position: top;
                      }
                      .textImage {
                        margin-bottom:10px;
                      }
                      .textImage img {
                        width:155px;
                        height:121px !important;
                        padding:0;
                        margin-top:0;
                      }
                  } */
                  }
                `}
              </style>
            </Box>
            {pageName !== 'ieomigrate' && (
              <Box position={'relative'}>
                <Box
                  display={{ base: 'block', md: 'none' }}
                  mx="auto"
                  pos={{ base: 'absolute', md: 'static' }}
                  bottom={{
                    base: changeUi ? '-50px' : '-20px',
                    sm: changeUi ? '-55px' : '-20px',
                  }}
                  left="50%"
                  w="100%"
                  transform={{ base: 'translateX(-50%)', md: 'none' }}
                  h="auto"
                  zIndex={150}
                >
                  <SimpleButton
                    width={
                      isGiftPage
                        ? { base: '300px', md: '324px' }
                        : { base: 'auto', md: '184px', lg: '210px' }
                    }
                    minW="230px"
                    background={
                      '#F37021 !important' ||
                      (pageConfigVal?.btn?.bgColor
                        ? pageConfigVal?.btn?.bgColor
                        : '#F37021 !important')
                    }
                    // h={{ base: '50px', md: '50px', lg: '60px' }}
                    textAlign="center"
                    mb={{ base: '0', md: '30px' }}
                    mx="auto"
                    _hover={{
                      background:
                        '#BC570F !important' ||
                        pageConfigVal?.btn?.bgHoverColor,
                    }}
                    borderRadius="10px !important"
                    // style={sectionContent[3]}
                    style={sectionContent[3]}
                    clickHandler={() => {
                      gtmClick();
                    }}
                    padding={{
                      base: '9px 10px',
                      md: '10px 25px',
                      lg: '10px 29px',
                    }}
                    mt={{ base: '0', md: '30px', lg: '50px' }}
                    my="0"
                    zIndex={100}
                    lineHeight="inherit"
                    icon={pageConfigVal?.btn?.icon}
                    {...TopBannerSt[lang][buttonSt]}
                  />
                  {changeUi && (
                    <NextLink
                      legacyBehavior
                      href={refineUrlDomain(
                        programFeeLink?.linkUrl,
                        region,
                        lang
                      )}
                      passHref
                    >
                      <Link
                        w="100%"
                        mt={{ base: '10px', md: '0', lg: '0' }}
                        fontSize={{ base: '16px', md: '18px' }}
                        lineHeight={{ base: '21.44px', md: '25.2px' }}
                        fontFamily={
                          lang ? config.langFont[lang] : 'FedraSansStd-medium'
                        }
                        textAlign={{
                          base: 'center',
                          sm: 'center',
                          lg: 'center',
                        }}
                        color={
                          pageConfigVal?.topbanner?.bottomAd ? '#fff' : '#000'
                        }
                        ml={{ base: '0', sm: '0', lg: '0' }}
                        textDecor="underline"
                        cursor="pointer"
                        display="block"
                      >
                        {programFeeLink?.buttonText}
                      </Link>
                    </NextLink>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          {!isLoader &&
            greyBannerText1 &&
            greyBannerText2 &&
            Object.values(prgData)?.length > 0 &&
            Object.values(prgData)[0].coupon &&
            pageConfigVal?.topbanner?.bottomAd && (
              <Box
                w="full"
                bg="#094B10"
                display={
                  pageConfigVal?.topbanner?.bottomAd?.desktop ? 'block' : 'none'
                }
                zIndex="999"
              >
                <Show breakpoint="(min-width: 768px)">
                  <Flex
                    bgImage={pageConfigVal?.topbanner?.bottomAd?.desktop}
                    h="100%"
                    py="33px"
                    bgSize="cover"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Flex alignItems="center">
                      <StructuredTextParser
                        color="#ffffff"
                        textAlign="center"
                        display="flex"
                        alignItems="center"
                        fontFamily="FedraSerifAStdBook"
                        //FedraSansStd-A-medium
                        fontSize={{ md: '30px', lg: '40px' }}
                        fontStyle="normal"
                        mt="0"
                        str={render(greyBannerText1?.body)}
                        fontWeight="600"
                        lineHeight={{ md: '36px', lg: '46px' }}
                        verticalAlign="middle"
                      ></StructuredTextParser>
                      <Box w="1px" mx="30px" height="60px" background="#fff" />
                      {pageConfigVal?.topbanner?.bottomAd?.icon && (
                        <LazyLoadImageComponent
                          src={pageConfigVal?.topbanner?.bottomAd?.icon}
                          mx="20px"
                          my="0"
                        />
                      )}
                      <Flex alignItems="center">
                        <StructuredTextParser
                          // pl="77px"
                          color="#FFEFC7"
                          textAlign="center"
                          fontFeatureSettings="'case' on"
                          fontFamily="FedraSansStd-medium"
                          fontSize={{ md: '27px', lg: '37px' }}
                          mt="0"
                          // lineHeight="37px"
                          lineHeight={{ md: '35px', lg: '45px' }}
                          str={render(greyBannerText2?.body)}
                        />
                        <StructuredTextParser
                          color="#FFEFC7"
                          pl="16px"
                          textAlign="center"
                          fontFeatureSettings="'case' on"
                          fontFamily="FedraSansStd-medium"
                          fontSize={{ base: '16px', lg: '23px' }}
                          mt="0"
                          // lineHeight="37px"
                          lineHeight={{ md: '35px', lg: '45px' }}
                          str={render(greyBannerText3?.body)}
                        />
                        {greyBannerText4 && (
                          <StructuredTextParser
                            color="#FFF"
                            pl="7px"
                            textAlign="center"
                            fontFamily="FedraSansStd-medium"
                            fontSize={{ base: '12px', lg: '24px' }}
                            mt="0"
                            // lineHeight="37px"
                            lineHeight={{ md: '14px', lg: '28px' }}
                            str={render(greyBannerText4?.body)}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Show>

                <Show breakpoint="(max-width: 767px)">
                  <Flex
                    bgImage={pageConfigVal?.topbanner?.bottomAd?.mob}
                    h="100%"
                    pt={changeUi ? '85px' : '35px'}
                    pb="35px"
                    px="15px"
                    bgSize="cover"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    flexDir="column"
                  >
                    {!changeUi && programFeeLink?.linkUrl && (
                      <Show below="sm">
                        <NextLink
                          legacyBehavior
                          href={refineUrlDomain(
                            programFeeLink?.linkUrl,
                            region,
                            lang
                          )}
                          passHref
                        >
                          <Link
                            w="100%"
                            mt={{ base: '0', md: '26px', lg: '30px' }}
                            color={
                              pageConfigVal?.topbanner?.bottomAd
                                ? '#fff'
                                : '#000'
                            }
                            fontSize={{ base: '16px', md: '18px' }}
                            lineHeight={{ base: '21.44px', md: '25.2px' }}
                            fontFamily={
                              lang
                                ? config.langFont[lang]
                                : 'FedraSansStd-medium'
                            }
                            fontWeight="normal"
                            textAlign={{
                              base: 'center',
                              sm: 'center',
                              lg: 'left',
                            }}
                            ml={{
                              base: '0',
                              sm: '0',
                              md: '35px',
                              lg: '0',
                            }}
                            mb={{ base: '20px', md: '30px' }}
                            textDecor="underline"
                            cursor="pointer"
                          >
                            {programFeeLink?.buttonText}
                          </Link>
                        </NextLink>
                      </Show>
                    )}
                    <Flex
                      w="100%"
                      justifyContent="center"
                      flexDir="column"
                      alignItems="center"
                    >
                      <Box>
                        <StructuredTextParser
                          str={render(greyBannerText1?.body)}
                          color="#FFEFC7"
                          textAlign="center"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          fontFamily="FedraSansStd-A-medium"
                          fontSize="24px"
                          fontStyle="normal"
                          mt="0"
                          lineHeight="25px"
                          w="100%"
                          verticalAlign="middle"
                        ></StructuredTextParser>
                      </Box>
                      {pageConfigVal?.topbanner?.bottomAd?.icon && (
                        <LazyLoadImageComponent
                          src={pageConfigVal?.topbanner?.bottomAd?.icon}
                          mx="10px"
                          my="0"
                          width="40.272px"
                          height="38.283px"
                        />
                      )}
                      <Flex pt="5px">
                        <StructuredTextParser
                          color="#FFEFC7"
                          textAlign="left"
                          fontFeatureSettings="'case' on"
                          fontFamily="FedraSansStd-medium"
                          fontSize="22px"
                          mt="0"
                          w="100%"
                          str={render(greyBannerText2?.body)}
                        ></StructuredTextParser>
                        <StructuredTextParser
                          color="#FFF"
                          textAlign="left"
                          fontFamily="FedraSansStd-medium"
                          fontSize="14px"
                          mt="0"
                          pt="10px"
                          pl="15px"
                          lineHeight="14px"
                          str={render(greyBannerText3?.body)}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Show>
              </Box>
            )}
        </Box>
      </Box>
    )
  );
};

export default TopBannerYo;
