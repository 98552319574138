import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h2: {
      fontSize: {
        base: 28,
        lg: 32,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      color: '#433F3C',
    },
    p: {
      fontSize: {
        base: 16,
      },
      fontWeight: '500',
      color: '#6D6358',
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    p1: {
      fontSize: {
        base: 16,
      },
      fontWeight: '500',
      color: '#6D6358',
    },
    span: {
      fontSize: {
        base: 18,
      },
      lineHeight: '27px',
      color: '#6D6358',
      fontFamily: config.langFont[lang],
      fontWeight: isIndian ? 500 : 'normal',
      p: '25px 25px 27px 25px',
    },
    card: {
      height: { base: '243px', sm: '280px' },
    },
    cardV3: {
      color: '#7A6E4B',
      textAlign: 'center',
      fontFamily: 'FedraSansStd-book',
      fontSize: { base: '12px', sm: '20px' },
      fontStyle: 'normal',
      fontWeight: '450',
      lineHeight: { base: '13px', sm: '24px' },
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const programStructureCardSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({
    lang: 'bn',
    card: {
      height: { base: '243px', sm: '280px' },
    },
  }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({
    lang: 'te',
    card: {
      height: { base: '243px', sm: '280px' },
    },
  }),
  kn: commonLangStyle({
    lang: 'kn',
    card: {
      height: { base: '330px', sm: '280px' },
    },    
    cardV3: {
      fontSize: { base: '9px', sm: '11px', md: '16px' },
      lineHeight: { base: '1.3em', sm: '15px', sm: '20px' },
      wordBreak: 'break-word'
    },
  }),
  gu: commonLangStyle({
    lang: 'gu',
    card: {
      height: { base: '330px', sm: '280px' },
    },
  }),
  mr: commonLangStyle({
    lang: 'mr',
    card: {
      height: { base: '330px', sm: '280px' },
    },
  }),
  ml: commonLangStyle({
    lang: 'ml',
    card: {
      height: { base: '330px', sm: '280px' },
    },
    cardV3: {
      fontSize: { base: '9px', sm: '11px', md: '16px' },
      lineHeight: { base: '1.3em', sm: '15px', sm: '20px' },
      wordBreak: 'break-word'
    },
  }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({
    lang: 'ta',
    card: {
      height: { base: '330px', sm: '320px' },
    },
    p1: {
      p: {
        fontSize: 16,
        lineHeight: { base: '20px', md: '24px' },
      },
    },
    p: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    cardV3: {
      fontSize: { base: '9px', sm: '11px', md: '16px' },
      lineHeight: { base: '1.3em', sm: '15px', sm: '20px' },
      wordBreak: 'break-word'
    },
  }),
  ar: commonLangStyle({
    lang: 'ar',
    p1: {
      p: {
        fontSize: 16,
        lineHeight: { base: '20px', md: '24px' },
        textAlign: 'right',
        fontFamily: "'Almarai', sans-serif",
      },
    },
    p: {
      fontWeight: 400,
      textAlign: 'right',
      fontFamily: "'Almarai', sans-serif",
    },
    h6: {
      textAlign: 'right',
      fontFamily: "'Almarai', sans-serif",
    },

    h2: {
      textAlign: 'right',
      fontFamily: "'Almarai', sans-serif",
    },
  }),
  id: commonLangStyle({
    lang: 'id',
    span: {
      p: '25px 10px 27px 10px',
      lineHeight: '1.4rem',
    },
  }),
};

export default programStructureCardSt;
