/* Built In Imports */

/* External Imports */
import {
  Box,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/* Styles */

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns ProgramFee
 */
const InvalidProgramCorporates = ({ sectionContent, region, lang }) => {
  const isMobile = useBreakpointValue({ base: false, md: true });

  return (
    <Box
      minW={'100%'}
      minHeight={'100vh'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="18px"
    >
      <Image
        src={
          isMobile
            ? sectionContent[0]?.image?.url
            : sectionContent[0]?.mobileImage?.url
        }
        width={'auto'}
        alt=""
      />
      <Box color="#fff" mt={{ base: '1rem', md: '2rem' }}>
        <Heading
          as="h2"
          color="#242424"
          fontFamily="FedraSansStd-medium"
          fontSize={{ base: '18px', md: '32px' }}
          lineHeight={{ base: '22.5px', md: '40px' }}
          textAlign="center"
          fontWeight="500"
        >
          {sectionContent[1]?.text}
        </Heading>
        <Text
          mt="8px"
          fontFamily="FedraSansStd-book"
          fontSize={{ base: '16px', md: '26px' }}
          lineHeight={{ base: '24px', md: '41.44px' }}
          fontWeight="400"
          textAlign="center"
          color="#242424"
        >
          {sectionContent[2]?.text}
        </Text>
      </Box>
    </Box>
  );
};

export default InvalidProgramCorporates;
