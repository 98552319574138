/* Built In Imports */
import { usePathname } from 'next/navigation';
/* External Imports */
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { ProgramStructureSt } from '@ieo/Common/CompLangConfig';
import ProgramStructureCardV3 from '@ieo/Components/Cards/ProgramStructureCardV3';

import { ResearchShowsSt } from '@ieo/Common/CompLangConfig';
/* Services */
/* Styles */

/**
 *
 * @param {Object} SectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} ProgramStructure
 */

const ProgramStructureIYP = ({
  sectionContent,
  region,
  lang,
  pageName,
  pageData,
}) => {
  const path = usePathname();
  const isPageURLmatch = path.includes('/offer/under-25s');
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;
  // console.log(sectionContent, 'sectionContent');
  const duration = sectionContent.find(
    el => el.fieldId === 'ieo-program-structure-duration'
  );
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }
  const url = path.includes('offer/under-25s');
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      background={
        url
          ? 'url(https://static.sadhguru.org/d/46272/1710743888-1710584276-pbg1.jpg) repeat'
          : {
              base: pageConfigVal
                ? `url(${pageConfigVal?.prgStructure?.bgImage?.mob}) repeat-x #F1EBDF`
                : `#F1EBDF`,
              sm: pageConfigVal
                ? `url(${pageConfigVal?.prgStructure?.bgImage?.tab}) repeat-x #F1EBDF`
                : `#F1EBDF`,
              md: pageConfigVal
                ? `url(${pageConfigVal?.prgStructure?.bgImage?.desktop}) repeat-x #F1EBDF`
                : `#F1EBDF`,
              lg: pageConfigVal
                ? `url(${pageConfigVal?.prgStructure?.bgImage?.bigScreen}) repeat-x #F1EBDF`
                : `#F1EBDF`,
            }
      }
      // bgRepeat="repeat-x"
      bgRepeat={url && 'repeat'}
      bgPos={{ base: 'inherit', md: 'top' }}
      // bgSize={{ base: 'auto 50px', md: 'auto 10%', xl: 'auto 15%' }}
    >
      <Flex
        maxW={1030}
        // bg="#F1EBDF"
        pt={{ base: '50px', lg: '90px' }}
        pb={{ base: '16px', md: '41px' }}
        margin={'0 auto'}
      >
        <Box w="full" pb="44.7px" mt={{ base: '45px', md: '90px' }}>
          <Flex
            alignItems={{ sm: 'center' }}
            borderBottom={{ base: 'none', md: '1px solid #CF915E' }}
            flexDir={{ base: 'column', sm: 'row' }}
            justify="space-between"
            px={{ base: '1rem', xl: '0px' }}
          >
            <Box
              className="ResearchShowText"
              maxW={{ base: '100%', md: '348px', lg: '388px' }}
              w="100%"
              pl={{ base: '0', md: '15px', lg: '0' }}
            >
              <Box
                as="h1"
                lineHeight={{ base: '42px', lg: 'initial' }}
                pb={{ base: '18px', md: '26px' }}
                {...ProgramStructureSt[lang]?.h2}
                w={{ base: '300px', md: '320px', xl: '480px' }}
                fontFamily="FedraSansStd-book"
                fontSize="22px"
                textAlign={lang === 'ar' ? 'right' : 'left'}
              >
                {sectionContent?.[0]?.titleText}
              </Box>
            </Box>

            <style>
              {`
                .ResearchShowText {
                  position: relative;
                }
                .ResearchShowText:before {
                  position: absolute;
                  content: '';
                  background: url(${
                    isPageURLmatch &&
                    'https://static.sadhguru.org/d/46272/1710584133-what-to-learn-bg-1.png'
                  });
                  background-position: center center;
                  background-size: cover;
                  width: 100%;
                  height: 250px;
                  background-size: 100%;
                  top: -90px;
                  background-repeat: no-repeat;
                  left: ${isPageURLmatch ? '-10px' : '0px'};
                }
                .ResearchShowText h1,
                .ResearchShowText h2 {
                  color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                  width: auto;
                  font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
                  font-weight: ${ResearchShowsSt[lang]?.heading?.fontWeight};
                  font-size: ;
                  line-height: ${isPageURLmatch ? '1.1em' : '64px'};
                  margin-bottom: 59px;
                  position: relative;
                  z-index: 2;
                  text-align: ${isPageURLmatch ? 'center' : 'left'};
                  margin: auto;
                  min-height: ${isPageURLmatch ? '145px' : 'auto'};
                  padding-bottom: ${isPageURLmatch ? '0px' : '20px'};
                  top: ${
                    isPageURLmatch ? (lang === 'hi' ? '0' : '-15px') : '-20px'
                  };
                }

                @media (max-width: 992px) and (min-width: 768px) {
                  .ResearchShowText:before {
                    top: -100px;
                    background: url(${
                      isPageURLmatch &&
                      'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                    });
                    background-repeat: no-repeat;
                    background-position: center center;
                    left: 0px;
                  }
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    font-size: 40px;
                    line-height: 40px;
                    margin-bottom: ${isPageURLmatch ? '0px' : '0px'};
                    width: ${isPageURLmatch ? '240px' : '100%'};
                    top: ${isPageURLmatch ? '-20px' : 'unset'};
                  }
                }
                @media (max-width: 768px) and (min-width: 480px) {
                  .ResearchShowText:before {
                    top: -100px;
                    background: url(${
                      isPageURLmatch &&
                      'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                    });
                    background-repeat: no-repeat;
                    background-position: 50% center;
                    left: 0px;
                  }
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                    font-size: 40px;
                    line-height: 40px;
                    width: ${isPageURLmatch ? '240px' : 'auto'};
                    padding-bottom: 30px;
                    top: ${isPageURLmatch ? '-20px' : '0'};
                  }
                }
                @media (max-width: 480px) and (min-width: 425px) {
                  .ResearchShowText:before {
                    top: -10px;
                    background: url(${
                      isPageURLmatch &&
                      'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                    });
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 135px;
                    background-size: contain;
                    margin: 0;
                    width: 100%;
                    left: -90px;
                  }
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                    font-size: 24px;
                    line-height: 26px;
                    width: ${isPageURLmatch ? '160px' : ''};
                    margin: ${isPageURLmatch ? '15px 0 0 15px' : '0'};
                    padding-bottom: ${isPageURLmatch ? '30px' : '0'};
                    top: ${isPageURLmatch && '15px'};
                  }
                }
                @media (max-width: 320px) {
                  .ResearchShowText:before {
                    top: -25px;
                    background: url(${
                      isPageURLmatch &&
                      'https://static.sadhguru.org/d/46272/1710739933-group_mob_bg.png'
                    });
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 135px;
                    background-size: contain;
                    margin: 0;
                    width: 100%;
                    left: -40px;
                  }
                  .ResearchShowText h1,
                  .ResearchShowText h2 {
                    color: ${isPageURLmatch ? '#fff' : '#3b444b'};
                    font-size: 20px;
                    line-height: 22px;
                    width: ${isPageURLmatch ? '160px' : ''};
                    margin: ${isPageURLmatch ? '0' : '0'};
                    padding-bottom: ${isPageURLmatch ? '30px' : '0'};
                    top: ${isPageURLmatch && '15px'};
                    left: ${isPageURLmatch && '20px'};
                  }
                }
              `}
            </style>

            <Box
              borderBottom="1px solid #CF915E"
              display={{ md: 'none' }}
            ></Box>
          </Flex>
          <Box mt="34px">
            {isMobile && (
              <Text
                as="h4"
                textAlign={{
                  base: 'center',
                  md:
                    path.indexOf('inner-engineering-v2') > -1
                      ? 'right'
                      : 'center',
                }}
                {...ProgramStructureSt[lang]?.h4}
              >
                {findField('ieo-program-structure-steps')?.text}
              </Text>
            )}
            <Flex
              gridRowGap={{ base: '0', sm: '40px' }}
              gridColumnGap={{ base: '0', sm: '15px' }}
              flexWrap="wrap"
              alignItems="center"
              mt={{ base: '0', md: '14.4px' }}
              justify="center"
            >
              {sectionContent[1]?.cards?.map(card => {
                return (
                  <ProgramStructureCardV3
                    key={nanoid(1)}
                    lang={lang}
                    data={card}
                  />
                );
              })}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default ProgramStructureIYP;
