import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h4: {
      fontSize: {
        base: 20,
        md: 26,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
    },
    h5: {
      fontSize: {
        base: 28,
        md: 30,
        xl: 32,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      color: '#F37021',
    },
    h6: {
      fontSize: {
        base: 16,
        md: 20,
        xl: 22,
      },
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 500 : 'normal',
      color: 'white',
    },
    p: {
      fontSize: 18,
      fontFamily: config.langFont[lang],
      lineHeight: '27px',
      fontWeight: '400',
      color: 'white',
    },
    video: {
      fontSize: { base: '12px', md: '1rem' },
      fontFamily: isIndian ? config.langFont[lang] : 'FedraSansStd-book',
      color: 'white',
      fontWeight: isIndian ? 550 : 450,
    },
    videoV5: {
      fontSize: { base: '10px', md: '1rem' },
      fontFamily: isIndian ? config.langFont[lang] : 'FedraSansStd-book',
      color: 'white',
      fontWeight: isIndian ? 550 : 450,
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const ProgramStepsSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ar: commonLangStyle({
    lang: 'ar',
    h4: {
      fontFamily: "'Almarai', sans-serif",
    },
    h5: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    h6: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    video: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  ta: commonLangStyle({
    lang: 'ta',

    h6: {
      fontWeight: 600,
      fontSize: '18px',
    },
    p: {
      fontSize: '16px',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default ProgramStepsSt;
