/* Built In Imports */
import React from 'react';

/* External Imports */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import SingleImage from '@ieo/Components/SingleImage';

/* Configs */
import { ResearchShowsSt } from '@ieo/Common/CompLangConfig';

/* Styles */
/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const ResearchShows = ({ sectionContent, region, lang }) => {
  // console.log('Research', sectionContent);
  // const router = useRouter();
  //  const [showPopUp, setShowPopUp] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState('inside');
  const isIndian = config.indianLang.indexOf(lang) > -1;

  const btnRef = React.useRef(null);
  // useEffect(() => {
  //   if (!isOpen && window?.loc?.indexOf('popup=1') > -1) {
  //     const parentUrl =
  //       location.origin +
  //       location.pathname +
  //       location.search.replace(/[\?&]popup=1/, '').replace(/^&/, '?');
  //     window.history.pushState(window.history.state, '', parentUrl);
  //   }
  //   //recaptchaRef?.current?.reset();
  // }, [isOpen]);

  // useEffect(() => {
  //   window.onpopstate = e => {
  //     // consoleLog('onpopstate', router, window, e);
  //     if (window?.loc?.indexOf('popup=1') > -1) {
  //       // consoleLog('onpopstate return', router.asPath);
  //       onClose();
  //       window['loc'] = router.asPath;
  //     }
  //   };
  // }, [router.asPath]);
  return (
    <Box w="100%" mx="auto" px={{ base: '16px', sm: '0', md: '0px' }} py="40px">
      <Box
        maxW={{
          base: '100%',
          sm: 'calc(100% - 66px)',
          xl: 1050,
          '2xl': '1036px',
        }}
        w="100%"
        ml={{ base: '0', sm: '30px', md: '50px', xl: 'auto' }}
        mt={{ base: '0', sm: 'auto', xl: '40px' }}
        mr={{ base: '0', sm: 'auto', xl: 'auto' }}
      >
        <Flex dir={{ base: 'column', md: 'column', lg: 'row' }} flexWrap="wrap">
          <Box w={{ base: '100%', md: '100%', xl: '50%' }}>
            <Flex
              flexDir="column"
              maxW={{ base: '100%', xl: 388 }}
              // flexWrap={{ base: 'nowrap', md: 'nowrap', lg: 'wrap' }}
              flexDirection={{
                base: 'column',
                md: 'row',
                lg: 'row',
                xl: 'column',
              }}
              w="100%"
              pt={{ base: '30px', lg: '0px' }}
            >
              <Box
                maxW={{ base: '100%', md: '348px', lg: '100%' }}
                w="100%"
                px={{ base: '0', md: '15px', lg: '0' }}
                dir={lang == 'ar' ? 'rtl' : 'ltr'}
              >
                <StructuredTextParser
                  str={render(sectionContent[0]?.body)}
                  fontFamily="FedraSansStd-book"
                  className="ResearchShowText"
                  fontSize="18px"
                  lineHeight="27px"
                  {...ResearchShowsSt[lang]?.stText}
                  textAlign={lang === 'ar' ? 'right' : 'left'}
                />
              </Box>
              <style global jsx>
                {`
              .ResearchShowText h1,
              .ResearchShowText h2{
              color: #3B444B;
              font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
              font-weight:${ResearchShowsSt[lang]?.heading?.fontWeight};
              font-size: 64px;
              line-height: 64px;
              margin-bottom:30px;
              }

              .ResearchShowText mark{
              color:#282E8F;
              font-family: ${ResearchShowsSt[lang]?.heading?.fontFamily};
              background:none; 
              }
              .ResearchShowText p{
              color:#6D6358;
              }
              @media (max-width:992px) and (min-width:768px){
              .ResearchShowText h1,
              .ResearchShowText h2{
              font-size: 40px;
              line-height: 40px;
              margin-bottom:30px;
              }
              }
              @media (max-width:768px) and (min-width:480px){

              .ResearchShowText h1,
              .ResearchShowText h2{
              color: #3B444B;
              font-size: 40px;
              line-height: 40px;
              margin-bottom:20px;
              }
              .ResearchShowText mark{
              font-size: 16px;
              line-height: 24px;
              }
              .ResearchShowText p{
              font-size: 16px;
              line-height: 24px;
              }

              }
              @media (max-width:480px){
              .ResearchShowText h1,
              .ResearchShowText h2{
              color: #3B444B;
              font-size: 32px;
              line-height: 32px;
              margin-bottom:20px;
              }
              .ResearchShowText mark{
              font-size: 18px;
              line-height: 27px;
              }
              .ResearchShowText p{
              font-size: 18px;
              line-height: 27px;
              }
              `}
              </style>
              <SingleImage
                px={{ base: '0', md: '30px', lg: '0' }}
                w={{ base: 'auto', md: '90%', lg: '100%' }}
                maxW={{ base: '100%', md: '350px', lg: '394px' }}
                // ml={{ base: '0', md: 'auto', lg: '0' }}
                marginRight={{ base: '0', sm: '0', md: 'auto' }}
                ml={{ base: '0', sm: '0', md: 'auto' }}
                marginTop={{ base: '30px', md: '85px', lg: '40px' }}
                h={{ base: 'auto', md: 'auto', lg: 'auto' }}
                sectionContent={sectionContent[1]}
              />
            </Flex>
          </Box>
          <Flex
            flexDir="column"
            w={{ base: '100%', md: '100%', xl: '50%' }}
            alignItems="start"
            justifyContent={{ base: 'start', md: 'center', xl: 'start' }}
            mt={{ base: '40px', md: '50px', lg: '0' }}
            maxW={{ base: '524px', lg: '524px', xl: 'auto' }}
            mx="auto"
          >
            <Heading
              as="h3"
              color="#12166F"
              fontFamily={ResearchShowsSt[lang]?.heading?.fontFamily}
              fontSize={{ base: '20px', md: '28px', lg: '26px' }}
              fontStyle="normal"
              fontWeight={isIndian ? '600' : 'normal'}
              lineHeight={{ base: '19.4px', md: '27.16px', lg: '25.22px' }}
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              w={{
                base: lang === 'ar' ? '94%' : 'auto',
                md: lang === 'ar' ? '94%' : '100%',
                xl: lang === 'ar' ? '94%' : 'auto',
              }}
              mt={{ lg: '40px' }}
              textAlign={{
                base: lang === 'ar' ? 'right' : 'left',
                md: lang === 'ar' ? 'right' : 'center',
                lg: lang === 'ar' ? 'right' : 'center',
              }}
            >
              {sectionContent[2]?.titleText}
            </Heading>

            <Flex
              flexWrap="wrap"
              mt={{ base: '50px', md: '43px', lg: '53px' }}
              gridRowGap={{ base: '0', sm: '50px' }}
              gridColumnGap="20px"
              w="100%"
              justifyContent={{
                base: lang === 'ar' ? 'flex-end' : 'flex-start',
                xl: lang === 'ar' ? 'flex-end' : 'flex-start',
              }}
              alignItems={{ base: 'center', lg: 'start' }}
            >
              {sectionContent[3]?.cards?.map((card, index) => {
                return (
                  <Flex
                    flexDirection={{
                      base:
                        index === sectionContent[3]?.cards.length - 1
                          ? 'column-reverse'
                          : 'column',
                      md: 'row',
                    }}
                    gridGap="20px"
                    minW={{ base: '100%', sm: 'inherit' }}
                  >
                    {lang == 'ar' &&
                      index === sectionContent[3]?.cards?.length - 1 && (
                        <Flex
                          w={{ base: '100%', md: 'auto', lg: '240px' }}
                          h={{ base: '30px', md: '150px' }}
                          // display="inline-flex"
                          alignItems={{ base: 'unset', md: 'center' }}
                          justifyContent="center"
                          mt={{ base: '20px', md: '0' }}
                        >
                          <span onClick={onOpen}>
                            <Text
                              fontFamily={
                                ResearchShowsSt[lang]?.heading?.fontFamily
                              }
                              fontWeight={isIndian ? 550 : 450}
                              ref={btnRef}
                              style={sectionContent[4]}
                              textAlign="center"
                              color="#F37021"
                              cursor="pointer"
                              fontSize="18px"
                              _hover={{
                                textDecoration: 'underline',
                                color: '#BC570F',
                              }}
                            >
                              {lang === 'ar' ? (
                                <>
                                  <ChevronLeftIcon fontSize="24px" />{' '}
                                  {sectionContent[4].buttonText}
                                </>
                              ) : (
                                <>
                                  {sectionContent[4].buttonText}
                                  <ChevronRightIcon fontSize="24px" />
                                </>
                              )}
                            </Text>
                            {/* </NextLink> */}
                          </span>
                        </Flex>
                      )}

                    <Box
                      key={nanoid()}
                      pos="relative"
                      w={{ base: '100%', sm: '240px' }}
                      h={{ base: 'auto', sm: '150px' }}
                      bg="#F1EBDF"
                      mb={{
                        base:
                          index === sectionContent[3].cards.length - 1
                            ? '20px'
                            : '50px',
                        sm: '0',
                      }}
                    >
                      {lang === 'ar' ? (
                        <Box
                          pos="absolute"
                          right={{ base: '20px', sm: '20px', lg: '20px' }}
                          top={{ base: '-30px', lg: '-34px' }}
                        >
                          <LazyLoadImageComponent
                            px="0"
                            h={{ base: '60px', lg: '68px' }}
                            w={{ base: '60px', lg: '68px' }}
                            alt={card.image?.image?.alt}
                            title={card.image?.image?.title}
                            src={card.image?.image?.url}
                          />
                        </Box>
                      ) : (
                        <Box
                          pos="absolute"
                          left={{ base: '26px', sm: '26px', lg: '20px' }}
                          top={{ base: '-30px', lg: '-34px' }}
                        >
                          <LazyLoadImageComponent
                            px="0"
                            h={{ base: '60px', lg: '68px' }}
                            w={{ base: '60px', lg: '68px' }}
                            alt={card.image?.image?.alt}
                            title={card.image?.image?.title}
                            src={card.image?.image?.url}
                          />
                        </Box>
                      )}

                      <Box
                        w="100%"
                        p={0}
                        borderLeft={{
                          base: lang === 'ar' ? 'none' : '7px solid #A81D28',
                          md: lang === 'ar' ? 'none' : '6px solid #A81D28',
                        }}
                        borderRight={{
                          base: lang === 'ar' ? '7px solid #A81D28' : 'none',
                          md: lang === 'ar' ? '6px solid #A81D28' : 'none',
                        }}
                        mt={{ base: '45px', sm: '59px', lg: '61px' }}
                        height={{ base: 'auto', sm: '89px', lg: '89px' }}
                        pb={{ base: '20px', sm: '10px' }}
                        minW={{ base: '280px', sm: 'auto' }}
                      >
                        <StructuredTextParser
                          str={render(card?.image?.description)}
                          p="0 20px"
                          fontFamily={
                            lang === 'ar'
                              ? "'Almarai', sans-serif"
                              : ResearchShowsSt[lang]?.stText?.fontFamily
                          }
                          fontSize="18px"
                          className="res-text"
                          lineHeight="22.05px"
                          noOfLines={3}
                          textAlign={lang === 'ar' ? 'right' : 'left'}
                        />

                        <style>
                          {`
                          .res-text strong {
                            color: #A81D28 !important;
                            font-family: FedraSansStd-medium;
                            font-weight:${isIndian ? 600 : 'normal'};
                          }
                        `}
                        </style>
                      </Box>
                    </Box>
                  </Flex>
                );
              })}

              {lang !== 'ar' && (
                <Flex
                  w={{ base: '100%', md: '240px' }}
                  h={{ base: '70px', md: '150px' }}
                  // display="inline-flex"
                  alignItems={{ base: 'unset', md: 'center' }}
                  justifyContent="center"
                  mt={{ base: '20px', md: '0' }}
                >
                  <span onClick={onOpen}>
                    {/* 
                <NextLink
                // href={refineUrlDomain( sectionContent[4].linkUrl, region, lang)}
                  

                 onClick={onOpen}
                 legacyBehavior
                > */}
                    <Text
                      fontFamily={ResearchShowsSt[lang]?.heading?.fontFamily}
                      fontWeight={isIndian ? 550 : 450}
                      ref={btnRef}
                      style={sectionContent[4]}
                      textAlign="center"
                      color="#F37021"
                      cursor="pointer"
                      fontSize="18px"
                      _hover={{ textDecoration: 'underline', color: '#BC570F' }}
                    >
                      {lang === 'ar' ? (
                        <>
                          <ChevronLeftIcon fontSize="24px" />{' '}
                          {sectionContent[4].buttonText}
                        </>
                      ) : (
                        <>
                          {sectionContent[4].buttonText}
                          <ChevronRightIcon fontSize="24px" />
                        </>
                      )}
                    </Text>
                    {/* </NextLink> */}
                  </span>
                </Flex>
              )}
            </Flex>
            {/* <Flex
              flexWrap={{ base: 'wrap', sm: 'wrap', md: 'wrap' }}
              justifyContent={{ base: 'space-between', md: 'center' }}
              alignItems={{
                base: "stretch", md: "center"
              }}
              alignContent='stretch'
              // h={{ base: '120px', lg: '80px' }}
              my={{ base: '0px', md: '50px', lg: '35px' }}
              w="100%"
              mx={{ base: "auto", md: "0" }}
              mb={{ base: '30px', md: '0' }}
              gridColumnGap="23px"
              gridRowGap="12px"
              maxW={{ base: "95%", sm: "100%", md: "100%" }}
              css={{ '-webkit-grid-column-gap': "23px", "-webkit-row-gap": "12px" }}
            // borderBottom={{ base: '1px solid #CF915E', md: 'none' }}
            >
              <Box w={{ base: '100%', sm: 'auto', md: '30%' }}>
                <SingleImage
                  sectionContent={sectionContent[5]}
                  h="100%"
                  px="0"
                  my={{ base: "10px", md: "0px" }}
                  w={{ base: "auto", md: "100%" }}
                  mx={{ base: '0', md: '0px' }}
                  // flex={1}
                  // w={{ base: '122px', sm: '122px', md: '145px', lg: '150px' }}
                />
              </Box>
              <Box w={{ base: '45%', sm: 'auto', md: '30%' }}>
                <SingleImage
                  sectionContent={sectionContent[6]}
                  h="auto"
                  px="0"
                  my="0px"
                  w={{ base: "auto", md: "100%" }}
                  mx={{ base: '0', md: '0px' }}
                // flex={1}
                // w={{ base: '122px', sm: '122px', md: '145px', lg: '150px' }}
                />
              </Box>
              <Box w={{ base: '45%', sm: 'auto', md: '30%' }}
                mx={{ base: 'auto', sm: "0", md: '0px' }}
              >
                <SingleImage
                  sectionContent={sectionContent[7]}
                  h="auto"
                  w={{ base: "auto", md: "100%" }}
                  px="0"
                  my={{ base: "0", sm: "0", md: "0px" }}
                  mx={{ base: 'auto', sm: "0", md: '0px' }}
                  // flex={1}
                  // w={{ base: '122px', sm: '122px', md: '145px', lg: '150px' }}
                />
              </Box>
            </Flex> */}
          </Flex>
        </Flex>
      </Box>
      <Modal
        w={{ md: '808px', base: '321px' }}
        h={{ md: '85vh', base: 'auto' }}
        // closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={useBreakpointValue({ base: 'full', md: '808px' })}
        finalFocusRef={btnRef}
        // scrollBehavior={scrollBehavior}
        background="#F1EBDF"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="0px"
          background="#F1EBDF"
          w="100%"
          maxW={{ base: '100%', md: '746px', lg: '808px' }}
          my={{ base: '0', md: '8px' }}
          h={{ base: '95%', md: '97%' }}
          overflow="hidden"
        >
          <ModalHeader mt={{ base: '13px', md: 'calc(1rem + 20px)' }} pt="0">
            <Heading
              as="h3"
              pt={{ base: '10px', md: 0 }}
              {...ResearchShowsSt[lang]?.h3}
              dir={lang == 'ar' ? 'rtl' : 'ltr'}
              pr={lang == 'ar' ? '35px' : '0'}
            >
              {sectionContent[2]?.titleText}
            </Heading>
          </ModalHeader>
          <ModalCloseButton
            mt="10px"
            color="E86F34"
            top={{ base: 1, md: 5 }}
            background="transparent"
            _hover={{
              background: 'transparent',
              boxShadow: 'none',
            }}
            _active={{
              background: 'transparent',
              boxShadow: 'none',
            }}
            _focus={{
              background: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19 20.4143L12 13.4143L4.99997 20.4143L3.58576 19.0001L10.5858 12.0001L3.58576 5.00009L4.99997 3.58588L12 10.5859L19 3.58588L20.4142 5.00009L13.4142 12.0001L20.4142 19.0001L19 20.4143Z"
                  fill="#E86F34"
                />
              </svg>
            </Box>
          </ModalCloseButton>
          <ModalBody pr={{ base: '12px', md: 6 }} pl={{ base: '32px', md: 6 }}>
            <Box pb={{ base: '0', md: '0', lg: '30px' }} background="#F1EBDF">
              <Flex
                flexWrap="wrap"
                w="100%"
                justifyContent={{ base: 'start', lg: 'start' }}
                alignItems={{ base: 'start', lg: 'start' }}
                maxH={{ base: '85vh', md: '80vh' }}
                h="100%"
                overflowY="auto"
                pr={{ base: '16px', md: '16px' }}
                className="customScrollbar"
              >
                {sectionContent[3]?.cards?.map((card, index) => {
                  return (
                    <Box
                      w="100%"
                      key={nanoid()}
                      borderBottom={{
                        base:
                          index === sectionContent[3].cards.length - 1
                            ? '0px solid'
                            : 'solid 1px #C5BFB1',
                        md: 'none',
                      }}
                    >
                      <Flex
                        m={{
                          base: '0 0px 0 0px',
                          md: '0 0px 0 10px',
                          lg: '0 0px 0 0px',
                        }}
                        p={{
                          base: '0 0px 0 0px',
                          md: '0 10px 0 0px',
                        }}
                        flexGrow="1"
                        justifyContent={{
                          base: 'center',
                          md: 'start',
                        }}
                        alignItems={{
                          base: 'center',
                          md: 'start',
                        }}
                      >
                        <Box w="100px" height="100%">
                          <LazyLoadImageComponent
                            objectFit="cover"
                            w="100%"
                            h="100%"
                            maxW="65px"
                            src={card.image?.image?.url}
                            alt={card.image?.image?.alt}
                            title={card.image?.image?.title}
                            mt="15px"
                          />
                        </Box>
                        <Box
                          borderBottom={{
                            base: 'none',
                            md: 'solid 1px #C5BFB1',
                          }}
                          w={{
                            base: 'calc(100% - 5%)',
                            md: 'calc(100% - 100px)',
                          }}
                        >
                          <StructuredTextParser
                            {...ResearchShowsSt[lang].popUpTitle}
                            // ml={{ base: '55px' }}
                            str={render(card?.image?.description?.value)}
                            textAlign={lang == 'ar' ? 'right' : 'left'}
                            dir={lang == 'ar' ? 'ltr' : ' rtl'}
                            fontFamily={lang == 'ar' && "'Almarai', sans-serif"}
                          />

                          <StructuredTextParser
                            display={{
                              base: 'none',
                              md: 'block',
                            }}
                            {...ResearchShowsSt[lang].popUpDesc}
                            pt="5px"
                            pb="20px"
                            str={render(card?.description.value)}
                          />
                        </Box>
                      </Flex>
                      <StructuredTextParser
                        display={{
                          base: 'block',
                          md: 'none',
                        }}
                        {...ResearchShowsSt[lang].popUpDesc}
                        pt="5px"
                        pb="10px"
                        str={render(card?.description.value)}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ResearchShows;
