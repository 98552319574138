/* Built In Imports */

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ProgramFeeSt } from '@ieo/Common/CompLangConfig';

/* Services */
/* Styles */

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns ProgramFee
 */
const ArmyOfferFees = ({ sectionContent, region, lang, prgData, pageData }) => {
  const priceData = Object?.values(prgData || '');
  //console.log('ArmyOfferFees', sectionContent, prgData);
  // const pageConfigVal =
  //   pageData?.pageConfig?.length &&
  //   !pageData?.pageConfig[0]?.value?.disabled &&
  //   pageData?.pageConfig[0].value;

  return (
    <Box
      bg="url(https://static.sadhguru.org/d/46272/1715585178-army-offer-bg-desk.jpg) #F1EBDF"
      id="ieo-program-fees"
      bgSize="100% 100%"
      bgPosition="center"
      py={10}
      bgRepeat="no-repeat"
    >
      <Flex maxW={1080} bgRepeat="no-repeat" mx={'auto'}>
        <Flex w="full" mx="auto" flexWrap="wrap" alignItems="center">
          <Flex
            w="full"
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent="space-between"
            gridGap="20px"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
              my={{ base: '20px', md: '0px' }}
              px={'20px'}
              maxW="480px"
              w="full"
            >
              <Box
                color="#3B444B"
                fontFamily="'FedraSansStd-medium'"
                lineHeight={{ base: '40px', md: '65px' }}
                fontSize={{ base: '32px', md: '64px' }}
                textAlign="left"
                mb="20px"
              >
                {sectionContent?.[0]?.titleText}
              </Box>

              <StructuredTextParser
                mt={{ base: '0px', md: '10px' }}
                str={render(sectionContent?.[1].body)}
                {...ProgramFeeSt[lang].p}
                className="pStrong"
                fontSize={{ base: '18px', md: '24px' }}
                color="#3B444B"
                textAlign={'left'}
              />
              <StructuredTextParser
                mt="10px"
                str={render(sectionContent?.[2].body)}
                {...ProgramFeeSt[lang].p1}
                maxW="auto"
                color="#3B444B"
              />
            </Box>
            <Flex
              bg="#fff"
              boxShadow="0px 29px 36px rgba(0, 0, 0, 0.05)"
              bgRepeat="no-repeat"
              bgSize="cover"
              flexGrow="1"
              py={{ base: '20px', md: '30px' }}
              px={{ base: '20px', xl: '60px' }}
              flexDir="column"
              position="relative"
              m={{
                base: '0px 15px 30px',
                xl: '30px',
              }}
              alignItems="center"
              alignSelf="center"
            >
              <Flex
                alignItems={{ base: 'flex-start', md: 'center' }}
                gridGap="20px"
                css={{ '-webkit-grid-gap': '20px' }}
              >
                <Flex>
                  <LazyLoadImageComponent
                    src="https://static.sadhguru.org/d/46272/1710781775-pngfind-1.png"
                    alt=""
                  />
                </Flex>
                <StructuredTextParser
                  mt="0"
                  str={render(sectionContent?.[3]?.body)}
                  {...ProgramFeeSt[lang].feeDiscount1}
                  maxW="auto"
                  color="#6D6358"
                  fontSize={{ base: '16px', md: '18px' }}
                />
              </Flex>

              {priceData?.length > 0 && (
                <Flex
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <>
                    <Box
                      borderBottom="2px solid #6D6358"
                      opacity={0.3}
                      my="20px"
                      w="full"
                    />
                    <StructuredTextParser
                      str={`${priceData[0]?.curr} ${priceData[0]?.pgmFee}`}
                      maxW="none"
                      textDecoration="line-through"
                      {...ProgramFeeSt[lang].feeDiscount2}
                      color="#6D6358"
                    />

                    <StructuredTextParser
                      color="#CD9F00"
                      fontSize={{ base: '30px', md: '36px' }}
                      {...ProgramFeeSt[lang].feeDiscount3}
                      textAlign="center"
                      px={{ base: 4, sm: 10 }}
                      str={render(sectionContent?.[9]?.body)}
                      mt="0px"
                      whiteSpace="nowrap"
                    />
                  </>
                </Flex>
              )}

              {/* <Image
                  src={pageConfigVal?.prgFees?.offerBgFlower?.desktop}
                  alt=""
                  position="absolute"
                  bottom={{ base: '20px', md: '15px' }}
                  left="0px"
                /> */}
            </Flex>
          </Flex>
        </Flex>
        <style global>
          {`
            .thanksgiving:hover svg {
              fill: #E86F34;
              color: #E86F34;
            }
            .thanksgiving:hover {
              background: '#FAE4B8';
              color: '#FFFFFF';
            }
            .pStrong p  {
              color:#3B444B
            }
            .pStrong p strong {
              color:#3652B8
            }
              `}
        </style>
      </Flex>
    </Box>
  );
};

export default ArmyOfferFees;
