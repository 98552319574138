/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { refineUrlDomain } from '@components/Utility/utils';

/**
 *
 * @param {Object} sectionContent
 * @param {string} region
 * @param {string} lang
 * @returns {ReactElement} component
 */
const AdBanner = ({ sectionContent, region, lang, width, height, my }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box
      maxW="1330px"
      width="100%"
      m={{ base: '0px auto 45px', md: '10px auto 45px' }}
    >
      <Box
        textAlign="center"
        position="relative"
        mx="auto"
        my={{ base: '0px', md: my || 10 }}
        width={width || '100%'}
        height={height || 'auto'}
      >
        <NextLink
          href={refineUrlDomain(sectionContent?.linkUrl, region, lang)}
          passHref
          legacyBehavior
        >
          <Link
            target={
              sectionContent?.linkUrl?.indexOf('http') > -1 &&
              sectionContent?.linkUrl.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            aria-label="Read more"
          >
            <LazyLoadImageComponent
              alt={
                sectionContent?.image?.title ||
                sectionContent?.image?.alt ||
                sectionContent?.mobileImage?.alt ||
                ''
              }
              title={sectionContent?.image?.title}
              textAlign="center"
              src={
                isMobile
                  ? sectionContent?.mobileImage?.url ||
                    sectionContent?.image?.url
                  : sectionContent?.image?.url
              }
              height="auto"
              w="100%"
            />
          </Link>
        </NextLink>
      </Box>
    </Box>
  );
};

export default AdBanner;
