/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */

/**
 * Renders the SingleImage Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param sectionContent.my
 * @param sectionContent.px
 * @param sectionContent.w
 * @param sectionContent.h
 * @param sectionContent.maxWidth
 * @param sectionContent.mx
 * @returns {ReactElement} SingleImage Card component
 */

const BannerImage = ({ sectionContent, w, h, maxWidth, ...props }) => {
  const isLessThen = useMediaQuery({ maxWidth: 560 });

  return sectionContent ? (
    <Box textAlign="center" {...props}>
      {/* {sync && ( */}
      <Image
        h={h || '100%'}
        alt={sectionContent.image?.alt}
        title={sectionContent.image?.title}
        objectFit={
          props.objectFit || { base: 'cover', sm: 'cover', md: 'cover' }
        }
        objectPosition="top"
        loading="eager"
        // src={sectionContent.image?.url}
        src={
          isLessThen
            ? sectionContent.mobileImage?.url != null
              ? sectionContent.mobileImage?.url
              : sectionContent.image?.url
            : 'https://static.sadhguru.org/d/46272/1700834565-sadhguru-xl-image.png'
        }
        w={w || 'auto'}
        maxWidth={maxWidth || '100%'}
      />
      {/* )} */}
    </Box>
  ) : null;
};

export default BannerImage;
