/* Built In Imports */
import dynamic from 'next/dynamic';

/* External Imports */
import { Box, Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
const UpcomingProgramsV2 = dynamic(() =>
  import('@components/UI/UpcomingProgramsV2')
);

/* Services */
/* Styles */

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns UpcomingPrograms
 */
const UpcomingPrograms = ({ sectionContent, region, language }) => {
  return (
    <Box maxW="1330px" m="0 auto" w="95%" id="upcoming-programs">
      <Box
        maxW={{ base: '100%', md: '1065px' }}
        width={'100%'}
        mx="auto"
        mt="4rem"
        mb="2rem"
      >
        <Heading
          textAlign={{ base: 'left', md: 'left' }}
          fontSize={{ base: '32px', md: '64px' }}
          lineHeight={{ base: '40.8px', md: '80px' }}
        >
          {sectionContent[0].text}
        </Heading>
      </Box>
      <UpcomingProgramsV2
        sectionContent={sectionContent[1]}
        region={region}
        language={language}
        isLeftMenu={false}
      />
    </Box>
  );
};

export default UpcomingPrograms;
