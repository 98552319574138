/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Service */

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const DonateCardSection = ({ sectionContent, region, lang }) => {
  return (
    <>
      <Box bg="#F1EBDF" position="relative" ml={{ base: '0rem', md:'0.5rem' }}>
        <Text
          color="#433F3C"
          fontFamily="'FedraSansStd-medium'"
          fontSize={{ base: '20px', md: '24px' }}
        >
          {sectionContent[0]?.title}
        </Text>

        <StructuredTextParser
          mt="15px"
          str={render(sectionContent[0]?.description)}
          fontFamily="'FedraSansStd-book'"
          fontSize="18px"
          maxW="1000px !important"
          className="paLink"
          mb={{ base: '2rem', md: '0px' }}
        />
        <Link
          href={sectionContent[0]?.buttonLink}
          target="_blank"
          color="#CE6113"
          fontFamily="'FedraSansStd-medium'"
          textDecor="underline"
          _hover={{ textDecor: 'none' }}
          position="absolute"
          bottom="0"
        >
          {sectionContent[0]?.buttonText}
        </Link>
      </Box>
      <style global jsx>
        {`
          .paLink p {
            padding-bottom: 8px;
          }
          .paLink p a {
            color: #ce6113;
            text-decoration: underline;
          }
          .paLink p a:hover {
            color: #ce6113;
            text-decoration: none;
          }
        `}
      </style>
    </>
  );
};
export default DonateCardSection;
