/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Image, Img } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import filter from 'lodash/filter';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import { usePageType } from '@hooks/usePageType';
import { ProgramFeeSt } from '@ieo/Common/CompLangConfig';

/* Services */
/* Styles */

/**
 *
 * @param {Object} prgData
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @param {Object} pageData
 * @returns ProgramFee
 */
const FeesYouthOffer = ({
  sectionContent,
  region,
  lang,
  prgData,
  pageData,
}) => {
  const priceData = Object?.values(prgData || '');
  const { isGiftPage } = usePageType();
  const adharValidation = sectionContent?.find(
    e => e.fieldId === 'adhar-validation'
  );
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const router = useRouter();

  let PriceDataList = [];
  if (region === 'in') {
    config.orderedLang.forEach(language => {
      const price = priceData.filter(item => {
        return item.lang === language.toUpperCase();
      });
      if (price.length > 0) {
        PriceDataList.push(price[0]);
      }
    });
  } else {
    PriceDataList = [...priceData];
  }
  const [discountText, setDiscountText] = useState({});

  useEffect(() => {
    if (priceData?.length) {
      let filteredText = filter(pageConfigVal?.discounText, function (item) {
        return (
          item.code === priceData[0].coupon?.couponCode &&
          item.lang?.toLowerCase() === lang.toLowerCase()
        );
      });
      if (filteredText?.length) {
        setDiscountText(filteredText[0]);
      }
    }
  }, [priceData]);

  useEffect(() => {
    if (router.asPath.indexOf('/gift') > -1) {
      setIsGiftingFlow(true);
    }
  }, [router.asPath]);

  return (
    <Box
      bg="url(https://static.sadhguru.org/d/46272/1710743888-1710584276-pbg1.jpg) #F1EBDF"
      id="ieo-program-fees"
    >
      <Flex maxW={1570} bgRepeat="no-repeat" id="ieo-program-fees">
        <Flex width="30%" display={{ base: 'none', xl: 'flex' }}>
          <Image
            src={
              'https://www.datocms-assets.com/46272/1710574442-ie-enroll-img.png'
            }
            objectFit="cover"
            objectPosition="0px"
          />
        </Flex>
        <Flex
          w="full"
          mx={{ base: '15px', md: '30px', lg: 'auto' }}
          flexWrap="wrap"
          p={{ md: '24px 0px', lg: '24px 30px 24px 0px' }}
        >
          <Flex
            px={{ base: '0', xl: 0 }}
            flexDir={{ base: 'column', lg: 'row' }}
            alignItems="center"
            justifyContent="center"
            gridGap={{ base: '30px', md: '40px', '2xl': '70px' }}
            my={{ base: '15px', md: 15 }}
            css={{
              '-webkit-grid-gap': '40px',
              '@media screen and (min-width: 768px)': {
                '-webkit-grid-gap': '80px',
              },
            }}
          >
            
            <Box
              color="#fff"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexDirection={{ base: 'column', md: 'column' }}
              textAlign="center"
              m={{ base: '0', md: '20px' }}
            >
              <Img
                src={sectionContent?.[13]?.image?.url}
                alt=""
                width={{ base: '300px', lg: '500px' }}
                mx="auto"
              />
              <Box
                p={6}
                height={'auto'}
                display="flex"
                alignItems="start"
              >
                <StructuredTextParser
                  str={render(sectionContent?.[1].body)}
                  {...ProgramFeeSt[lang].p}
                  maxW="auto"
                  mt="0px"
                  className="pStrong"
                  fontSize={{ base: '18px', md: '24px' }}
                  color="#3B444B"
                  textAlign="center"
                />
                <StructuredTextParser
                  mt="0px"
                  str={render(sectionContent?.[2].body)}
                  {...ProgramFeeSt[lang].p1}
                  maxW="auto"
                  color="#3B444B"
                />
              </Box>
            </Box>

            {(priceData[0]?.coupon?.couponCode ||
              priceData[0]?.coupon?.categoryType) && (
              <Flex
                bg={`url(${pageConfigVal?.prgFees?.offerBg?.desktop}) #fff`}
                bgRepeat="no-repeat"
                bgSize="cover"
                flexGrow="1"
                py={{ base: '20px', md: '30px' }}
                px={{ base: '20px', xl: '30px' }}
                flexDir="column"
                position="relative"
                m={{ base: '0px 30px 30px', xl: '30px' }}
                maxW="540px"
              >
                <Flex
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  gridGap="20px"
                  css={{ '-webkit-grid-gap': '20px' }}
                >
                  <Flex>
                    <LazyLoadImageComponent src="https://static.sadhguru.org/d/46272/1710781775-pngfind-1.png" />
                  </Flex>
                  <StructuredTextParser
                    mt="0"
                    str={render(sectionContent?.[3]?.body)}
                    {...ProgramFeeSt[lang].feeDiscount1}
                    maxW="auto"
                    color="#6D6358"
                    fontSize={{ base: '16px', md: '18px' }}
                  />{' '}
                </Flex>

                <Box borderBottom="1px solid #6D6358" opacity={0.3} mt="20px" />

                {priceData?.length > 0 && (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StructuredTextParser
                      str={`${priceData[0]?.curr} ${priceData[0]?.pgmFee}`}
                      maxW="none"
                      textDecoration="line-through"
                      {...ProgramFeeSt[lang].feeDiscount2}
                      color="#6D6358"
                    />
                    <StructuredTextParser
                      str={render(additionalText?.body)?.replace(
                        '#num#',
                        priceData[0]?.coupon.offerValue
                      )}
                      color="#BD0015"
                      fontSize={{ base: '24px', md: '32px' }}
                      fontFamily="'FedraSansStd-A-medium'"
                      mt="0px"
                      textAlign="center"
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDir={{ base: 'column', lg: 'row' }}
                    >
                      <StructuredTextParser
                        str={`${priceData[0]?.curr} ${priceData[0]?.discountedPrice}`}
                        maxW="none"
                        textDecor="line-through"
                        mt="0px"
                        {...ProgramFeeSt[lang].feeDiscount3}
                        color="#CD9F00"
                        fontSize={{ base: '18px', md: '22px' }}
                      />
                      <Box display="flex">
                        <StructuredTextParser
                          str={`${priceData[0]?.curr} ${priceData[0]?.coupon?.postCouponPrice}`}
                          maxW="none"
                          // textDecor="line-through"
                          mt="0px"
                          {...ProgramFeeSt[lang].feeDiscount3}
                          color="#CD9F00"
                          fontSize={{ base: '30px', md: '36px' }}
                          pl="10px"
                        />
                        {region === 'in' && (
                          <Box
                            color="#CD9F00"
                            fontSize={{ base: '18px', md: '36px' }}
                            fontFamily="'FedraSansStd-book'"
                          >
                            *
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      fontSize={{ base: '26px', md: '30px' }}
                      color="#BD0015"
                      fontFamily="'FedraSansStd-A-medium'"
                      textAlign="center"
                    >
                      {discountText?.text}
                    </Box>
                    {priceData?.length > 1 && (
                      <>
                        <Box
                          borderBottom="1px solid #6D6358"
                          opacity={0.3}
                          mt="20px"
                          w="full"
                        />
                        <Box>
                          {prgFeeDiscount4 && (
                            <StructuredTextParser
                              str={render(prgFeeDiscount4?.body)}
                              fontSize={{ base: '22px', md: '30px' }}
                              color="#A69786"
                              fontFamily="'FedraSansStd-medium'"
                              mt="20px"
                            />
                          )}
                        </Box>
                        <StructuredTextParser
                          str={`${priceData[1]?.curr} ${priceData[1]?.pgmFee}`}
                          maxW="none"
                          textDecoration="line-through"
                          {...ProgramFeeSt[lang].feeDiscount2}
                          color="#6D6358"
                          mt="0px"
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDir={{ base: 'column', lg: 'row' }}
                        >
                          <StructuredTextParser
                            str={`${priceData[1]?.curr} ${priceData[1]?.discountedPrice}`}
                            maxW="none"
                            textDecor="line-through"
                            mt="0px"
                            {...ProgramFeeSt[lang].feeDiscount3}
                            color="#CD9F00"
                            fontSize={{ base: '18px', md: '22px' }}
                          />
                          <Box display="flex">
                            <StructuredTextParser
                              str={`${priceData[1]?.curr} ${priceData[1]?.coupon?.postCouponPrice}`}
                              maxW="none"
                              //textDecor="line-through"
                              mt="0px"
                              {...ProgramFeeSt[lang].feeDiscount3}
                              color="#CD9F00"
                              fontSize={{ base: '30px', md: '36px' }}
                              pl="10px"
                            />
                            <Box
                              color="#CD9F00"
                              fontSize={{ base: '18px', md: '36px' }}
                              fontFamily="'FedraSansStd-book'"
                            >
                              *
                            </Box>
                          </Box>
                        </Box>
                        {adharValidation && (
                          <Box display="flex" alignItems="center">
                            <Box
                              color="#CD9F00"
                              fontSize={{ base: '18px', md: '32px' }}
                              fontFamily="'FedraSansStd-book'"
                            >
                              *
                            </Box>
                            <StructuredTextParser
                              str={render(adharValidation?.body)}
                              color="#6D6358"
                              fontSize="12px"
                              mt="5px"
                              fontFamily="'FedraSansStd-book'"
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </Flex>
                )}

                <Image
                  src={pageConfigVal?.prgFees?.offerBgFlower?.desktop}
                  alt=""
                  position="absolute"
                  bottom={{ base: '20px', md: '15px' }}
                  left="0px"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <style global>
          {`
            .thanksgiving:hover svg {
              fill: #E86F34;
              color: #E86F34;
            }
            .thanksgiving:hover {
              background: '#FAE4B8';
              color: '#FFFFFF';
            }
            .pStrong p  {
              color:#3B444B
            }
            .pStrong p strong {
              color:#2A2A6E
            }
              `}
        </style>
      </Flex>
    </Box>
  );
};

export default FeesYouthOffer;
