/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { render } from 'datocms-structured-text-to-html-string';
import { WhoCanDoV5St } from '../Common/CompLangConfig';

/* Services */
/* Config */

/**
 * @param {Object} sectionContent - Data
 * @param {string} lang - Language of the page
 * @returns
 */

const styles = {
  contentText: {
    p: {
      padding: '4px 0',
      fontFamily: 'FedraSansStd-book',
      fontStyle: 'italic',
      a: {
        color: '#F37021',
        textDecoration: 'underline',
      },
    },
  },
};

const ProgramOpenCorporates = ({ sectionContent, lang }) => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = sectionContent
      .filter(item => item._modelApiKey === 'single_image')
      .map(item => item);
    setImageUrls(urls);
  }, [sectionContent]);

  return (
    <Box
      bg={`url(${sectionContent[8]?.image?.url}) top right no-repeat`}
      bgSize={{ base: '70%', md: 'auto' }}
      position="relative"
      _before={{
        content: "''",
        background: `url(${sectionContent[9]?.image?.url}) bottom left no-repeat`,
        position: 'absolute',
        width: '100%',
        height: 400,
        bottom: '0px',
        left: 0,
        bgSize: { base: '70%', md: 'auto' },
      }}
      maxW={'100%'}
      py={8}
      px={{ base: 4, md: 8 }}
    >
      <Box maxW={'1031px'} mx={'auto'} position="relative" zIndex={1}>
        <Text
          mt={{ base: '2.5rem', md: '4rem' }}
          mb={{ base: '1rem', md: '2rem' }}
          color={'#433F3C'}
          fontFamily={'FedraSansStd-medium'}
          fontSize={{ base: '32px', md: '64px' }}
          lineHeight={{ base: '35.2px', md: '64px' }}
          fontWeight={'500'}
        >
          {sectionContent[0].text}
        </Text>
        <Grid
          gap={{ base: 4, md: 8 }}
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        >
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Box maxW="100%" maxH="100%">
              <Box
                bgImage={{
                  base: sectionContent[1]?.mobileImage.url,
                  md: sectionContent[1]?.image?.url,
                }}
                width={{ base: '280px', md: '100%' }}
                height={{ base: '250px', md: '470px' }}
                display="flex"
                alignItems="flex-end"
                bgSize="cover"
                bgPos="top center"
                p={{ base: '15px', md: '30px 40px' }}
                justifyContent={{ base: 'flex-start', md: 'flex-end' }}
                mx="auto"
              >
                <Box w={{ base: '100%', md: '60%' }}>
                  <StructuredTextParser
                    str={render(sectionContent[5]?.body)}
                    py={{ base: 4, sm: 2, md: 2 }}
                    color={'#fff'}
                    fontFamily={'FedraSansStd-medium'}
                    fontSize={{ base: '14px', md: '24px' }}
                    lineHeight={{ base: '18px', md: '33.6px' }}
                    fontWeight={'500'}
                    textAlign={{ base: 'left', md: 'right' }}
                  />
                </Box>
              </Box>
            </Box>
          </GridItem>
          {sectionContent.slice(2, 4)?.map((url, index) => (
            <GridItem key={index} colSpan={{ base: 1, md: 1 }}>
              <Box
                bgImage={{
                  base: url?.mobileImage?.url,
                  md: url?.image?.url,
                }}
                width={{ base: '280px', md: '100%' }}
                height={{ base: '250px', md: '470px' }}
                display="flex"
                alignItems="flex-end"
                bgSize="cover"
                bgPos="top center"
                p={{ base: '5px 15px', md: '30px 40px' }}
                mx="auto"
              >
                <StructuredTextParser
                  str={render(sectionContent[6 + index]?.body)}
                  bottom={{ base: 3, sm: 3, lg: 12 }}
                  left={{ base: 4, sm: 6, lg: 10 }}
                  py={{ base: 4, sm: 2, md: 2 }}
                  color={'#fff'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={{ base: '14px', md: '24px' }}
                  lineHeight={{ base: '18px', md: '33.6px' }}
                  fontWeight={'500'}
                />
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
      <Box
        maxW={'1031px'}
        mx={'auto'}
        textAlign={'center'}
        mt={{ base: '1rem', md: '2.5rem' }}
        mb={{ base: '1rem', md: '2rem' }}
      >
        <StructuredTextParser
          str={render(sectionContent[4]?.body)}
          color="#000"
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          fontSize={{ base: '16px', md: '22px' }}
          fontWeight="400"
          mx="auto"
          sx={lang === 'ta' ? styles.descContent : styles.contentText} // Apply the styles here
          {...WhoCanDoV5St[lang]}
        />
      </Box>
    </Box>
  );
};

export default ProgramOpenCorporates;
