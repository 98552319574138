/* External Imports */
import { Box, useBreakpointValue } from '@chakra-ui/react';

/* Components */

/* Service */
import DonateCardSection from './DonateCardSection';
import ScholarshipsSection from './ScholarshipsSection';
import SpecialPriceSection from './SpecialPriceSection';

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns
 */
const ProgramCardSection = ({
  sectionContent,
  lang,
  region,
  scholarshipsRef,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const programCard = sectionContent[sectionContent.length - 1]?.cards;
  return (
    <Box
      ref={scholarshipsRef}
      id="ieo-program-section"
      maxW="1030px"
      mx={{ base: '20px', xl: 'auto' }}
      mt={{ base: '100px', md: '150px' }}
      mb={{ base: '165px', md: '175px' }}
    >
      <Box
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        gap="1rem"
      >
        <SpecialPriceSection
          sectionContent={sectionContent}
          region={region}
          lang={lang}
          programCard={programCard}
        />

        <Box
          maxW="1030px"
          p={{ base: '30px 25px', md: '35px 25px' }}
          textAlign="left"
          bg="#F1EBDF"
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <ScholarshipsSection
            sectionContent={programCard}
            region={region}
            lang={lang}
          />

          <Box
            width={isMobile && '100%'}
            m={isMobile ? '2rem 0rem' : '0rem 1rem'}
            borderRight={
              isMobile
                ? 'none'
                : { base: '1px solid #6D6358', md: '2px solid #6D6358' }
            }
            borderBottom={
              isMobile
                ? { base: '1px solid #6D6358', md: '2px solid #6D6358' }
                : 'none'
            }
            opacity={0.3}
          />

          <DonateCardSection
            sectionContent={programCard}
            region={region}
            lang={lang}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ProgramCardSection;
