/* Built In Imports */
import { usePathname } from 'next/navigation';
import { useState } from 'react';

/* External Imports */
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ProgramTestimonialSt } from '@ieo/Common/CompLangConfig';

/* Services */
/* Styles */

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const SliderExperienceCorporates = ({
  sectionContent,
  lang,
  color,
  paddingBottom,
  heightBase,
  arrowTop,
  left,
  right,
  region,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  // console.log('slider', sectionContent);
  // console.log('ProgramTestimonialSt', ProgramTestimonialSt, ProgramTestimonialSt[lang].h1);
  const pathname = usePathname();
  const urlV3 = pathname === `/${region}/${lang}/inner-engineering-v3`;

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        bgColor={
          Math.ceil(activeSlide + 1) === sectionContent?.testimonials?.length
            ? '#ffffff'
            : '#F37021'
        }
        onClick={onClick}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={{ base: '30px', md: '0px' }}
        pos={{ base: 'sticky', md: 'absolute' }}
        top={{
          base: arrowTop || '100%',
          md: '-90px',
          lg: '-102px',
          xl: '-102px',
        }}
        right={{
          base: right || '20%',
          sm: right || '30%',
          md: '40px',
          xl: '-70px',
          '2xl': '-80px',
        }}
        left={{ base: '70%', sm: '60%', md: 'auto' }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor:
            Math.ceil(activeSlide + 1) === sectionContent?.testimonials?.length
              ? '#ffffff'
              : '#BC570F',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.83337 11.3333L7.16671 6.99996L2.83337 2.66663"
            stroke={
              Math.ceil(activeSlide + 1) ===
              sectionContent?.testimonials?.length
                ? '#F37021'
                : '#ffffff'
            }
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        cursor="pointer"
        bgColor={
          Math.ceil(activeSlide + 1) === sectionContent?.testimonials?.length
            ? '#F37021'
            : '#ffffff'
        }
        onClick={onClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos={{ base: 'absolute', md: 'absolute' }}
        top={{
          base: arrowTop || '100%',
          md: '-90px',
          lg: '-102px',
          xl: '-102px',
        }}
        right={{ base: '0', md: '174px', xl: '175px' }}
        left={{
          base: left || '20%',
          sm: left || '30%',
          md: '75.5%',
          lg: '81.4%',
          xl: '93%',
          '2xl': '94%',
        }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor:
            Math.ceil(activeSlide + 1) === sectionContent?.testimonials?.length
              ? '#BC570F'
              : '#ffffff',
        }}
        bottom={{ base: '0', md: 'inherit' }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.16663 11.6678L2.83329 7.33443L7.16663 3.0011"
            stroke={
              Math.ceil(activeSlide + 1) ===
              sectionContent?.testimonials?.length
                ? '#ffffff'
                : '#F37021'
            }
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    arrow: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //console.log('logo==>', sectionContent);

  return (
    <Flex
      maxW="1330px"
      flexDir="column"
      alignItems="center"
      w="full"
      justify="center"
      mb={{ md: '100px', xl: paddingBottom || '146px' }}
    >
      <Box
        maxW={1030}
        w="full"
        pos="relative"
        py="20px"
        px={{ base: '16px', xl: 0 }}
      >
        <Flex
          justifyContent="space-between"
          w={{ base: 'full', md: '87.5%', lg: '79.6%', xl: '90%' }}
          alignItems={{ md: 'baseline', lg: 'center' }}
          minH={{ base: '60px', md: '150px' }}
        >
          <Heading
            as="h2"
            pt={{ base: '20px', md: 0 }}
            pb={{ base: '16px', md: '10px' }}
            pr={{ md: '14%', lg: '6%', xl: '2%' }}
            textAlign="left"
            color={color || '#12166F'}
            letterSpacing={{ base: '-1px', md: '-0.66px' }}
            lineHeight={{ base: '29.96px', md: '42.68px' }}
            {...ProgramTestimonialSt[lang].h2}
            w={{ base: 'full', md: 'auto' }}
            dir={lang == 'ar' ? 'rtl' : 'ltr'}
          >
            {' '}
            {sectionContent?.title?.[0]?.titleText}
          </Heading>
          <Text
            pos={{ base: 'absolute', md: 'static' }}
            bottom={{ base: '30px', md: '155px' }}
            left="47%"
            color={color || '#433F3C'}
            {...ProgramTestimonialSt[lang].countNumber}
            display={{ base: urlV3 ? 'none' : 'block', md: 'block' }}
          >
            {Math.ceil(activeSlide + 1)}/{sectionContent?.testimonials?.length}
          </Text>
        </Flex>
        <Slider {...settings}>
          {sectionContent?.testimonials?.map((item, index) => (
            <Box
              w="full"
              key={index}
              pb={{ base: '0px', xl: paddingBottom || '0px' }}
            >
              <Box
                position="relative"
                display="flex"
                gridGap="30px"
                css={{ '-webkit-grid-gap': '30px' }}
                w={{ base: '90%', lg: 'full' }}
                mx="auto"
              >
                <Image
                  src={item?.photo?.url}
                  alt={item?.photo?.alt || ''}
                  title={item?.photo?.title || ''}
                  mt={{ base: '12px', md: '30px' }}
                  w={{ base: '101px', md: '256px' }}
                  h={{ base: '131px', md: '328px' }}
                  pos="absolute"
                />

                <Flex
                  bg="#fff"
                  flexDirection="column"
                  m={{
                    base: '0 0px 0 20px',
                    md: '0 40px 0 100px',
                    lg: '0 40px 0 100px',
                  }}
                  p={{ base: '8px 16px 16px 20px', md: '0px 40px 40px 196px' }}
                  flexGrow="1"
                  boxShadow="0 12px 14px -15px #6D6358"
                  overflow="auto"
                  minH={{ base: '400px', xl: '424px' }}
                >
                  <Box
                    // ml={{ base: '80px', md: 0 }}
                    minH="100px"
                    h="auto"
                    dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    pl={{ base: '75px', md: '0' }}
                    pt={{ base: '10px', lg: '30px' }}
                  >
                    <StructuredTextParser
                      str={render(item?.testimonialText)}
                      {...ProgramTestimonialSt[lang].p}
                      color="#6D6358"
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    />
                    <StructuredTextParser
                      str={render(item?.testimonialTitle)}
                      {...ProgramTestimonialSt[lang].p}
                      color="#6D6358"
                      pt={{ base: '10px', md: '10px', lg: '10px' }}
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    />

                    <Text
                      // pt={{ base: '10px', md: '40px', lg: '70px' }}
                      color="#6D6358"
                      {...ProgramTestimonialSt[lang].title}
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    >
                      {' '}
                      {item?.userName}
                    </Text>

                    <StructuredTextParser
                      mt="0px"
                      str={render(item?.userDescription)}
                      {...ProgramTestimonialSt[lang].subTitle}
                      maxW="auto"
                      color="#12166F"
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Flex>
  );
};

export default SliderExperienceCorporates;
