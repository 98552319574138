/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parser from 'html-react-parser';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Configs */
import { StoriesSt } from '@ieo/Common/CompLangConfig';

/* Styles */
/* Services */

/**
 *
 * @param {Object} sectionContent
 * @param {String} lang
 * @returns {ReactElement} Stories
 */
const Stories = ({ sectionContent, lang, pageData }) => {
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;

  const [activeSlide, setActiveSlide] = useState(0);
  const numberVariant = useBreakpointValue({
    base: 1,
    lg: 2,
  });

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        bgColor={
          Math.ceil(activeSlide + numberVariant) ===
          sectionContent?.[0]?.testimonials?.length
            ? '#ffffff'
            : '#F37021'
        }
        onClick={onClick}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos={{ base: 'sticky', md: 'absolute' }}
        top={{ base: '100%', md: '-90px', lg: '-102px' }}
        mt={{ base: '30px', md: '0px' }}
        right={{
          base: '20%',
          sm: '30%',
          md: '40px',
          xl: '-70px',
          '2xl': '-50px',
        }}
        left={{ base: '70%', md: 'auto' }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor:
            Math.ceil(activeSlide + numberVariant) ===
            sectionContent?.[0]?.testimonials?.length
              ? '#ffffff'
              : '#BC570F',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.83337 11.3333L7.16671 6.99996L2.83337 2.66663"
            stroke={
              Math.ceil(activeSlide + numberVariant) ===
              sectionContent?.[0]?.testimonials?.length
                ? '#F37021'
                : '#ffffff'
            }
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        cursor="pointer"
        bgColor={
          Math.ceil(activeSlide + numberVariant) ===
          sectionContent?.[0]?.testimonials?.length
            ? '#F37021'
            : '#ffffff'
        }
        onClick={onClick}
        display="flex"
        alignItems="center"
        justifyContent="center"
        pos={{ base: 'sticky', md: 'absolute' }}
        top={{ base: '100%', md: '-90px', lg: '-102px', xl: '-102px' }}
        right={{ base: '0px', md: '174px', xl: '175px' }}
        left={{
          base: '20%',
          sm: '30%',
          md: '75.5%',
          lg: '81.4%',
          xl: '93%',
          '2xl': '91%',
        }}
        zIndex="99"
        w={{ base: '44px', md: '64px' }}
        h={{ base: '44px', md: '64px' }}
        _hover={{
          boxShadow: '0 4px 10px -5px #6D6358',
          bgColor:
            Math.ceil(activeSlide + numberVariant) ===
            sectionContent?.[0]?.testimonials?.length
              ? '#BC570F'
              : '#ffffff',
        }}
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.16663 11.6678L2.83329 7.33443L7.16663 3.0011"
            stroke={
              Math.ceil(activeSlide + numberVariant) ===
              sectionContent?.[0]?.testimonials?.length
                ? '#ffffff'
                : '#F37021'
            }
            stroke-width="3"
            stroke-linecap="square"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    arrow: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    lazyLoad: false,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [activeCard, setActiveCard] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg={{
        base: pageConfigVal
          ? `url(${pageConfigVal?.stories?.bgImage?.mob}) repeat-x rgb(241 235 223)`
          : `rgb(241 235 223)`,
        sm: pageConfigVal
          ? `url(${pageConfigVal?.stories?.bgImage?.tab}) repeat-x rgb(241 235 223)`
          : `rgb(241 235 223)`,
        md: pageConfigVal
          ? `url(${pageConfigVal?.stories?.bgImage?.desktop}) repeat-x rgb(241 235 223)`
          : `rgb(241 235 223)`,
        lg: pageConfigVal
          ? `url(${pageConfigVal?.stories?.bgImage?.bigScreen}) repeat-x rgb(241 235 223)`
          : `rgb(241 235 223)`,
      }}
      // bgRepeat="repeat-x"
      bgPos="top"
      // bgSize={{ base: 'auto 50px', md: 'auto 72px' }}
    >
      <Flex
        maxW="1030px"
        flexDir="column"
        py={{ base: '90px', md: '60px', xl: '100px' }}
        alignItems="center"
        w="full"
        justify="center"
        margin={'0 auto'}
      >
        <Box maxW={1130} w="full" pos="relative" pt={{ md: '30px', xl: '0px' }}>
          <Flex
            justifyContent="space-between"
            w={{
              base: 'full',
              md: '87.5%',
              lg: '79.6%',
              xl: '91%',
              '2xl': '88%',
            }}
            alignItems={{
              md: lang === 'ar' ? 'flex-start' : 'baseline',
              lg: 'center',
            }}
            minH={{ base: '60px', md: '140px' }}
          >
            <Flex flexDir="column" w="full" px="16px">
              <Heading
                as="h2"
                pb={{ base: '8px', md: '30px' }}
                color="#E86F34"
                pr={{ md: '14%', xl: lang == 'ar' ? '40%' : '2%' }}
                {...StoriesSt[lang].subTitle}
                dir={lang == 'ar' ? 'rtl' : 'ltr'}
                textAlign={lang == 'ar' ? 'right' : 'left'}
              >
                {sectionContent?.[0]?.title[0]?.titleSubtext}
                <Box
                  as="span"
                  display="block"
                  color="#433F3C"
                  {...StoriesSt[lang].h2}
                  textAlign={lang == 'ar' ? 'right' : 'left'}
                >
                  {' '}
                  {sectionContent?.[0]?.title[0]?.titleText}
                </Box>
              </Heading>
            </Flex>
            <Text
              pos={{ base: 'absolute', md: 'static' }}
              bottom={{ base: '12px', md: '35px' }}
              left="47%"
              {...StoriesSt[lang].countNumber}
              color="#433F3C"
            >
              {' '}
              {Math.ceil(activeSlide + numberVariant)}/
              {sectionContent?.[0]?.testimonials?.length}
            </Text>
          </Flex>
          <Slider {...settings}>
            {sectionContent?.[0]?.testimonials?.map((item, index) => {
              let testimonialTitle = JSON.parse(
                JSON.stringify(item?.testimonialTitle)
              );
              delete testimonialTitle?.value?.document?.children?.[0]
                ?.children[1];
              return (
                <Box
                  w="full"
                  key={index}
                  pt={{ base: '30px', md: '10px' }}
                  pl="16px"
                >
                  <Box
                    position="relative"
                    display="flex"
                    gridGap="30px"
                    css={{ '-webkit-grid-gap': '30px' }}
                  >
                    <Box
                      pos="absolute"
                      left="10px"
                      top="45%"
                      zIndex="-1"
                      display={{ base: 'flex', md: 'none' }}
                    >
                      <svg
                        width="50"
                        height="102"
                        viewBox="0 0 50 102"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.1" clip-path="url(#clip0_4350_8717)">
                          <path
                            d="M49.6851 58.9361C45.5558 54.2864 47.7537 48.6996 54.1741 47.5554C54.6536 47.4702 55.1198 47.4215 55.5727 47.385L55.5727 62.4294C53.7478 62.3077 51.7631 61.2731 49.6851 58.9361Z"
                            fill="black"
                          />
                          <path
                            d="M32.4748 23.0777C32.6613 23.5402 32.5681 24.4287 32.2217 24.66C31.1561 25.3781 29.904 25.8772 28.6119 26.5223C29.904 25.8772 27.2665 25.9867 28.6119 26.5223C27.2665 25.9867 25.9212 25.6216 24.8023 24.9521C23.377 24.1001 23.377 22.8951 24.5492 21.7753C26.3341 20.059 31.5957 20.8867 32.4748 23.0777Z"
                            fill="black"
                          />
                          <path
                            d="M28.612 26.5225C29.9041 25.8774 27.2666 25.9748 28.612 26.5225V26.5225Z"
                            fill="black"
                          />
                          <path
                            d="M12.7606 36.6372C12.8405 36.7954 12.9471 36.9172 13.0403 37.051C12.9604 36.9415 12.8672 36.8198 12.7606 36.6372Z"
                            fill="black"
                          />
                          <path
                            d="M21.4189 36.7102C21.2457 38.2195 20.1934 39.1811 18.5283 39.1446C16.4504 39.0959 14.3591 38.9863 13.0403 37.051C14.0793 38.2925 14.1992 34.361 12.7606 36.6372C14.3191 34.1784 16.0374 33.5212 19.1011 34.1298C20.7928 34.4706 21.5787 35.3469 21.4189 36.7102Z"
                            fill="black"
                          />
                          <path
                            d="M13.0403 37.0511C12.9471 36.9172 12.8539 36.7955 12.7606 36.6373C14.1992 34.3612 14.0793 38.2927 13.0403 37.0511Z"
                            fill="black"
                          />
                          <path
                            d="M16.9703 44.7193C16.9437 47.0928 15.7715 47.8718 13.2939 47.1171C11.7088 46.6424 9.28446 45.7052 9.48427 43.7821C9.67075 41.9928 11.5223 41.9806 12.881 41.7007C15.585 41.1408 16.997 42.2606 16.9703 44.7193Z"
                            fill="black"
                          />
                          <path
                            d="M51.8693 92.4815C49.5915 90.4366 49.6981 88.55 52.4288 87.1259C51.4697 87.7345 54.6 87.2233 53.8674 86.5051C54.3469 86.9798 54.9863 87.5032 55.5724 88.0753L55.5724 93.1023C54.5068 93.6987 53.0415 93.5283 51.8693 92.4815Z"
                            fill="black"
                          />
                          <path
                            d="M47.9131 90.3759C47.9927 89.536 47.9396 90.1203 47.9131 90.4611C47.9396 90.1689 47.9661 89.7429 47.9131 90.3759Z"
                            fill="black"
                          />
                          <path
                            d="M47.9134 90.4609C47.9 90.6192 47.8867 90.7409 47.8867 90.607C47.8867 90.7165 47.9 90.6192 47.9134 90.4609Z"
                            fill="black"
                          />
                          <path
                            d="M47.9134 90.4613C47.9 90.6196 47.8867 90.7291 47.8867 90.6074C47.9 90.5222 47.9 90.4491 47.9134 90.3761C47.9666 89.7432 47.94 90.1692 47.9134 90.4613Z"
                            fill="black"
                          />
                          <path
                            d="M42.6387 88.8544C44.5701 86.7121 48.18 87.4303 47.8869 90.595C47.7937 92.2138 47.6072 94.3561 45.4759 94.9038C42.7452 95.5976 41.3066 90.3515 42.6387 88.8544Z"
                            fill="black"
                          />
                          <path
                            d="M23.457 75.6968C23.4704 75.5873 23.4837 75.2465 23.497 74.5405C23.5503 74.6379 23.4704 75.5264 23.457 75.6968Z"
                            fill="black"
                          />
                          <path
                            d="M23.4975 74.5403C23.4442 74.4429 23.4175 74.735 23.4175 75.0515C22.7382 77.5832 17.7963 81.6608 16.3178 77.8997C15.5585 75.9887 20.4471 71.0104 22.5118 72.8241C23.0978 73.3474 23.3243 74.236 23.4975 74.5403Z"
                            fill="black"
                          />
                          <path
                            d="M23.4977 74.5403C23.4977 74.6985 23.4711 74.8689 23.4178 75.0515C23.4178 74.7229 23.4311 74.4307 23.4977 74.5403Z"
                            fill="black"
                          />
                          <path
                            d="M23.4571 75.6966C23.4305 75.8792 23.4172 75.441 23.4172 75.0515C23.4705 74.8689 23.4971 74.6985 23.4971 74.5403C23.4838 74.9176 23.4705 75.3071 23.4571 75.6966Z"
                            fill="black"
                          />
                          <path
                            d="M30.9963 89.4263C30.9963 89.402 31.0094 89.3898 31.0094 89.3655C31.0094 89.3777 31.0094 89.402 30.9963 89.4263Z"
                            fill="black"
                          />
                          <path
                            d="M30.6504 88.915C30.8502 89.0002 31.01 89.1341 31.01 89.3289C30.9301 89.1341 30.7969 89.0002 30.6504 88.915Z"
                            fill="black"
                          />
                          <path
                            d="M31.0224 89.3413C31.0224 89.3534 31.0224 89.3534 31.0224 89.3656C31.0224 89.3534 31.0224 89.3413 31.0224 89.3291C31.0093 89.3291 31.0224 89.3413 31.0224 89.3413Z"
                            fill="black"
                          />
                          <path
                            d="M30.9565 89.5358C30.9831 89.5114 30.9964 89.4627 30.9964 89.4262C30.9964 89.4019 31.0098 89.3897 31.0098 89.3654C31.0098 89.3532 31.0098 89.3532 31.0098 89.341L30.9964 89.3288C30.9964 89.1463 30.8366 89.0002 30.6368 88.915C30.6102 88.9028 30.5702 88.8907 30.5302 88.8785C30.5169 88.8785 30.5036 88.8663 30.4903 88.8663C30.397 88.842 30.3038 88.8298 30.1972 88.8298C30.1306 88.8298 30.064 88.8298 30.0107 88.842C29.9974 88.842 29.9841 88.842 29.9708 88.842C29.9708 88.842 29.9708 88.842 29.9575 88.8541C29.9042 88.8663 29.8642 88.8785 29.8242 88.9028C28.9851 87.9534 28.1459 86.2007 28.9584 84.7888C30.1439 82.7439 33.3009 81.0885 34.0601 84.6914C34.6196 87.3327 32.5949 88.6594 30.3704 90.047C30.7167 89.8644 30.8766 89.694 30.9565 89.5358Z"
                            fill="black"
                          />
                          <path
                            d="M29.9834 88.8423C29.9967 88.8423 30.0099 88.8423 30.0232 88.8423C30.0099 88.8423 29.9967 88.8545 29.9834 88.8423C29.9834 88.8545 29.9834 88.8545 29.9834 88.8423Z"
                            fill="black"
                          />
                          <path
                            d="M30.024 88.8422C30.0906 88.8301 30.1572 88.8301 30.2104 88.8301C30.3037 88.8301 30.3969 88.8422 30.5035 88.8666C30.5168 88.8666 30.5301 88.8666 30.5434 88.8788C30.5834 88.8909 30.61 88.9031 30.65 88.9153C30.8099 89.0005 30.9297 89.1344 31.0097 89.3291C31.0097 89.3413 31.0097 89.3535 31.0097 89.3656C31.0097 89.39 30.9963 89.4022 30.9963 89.4265C30.983 89.463 30.9697 89.4995 30.9564 89.536C30.7965 89.7308 30.3436 89.463 29.8907 88.9518C29.9307 88.9153 29.944 88.8909 29.984 88.8544C29.9973 88.8544 30.0106 88.8422 30.024 88.8422Z"
                            fill="black"
                          />
                          <path
                            d="M29.8915 88.9519C29.8782 88.9397 29.8649 88.9154 29.8516 88.9032C29.8915 88.8788 29.9315 88.8667 29.9848 88.8545C29.9315 88.891 29.9182 88.9154 29.8915 88.9519Z"
                            fill="black"
                          />
                          <path
                            d="M30.957 89.5358C30.9836 89.4993 30.9968 89.4628 30.9968 89.4263C30.9968 89.4628 30.9836 89.5115 30.957 89.5358Z"
                            fill="black"
                          />
                          <path
                            d="M30.5038 88.8666C30.4106 88.8422 30.3173 88.8301 30.2108 88.8301C30.3307 88.8301 30.4239 88.8422 30.5038 88.8666Z"
                            fill="black"
                          />
                          <path
                            d="M30.6497 88.9152C30.6098 88.903 30.5831 88.8908 30.5432 88.8787C30.5831 88.8908 30.6231 88.903 30.6497 88.9152Z"
                            fill="black"
                          />
                          <path
                            d="M29.8906 88.9518C29.7841 89.0491 29.7175 89.1587 29.6908 89.2561C29.6376 89.0857 29.7175 88.9761 29.8374 88.9031C29.8507 88.9152 29.8773 88.9396 29.8906 88.9518Z"
                            fill="black"
                          />
                          <path
                            d="M30.2244 88.8301C30.1578 88.8301 30.1045 88.8301 30.0379 88.8422C30.0912 88.8301 30.1578 88.8301 30.2244 88.8301Z"
                            fill="black"
                          />
                          <path
                            d="M30.957 89.5361C30.8771 89.6944 30.7173 89.8648 30.3976 90.0595C29.9447 89.7187 29.7449 89.4509 29.6916 89.2562C29.7182 89.1588 29.7715 89.0493 29.8914 88.9519C30.3443 89.4631 30.7972 89.7309 30.957 89.5361Z"
                            fill="black"
                          />
                          <path
                            d="M30.5437 88.8789C30.5304 88.8789 30.5172 88.8667 30.5039 88.8667C30.5172 88.8789 30.5304 88.8789 30.5437 88.8789Z"
                            fill="black"
                          />
                          <path
                            d="M30.3969 90.0594C29.8508 89.7917 29.6244 89.5239 29.691 89.2561C29.7443 89.4509 29.9574 89.7065 30.3969 90.0594Z"
                            fill="black"
                          />
                          <path
                            d="M22.7781 84.8134C22.7914 84.8134 22.818 84.8013 22.8314 84.8013C22.858 84.8013 22.8846 84.8013 22.898 84.8013C23.1111 84.8134 23.3908 84.9595 23.6039 85.1786C23.857 85.5803 23.857 86.201 23.6039 86.481C23.231 86.1158 22.9113 85.6776 22.6582 85.2029C22.6848 85.0691 22.7115 84.9473 22.7781 84.8134Z"
                            fill="black"
                          />
                          <path
                            d="M23.6164 85.1785C23.9228 85.4949 24.0693 85.9696 23.6164 86.4809C23.8695 86.1887 23.8695 85.5801 23.6164 85.1785Z"
                            fill="black"
                          />
                          <path
                            d="M23.63 86.4807C23.0572 85.9086 22.7775 85.5191 22.6709 85.2513C22.6709 85.2392 22.6842 85.227 22.6842 85.2026C22.9373 85.6652 23.2437 86.1034 23.63 86.4807Z"
                            fill="black"
                          />
                          <path
                            d="M22.9111 84.8013C23.2308 84.7769 23.4706 84.9352 23.6171 85.1786C23.404 84.9595 23.1243 84.8135 22.9111 84.8013Z"
                            fill="black"
                          />
                          <path
                            d="M22.6577 85.2393C22.7642 85.507 23.044 85.9087 23.6167 86.4686C22.9507 86.1278 22.631 85.714 22.6577 85.2393Z"
                            fill="black"
                          />
                          <path
                            d="M22.7778 84.8135C22.7112 84.9474 22.6846 85.0813 22.6713 85.203C22.6579 85.1665 22.6313 85.1421 22.618 85.1056C22.5913 84.9352 22.6579 84.8378 22.7778 84.8135Z"
                            fill="black"
                          />
                          <path
                            d="M26.9601 78.9222C29.7175 81.3931 25.1619 84.7282 23.63 86.4688C24.0829 85.9576 23.9364 85.4829 23.63 85.1664C23.4835 84.9229 23.2437 84.7769 22.9241 84.7891C22.8974 84.7891 22.8708 84.7891 22.8575 84.7891C22.8441 84.7891 22.8175 84.7891 22.8042 84.7891C22.8042 84.7891 22.8042 84.7891 22.8042 84.8012C22.6843 84.8256 22.6177 84.9229 22.6443 85.0934C21.3922 82.7198 21.7385 79.4456 24.7756 78.3867C25.3484 78.1797 26.4673 78.484 26.9601 78.9222Z"
                            fill="black"
                          />
                          <path
                            d="M22.6581 85.2393C22.6315 85.1906 22.6182 85.1419 22.6182 85.0933C22.6315 85.1298 22.6581 85.1541 22.6714 85.1906C22.6714 85.215 22.6581 85.2271 22.6581 85.2393Z"
                            fill="black"
                          />
                          <path
                            d="M49.7116 16.9554C49.5518 20.4852 42.5319 18.6595 42.3721 15.7747C42.1057 11.2103 49.8981 12.8413 49.7116 16.9554Z"
                            fill="black"
                          />
                          <path
                            d="M20.3398 68.1866C20.3398 68.5031 20.3531 69.0265 20.4197 68.8926C19.4873 70.5845 15.5844 73.6518 13.5997 71.3026C13.1468 70.7671 13.0269 69.4403 13.4398 68.99C14.4122 67.931 15.691 66.9816 17.0497 66.373C18.6481 65.6549 20.0068 66.4217 20.3398 68.1866Z"
                            fill="black"
                          />
                          <path
                            d="M20.3398 68.1865C20.3798 68.4056 20.4198 68.6369 20.4198 68.8925C20.3532 69.0142 20.3398 68.503 20.3398 68.1865Z"
                            fill="black"
                          />
                          <path
                            d="M20.4334 68.8926C20.4201 68.637 20.3935 68.4058 20.3535 68.1867C20.3535 67.8337 20.3935 67.712 20.4334 68.8926Z"
                            fill="black"
                          />
                          <path
                            d="M33.6868 18.5501C33.6735 18.4163 33.6602 18.2824 33.6735 18.1363C33.7001 18.0511 33.7001 18.3189 33.6868 18.5501Z"
                            fill="black"
                          />
                          <path
                            d="M33.6736 18.1362C33.6736 18.2823 33.6869 18.4162 33.6869 18.5501C33.6869 18.8787 33.6604 19.1343 33.6736 18.1362Z"
                            fill="black"
                          />
                          <path
                            d="M33.687 18.5498C33.687 18.3185 33.687 18.0629 33.6737 18.1359C33.021 20.3512 33.8069 16.9918 34.2199 16.5171C34.6328 16.0546 35.8849 15.5677 36.2179 15.7868C45.1159 21.5684 34.1133 24.4653 33.687 18.5498Z"
                            fill="black"
                          />
                          <path
                            d="M14.3196 59.715C12.3216 59.5811 15.4652 59.6784 14.3196 59.715V59.715Z"
                            fill="black"
                          />
                          <path
                            d="M17.1964 61.0783C17.7825 66.4339 7.37928 65.0463 9.81691 61.6017C10.8159 60.1897 12.3211 59.569 14.3192 59.7028C15.4648 59.6785 17.0366 59.6055 17.1964 61.0783Z"
                            fill="black"
                          />
                          <path
                            d="M16.5703 52.9353C16.5703 54.9314 15.7311 55.6739 13.4533 55.6983C2.90358 55.8078 16.5969 45.7539 16.5703 52.9353Z"
                            fill="black"
                          />
                          <path
                            d="M21.4729 27.4473C22.2455 27.4473 20.3806 27.7029 21.4729 27.4473V27.4473Z"
                            fill="black"
                          />
                          <path
                            d="M22.432 31.878C20.9268 31.4885 17.6633 30.9529 18.0629 28.9324C18.4359 27.0579 20.3807 27.6909 21.4596 27.4353C22.2322 27.4353 20.3674 27.6909 21.4596 27.4353C20.5938 27.4353 23.6175 27.3744 24.1503 27.7517C26.3482 29.2976 26.0152 32.8274 22.432 31.878Z"
                            fill="black"
                          />
                          <path
                            d="M40.7335 88.9275C40.4538 87.4182 40.2806 89.7552 40.7335 88.9275V88.9275Z"
                            fill="black"
                          />
                          <path
                            d="M39.122 85.8966C40.7471 86.1888 40.4674 87.4181 40.7471 88.9153C40.4674 87.406 40.2809 89.7429 40.7471 88.9153C40.2809 89.7429 39.7881 91.021 38.9356 92.0678C37.9632 93.2484 36.7643 93.0293 35.8852 91.7635C34.8995 90.3515 35.4057 88.9396 35.9252 87.5764C36.4713 86.0914 37.5369 85.6167 39.122 85.8966Z"
                            fill="black"
                          />
                          <path
                            d="M25.7481 44.9628C27.0668 48.237 21.1259 50.8296 21.0193 46.253C20.9527 42.9788 24.4294 41.7007 25.7481 44.9628Z"
                            fill="black"
                          />
                          <path
                            d="M32.355 33.3753C30.9697 33.351 29.7309 32.3529 29.7975 31.3061C30.2637 22.8954 40.9333 33.5092 32.355 33.3753Z"
                            fill="black"
                          />
                          <path
                            d="M27.5063 34.9452C28.6918 34.8965 29.3712 35.8094 29.3978 37.4526C29.4245 39.254 28.6652 40.0574 26.9202 40.1061C25.1619 40.1548 24.0164 39.3879 23.9631 38.1464C23.9098 36.7832 25.8812 34.9939 27.5063 34.9452Z"
                            fill="black"
                          />
                          <path
                            d="M43.198 23.431C43.1847 23.4188 43.1715 23.3945 43.1582 23.3823C43.1715 23.4067 43.1847 23.4188 43.198 23.431Z"
                            fill="black"
                          />
                          <path
                            d="M43.2113 23.4431C43.2113 23.4431 43.2113 23.4309 43.1982 23.4309C43.1982 23.4431 43.2113 23.4431 43.2113 23.4431Z"
                            fill="black"
                          />
                          <path
                            d="M42.5451 28.044C41.7859 28.3361 40.294 28.3727 39.9876 27.971C38.2959 25.7801 40.8934 24.0638 42.3187 22.6641C41.5994 23.37 43.7573 24.1855 43.2111 23.4552C44.8762 25.208 44.6231 27.2285 42.5451 28.044Z"
                            fill="black"
                          />
                          <path
                            d="M43.1585 23.3944C42.9188 23.1509 42.639 22.9075 42.3193 22.6641C42.7589 22.9927 43.012 23.224 43.1585 23.3944Z"
                            fill="black"
                          />
                          <path
                            d="M42.3191 22.6641C42.6388 22.8953 42.9185 23.1388 43.1583 23.3944C43.1716 23.4065 43.1849 23.4309 43.1982 23.4431C43.1982 23.4431 43.1982 23.4552 43.2116 23.4552C43.7577 24.1855 41.5998 23.37 42.3191 22.6641Z"
                            fill="black"
                          />
                          <path
                            d="M25.0024 71.8262C25.0024 71.887 24.9893 71.9722 24.9893 72.0696C25.0024 71.9601 25.0024 71.887 25.0024 71.8262Z"
                            fill="black"
                          />
                          <path
                            d="M24.9892 72.179C24.9759 72.4467 24.9626 72.6171 24.9892 72.179V72.179Z"
                            fill="black"
                          />
                          <path
                            d="M25.0028 71.8261C25.0159 71.607 25.0159 71.5948 25.0159 71.6679C25.0159 71.6679 25.0159 71.7165 25.0028 71.8261Z"
                            fill="black"
                          />
                          <path
                            d="M25.0159 71.7288C25.0159 71.8018 25.0028 71.9357 25.0028 72.0574C25.0028 71.9722 25.0028 71.8261 25.0159 71.7288Z"
                            fill="black"
                          />
                          <path
                            d="M28.0397 69.2578C35.992 69.416 24.6696 77.985 24.9893 72.0695C25.2824 70.4628 26.2947 69.5256 28.0397 69.2578Z"
                            fill="black"
                          />
                          <path
                            d="M25.0161 71.7288C25.0161 71.8262 25.0028 71.9601 25.0028 72.0574C25.0028 71.9479 25.0161 71.8749 25.0161 71.814C25.0161 71.7045 25.0294 71.6558 25.0294 71.6558C25.0294 71.6679 25.0294 71.6923 25.0294 71.7045C25.0161 71.7166 25.0161 71.7288 25.0161 71.7288Z"
                            fill="black"
                          />
                          <path
                            d="M29.5977 78.0458C29.5977 77.9241 29.5977 77.7537 29.5977 77.5224C29.5977 77.5224 29.5977 77.5102 29.5977 77.498C29.6108 77.6928 29.5977 77.8876 29.5977 78.0458Z"
                            fill="black"
                          />
                          <path
                            d="M29.6113 77.1451C29.6646 72.4346 36.5779 76.2322 34.38 78.8613C32.7949 80.748 29.5847 79.3969 29.6113 77.1451Z"
                            fill="black"
                          />
                          <path
                            d="M21.8448 57.9868C20.4729 58.0355 19.5804 57.147 19.607 55.7472C19.7535 47.726 28.3985 57.7312 21.8448 57.9868Z"
                            fill="black"
                          />
                          <path
                            d="M46.5008 85.5071C40.1736 84.7647 50.4836 76.8408 50.0707 83.1823C49.9907 84.3508 47.9527 85.6775 46.5008 85.5071Z"
                            fill="black"
                          />
                          <path
                            d="M49.2188 23.869C49.3254 22.664 51.2435 21.3008 52.6688 21.4468C53.8809 21.5686 54.1607 22.3475 54.1207 23.3091C54.0541 24.6359 52.6022 26.0356 51.3634 25.9869C50.3111 25.9382 49.1389 24.7819 49.2188 23.869Z"
                            fill="black"
                          />
                          <path
                            d="M24.4823 61.772C24.6688 61.6747 24.8419 61.5651 25.0551 61.4556C25.6012 62.2224 22.7506 62.8066 24.4823 61.772Z"
                            fill="black"
                          />
                          <path
                            d="M24.4828 61.772C22.7378 62.8066 25.5884 62.2224 25.0556 61.4556C25.6417 62.2833 26.3343 62.9405 26.6007 63.7074C26.9204 64.6202 26.8805 65.7035 25.7083 66.2148C24.4695 66.7503 23.4438 66.2878 22.6712 65.3506C21.6589 64.1455 22.1384 63.0744 24.4828 61.772Z"
                            fill="black"
                          />
                          <path
                            d="M25.0562 61.4556C24.843 61.5651 24.6699 61.6625 24.4834 61.772C24.6432 61.6747 24.8164 61.5773 25.0562 61.4556Z"
                            fill="black"
                          />
                          <path
                            d="M30.7166 66.4095C32.6081 64.036 31.3959 67.3589 30.7166 66.4095V66.4095Z"
                            fill="black"
                          />
                          <path
                            d="M33.4473 68.6004C31.6091 69.0873 31.4093 67.3589 30.7166 66.3973C32.6081 64.0238 31.3959 67.3589 30.7166 66.3973C32.6081 64.0238 32.6081 64.0238 34.8992 65.1679C34.8992 66.4947 35.2322 68.1379 33.4473 68.6004Z"
                            fill="black"
                          />
                          <path
                            d="M47.5007 31.0749C47.5007 31.3305 47.474 31.5617 47.4341 31.793C47.3675 31.8295 47.2742 30.8679 47.5007 31.0749Z"
                            fill="black"
                          />
                          <path
                            d="M47.4336 31.7928C47.4736 31.5738 47.5002 31.3303 47.5002 31.0747C47.5002 31.5859 47.4736 31.7685 47.4336 31.7928Z"
                            fill="black"
                          />
                          <path
                            d="M45.5153 33.8012C44.3431 33.7769 43.1976 32.7544 43.2375 31.7685C43.2775 30.8069 44.8227 29.4802 45.9416 29.7723C46.6609 29.9549 47.1804 30.7948 47.5 31.0747C47.2736 30.8678 47.3668 31.8294 47.4334 31.7929C47.2203 33.0952 46.4477 33.8256 45.5153 33.8012Z"
                            fill="black"
                          />
                          <path
                            d="M40.0541 80.2856C41.8524 80.1882 38.0427 83.1459 40.0541 80.2856V80.2856Z"
                            fill="black"
                          />
                          <path
                            d="M40.5607 85.0325C38.0431 83.2676 38.0298 83.158 40.0412 80.2855C41.8528 80.1881 38.0298 83.1459 40.0412 80.2855C41.8528 80.1881 42.5055 81.028 42.7053 82.3425C42.9717 83.9127 41.9993 84.6187 40.5607 85.0325Z"
                            fill="black"
                          />
                          <path
                            d="M34.6326 36.0288C34.6059 36.041 34.5793 36.041 34.5526 36.0653C34.5793 36.041 34.6059 36.0288 34.6326 36.0288Z"
                            fill="black"
                          />
                          <path
                            d="M34.5526 36.3695C34.5793 37.0754 34.7924 36.479 34.7524 36.1747C34.7791 36.0773 34.7524 35.9921 34.6326 36.0287C34.6059 36.0287 34.5793 36.0408 34.5526 36.0652C35.0322 35.7365 35.7248 34.9819 36.5773 34.7628C41.3727 33.4604 34.5926 43.6117 34.5526 36.3695Z"
                            fill="black"
                          />
                          <path
                            d="M34.7532 36.1745C34.7931 36.4788 34.58 37.0752 34.5534 36.3692C34.5534 36.2719 34.5534 36.1745 34.5534 36.0649C34.5401 36.5762 34.7132 36.3571 34.7532 36.1745Z"
                            fill="black"
                          />
                          <path
                            d="M34.6328 36.0285C34.7394 35.992 34.766 36.0651 34.7527 36.1746C34.7394 36.0772 34.6994 36.0164 34.6328 36.0285Z"
                            fill="black"
                          />
                          <path
                            d="M34.7532 36.1747C34.7132 36.3695 34.5401 36.5885 34.5534 36.0652C34.5933 36.0408 34.6067 36.0408 34.6333 36.0286C34.6999 36.0165 34.7399 36.0773 34.7532 36.1747Z"
                            fill="black"
                          />
                          <path
                            d="M47.5671 78.7639C47.6337 78.7883 47.7003 78.8004 47.727 78.8248C47.687 78.837 47.6604 78.8491 47.6204 78.8491C47.6471 78.8248 47.6204 78.8004 47.5671 78.7639Z"
                            fill="black"
                          />
                          <path
                            d="M47.6076 78.8613C47.6475 78.8491 47.6742 78.8369 47.7141 78.8369C47.7941 78.8978 47.6742 78.9465 47.0215 78.8978C47.3945 78.9099 47.5676 78.8978 47.6076 78.8613Z"
                            fill="black"
                          />
                          <path
                            d="M47.5672 78.764C47.3141 78.691 46.9012 78.6666 47.0344 78.8857C46.8346 78.5692 47.3674 78.6544 47.5672 78.764Z"
                            fill="black"
                          />
                          <path
                            d="M47.0215 78.8856C47.2213 78.8978 47.4211 78.8856 47.6076 78.8491C47.5676 78.8978 47.3945 78.91 47.0215 78.8856Z"
                            fill="black"
                          />
                          <path
                            d="M47.9934 74.8569C48.9925 74.9786 49.8716 75.2829 49.7651 76.4636C49.6718 77.5225 48.8593 78.5693 47.7137 78.8249C47.6871 78.8005 47.6205 78.7762 47.5539 78.764C47.3674 78.6545 46.8346 78.5693 47.0211 78.8857C46.6348 78.1798 46.2485 77.4738 45.8755 76.7678C45.689 75.5628 46.7813 74.7108 47.9934 74.8569Z"
                            fill="black"
                          />
                          <path
                            d="M47.5674 78.7639C47.6207 78.8004 47.6474 78.8248 47.6207 78.8613C47.4342 78.8978 47.2344 78.91 47.0346 78.8978C46.9014 78.6665 47.3143 78.6909 47.5674 78.7639Z"
                            fill="black"
                          />
                          <path
                            d="M39.6275 38.8769C40.9729 38.2804 39.4277 40.2644 39.6275 38.8769V38.8769Z"
                            fill="black"
                          />
                          <path
                            d="M39.6282 38.8771C40.9735 38.2806 39.4284 40.2646 39.6282 38.8771C40.9602 38.2806 42.199 37.5503 43.198 38.7432C43.571 39.1813 43.4778 40.289 43.1048 40.8002C42.7452 41.3114 41.7328 41.7983 41.0934 41.7131C39.495 41.4818 39.4284 40.2646 39.6282 38.8771Z"
                            fill="black"
                          />
                          <path
                            d="M34.9929 61.1392C34.8064 60.2263 34.9396 59.2648 34.9396 58.3153C35.1927 58.1936 34.9396 59.2648 34.9396 58.3153C35.1927 58.1936 35.4325 58.0719 35.6856 57.9502C40.2145 61.8452 35.3126 62.7094 34.9929 61.1392Z"
                            fill="black"
                          />
                          <path
                            d="M34.9395 58.3151C35.1925 58.1934 34.9395 59.2523 34.9395 58.3151V58.3151Z"
                            fill="black"
                          />
                          <path
                            d="M31.9552 51.9616C31.5955 51.3773 31.3824 51.3408 31.3158 51.4747C31.1559 51.633 31.3291 52.2902 31.9552 51.9616C30.703 52.6189 27.8258 54.6516 27.7726 51.7547C27.7592 51.1339 28.3187 50.148 28.8915 49.9532C30.3967 49.442 31.1027 50.5497 31.9552 51.9616Z"
                            fill="black"
                          />
                          <path
                            d="M31.3297 51.4746C31.2364 51.6694 31.4362 52.2293 31.969 51.9615C31.3297 52.2901 31.1565 51.6328 31.3297 51.4746Z"
                            fill="black"
                          />
                          <path
                            d="M31.3293 51.4747C31.3959 51.3408 31.6091 51.3773 31.9687 51.9616C31.649 51.4382 31.4226 51.3773 31.3293 51.4747Z"
                            fill="black"
                          />
                          <path
                            d="M31.3295 51.4747C31.4361 51.3773 31.6492 51.4504 31.9689 51.9616C31.4361 52.2294 31.2229 51.6695 31.3295 51.4747Z"
                            fill="black"
                          />
                          <path
                            d="M49.8984 39.4125C48.0468 37.891 48.0468 37.891 50.3779 35.1646C53.1219 37.0025 53.0819 37.319 49.8984 39.4125Z"
                            fill="black"
                          />
                          <path
                            d="M36.5643 74.3213C36.6575 74.443 36.6709 74.4674 36.5643 74.3213V74.3213Z"
                            fill="black"
                          />
                          <path
                            d="M36.8847 74.1628C36.7782 74.175 36.6583 74.1872 36.4851 74.1994C36.6183 74.1872 36.7515 74.175 36.8847 74.1628Z"
                            fill="black"
                          />
                          <path
                            d="M36.8833 74.1629C37.869 74.0534 36.3372 73.9073 36.4704 74.1751C36.124 73.6639 35.4047 72.4954 35.6179 72.1789C36.4171 71.0469 39.6806 69.6472 39.4408 72.4102C39.3343 73.6274 38.4151 74.0169 36.8833 74.1629Z"
                            fill="black"
                          />
                          <path
                            d="M36.5649 74.3214C36.5383 74.2849 36.5116 74.2484 36.4717 74.1997C36.5116 74.2484 36.5383 74.2849 36.5649 74.3214Z"
                            fill="black"
                          />
                          <path
                            d="M36.4846 74.1994C36.4846 74.1873 36.4846 74.1873 36.4846 74.1873C36.4714 74.1873 36.4846 74.1873 36.4846 74.1994Z"
                            fill="black"
                          />
                          <path
                            d="M36.8844 74.1629C36.7512 74.1751 36.6313 74.1872 36.4848 74.1994L36.4715 74.1872C36.3383 73.9073 37.8568 74.0533 36.8844 74.1629Z"
                            fill="black"
                          />
                          <path
                            d="M32.7545 58.5344C31.3559 57.7919 31.7022 59.496 32.7545 58.5344V58.5344Z"
                            fill="black"
                          />
                          <path
                            d="M29.8646 56.9888C30.6639 57.4148 31.3565 57.7921 32.7552 58.5346C31.3565 57.7921 31.7028 59.4962 32.7552 58.5346C31.7028 59.4962 31.0368 60.3117 30.1577 60.7864C29.878 60.9446 28.7191 60.3482 28.466 59.8735C27.7733 58.5711 28.8922 57.853 29.8646 56.9888Z"
                            fill="black"
                          />
                          <path
                            d="M45.2631 70.5968C44.5971 71.5705 43.3583 71.3514 42.7722 70.7672C41.4002 69.3918 42.8522 68.3328 43.6913 66.9209C45.1832 68.1259 42.8655 68.3328 43.6913 66.9209C45.1832 68.1259 46.3021 69.0631 45.2631 70.5968Z"
                            fill="black"
                          />
                          <path
                            d="M43.6913 66.9209C45.1832 68.1259 42.8654 68.3328 43.6913 66.9209V66.9209Z"
                            fill="black"
                          />
                          <path
                            d="M52.23 32.3526C52.0968 32.3648 52.0036 32.3769 51.9903 32.3891C47.2216 31.6223 54.7875 26.4736 54.2947 30.8554C54.2148 31.5127 53.3356 32.0848 53.0159 32.4743C53.2157 32.243 52.3899 32.2796 52.23 32.3526Z"
                            fill="black"
                          />
                          <path
                            d="M33.0219 45.1453C33.0219 44.1959 30.7841 44.6462 32.4757 45.0357C26.8279 43.9525 32.6756 41.226 32.9686 42.4797C33.1551 43.2587 33.0219 44.0985 33.0219 45.1453Z"
                            fill="black"
                          />
                          <path
                            d="M33.0212 45.1454C32.8081 45.1089 32.6615 45.0723 32.4751 45.0358C30.7967 44.6463 33.0212 44.1838 33.0212 45.1454Z"
                            fill="black"
                          />
                          <path
                            d="M32.4746 45.0356C32.6611 45.0722 32.8209 45.1087 33.0207 45.1452C32.8076 45.1087 32.6345 45.0722 32.4746 45.0356Z"
                            fill="black"
                          />
                          <path
                            d="M41.3995 65.8374C41.3862 65.5453 41.3597 65.0219 41.3862 65.6427C41.3332 64.0117 41.3862 65.4845 41.3995 65.8374Z"
                            fill="black"
                          />
                          <path
                            d="M41.3861 65.6551C41.4527 66.8723 40.4803 67.0913 39.5079 67.2131C34.939 67.8095 41.2262 60.8837 41.3861 65.6551Z"
                            fill="black"
                          />
                          <path
                            d="M41.4 65.8373C41.4132 66.0442 41.4132 66.1416 41.3867 65.6426C41.4 65.959 41.4 65.959 41.4 65.8373Z"
                            fill="black"
                          />
                          <path
                            d="M41.3998 65.8375C41.3998 65.9592 41.3998 65.9592 41.3865 65.6427C41.3598 65.022 41.3865 65.5454 41.3998 65.8375Z"
                            fill="black"
                          />
                          <path
                            d="M42.0653 52.5945C42.385 52.6067 42.5981 52.5702 42.5182 52.5337C42.6914 52.6067 42.7713 52.8136 42.4916 53.191C42.012 52.8745 41.8788 52.6919 42.0653 52.5945Z"
                            fill="black"
                          />
                          <path
                            d="M42.0654 52.5945C42.1454 52.5458 42.2786 52.5215 42.505 52.5215L42.5183 52.5215C42.6116 52.5702 42.3984 52.6067 42.0654 52.5945Z"
                            fill="black"
                          />
                          <path
                            d="M42.5056 53.1909C41.9329 52.9353 41.8929 52.7162 42.0261 52.5945C42.0394 52.5945 42.0527 52.5945 42.0794 52.5945C41.8796 52.6919 42.0128 52.8744 42.5056 53.1909Z"
                            fill="black"
                          />
                          <path
                            d="M42.5055 52.5214C42.2791 52.5214 42.1459 52.5457 42.066 52.5944C42.0527 52.5944 42.0393 52.5944 42.0127 52.5944C42.1326 52.4849 42.359 52.4605 42.5055 52.5214Z"
                            fill="black"
                          />
                          <path
                            d="M42.505 53.191C42.7714 52.8137 42.7048 52.6067 42.5317 52.5337C42.5317 52.5337 42.5317 52.5337 42.5183 52.5337C42.3585 52.4728 42.1454 52.4972 42.0255 52.6067C41.133 52.5459 39.4946 52.0955 40.4004 50.3793C40.9199 49.3934 42.132 49.6246 42.878 50.1237C44.3965 51.0974 43.3309 52.0225 42.505 53.191Z"
                            fill="black"
                          />
                          <path
                            d="M49.2719 72.9214C49.2853 72.9214 49.2986 72.9214 49.3119 72.9214C49.192 73.0066 49.0721 73.104 48.9522 73.2013C49.0721 73.0918 49.1787 73.0066 49.2719 72.9214Z"
                            fill="black"
                          />
                          <path
                            d="M49.6585 72.3616C49.6985 72.4711 49.6985 72.5441 49.6985 72.6172C49.5653 72.7146 49.4454 72.8241 49.2989 72.9215C49.2856 72.9215 49.2722 72.9215 49.2589 72.9215C49.5653 72.6537 49.6719 72.4711 49.6585 72.3616Z"
                            fill="black"
                          />
                          <path
                            d="M49.272 72.9215C49.1787 73.0067 49.0721 73.0919 48.9389 73.2015C48.9123 73.0432 48.899 72.9094 48.9123 72.7998C49.0322 72.8485 49.1654 72.8972 49.272 72.9215Z"
                            fill="black"
                          />
                          <path
                            d="M48.9121 72.7874C48.9254 72.6778 48.9521 72.5926 49.0054 72.5196C49.0187 72.4953 49.0453 72.4831 49.0586 72.4587C49.1785 72.337 49.365 72.2883 49.6314 72.3248C49.6314 72.337 49.6447 72.337 49.6447 72.337C49.6447 72.3492 49.6447 72.3492 49.6447 72.3492C49.658 72.4587 49.5515 72.6413 49.2584 72.9091C49.1652 72.8969 49.032 72.8482 48.9121 72.7874Z"
                            fill="black"
                          />
                          <path
                            d="M49.0589 72.4587C49.0456 72.4831 49.019 72.4953 49.0057 72.5196C49.019 72.4953 49.0456 72.4709 49.0589 72.4587Z"
                            fill="black"
                          />
                          <path
                            d="M48.9123 72.7875C48.8989 72.7875 48.8989 72.7875 48.8856 72.7754C48.8989 72.678 48.9389 72.5806 49.0055 72.5076C48.9522 72.5928 48.9256 72.678 48.9123 72.7875Z"
                            fill="black"
                          />
                          <path
                            d="M48.9391 73.1892C48.8725 73.031 48.8725 72.8971 48.8859 72.7754C48.8992 72.7754 48.8992 72.7754 48.9125 72.7876C48.8992 72.9093 48.9125 73.0432 48.9391 73.1892Z"
                            fill="black"
                          />
                          <path
                            d="M49.6985 72.6172C49.6985 72.5441 49.6852 72.4711 49.6585 72.3616L49.6585 72.3494C49.6585 72.3372 49.6585 72.3372 49.6585 72.325C49.5919 72.1911 49.2856 72.2398 49.0857 72.4589C49.0591 72.4833 49.0458 72.4954 49.0325 72.5198C48.9792 72.5928 48.9392 72.678 48.9126 72.7876C48.3798 72.5076 47.847 71.8869 48.1 71.2539C48.5796 70.0611 48.7661 69.8542 50.0581 69.063C51.8431 70.6453 51.0971 71.5217 49.6985 72.6172Z"
                            fill="black"
                          />
                          <path
                            d="M49.2988 72.9215C49.432 72.8119 49.5652 72.7146 49.6984 72.6172C49.7118 72.8606 49.5519 72.958 49.2988 72.9215Z"
                            fill="black"
                          />
                          <path
                            d="M49.6451 72.325C49.3787 72.2885 49.1922 72.3371 49.0723 72.4589C49.2721 72.2398 49.5785 72.1789 49.6451 72.325Z"
                            fill="black"
                          />
                          <path
                            d="M39.1361 46.971C38.5233 47.2266 37.4177 47.1658 36.8982 46.8006C36.0324 46.192 36.7517 44.9627 38.3768 44.0376C36.7517 44.9505 40.0019 45.6078 38.3768 44.0376C40.0019 45.6199 40.2683 46.5085 39.1361 46.971Z"
                            fill="black"
                          />
                          <path
                            d="M38.3754 44.0498C36.7503 44.9627 40.0005 45.62 38.3754 44.0498V44.0498Z"
                            fill="black"
                          />
                          <path
                            d="M42.3991 61.054C42.439 61.2244 42.1992 61.553 41.8263 61.2244C42.146 60.9444 42.3325 60.9566 42.3991 61.054Z"
                            fill="black"
                          />
                          <path
                            d="M41.8263 61.2244C42.2925 61.6261 42.5189 61.2122 42.3991 61.054C42.3724 60.9323 42.1992 60.8958 41.8263 61.2244C45.9689 64.7664 46.3419 57.2807 41.8263 61.2244Z"
                            fill="black"
                          />
                          <path
                            d="M42.3991 61.054C42.5189 61.2244 42.2925 61.626 41.8263 61.2244C42.1992 61.553 42.439 61.2244 42.3991 61.054Z"
                            fill="black"
                          />
                          <path
                            d="M42.3991 61.0541C42.3325 60.9567 42.146 60.9445 41.8263 61.2245C42.1992 60.8959 42.3724 60.9324 42.3991 61.0541Z"
                            fill="black"
                          />
                          <path
                            d="M46.715 48.176C46.8348 48.2004 46.9414 48.2247 47.0879 48.2369C46.9414 48.2125 46.8215 48.1882 46.715 48.176Z"
                            fill="black"
                          />
                          <path
                            d="M47.0746 48.2247C46.9414 48.2125 46.8348 48.1882 46.7016 48.1638C45.2763 47.8717 46.8615 47.1049 47.0746 48.2247Z"
                            fill="black"
                          />
                          <path
                            d="M46.5279 45.5834C46.8609 46.2285 46.8609 47.0197 47.0874 48.2368C46.8743 47.1049 45.2891 47.8717 46.7144 48.176C41.9191 47.4335 45.9019 44.4027 46.5279 45.5834Z"
                            fill="black"
                          />
                          <path
                            d="M34.5667 51.2922C37.0043 52.3268 37.031 52.4486 35.3126 54.6273C32.875 53.4832 32.8617 53.4101 34.5667 51.2922Z"
                            fill="black"
                          />
                          <path
                            d="M49.4192 66.3C49.3926 65.8862 49.3926 65.6792 49.3926 65.6792C49.5524 65.6671 49.7389 65.8618 49.4192 66.3Z"
                            fill="black"
                          />
                          <path
                            d="M50.6973 64.4742C50.7906 64.8759 50.058 65.4358 49.4319 66.2878C49.7516 65.8618 49.5651 65.6549 49.4053 65.6671C49.2321 65.6792 49.0722 65.8861 49.4319 66.2878C46.5281 63.0866 50.391 63.0988 50.6973 64.4742Z"
                            fill="black"
                          />
                          <path
                            d="M49.3919 65.667C49.3919 65.667 49.4052 65.8739 49.4185 66.2878C49.0588 65.8983 49.2187 65.6792 49.3919 65.667Z"
                            fill="black"
                          />
                          <path
                            d="M42.3057 56.9521C42.4522 57.0495 42.6387 57.1104 42.8918 57.0252C42.4255 57.1834 42.279 57.0739 42.3057 56.9521Z"
                            fill="black"
                          />
                          <path
                            d="M42.8912 57.025C42.8113 56.9155 42.7314 56.8181 42.6514 56.7207C42.7314 56.7937 42.8113 56.8911 42.8912 57.025Z"
                            fill="black"
                          />
                          <path
                            d="M42.3048 56.9519C41.8786 56.6476 41.9585 55.8564 42.6645 56.7084C42.7444 56.8058 42.8243 56.9032 42.9042 57.0127C42.6245 56.6354 42.3315 56.7936 42.3048 56.9519Z"
                            fill="black"
                          />
                          <path
                            d="M42.3058 56.9521C42.3324 56.806 42.6255 56.6356 42.8919 57.0251C42.6388 57.1103 42.439 57.0494 42.3058 56.9521Z"
                            fill="black"
                          />
                          <path
                            d="M42.665 56.7087C41.959 55.8566 41.8791 56.6356 42.3054 56.9521C42.2921 57.0738 42.4253 57.1834 42.8915 57.0251C41.9591 57.3416 41.0133 57.6581 40.0809 57.9745C39.8145 55.8079 41.1998 54.8951 42.665 56.7087Z"
                            fill="black"
                          />
                          <path
                            d="M41.3457 78.1189C41.4256 77.7294 41.4789 77.5225 41.4789 77.5225C41.6254 77.5468 41.7453 77.7659 41.3457 78.1189Z"
                            fill="black"
                          />
                          <path
                            d="M41.4664 77.5223C41.4664 77.5223 41.4264 77.717 41.3332 78.1187C41.1067 77.6683 41.3065 77.4979 41.4664 77.5223Z"
                            fill="black"
                          />
                          <path
                            d="M41.3465 78.1189C41.7594 77.7781 41.6396 77.559 41.4797 77.5225C41.3065 77.4982 41.1067 77.6686 41.3465 78.1189C39.7081 75.0394 40.9869 74.9056 43.0116 76.7192C42.5853 77.0721 42.1591 77.4251 41.3465 78.1189Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4350_8717">
                            <rect
                              width="102"
                              height="50"
                              fill="white"
                              transform="matrix(4.81733e-08 1 1 -3.96627e-08 0 0)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </Box>

                    <Image
                      src={item?.photo?.url}
                      alt={item?.photo?.alt || ''}
                      title={item?.photo?.title || ''}
                      mt={{ base: '12px', md: '30px' }}
                      w={{ base: '101px', md: '200px', lg: '213px' }}
                      h={{ base: '131px', md: '260px', lg: '284px' }}
                      pos="absolute"
                    />

                    <Flex
                      bg="linear-gradient(180deg, #FAF6ED 0%, #FFF 100%)"
                      flexDirection="column"
                      m={{
                        base: '0 16px 0 50px',
                        md: '0 0px 0 80px',
                        lg: '0 0px 0 90px',
                      }}
                      p={{ base: '0 20px 0 20px', md: '0 20px 0 140px' }}
                      flexGrow="1"
                      w="full"
                      boxShadow="0 12px 14px -15px #6D6358"
                      minH={{
                        base: '430px',
                        md: '400px',
                        xl: '390px',
                        '2xl': '400px',
                      }}
                    >
                      <Box
                        ml={{ base: '50px', md: 0 }}
                        h="auto"
                        dir={lang == 'ar' ? 'rtl' : 'ltr'}
                      >
                        <Text
                          pt={{ base: '30px', md: '40px', lg: '40px' }}
                          {...StoriesSt[lang].description}
                          color="#433F3C"
                          wordWrap="break-word"
                          wordSpacing="normal"
                          wordBreak="break-word"
                        >
                          {item?.userName}
                        </Text>

                        {/* <StructuredTextParser
                        str={render(item?.testimonialTitle)}
                        color="#6D6358"
                        className="lines-2"
                        wordWrap="break-word"
                        wordSpacing="normal"
                        wordBreak="break-word"
                        {...StoriesSt[lang].p}
                      /> */}

                        <Box
                          color="#6D6358"
                          className="lines-2"
                          wordWrap="break-word"
                          wordSpacing="normal"
                          wordBreak="break-word"
                          {...StoriesSt[lang].p}
                        >
                          {parser(render(testimonialTitle))}
                        </Box>

                        {/* <NextLink
                        href="#"
                        //href={disabled ? '#' : currentUrl}
                        passHref
                        legacyBehavior
                      > */}
                        <Box
                          as="span"
                          color="#E86F34"
                          _hover={{
                            color: '#CE6113',
                            textDecoration: 'underline',
                          }}
                          {...StoriesSt[lang].linkReadMore}
                          cursor="pointer"
                          onClick={() => {
                            setActiveCard(index);
                          }}
                        >
                          <Box as="span" onClick={onOpen}>
                            {/* Read More */}
                            {item?.testimonialTitle?.value?.document
                              ?.children?.[0]?.children?.[1]?.children?.[0]
                              ?.value ||
                              item?.testimonialTitle?.value?.document
                                ?.children?.[0]?.children?.[2]?.children?.[0]
                                ?.value}
                          </Box>
                        </Box>
                        {/* </NextLink> */}
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          size="2xl"
                          isCentered={true}
                          h={{ md: '100%', base: 'auto' }}
                          dir={lang == 'ar' ? 'rtl' : 'ltr'}
                        >
                          <ModalOverlay bg="blackAlpha.400" />
                          <ModalContent
                            background="none"
                            my={{ base: '0', md: '0', lg: '3.75rem' }}
                            border="none"
                            boxShadow="none"
                          >
                            <ModalBody
                              p={{
                                base: '25px 0 0 0',
                                md: '0 0 0 40px',
                                lg: '0 0 0 10px',
                                xl: '0px',
                              }}
                            >
                              <Center
                                pb={{ base: '0%', md: '10%' }}
                                w={{ base: '100%', md: '632px', lg: '694px' }}
                                mx="auto"
                                height="100%"
                                m={{ base: '15% 0 0 0', md: '10% 0 0' }}
                                pos="relative"
                                bg="#F1EBDF"
                                maxH={{
                                  base: '100%',
                                  md: '446px',
                                  lg: '424px',
                                }}
                              >
                                <Box
                                  position="absolute"
                                  top={{
                                    base: '17px',
                                    md: '21px',
                                    lg: '21px',
                                  }}
                                  right={{
                                    base: '22px',
                                    md: '14px',
                                    lg: '15px',
                                  }}
                                  onClick={onClose}
                                  w="auto"
                                  height="auto"
                                  cursor="pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M19 20.4143L12 13.4143L4.99997 20.4143L3.58576 19.0001L10.5858 12.0001L3.58576 5.00009L4.99997 3.58588L12 10.5859L19 3.58588L20.4142 5.00009L13.4142 12.0001L20.4142 19.0001L19 20.4143Z"
                                      fill="#E86F34"
                                    />
                                  </svg>
                                </Box>
                                <Flex
                                  h="100%"
                                  w={{ base: '100%', md: '90%', lg: '100%' }}
                                  flexDir={{ base: 'column', md: 'row' }}
                                  pl={{ base: '35px', md: '0' }}
                                  pr={{ base: '23px', md: '0' }}
                                  mb={{ base: '30px', md: '0' }}
                                  // pt={{ base: '20px', md: '30px' }}
                                >
                                  <Box
                                    w={{
                                      base: '167px',
                                      md: '239px',
                                      lg: '239px',
                                    }}
                                    maxW="none"
                                  >
                                    <Image
                                      src={
                                        sectionContent?.[0]?.testimonials[
                                          activeCard
                                        ]?.photo?.url
                                      }
                                      alt={
                                        sectionContent?.[0]?.testimonials[
                                          activeCard
                                        ]?.photo?.alt || ''
                                      }
                                      mt={{
                                        base: '-64px',
                                        md: '80px',
                                        lg: '90px',
                                        xl: '100px',
                                      }}
                                      ml={{ base: '10px', md: '-55px' }}
                                      h={{ base: 'auto', md: '302px' }}
                                      w={{ base: '138.58px', md: '226.5px' }}
                                      maxW="none"
                                    />
                                  </Box>
                                  <Box
                                    p={{
                                      base: '0px',
                                      md: '20px 20px 0 20px',
                                      lg: '20px',
                                    }}
                                  >
                                    <Box
                                      mt={{
                                        base: '20px',
                                        md: '60px',
                                        lg: '80px',
                                      }}
                                      w="100%"
                                      maxH={330}
                                      h="100%"
                                      overflowY="auto"
                                      className="customScrollbar"
                                    >
                                      <StructuredTextParser
                                        str={render(
                                          sectionContent?.[0]?.testimonials[
                                            activeCard
                                          ].testimonialText.value
                                        )}
                                        px={{ base: '10px', md: '30px' }}
                                        mt="0px"
                                        className="textp"
                                        {...StoriesSt[lang].modalDes}
                                        color="#423E3B"
                                        dir={lang == 'ar' ? 'rtl' : 'ltr'}
                                      />

                                      <StructuredTextParser
                                        str={render(
                                          sectionContent?.[0]?.testimonials[
                                            activeCard
                                          ].userDescription
                                        )}
                                        px={{ base: '10px', md: '30px' }}
                                        {...StoriesSt[lang].modalName}
                                        color="#6D6358"
                                      />
                                      <Flex
                                        flexDir={{ base: 'column', sm: 'row' }}
                                        display={
                                          lang === ' ar' ? 'initial' : 'block'
                                        }
                                      >
                                        <StructuredTextParser
                                          mt={{ base: '7px', md: '12px' }}
                                          pl={{ base: '10px', md: '30px' }}
                                          pr="5px"
                                          maxWidth="auto"
                                          str={render(
                                            sectionContent?.[0]?.testimonials[
                                              activeCard
                                            ].details
                                          )}
                                          {...StoriesSt[lang].modalDetail}
                                          color="#12166F"
                                        />

                                        <StructuredTextParser
                                          mt={{ base: '7px', md: '12px' }}
                                          pl={{ base: '10px', md: '30px' }}
                                          pr="5px"
                                          str={render(
                                            sectionContent?.[0]?.testimonials[
                                              activeCard
                                            ]?.additionalDetails
                                          )}
                                          {...StoriesSt[lang].modalDetail}
                                          maxWidth="auto"
                                          color="#12166F"
                                        />
                                      </Flex>
                                    </Box>
                                  </Box>
                                </Flex>
                              </Center>
                            </ModalBody>
                          </ModalContent>
                        </Modal>
                        <StructuredTextParser
                          str={render(item?.userDescription)}
                          {...StoriesSt[lang].userDescription}
                          maxW="auto"
                          color="#6D6358"
                        />

                        <StructuredTextParser
                          mt="0px"
                          str={render(item?.details)}
                          {...StoriesSt[lang].details}
                          maxW="auto"
                          color="#12166F"
                        />
                        <StructuredTextParser
                          mt="0px"
                          str={render(item?.additionalDetails)}
                          {...StoriesSt[lang].additionalDetails}
                          maxW="auto"
                          color="#12166F"
                        />
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
        <style jsx global>
          {`
            .textp p {
              padding-bottom: 20px;
            }
            .lines-2 {
              height: 50px;
              overflow: hidden;
            }
            .lines-2 p {
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 50px;
            }
          `}
        </style>
      </Flex>
    </Box>
  );
};

export default Stories;
