/* Built In Imports */

/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SingleLineText from '@components/UI/SingleLineText';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { ResearchIntroSt } from '@ieo/Common/CompLangConfig';

/* Services */

import { searchByFieldId } from '@components/Utility/Shared/SharedService';
/* Styles */
/* Services */

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} Research Intro
 */
const IeoPoweredBy = ({ sectionContent, region, lang, pageData }) => {
  function findField(fieldId) {
    const data = sectionContent.find(el => el.fieldId === fieldId);
    return data;
  }
  console.log('fdddssss', searchByFieldId(sectionContent, 'sza-offer-text'));
  return (
    <Box bg="#F1EBDF" p={{ base: '10px 15px', md: '10px 0px 50px 0px' }}>
      <Box w="100%" h="100%" maxW="1130px" mx="auto" fontSize="70px">
        <Box maxW="1080px" w="100%" mx="auto">
          <SingleLineText
            sectionContent={findField('research-shows-title')}
            mt="0px"
            mb={{ base: '6px', md: '10px' }}
            px={{ base: '10px', md: '20px' }}
            {...ResearchIntroSt[lang]?.ReInHeading}
            textAlign={{ base: 'center', md: 'left' }}
            maxW="1080px"
          />
          <Box
            display="flex"
            flexDir={{ base: 'column-reverse', md: 'row' }}
            gap={{ base: '20px', md: '45px' }}
            px={{ base: '10px', md: '20px' }}
            justifyContent="space-between"
          >
            <Box maxW={{ base: 'auto', lg: '500px' }} w="full">
              {searchByFieldId(sectionContent, 'sza-offer-text') && (
                <StructuredTextParser
                  str={render(
                    searchByFieldId(sectionContent, 'sza-offer-text').body
                  )}
                  region={region}
                  lang={lang}
                  mt="0px"
                  fontFamily="FedraSansStd-book"
                  fontSize={{ base: '18px', lg: '22px' }}
                  className="textPadBottom"
                />
              )}
            </Box>
            <Box w="full">
              <Image
                src={sectionContent[2]?.image?.url}
                w="477px"
                // h="100%"
                objectFit="cover"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <style>{`
        .textPadBottom p {
          padding-bottom: 15px;
        }
      `}</style>
    </Box>
  );
};

export default IeoPoweredBy;
